import Api from "../Api";

export const createAdmission = (data) => {
  return Api.post(`/create-admissionforms`, data);
};
export const getAllexamlocks = () => {
    return Api.get('/get-all-exam-report-lock', {
    });
  };
  export const getAllexamtypes = () => {
    return Api.get('/get-exams');
  };
  export const getAdmissionById = (id) => {
    return Api.get(`/get-admissionform/${id}`);
  };
  export const updateAdmissionById = (id, data) => {
    return Api.post(`/update-admissionform/${id}`,data);
  };
  export const deleteAnnouncementById = (id) => {
    return Api.delete(`/delete-admission-announcement/${id}`);
  };
  export const getClassByBranchId = async (branchId) => {
    console.log(branchId)
    const id=branchId
    return await Api.get(`/get-classes-branch/${id}`);
  };
  export const getAllAdmissions = async (branchId) => {
    return await Api.get('get-admissionforms', {
      params: { branch_id: branchId }
    });
  };
  
  