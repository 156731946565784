import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../../Styles/Reciept.css";

import {
  createEnquiry,
  getAllEnquirys,
  getEnquiryById,
  deleteEnquiryById,
  updateEnquiryById,
} from "../../Services/CommonApis/EnquiryApi";
import {
  getAllAnnouncements,
  getAnnouncementById,
} from "../../Services/CommonApis/AnnouncementApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import FeeReceipt from "../../Components/CommonComponents/FeeReciept";
import "../../Styles/AdmissionAdmitcard.css";

const AdmissionEnquiry = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(null);
  const [classes, setClasses] = useState([]);
  const [language, setLanguage] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState("");
  const [formAnnouncements, setFormAnnouncements] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [data, setData] = useState({});
  const [showDropdown, setShowDropdown] = useState(true);
  const receiptRef = useRef();
  const [modalDetails, setModalDetails] = useState({
    appNotification: "Dear Parent ,Your Child",
    venue: "",
    hallNo: "",
    additionalComments: "",
    admissionfee: "",
    interviewDate: "",
    notificationcontent: "",
  });
  const Navigate = useNavigate();

  const { schoolId, branchId } = useParams();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handlemodalSubmit = () => {
    console.log("Modal Type:", modalType);
    console.log("Modal Data:", modalDetails);
    console.log("Selected Rows:", selectedRows);

    setShowModal(false);
  };

  const handleSubmit = async (values) => {
    let response;
    const id = values.id;
    try {
      const formattedValues = {
        ...values,
        dob: formatDate(values.dob),
        assesment_date: formatDate(values.assesment_date),
      };
      if (formData) {
        response = await updateEnquiryById(id, formattedValues);
      } else {
        response = await createEnquiry(formattedValues);
      }
      if (response.status === 200) {
        setShowForm(false);
        setShowDropdown(true);
        tableInstance.current.ajax.reload(null, false);
        setApiMessage(response.data.message || response.message);
        showSuccessAlert("Success", apiMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getAllLanguages();
      setLanguage(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const buttonStyles = {
    display: "inline-flex",
    justifyContent: "center", // Center text horizontally
    alignItems: "center", // Center text vertically
    marginRight: "10px", // Spacing between buttons
    flex: "none", // Remove flex-grow to prevent stretching
    width: "120px", // Adjust width to desired size
    height: "40px", // Adjust height to match the button height
    textAlign: "center", // Center text horizontally
    border: "1px solid transparent", // Ensure border doesn't affect button width
    borderRadius: "4px", // Rounded corners
    lineHeight: "40px", // Vertically center text
  };

  const primaryStyle = {
    ...buttonStyles,
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    color: "#fff",
  };

  const warningStyle = {
    ...buttonStyles,
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
    color: "#212529",
  };

  const dangerStyle = {
    ...buttonStyles,
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#fff",
  };

  const handlePrintReceipt = (data) => {
    try {
      const receiptContainer = document.createElement("div");
      receiptContainer.className = "fee-container";
      receiptContainer.innerHTML = `
        <div class="fee-header">
          <img id="school-logo" src="admit.png" alt="School Logo" />
          <div class="fee-header-text">
            <h1>DE PAUL SCHOOL</h1>
            <p>UKKUNAGARAM, VISAKHAPATNAM</p>
            <p>Email: depaul45@gmail.com</p>
            <p>Ph: 9492172721 / 9492157271</p>
            <p>Website: www.depaulsvizag.com</p>
          </div>
        </div>
        <div class="fee-receipt-title">FEE RECEIPT</div>
        <div class="details">
          <div class="details-row">
            <p><strong>Name:</strong> ${data.name}</p>
            <p class="date"><strong>Date:</strong> ${new Date().toLocaleDateString()}</p>
          </div>
          <p><strong>F Name:</strong> ${data.father_name}</p>
          <p><strong>Rec No:</strong> ${data.application_no}</p>
          <p><strong>Payment Mode:</strong> ${
            data.payment_mode === "1" ? "Cash" : "Online"
          }</p>
          <p><strong>Student Class:</strong> ${data.class_applied}</p>
          <p><strong>Mobile No:</strong> ${data.contact_no}</p>
        </div>
        <table class="receipt-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Head of Account</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Sample Item</td>
              <td>${data.application_fee}</td>
            </tr>
            <tr>
              <td colSpan="2"><strong>Total Amount</strong></td>
              <td><strong>${data.application_fee}</strong></td>
            </tr>
          </tbody>
        </table>
        <div class="signature">Signature</div>
      `;

      // Append the receipt container to the document
      document.body.appendChild(receiptContainer);

      html2canvas(receiptContainer).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const doc = new jsPDF();

        const imgWidth = 190;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

        const pdfOutput = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfOutput);
        window.open(pdfUrl);

        // Delay removal slightly to ensure everything is completed
        setTimeout(() => {
          if (document.body.contains(receiptContainer)) {
            document.body.removeChild(receiptContainer);
          }
        }, 100); // 100ms delay for safety
      });
    } catch (error) {
      console.error("Error generating receipt:", error);
    }
  };

  const handlePrintAdmitCard = (data) => {
    try {
      const admitCardContainer = document.createElement("div");
      admitCardContainer.className = "admit-card-container";
      admitCardContainer.innerHTML = `
        <div class="admit-header">
          <img src="admit.png" alt="School Logo" class="admit-logo" />
          <div className="header-text ">
            <h1>DE PAUL SCHOOL</h1>
            <p>UKKUNAGARAM, VISAKHAPATNAM</p>
            <p>Email: depaul45@gmail.com</p>
            <p>Phone: 9492172721 / 9492157271</p>
            <p>Website: www.depaulvisakha.com</p>
          </div>
        </div>
        <h2 class="heading-h2">COUNSELLING AND ASSESSMENT ADMIT CARD</h2>
        <div class="admit-info-container">
          <div class="left-info">
            <p><strong>ADMIT CARD NO:</strong> ${data.admitCardNo}</p>
            <p><strong>ROOM NO:</strong> <span class="underline">${data.roomNo}</span></p>
          </div>
          <div class="right-info">
            <p><strong>TOKEN NO:</strong> <span class="underline">${data.tokenNo}</span></p>
            <p><strong>SL NO:</strong> <span class="underline">${data.slNo}</span></p>
          </div>
        </div>
        <table class="candidate-info" style="width:100%; border-collapse: collapse;">
          <tr>
            <td style="border: 1px solid black; padding: 8px;"><strong>NAME OF THE CANDIDATE</strong></td>
            <td style="border: 1px solid black; padding: 8px;">${data.candidateName}</td>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 8px;"><strong>CLASS</strong></td>
            <td style="border: 1px solid black; padding: 8px;">${data.class}</td>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 8px;"><strong>II LANGUAGE</strong></td>
            <td style="border: 1px solid black; padding: 8px;">${data.secondLanguage}</td>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 8px;"><strong>DATE OF ASSESSMENT</strong></td>
            <td style="border: 1px solid black; padding: 8px;">${data.assessmentDate}</td>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 8px;"><strong>CONTACT NO.</strong></td>
            <td style="border: 1px solid black; padding: 8px;">${data.contactNo}</td>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 8px;"><strong>FATHER NAME</strong></td>
            <td style="border: 1px solid black; padding: 8px;">${data.fatherName}</td>
          </tr>
        </table>
        <p class="signature">Signature of In-Charge</p>
        <h3 class="heading-h3">INSTRUCTIONS</h3>
        <ol class="instructions">
          <li>Retain the COUNSELLING AND ASSESSMENT ADMIT CARD for further official reference.</li>
          <li>Bring pen/pencil, eraser, writing pad, water bottle, and sanitizer.</li>
          <li>Reporting time - 08:30 AM to 09:00 AM.</li>
          <li>Pick up time - 12:00 AM to 1:30 PM.</li>
          <li>Collect the Child token during Reporting time and submit it at the time of pick-up.</li>
          <li>Only ONE person will be allowed into the campus along with the candidate.</li>
          <li>NO MASK - NO ENTRY. Follow COVID protocol strictly.</li>
          <li>Parents are requested to carry their own water bottles.</li>
          <li>Candidates are allowed only on the possession of Admit Card.</li>
          <li>Vehicles to be parked as allotted. No entry of vehicles into the campus.</li>
        </ol>
        <p class="admit-footer">KYCADMIN</p>
      `;

      // Append the admit card container to the body
      document.body.appendChild(admitCardContainer);

      // Use html2canvas to take a snapshot of the admit card
      html2canvas(admitCardContainer).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const doc = new jsPDF();

        const imgWidth = 190;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

        const pdfOutput = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfOutput);
        window.open(pdfUrl);

        // Remove the admit card container from the document
        document.body.removeChild(admitCardContainer);
      });
    } catch (error) {
      console.error("Error generating admit card:", error);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    announcement_id: Yup.string().required("Announcement name is required"),
    application_fee: Yup.number().required("Application fee is required"),
    application_no: Yup.string().required("Application number is required"),
    class_applied: Yup.string().required("Class applied is required"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid Gmail address"
      )
      .required("Email is required"),
    contact_no: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid Mobile Number")
      .min(10, "Mobile number must be at least 10 digits")
      .required("Mobile number is required"),
    father_name: Yup.string().required("Father's name is required"),
    comments: Yup.string(),
    dob: Yup.date()
      .max(new Date(), "Please enter a valid Date of Birth")
      .required("Date of Birth is required"),
    payment_mode: Yup.string().required("Payment mode is required"),
  });
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  const { id } = useParams();
  const fetchEnquiryById = async (id) => {
    try {
      const response = await getEnquiryById(id);
      setData(response.data.data);
      setFormData(response.data.data);
      setShowForm(true);
      setShowDropdown(false);
    } catch (error) {
      console.error("Error fetching enquiry by ID:", error);
    }
  };
  const fetchEnquiryfeeById = async (id) => {
    try {
      const response = await getEnquiryById(id);
      setData(response.data.data);
      handlePrintReceipt(response.data.data);
    } catch (error) {
      console.error("Error fetching enquiry by ID:", error);
    }
  };
  const fetchEnquiryadmitById = async (id) => {
    try {
      const response = await getEnquiryById(id);
      setData(response.data.data);
      handlePrintAdmitCard(response.data.data);
    } catch (error) {
      console.error("Error fetching enquiry by ID:", error);
    }
  };
  const deleteEnquiry = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete this enquiry!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteEnquiryById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The enquiry has been deleted.");

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the enquiry.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The enquiry is safe 😊");
    }
  };

  console.log(selectedAnnouncementId);
  const initialValues = {
    announcement_id: "",
  };
  const announcmentvalidationSchema = Yup.object({
    announcement_id: Yup.string().required("Announcement is required"),
  });

  const handleAnnouncementChange = (event, setFieldValue) => {
    const value = event.target.value;
    setFieldValue("announcement_id", value); // Update Formik field value
    setSelectedAnnouncementId(value); // Update local state
    console.log("Selected announcement ID:", value);
    // Call your API here using the value
    // Example: fetchAnnouncementData(value);
  };
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await getAllAnnouncements(branchId, {});
        setAnnouncements(response.data.data);
        setFormAnnouncements(response.data.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, [branchId]);
  useEffect(() => {
    getclassbybranchID(branchId);
  }, []);
  const getclassbybranchID = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRowSelection = () => {
    const selected = $(".select-row:checked")
      .map(function () {
        return $(this).data("id");
      })
      .get();
    setSelectedRows(selected);
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },

        { data: "application_no" },
        { data: "application_fee" },
        {
          data: null,
          render: (data, type, row) => {
            const studentFullName = row.name.split(" ");
            const firstName = studentFullName[0]; 
            const surname = studentFullName[studentFullName.length - 1]; 
            return `${firstName} ${row.father_name} ${surname}`;
          },
        },
        
        { data: "contact_no" },
        { data: "class_name" },
        { data: "dob" },
        { data: "email" },
        { data: "assesment_date" },
        { data: "payment_mode" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
              <div style="display: flex; gap: 10px;">
                <a title="Print Receipt" data-id="${row.id}" class="print-receipt-btn">
                  <i class="fas fa-id-card-clip"></i>
                </a>
                <a title="Print Admit card" data-id="${row.id}" class="print-admit-btn">
                  <i class="fas fa-print"></i>
                </a>
              </div>
            `;
          },
        },        
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const editIcon = `
        <a title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
      `;
            const deleteIcon = `
          <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
        `;
            return `
          <div class="icon-group" style="display: flex; gap: 10px;">
            ${editIcon}
            ${deleteIcon}
          </div>
        `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllEnquirys(selectedAnnouncementId, {
            page: page,
            limit: length,
            sortBy: orderColumn,
            sortOrder: orderDirection,
            search: searchValue,
          });

          const jsonData = response.data.data;
          const totalCount = response.data.total;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });
    $("#select-all")
      .off("click")
      .on("click", function () {
        const isChecked = $(this).is(":checked");
        $(".select-row").prop("checked", isChecked);
        handleRowSelection();
      });

    $(tableRef)
      .off("click", ".select-row")
      .on("click", ".select-row", function () {
        handleRowSelection();

        const totalCheckboxes = $(".select-row").length;
        const checkedCheckboxes = $(".select-row:checked").length;
        $("#select-all").prop("checked", totalCheckboxes === checkedCheckboxes);
      });
    $(tableRef.current).on("click", ".print-receipt-btn", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      fetchEnquiryfeeById(id);
    });

    $(tableRef.current).on("click", ".print-admit-btn", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      fetchEnquiryadmitById(id);
    });
    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      fetchEnquiryById(id);
      setShowForm(true);
      setShowDropdown((prev) => prev);
    });
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      deleteEnquiry(id);
    });

    return () => {
      tableInstance.current.destroy();
    };
  }, [selectedAnnouncementId]);
  const handleFeeAnnouncementChange = async (event, setFieldValue) => {
    const announcementId = event.target.value;

    if (announcementId) {
      try {
        const response = await getAnnouncementById(announcementId);
        const { application_fee } = response.data.data;

        setFieldValue("application_fee", application_fee);

        console.log("Fetched Announcement Data:", response.data);
      } catch (error) {
        console.error("Error fetching announcement details:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const generateApplicationNumber = (setFieldValue) => {
    const randomNumber = Math.floor(1000 + Math.random() * 1000);
    const currentYear = new Date().getFullYear();
    const num = `${randomNumber}`;

    setFieldValue("application_no", num);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setFormData(null);
                  setShowForm((prev) => !prev);
                  setShowDropdown((prev) => !prev);
                }}
              >
                {showForm ? (
                  <>
                    <i className="pi pi-arrow-left mr-2"></i> Back
                  </>
                ) : (
                  "+ Add Enquiry"
                )}
              </button>
            </div>
            {showDropdown && (
              <div className="col-6">
                <Formik
                  initialValues={{ announcement_id: "" }}
                  validationSchema={announcmentvalidationSchema}
                  onSubmit={() => {}}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <div className="form-group row">
                      <label
                        htmlFor="announcement_id"
                        className="col-md-3 col-form-label mb-0"
                        style={{ paddingRight: "5px" }} // Reduce the padding between the label and input
                      >
                        Announcement <span className="mandatory">*</span>
                      </label>
                      <div className="col-md-9 pl-0">
                        <Field
                          name="announcement_id"
                          as="select"
                          className="form-control"
                          style={{ borderColor: "skyblue", marginLeft: "-5px" }} // Reduce left margin to bring input closer
                          onChange={(e) =>
                            handleAnnouncementChange(e, setFieldValue)
                          }
                        >
                          <option value="">Select Announcement</option>
                          {announcements.map((announcement) => (
                            <option
                              key={announcement.id}
                              value={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
        <div id="AdmissionEnquiry" className="tab-pane fade show active">
          {showForm && (
            <div className="card">
              <div className="mb-3 p-4">
                <h5>Admission Form</h5>
                <Formik
                  initialValues={
                    formData || {
                      name: "",
                      announcement_id: "",
                      application_fee: "",
                      application_no: "",
                      class_applied: "",
                      email: "",
                      contact_no: "",
                      father_name: "",
                      comments: "",
                      dob: "",
                      second_language: "",
                      payment_mode: "",
                      assesment_date: "",
                      course_type: [],
                      applicationStatus: "",
                    }
                  }
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form>
                      <div className="row ml-3 mr-5 mt-3">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                              Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="announcement_id"
                              className="form-label"
                            >
                              Select Announcement{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              name="announcement_id"
                              as="select"
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                              onChange={(event) => {
                                setFieldValue(
                                  "announcement_id",
                                  event.target.value
                                ); // Update form field
                                handleFeeAnnouncementChange(
                                  event,
                                  setFieldValue
                                ); // Fetch announcement details
                                generateApplicationNumber(setFieldValue);
                              }}
                            >
                              <option value="">Select Announcement</option>
                              {announcements.map((announcement) => (
                                <option
                                  key={announcement.id}
                                  value={announcement.id}
                                >
                                  {announcement.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="announcement_id"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="application_fee"
                              className="form-label"
                            >
                              Application Fee{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="number"
                              name="application_fee"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="application_fee"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="application_no"
                              className="form-label"
                            >
                              Application Number{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="application_no"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="application_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="class_applied"
                              className="form-label"
                            >
                              Class Applied <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="class_applied"
                              className="form-control"
                            >
                              <option value="">Select Class</option>
                              {classes.map((cls) => (
                                <option key={cls.id} value={cls.id}>
                                  {cls.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="class_applied"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="contact_no" className="form-label">
                              Mobile Number <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="contact_no"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="contact_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="father_name" className="form-label">
                              Father's Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="father_name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="father_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="comments" className="form-label">
                              Comments
                            </label>
                            <Field
                              as="textarea"
                              name="comments"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="dob" className="form-label">
                              Date of Birth <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="date"
                              name="dob"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="second_language"
                              className="form-label"
                            >
                              Second Language{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              name="second_language"
                              as="select"
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            >
                              <option value="">Select Announcement</option>
                              {language.map((lang) => (
                                <option key={lang.id} value={lang.id}>
                                  {lang.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="second_language"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="payment_mode"
                              className="form-label"
                            >
                              Payment Mode <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="payment_mode"
                              className="form-control"
                            >
                              <option value="">Select Payment Mode</option>
                              <option value="creditCard">Credit Card</option>
                              <option value="debitCard">Debit Card</option>
                              <option value="cash">Cash</option>
                            </Field>
                            <ErrorMessage
                              name="payment_mode"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="course_type">
                              Course Type <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="course_type"
                              className="form-control"
                            >
                              <option value="">Select</option>
                              <option value="1">General</option>
                              <option value="2">Special</option>
                            </Field>
                            <ErrorMessage
                              name="course_type"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="assesment_date">
                              Assessment Date{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="date"
                              name="assesment_date"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="assesment_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {formData && formData.id && (
                          <div className="col-4">
                            <div className="form-group">
                              <label
                                htmlFor="applicationStatus"
                                className="form-label"
                              >
                                Application Status
                              </label>
                              <Field
                                as="select"
                                name="applicationStatus"
                                className="form-control"
                              >
                                <option value="">Select Status</option>
                                <option value="Submitted">Selected</option>
                                <option value="hold">On Hold</option>
                                <option value="rejected">Rejected</option>
                              </Field>
                              <ErrorMessage
                                name="applicationStatus"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
          <div className="card-body">
            <table
              ref={tableRef}
              id="announcementTable"
              className="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>App No</th>
                  <th>Fee</th>
                  <th>Full Name</th>
                  <th>Contact</th>
                  <th>Class</th>
                  <th>DOB</th>
                  <th>email</th>
                  <th>Exam Date</th>
                  <th>Payment</th>
                  <th>Print</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th>ID</th>
                  <th>App No</th>
                  <th>Fee</th>
                  <th>Full Name</th>
                  <th>Contact</th>
                  <th>Class</th>
                  <th>DOB</th>
                  <th>email</th>
                  <th>Exam Date</th>
                  <th>Payment</th>
                  <th>Print</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
            </table>
          </div>

          {showModal && (
            <div
              className="modal fade show"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {modalType === "reschedule" || modalType === "schedule"
                        ? "Reschedule"
                        : modalType.charAt(0).toUpperCase() +
                          modalType.slice(1)}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setShowModal(false)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {(modalType === "reschedule" ||
                      modalType === "schedule") && (
                      <>
                        <div className="form-group">
                          <label htmlFor="appNotification">
                            Application Notification
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="appNotification"
                            name="appNotification"
                            value={modalDetails.appNotification}
                            onChange={handleInputChange}
                            placeholder="Enter application notification"
                          />
                        </div>
                        {modalType === "schedule" && (
                          <>
                            <div className="form-group">
                              <label htmlFor="venue">Venue</label>
                              <input
                                type="text"
                                className="form-control"
                                id="venue"
                                name="venue"
                                value={modalDetails.venue}
                                onChange={handleInputChange}
                                placeholder="Enter venue"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="interviewDate">
                                Interview Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="interviewDate"
                                name="interviewDate"
                                value={modalDetails.interviewDate}
                                onChange={handleInputChange}
                                placeholder="Select interview date"
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="additionalComments">
                                Additional Comments
                              </label>
                              <textarea
                                className="form-control"
                                id="additionalComments"
                                name="additionalComments"
                                value={modalDetails.additionalComments}
                                onChange={handleInputChange}
                                placeholder="Enter additional comments"
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {(modalType === "reject" || modalType === "hold") && (
                      <div className="form-group">
                        <label htmlFor="appNotification">
                          Application Notification
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="appNotification"
                          name="appNotification"
                          value={modalDetails.appNotification}
                          onChange={handleInputChange}
                          placeholder="Enter application notification"
                        />
                      </div>
                    )}
                    {modalType === "rejectselect" && (
                      <div>
                        <div className="form-group">
                          <label htmlFor="admissionfee">
                            Admission Fee Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="admissionfee"
                            name="admissionfee"
                            value={modalDetails.admissionfee}
                            onChange={handleInputChange}
                            placeholder="Select fee date"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="additionalComments">
                            Additional Comments
                          </label>
                          <textarea
                            className="form-control"
                            id="additionalComments"
                            name="additionalComments"
                            value={modalDetails.additionalComments}
                            onChange={handleInputChange}
                            placeholder="Enter additional comments"
                          />
                        </div>
                      </div>
                    )}

                    {(modalType === "rejectselect" ||
                      modalType === "rejecthold") && (
                      <div className="form-group">
                        <label htmlFor="notificationcontent">
                          Notification Center
                        </label>
                        <textarea
                          className="form-control"
                          id="notificationcontent"
                          name="notificationcontent"
                          value={modalDetails.notificationcontent}
                          onChange={handleInputChange}
                          placeholder="Enter additional comments"
                        />
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handlemodalSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdmissionEnquiry;
