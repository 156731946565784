import React, { useState } from "react";
import SchoolUpload from "./SchoolUpload";
import SchoolGrid from "./SchoolGrid";
import HomeWorkCreateTab from "./HomeCreateTab";
import Homeworkreport from "./Homeworkreport";
function HomeworkManagement() {
  const [activeTab, setActiveTab] = useState("listview");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      {/* <section class="content"> */}
      {/* <div class="container-fluid"> */}
      {/* <div class="row"> */}
      {/* <div class="col-md-12"> */}
      {/* <div class="card"> */}
      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "listview" ? "active" : ""}`}
              onClick={() => handleTabClick("listview")}
            >
              List View
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "gridview" ? "active" : ""}`}
              onClick={() => handleTabClick("gridview")}
            >
              Grid View
            </a>
          </li>

          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createhomework" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createhomework")}
            >
              Create Homework
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div
            className={`tab-pane ${activeTab === "listview" ? "active" : ""}`}
            id="listview"
          >
            {activeTab === "listview" && <Homeworkreport />}
          </div>
          <div
            className={`tab-pane ${activeTab === "gridview" ? "active" : ""}`}
            id="gridview"
          >
            {activeTab === "gridview" && <SchoolGrid />}
          </div>
          <div
            className={`tab-pane ${
              activeTab === "createhomework" ? "active" : ""
            }`}
            id="createhomework"
          >
            {activeTab === "createhomework" && <HomeWorkCreateTab />}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
    </>
  );
}

export default HomeworkManagement;
