import React, { useEffect, useState } from "react";
// import "../../Styles/Attendance.css";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import {
  createAttendance,
  getAttendanceByDate,
  getConsolidatedAttendance,
  getDaywiseAttendance,
} from "../../Services/AdminApis/AttendanceApi";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import BeatLoader from "react-spinners/BeatLoader";
import { Checkbox } from "primereact/checkbox";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
} from "../../Modals/SweetAlertModel";
import { COOKIE } from "../../Schemas/cookieNames";
import IndividualStudentAttendance from "./IndividualStudentAttendance";

import { setSession, getSession, removeSession } from "../../Services/Storage";
import { getUserEmail } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import { useParams } from "react-router-dom";
import { CreateHoliday } from "../../Services/CommonApis/ParentPortalApi";

const AttendanceCreate = () => {
  const { branchId } = useParams();
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [activeTab, setActiveTab] = useState("home");
  const [profileActiveTab, setProfileActiveTab] = useState("dayView");
  const [classes, setClasses] = useState([]);
  const [disabledClasses, setDisabledClasses] = useState(new Set());
  const id = "1";
  const [studentsByClass, setStudentsByClass] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [sections, setSections] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [processedData, setProcessedData] = useState([]);
  const [dates, setDates] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [absenteesData, setAbsenteesData] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [message, setMessage] = useState("");
  const [verifiedMessage, setVerifiedMessage] = useState("");
  const [formikValues, setFormikValues] = useState(null);
  const [formikHelpers, setFormikHelpers] = useState("");
  const [otpToken, setOtpToken] = useState("");

  const [isStateloading, setIsStateLoading] = useState(false);

  useEffect(() => {
    if (date) {
      const formattedDate = formatDate(date);
      fetchClassesByDate(id, formattedDate);
    }
  }, [date]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    setEnteredOtp(pastedData.padEnd(6, ""));
  };

  const token = getSession(COOKIE.ADMIN_TOKEN);
  const email = getUserEmail(token);

  useEffect(() => {
    const fetchAllStudentsByClass = async () => {
      setIsStateLoading(true);

      const updatedStudentsByClass = {};
      for (const classItem of selectedClasses) {
        const students = await fetchStudentsByClass(id, classItem.id);
        updatedStudentsByClass[classItem.id] = students;
      }
      setStudentsByClass(updatedStudentsByClass);
      setIsStateLoading(false);
    };

    fetchAllStudentsByClass();
  }, [selectedClasses]);

  const Attendance = async (data) => {
    try {
      const response = await createAttendance(data);
    } catch (error) {
      console.error("Error:", error.data.message);
      showErrorAlert("Error", error.data.message);
    }
  };

  const createHoliday = async (data) => {
    try {
      const response = await CreateHoliday(data);
      showSuccessAlert();
    } catch (error) {
      console.error("Error:", error.data.message);
      showErrorAlert("Error", error.data.message);
    }
  };

  const fetchClassesByDate = async (branchId, date) => {
    try {
      setIsStateLoading(true);
      const response = await getAttendanceByDate(branchId, date);
      const disabledClassIds = new Set(
        response.data.map(({ class_id }) => class_id)
      );
      setDisabledClasses(disabledClassIds);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      setIsStateLoading(true);
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses(branchId);
  }, []);

  const fetchSections = async (classId, branchId) => {
    try {
      setIsStateLoading(true);
      const response = await getSectionByClassId(classId, branchId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const validationSchemaConsolidated = Yup.object().shape({
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date cannot be before start date"),
  });

  const fetchConsolidatedAttendance = async (branchId, startDate, endDate) => {
    setLoading(true);
    try {
      const response = await getConsolidatedAttendance(
        branchId,
        startDate,
        endDate
      );
      const { processedData, dates } = processData(response.data);
      setProcessedData(processedData);
      setDates(dates);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDaywiseAttendance = async (branchId, datewise) => {
    setLoading(true);

    try {
      const response = await getDaywiseAttendance(branchId, datewise);
      const data = response.data;

      const presentData = data.present_data || [];
      const absentData = data.absent_data || [];

      const combinedData = [...presentData, ...absentData].map((item) => ({
        studentName: item.student_name,
        className: item.class_name,
        date: item.attendance_date,
        status: presentData.some((p) => p.student_name === item.student_name)
          ? "Present"
          : "Absent",
      }));

      setAttendanceData(combinedData);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const processData = (data) => {
    const processedData = [];
    const { present_data, absent_data } = data;

    const students = [
      ...new Set([
        ...present_data.map((d) => d.student_name),
        ...absent_data.map((d) => d.student_name),
      ]),
    ];
    const dates = [
      ...new Set([
        ...present_data.map((d) => d.attendance_date),
        ...absent_data.map((d) => d.attendance_date),
      ]),
    ];

    students.forEach((student) => {
      const studentClasses = [
        ...new Set([
          ...present_data
            .filter((p) => p.student_name === student)
            .map((p) => p.class_name),
          ...absent_data
            .filter((a) => a.student_name === student)
            .map((a) => a.class_name),
        ]),
      ];

      // Assuming a student will be in only one class
      let studentRecord = {
        student_name: student,
        class_name: studentClasses[0] || "NA",
      };

      dates.forEach((date) => {
        studentRecord[date] = present_data.some(
          (p) => p.student_name === student && p.attendance_date === date
        )
          ? "present"
          : absent_data.some(
              (a) => a.student_name === student && a.attendance_date === date
            )
          ? "absent"
          : "NA";
      });

      processedData.push(studentRecord);
    });

    return { processedData, dates };
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
    class_id: Yup.string().required("Class selection is required"),
    sectionSelection: Yup.array()
      .min(1, "At least one section must be selected")
      .required("Section selection is required"),
  });

  const filteredClasses = classes.map(({ id, name, branch_id }) => ({
    id,
    name,
    branch_id,
  }));

  const fetchStudentsByClass = async (branchId, classId, section_id) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        section_id
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching students:", error);
      return [];
    }
  };

  const statusBodyTemplate = (rowData) => {
    const students = (studentsByClass[rowData.id] || []).map((student) => ({
      id: student.id,
      first_name: student.first_name,
      roll_no: student.roll_no,
      class_id: student.class_id,
      branch_id: student.branch_id,
      section_id: student.section_id,
      displayName: ` ${student.first_name} ( ${student.roll_no})`,
    }));

    // Handle "Select All" checkbox change
    const handleSelectAll = (e) => {
      const allStudentsSelected = e.checked ? students : [];
      handleStudentChange(rowData.id, allStudentsSelected);
    };

    // Custom header template with filter on top and "Select All" checkbox below
    const panelHeaderTemplate = (options) => {
      const allSelected =
        (selectedStudents[rowData.id] || []).length === students.length;

      return (
        <div>
          <div className="p-multiselect-filter-container">
            {options.filterElement}
            <i className="pi pi-search p-multiselect-filter-icon"></i>{" "}
            {/* Search Icon inside the search box */}
          </div>
          <div className="p-multiselect-selectall mt-2">
            <Checkbox
              checked={allSelected}
              onChange={handleSelectAll}
              className="p-checkbox-md"
            />
            <span className="ml-2">Select All</span>
          </div>
        </div>
      );
    };

    return (
      <MultiSelect
        value={selectedStudents[rowData.id] || []}
        options={students}
        optionLabel="displayName"
        placeholder={isStateloading ? "Loading sections..." : "Select Students"}
        onChange={(e) => handleStudentChange(rowData.id, e.value)}
        maxSelectedLabels={3}
        className="w-full"
        filter
        style={{ width: "250px" }}
        panelHeaderTemplate={panelHeaderTemplate}
        disabled={isStateloading}
      />
    );
  };

  const handleStudentChange = (classId, selectedStudents) => {
    setSelectedStudents((prev) => ({
      ...prev,
      [classId]: selectedStudents,
    }));
  };

  const MultiSelectField = ({ field, form, ...props }) => {
    const handleSectionChange = async (e) => {
      const selectedSection = e.value;
      form.setFieldValue(field.name, selectedSection);

      const updatedStudentsByClass = {};
      for (const sectionItem of selectedSection) {
        const students = await fetchStudentsByClass(
          id,
          sectionItem.class_id,
          sectionItem.id
        );
        updatedStudentsByClass[sectionItem.id] = students;
      }
      setStudentsByClass(updatedStudentsByClass);
    };

    // Handle "Select All" checkbox change
    const handleSelectAll = (e) => {
      if (e.checked) {
        form.setFieldValue(field.name, props.options); // Select all sections
      } else {
        form.setFieldValue(field.name, []); // Deselect all sections
      }
    };

    // Custom header template with filter on top and "Select All" checkbox below
    const panelHeaderTemplate = (options) => {
      const allSelected = field.value.length === props.options.length;

      return (
        <div className="">
          <div className="p-multiselect-filter-container">
            {options.filterElement}
            <i className="pi pi-search p-multiselect-filter-icon"></i>{" "}
            {/* Icon inside the search box */}
          </div>
          <div className="p-multiselect-selectall mt-2">
            <Checkbox
              checked={allSelected}
              onChange={handleSelectAll}
              className="p-checkbox-md"
            />
            <span className="ml-2">Select All</span>
          </div>
        </div>
      );
    };

    return (
      <MultiSelect
        {...field}
        {...props}
        value={field.value}
        onChange={handleSectionChange}
        placeholder={isStateloading ? "Loading sections..." : "Select Section"}
        filter
        optionLabel="name"
        panelHeaderTemplate={panelHeaderTemplate} // Add custom header template
        className="custom-multiselect"
        disabled={isStateloading}
      />
    );
  };

  const dateTemplate = (rowData, column) => {
    const status = rowData[column.field];
    const isPresent = status === "present";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          height: "40px",
          backgroundColor: isPresent ? "#e6ffe6" : "#ffe6e6", // light green for present, light red for absent
          padding: "5px 15px",
          borderRadius: "5px",
        }}
      >
        <i
          className={`pi ${isPresent ? "pi-check" : "pi-times"}`}
          style={{ color: isPresent ? "green" : "red", marginRight: "5px" }}
        ></i>
        <p style={{ color: isPresent ? "green" : "red", margin: 0 }}>
          {isPresent ? "Present" : "Absent"}
        </p>
      </div>
    );
  };

  const validationDaywise = Yup.object({
    datewise: Yup.date().required("Date is required").nullable(),
  });

  const handleSubmit = async (values) => {
    const { datewise } = values;
    const branchId = 1;
    await fetchDaywiseAttendance(branchId, formatDate(datewise));
  };

  const handleAbsentSubmit = async (values) => {
    const { datewise } = values;
    const branchId = 1;
    await fetchAbsentees(branchId, formatDate(datewise));
  };

  const fetchAbsentees = async (branchId, datewise) => {
    setLoading(true);
    try {
      const response = await getDaywiseAttendance(branchId, datewise);
      const data = response.data;
      const absenteesData = (data.absent_data || []).map((item) => ({
        studentName: item.student_name,
        className: item.class_name,
      }));
      setAbsenteesData(absenteesData);
    } catch (error) {
      console.error("Error fetching absentees:", error);
    } finally {
      setLoading(false);
    }
  };

  const daywiseTemplate = (rowData) => {
    const isPresent = rowData.status === "Present";

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          width: "150px",
          height: "40px",
          backgroundColor: isPresent ? "#e6ffe6" : "#ffe6e6", // light green for present, light red for absent
          padding: "5px 15px",
          borderRadius: "5px",
        }}
      >
        <i
          className={`pi ${isPresent ? "pi-check" : "pi-times"}`}
          style={{ color: isPresent ? "green" : "red", marginRight: "5px" }}
        ></i>
        <p style={{ color: isPresent ? "green" : "red", margin: 0 }}>
          {isPresent ? "Present" : "Absent"}
        </p>
      </div>
    );
  };

  const renderFullDay = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        width: "150px",
        height: "40px",
        backgroundColor: "#ffe6e6", // light green for present, light red for absent
        padding: "5px 15px",
        borderRadius: "5px",
      }}
    >
      <i className="pi pi-times" style={{ color: "red" }}></i>
      <p style={{ color: "red", margin: 0 }}>Absent</p>
    </div>
  );

  const handleVerifyOTP = async () => {
    try {
      await submitAttendance(formikValues, enteredOtp, otpToken); // Submit form with OTP
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setMessage("OTP verification failed. Please try again.");
    }
  };

  const generateOtpToken = () => {
    const characters =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const length = 10;
    let otpToken = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      otpToken += characters[randomIndex];
    }
    return otpToken;
  };

  const submitAttendance = async (values, otp = null, otpToken) => {
    const attendanceData = values.sectionSelection.reduce((acc, section) => {
      const sectionId = section.id;
      const studentIds = (selectedStudents[sectionId] || []).map(
        (student) => student.id
      );
      acc[sectionId] = JSON.stringify(studentIds);
      return acc;
    }, {});

    const data = {
      branch_id: id,
      class_id: values.class_id,
      attendance: attendanceData,
      date: formatDate(values.date),
      otp_token: otpToken,
      otp: otp,
      notification: values.notification,
      notification_type: "Attendance",
      subject_id: "",
    };

    try {
      await Attendance(data); // Call API to submit attendance

      if (!otp) {
        // If OTP is not provided, show OTP popup
        setShowOtpPopup(true);
      } else {
        // If OTP is provided, reset the form and state
        setShowOtpPopup(false);
        // Reset form and state
        // formikHelpers.resetForm(); // Reset Formik form
        setSelectedClasses([]);
        setStudentsByClass([]);
        setSelectedStudents([]);
        setDate("");
        setProcessedData([]);
        setDates([]);
        setAttendanceData([]);
        setAbsenteesData("");
        setMessage("");
        setVerifiedMessage("");
        setOtp(null);
        setEnteredOtp("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <section id="model_5" className="card">
        <div className="card-header">
          <ul class="nav nav-pills">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "individual" ? "active" : ""
                }`}
                // id="home-tab"
                // data-toggle="tab"
                // href="#home5"
                // role="tab"
                // aria-controls="home"
                // aria-selected={activeTab === "individual"}
                onClick={() => setActiveTab("individual")}
              >
                <i className="fas fa-user-clock"></i> Individual Student
                Attendance
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                // id="home-tab"
                // data-toggle="tab"
                // href="#home5"
                // role="tab"
                // aria-controls="home"
                // aria-selected={activeTab === "home"}
                onClick={() => setActiveTab("home")}
              >
                <i className="fas fa-user-clock"></i>Bulk entry
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "profile" ? "active" : ""
                }`}
                // id="profile-tab"
                // data-toggle="tab"
                // href="#profile5"
                // role="tab"
                // aria-controls="profile"
                // aria-selected={activeTab === "profile"}
                onClick={() => setActiveTab("profile")}
              >
                <i className="fas fa-user-check"></i>View Attendance
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "contact" ? "active" : ""
                }`}
                // id="contact-tab"
                // data-toggle="tab"
                // href="#contact5"
                // role="tab"
                // aria-controls="contact"
                // aria-selected={activeTab === "contact"}
                onClick={() => setActiveTab("contact")}
              >
                <i className="fas fa-user-times"></i> Holiday
              </a>
            </li>
          </ul>
        </div>
        <div
          className="attendance-tab-content container mt-5"
          id="myTabContent"
        >
          {activeTab === "individual" && <IndividualStudentAttendance />}
          {activeTab === "home" && (
            <>
              <Formik
                initialValues={{
                  date: date,
                  class_id: "",
                  sectionSelection: [],
                  notification: [],
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, formikHelpers) => {
                  try {
                    // Save formik values to state to use during OTP verification
                    setFormikValues(values);

                    // Generate OTP token
                    const otpToken = generateOtpToken();
                    setOtpToken(otpToken); // Set generated OTP token

                    // Submit attendance data without OTP initially and pass the OTP token
                    await submitAttendance(values, null, otpToken); // Pass otpToken as an argument
                  } catch (error) {
                    console.error("Error during submission:", error);
                    setMessage(
                      "There was an error submitting the attendance. Please try again."
                    );
                  }
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div
                      className="d-flex flex-column justify-content-center  "
                      style={{
                        border: "1px solid skyblue",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgb(0, 123, 255)",
                          padding: "10px",
                        }}
                      >
                        <h5
                          style={{
                            marginTop: "0px",
                            color: "white",
                          }}
                        >
                          <i className="fas fa-calendar-check"></i> Add
                          Attendance
                        </h5>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-3 mb-3">
                        <div className="col-4">
                          <label htmlFor="date">
                            Select Date<span className="mandatory">* </span>{" "}
                          </label>
                          <Field
                            type="date"
                            name="date"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("date", e.target.value);
                              setDate(e.target.value); // Update date state
                            }}
                            style={{ borderColor: "skyblue" }}
                            max={new Date().toISOString().split("T")[0]}
                          />
                          <ErrorMessage
                            name="date"
                            component="div"
                            className="text-error"
                          />
                        </div>

                        <div className=" col-4">
                          <label>
                            Select Class<span className="mandatory">* </span>{" "}
                          </label>
                          <Field
                            as="select"
                            name="class_id"
                            className="form-control d-flex justify-content-center text-left"
                            style={{
                              borderColor: "skyblue",
                              paddingBottom: "2px",
                              paddingTop: "0px",
                            }}
                            onChange={(event) => {
                              const selectedClassId = event.target.value;
                              setFieldValue("class_id", selectedClassId);

                              const selectedClass = filteredClasses.find(
                                (classItem) => classItem.id == selectedClassId
                              );
                              if (selectedClass) {
                                const classId = selectedClass.id;
                                const branchId = selectedClass.branch_id;

                                fetchSections(classId, branchId);
                                setSections([]);
                                setFieldValue("sectionSelection", []);
                                setStudentsByClass({});
                                setSelectedStudents({});
                              } else {
                                console.warn("No class selected");
                              }
                            }}
                          >
                            {isStateloading ? (
                              <option value="" disabled>
                                Loading...
                              </option>
                            ) : (
                              <>
                                <option value="">Select class</option>
                                {filteredClasses.map((classItem) => (
                                  <option
                                    key={classItem.id}
                                    value={classItem.id}
                                    disabled={disabledClasses.has(classItem.id)}
                                    style={{
                                      cursor: disabledClasses.has(classItem.id)
                                        ? "not-allowed"
                                        : "default",
                                    }}
                                  >
                                    {classItem.name}
                                  </option>
                                ))}
                              </>
                            )}
                          </Field>
                          <ErrorMessage
                            name="class_id"
                            component="div"
                            className="text-error"
                          />
                        </div>
                        <div className=" col-4 d-flex flex-column">
                          <label htmlFor="sectionSelection">
                            Select Section<span className="mandatory">* </span>{" "}
                          </label>
                          <Field
                            name="sectionSelection"
                            component={MultiSelectField}
                            options={sections}
                            optionLabel="name"
                            maxSelectedLabels={3}
                            className="form-control d-flex justify-content-center text-left"
                            style={{
                              borderColor: "skyblue",
                              paddingBottom: "2px",
                              paddingTop: "0px",
                            }}
                          />
                          <ErrorMessage
                            name="sectionSelection"
                            component="div"
                            className="text-error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card mt-5">
                      <DataTable value={values.sectionSelection}>
                        <Column
                          field="sno"
                          header="S.No"
                          body={(rowData, { rowIndex }) => rowIndex + 1}
                          style={{ width: "150px" }}
                        ></Column>

                        <Column
                          field="name"
                          header="Section Name"
                          body={(rowData) => rowData.name}
                          style={{ width: "250px" }}
                        ></Column>
                        <Column
                          field="first_name"
                          header="Add Absent Students"
                          body={statusBodyTemplate}
                          style={{ width: "250px" }}
                        ></Column>
                      </DataTable>
                    </div>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center mt-3"
                      style={{
                        border: "1px solid skyblue",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "20px",
                        }}
                      >
                        Send By:
                      </p>
                      <div className="d-flex justify-content-around flex-grow-1 ml-3">
                        {[
                          {
                            id: "whatsapp-notification",
                            label: "WhatsApp",
                            value: "1",
                          },
                          {
                            id: "email-notification",
                            label: "Email",
                            value: "4",
                          },
                          { id: "sms-notification", label: "SMS", value: "3" },
                          { id: "app-notification", label: "App", value: "2" },
                        ].map((checkbox) => (
                          <div key={checkbox.id}>
                            <input
                              type="checkbox"
                              id={checkbox.id}
                              name="notification"
                              className="mr-2"
                              value={checkbox.value}
                              checked={values.notification.includes(
                                checkbox.value
                              )}
                              onChange={(e) => {
                                const { value, checked } = e.target;
                                let newNotifications = [...values.notification];

                                if (checked) {
                                  newNotifications.push(value); // Add if checked
                                } else {
                                  newNotifications = newNotifications.filter(
                                    (notification) => notification !== value
                                  ); // Remove if unchecked
                                }

                                setFieldValue("notification", newNotifications);
                              }}
                            />
                            <label htmlFor={checkbox.id}>
                              {checkbox.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary mb-2 mt-3"
                        style={{
                          width: "100px",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {showOtpPopup && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      backdropFilter: "blur(5px)", // Apply blur effect
                      zIndex: 1000, // Ensure it's above other content
                    }}
                  />
                  <div
                    // className="container d-flex justify-content-center align-items-center min-vh-100"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "white",
                      padding: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      zIndex: 1001,
                      borderRadius: "8px",
                      // width: "300px",
                      textAlign: "center",
                    }}
                  >
                    <div className="text-center">
                      <h5>
                        Please verify the attendance data
                        <br></br>
                        and enter the code sent to
                        <br></br>
                        {email}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                      {[...Array(6)].map((_, index) => (
                        <input
                          key={index}
                          type="text"
                          className="form-control mx-1 text-center"
                          style={{ width: "40px" }}
                          maxLength="1"
                          value={enteredOtp[index] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setEnteredOtp((prev) => {
                              const newOtp = prev.split("");
                              newOtp[index] = value;
                              return newOtp.join("");
                            });
                          }}
                          onPaste={handlePaste}
                        />
                      ))}
                    </div>
                    {/* {message && <p>{message}</p>} */}
                    <button
                      onClick={handleVerifyOTP}
                      className="btn btn-primary w-75"
                    >
                      Verify OTP
                    </button>
                    <button
                      onClick={() => setShowOtpPopup(false)}
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {activeTab === "profile" && (
            <>
              <ul
                className="custom-nav-tabs container"
                id="myTab"
                role="tablist"
              >
                <li className="custom-nav-item">
                  <a
                    className={`custom-nav-link ${
                      profileActiveTab === "dayView" ? "active" : ""
                    }`}
                    id="dayView-tab"
                    data-toggle="tab"
                    href="#dayView"
                    role="tab"
                    aria-controls="dayView"
                    aria-selected={activeTab === "dayView"}
                    onClick={() => setProfileActiveTab("dayView")}
                  >
                    <i className="fas fa-calendar-day"></i>
                    Day View
                  </a>
                </li>

                <li className="custom-nav-item">
                  <a
                    className={`custom-nav-link ${
                      profileActiveTab === "absentView" ? "active" : ""
                    }`}
                    id="absentView-tab"
                    data-toggle="tab"
                    href="#absentView"
                    role="tab"
                    aria-controls="absentView"
                    aria-selected={activeTab === "absentView"}
                    onClick={() => setProfileActiveTab("absentView")}
                  >
                    <i className="fas fa-user-times"></i>
                    Absent View
                  </a>
                </li>
                <li className="custom-nav-item">
                  <a
                    className={`custom-nav-link ${
                      profileActiveTab === "consolidatedView" ? "active" : ""
                    }`}
                    id="consolidatedView-tab"
                    data-toggle="tab"
                    href="#consolidatedView"
                    role="tab"
                    aria-controls="consolidatedView"
                    aria-selected={activeTab === "consolidatedView"}
                    onClick={() => setProfileActiveTab("consolidatedView")}
                  >
                    <i className="fas fa-file-signature"></i>
                    Consolidated View
                  </a>
                </li>
              </ul>
              {profileActiveTab === "dayView" && (
                <Formik
                  initialValues={{
                    datewise: new Date().toISOString().split("T")[0],
                  }}
                  validationSchema={validationDaywise}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue }) => (
                    <>
                      <Form>
                        <div
                          className="d-flex flex-column justify-content-center mt-3"
                          style={{
                            border: "1px solid skyblue",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgb(0, 123, 255)",
                              padding: "10px",
                            }}
                          >
                            <h5
                              style={{
                                marginTop: "0px",
                                color: "white",
                              }}
                            >
                              <i className="fas fa-list"></i> View Day wise
                              Attendance
                            </h5>
                          </div>
                          <div className="d-flex flex-row col-12 justify-content-center mt-3 mb-3">
                            <div className="col-4">
                              <label htmlFor="datewise">
                                Select Date<span className="mandatory">* </span>{" "}
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                id="datewise"
                                name="datewise"
                                placeholder="Enter date"
                                style={{ borderColor: "skyblue" }}
                                max={new Date().toISOString().split("T")[0]}
                              />
                              <ErrorMessage
                                name="datewise"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-3">
                            <button type="submit" className="btn btn-primary">
                              search
                            </button>
                          </div>
                        </div>
                      </Form>

                      {loading ? (
                        <div className="text-center mt-3">
                          <BeatLoader color="#16dada" />
                        </div>
                      ) : (
                        <>
                          <div className="mt-3">
                            <DataTable
                              value={attendanceData}
                              paginator
                              rows={10}
                              className="p-datatable-striped"
                            >
                              <Column
                                field="className"
                                header="Class Name"
                              ></Column>
                              <Column
                                field="studentName"
                                header="Student Name"
                              ></Column>

                              <Column
                                header="Full Day"
                                body={daywiseTemplate}
                              ></Column>
                            </DataTable>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Formik>
              )}
              {profileActiveTab === "consolidatedView" && (
                <>
                  <Formik
                    initialValues={{
                      start_date: new Date().toISOString().split("T")[0],
                      end_date: "",
                    }}
                    validationSchema={validationSchemaConsolidated}
                    onSubmit={(values, { setSubmitting }) => {
                      const formatDate = (dateString) => {
                        const [year, month, day] = dateString.split("-");
                        return `${day}/${month}/${year}`;
                      };
                      setSubmitting(true);
                      fetchConsolidatedAttendance(
                        id,
                        formatDate(values.start_date),
                        formatDate(values.end_date)
                      );
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div
                          className="d-flex flex-column justify-content-center mt-3"
                          style={{
                            border: "1px solid skyblue",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgb(0, 123, 255)",
                              padding: "10px",
                            }}
                          >
                            <h5 style={{ marginTop: "0px", color: "white" }}>
                              <i className="fas fa-list"></i> View Consolidated
                              Attendance
                            </h5>
                          </div>
                          <div className="d-flex flex-row col-12 justify-content-center mt-3 mb-3">
                            <div className="col-4">
                              <label htmlFor="start_date">
                                From Date<span className="mandatory">* </span>
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                id="start_date"
                                name="start_date"
                                placeholder="Select from date"
                                style={{ borderColor: "skyblue" }}
                                max={new Date().toISOString().split("T")[0]}
                              />
                              <ErrorMessage
                                name="start_date"
                                component="div"
                                className="text-error"
                              />
                            </div>
                            <div className="col-4">
                              <label htmlFor="end_date">
                                To Date<span className="mandatory">* </span>
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                id="end_date"
                                name="end_date"
                                placeholder="Select to date"
                                style={{ borderColor: "skyblue" }}
                              />
                              <ErrorMessage
                                name="end_date"
                                component="div"
                                className="text-error"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-3">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              search
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {loading ? (
                    <div className="text-center mt-3">
                      <BeatLoader color="#16dada" />
                    </div>
                  ) : (
                    <>
                      <DataTable value={processedData} className="mt-3">
                        <Column field="class_name" header="Class Name" />
                        <Column field="student_name" header="Student Name" />
                        {dates.map((date, index) => {
                          const formattedDate = new Date(
                            date
                          ).toLocaleDateString("en-GB");

                          return (
                            <Column
                              key={index}
                              field={date}
                              header={formattedDate}
                              body={dateTemplate}
                            />
                          );
                        })}
                      </DataTable>
                    </>
                  )}
                </>
              )}
              {profileActiveTab === "absentView" && (
                <>
                  <Formik
                    initialValues={{
                      datewise: new Date().toISOString().split("T")[0],
                    }}
                    validationSchema={validationDaywise}
                    onSubmit={handleAbsentSubmit}
                  >
                    {({ values, setFieldValue, errors, touched }) => (
                      <Form
                        className="d-flex flex-column justify-content-center mt-3"
                        style={{
                          border: "1px solid skyblue",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgb(0, 123, 255)",
                            padding: "10px",
                          }}
                        >
                          <h5 style={{ marginTop: "0px", color: "white" }}>
                            <i className="fas fa-list"></i> View Absentees list
                          </h5>
                        </div>
                        <div
                          className="col-4 mt-3 mb-3"
                          style={{ margin: "auto" }}
                        >
                          <label htmlFor="datewise">
                            Select Date<span className="mandatory">* </span>
                          </label>
                          <Field
                            type="date"
                            className={`form-control ${
                              errors.datewise && touched.joining_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="datewise"
                            name="datewise"
                            placeholder=""
                            style={{ borderColor: "skyblue" }}
                            max={new Date().toISOString().split("T")[0]}
                          />
                          {errors.datewise && touched.datewise && (
                            <div className="invalid-feedback">
                              {errors.datewise}
                            </div>
                          )}
                        </div>

                        <div className="text-center mb-3">
                          <button type="submit" className="btn btn-primary">
                            Search
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {loading ? (
                    <div className="text-center mt-3">
                      <BeatLoader color="#16dada" />
                    </div>
                  ) : (
                    <>
                      <div className="mt-4">
                        <DataTable
                          value={absenteesData}
                          paginator
                          rows={10}
                          className="p-datatable-sm"
                        >
                          <Column
                            field="className"
                            header="Class Name"
                            sortable
                          />
                          <Column
                            field="studentName"
                            header="Student Name"
                            sortable
                          />
                          <Column body={renderFullDay} header="Full Day" />
                        </DataTable>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {activeTab === "contact" && (
            <Formik
              initialValues={{
                holiday_date: new Date().toISOString().split("T")[0],
                name: "",
              }}
              validationSchema={Yup.object({
                holiday_date: Yup.date().required("Date is required"),
                name: Yup.string().required("Message is required"),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  await createHoliday(values);
                  resetForm();
                } catch (error) {
                  console.error("Error:", error.data.message);
                  showErrorAlert("Error", error.data.message);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-column justify-content-center"
                  style={{
                    border: "1px solid skyblue",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ backgroundColor: "skyblue", padding: "10px" }}>
                    <h5 style={{ marginTop: "0px", color: "white" }}>
                      <i className="fas fa-calendar-check"></i> Add Holiday
                    </h5>
                  </div>

                  <div className="d-flex flex-row justify-content-center mt-3 mb-3">
                    <div className="col-4">
                      <label htmlFor="holiday_date">
                        Select Date<span className="mandatory">* </span>
                      </label>
                      <Field
                        type="date"
                        className="form-control"
                        id="holiday_date"
                        name="holiday_date"
                        style={{ borderColor: "skyblue" }}
                        min={new Date().toISOString().split("T")[0]}
                      />
                      <ErrorMessage
                        name="holiday_date"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div
                    className="form-group col-4 mt-3"
                    style={{ margin: "auto" }}
                  >
                    <label htmlFor="name">
                      Message <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Message"
                      style={{ borderColor: "skyblue" }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mb-2 mt-3"
                    style={{
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </div>
  );
};

export default AttendanceCreate;
