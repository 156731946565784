import React, { useState, useEffect } from "react";
import { getClassByBranchId } from "../../Services/AdminApis/ScheduleApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { getsubjectsbybranchidclasssection } from "../../Services/CommonApis/ReportcardApi";
import { getAllexamlocks } from "../../Services/CommonApis/examreportloockApi";
import { createexamconfig } from "../../Services/CommonApis/ReportcardApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { getAllexamtypes } from "../../Services/CommonApis/examreportloockApi";
import { getsubjectsbybranchIdandClassid } from "../../Services/CommonApis/ReportcardApi";
import KeyNavigationHandler from "../../Utility/CommonUtility/HandleKeyNavigation";
import { external } from "jszip";
const ReportcardConfiguration = () => {
  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const { branchId } = useParams();
  const [selectedClassId, setSelectedClassId] = useState("");
  const [topperVisibleStatus, setTopperVisibleStatus] = useState("1");
  const [rankVisibilityStatus, setRankVisibilityStatus] = useState("1");
  const [apiMessage, setApiMessage] = useState("");
  const [navigationMode, setNavigationMode] = useState("horizontal");

  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [subjectOptions, setSubjectOptions] = useState([]);

  const [sectionType, setSectionType] = useState("1");
  const [examlock, setExamlock] = useState([]);
  const [examtypes, setExamtypes] = useState([]);
  const initialFormData = {
    class_id: "",
    section_id: [],
    is_grade: "",
    entergrade: "",
    subjectOptions: [],
    is_average: "0",
  };
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);
  useEffect(() => {
    fetchexamlocks();
    fetchexamtypes();
  }, []);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  const fetchexamlocks = async () => {
    try {
      const response = await getAllexamlocks();
      console.log(response.data);
      setExamlock(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchexamtypes = async () => {
    try {
      const response = await getAllexamtypes();
      console.log(response.data);
      setExamtypes(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClassChange = async (classId) => {
    setSelectedClassId(classId);
    try {
      const response = await getSectionByClassId(classId);
      const sections = response.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  useEffect(() => {
    if (selectedClassId && sectionType === "1") {
      fetchSubjectsByBranchIdclassid(branchId, selectedClassId);
    } else if (sectionType === "0") {
      fetchSubjectsByBranchId(branchId, selectedClassId, selectedSectionId);
    }
  }, [selectedClassId, selectedSectionId, sectionType, branchId]);

  const fetchSubjectsByBranchId = async (
    branchId,
    selectedClassId,
    selectedSectionId
  ) => {
    try {
      const response = await getsubjectsbybranchidclasssection({
        branchId: branchId,
        classId: selectedClassId,
        sectionId: selectedSectionId,
      });

      const subjects = response.data.branchsubject;
      setSubjectOptions(subjects);
      setFormData((prev) => ({ ...prev, subjectOptions: subjects }));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjectsByBranchIdclassid = async (branchId, selectedClassId) => {
    try {
      const response = await getsubjectsbybranchIdandClassid({
        branchId: branchId,
        classId: selectedClassId,
      });

      const subjects = response.data.branchsubject;
      setSubjectOptions(subjects);
      setFormData((prev) => ({ ...prev, subjectOptions: subjects }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarksChange = (index, field, value) => {
    const updatedSubjects = [...subjectOptions];
    updatedSubjects[index][field] = value;
    setSubjectOptions(updatedSubjects);
    setFormData((prev) => ({ ...prev, subjectOptions: updatedSubjects }));
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If there is no destination, return
    if (!destination) return;

    const updatedSubjectOptions = Array.from(subjectOptions);

    // Check if the item is moved to a different droppable area
    if (source.droppableId !== destination.droppableId) {
      const [movedSubject] = updatedSubjectOptions.splice(source.index, 1);

      // Update the type based on the new droppable ID
      movedSubject.type =
        destination.droppableId === "mainSubjects" ? "main" : "not_required";

      updatedSubjectOptions.splice(destination.index, 0, movedSubject);
    } else {
      // If the item is reordered within the same droppable area
      const [reorderedItem] = updatedSubjectOptions.splice(source.index, 1);
      updatedSubjectOptions.splice(destination.index, 0, reorderedItem);
    }

    // Update the state with the new subject options
    setSubjectOptions(updatedSubjectOptions);
    setFormData((prev) => ({ ...prev, subjectOptions: updatedSubjectOptions }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let sectionIds = [];

    if (sectionType === "1") {
      sectionIds = sectionOptions.map((option) => option.value);
    } else if (sectionType === "0") {
      sectionIds = Array.isArray(formData.section_id)
        ? formData.section_id
        : [formData.section_id];
    }

    const structuredData = {
      exam_id: formData.exam_id,
      class_id: formData.class_id,
      section_id: sectionIds,

      subjects: subjectOptions.map((subject, index) => {
        return {
          sequence: index + 1,
          subject_id: subject.subject_id,
          max_marks: subject.maxMark || 0,
          internal: subject.internal || 0,
          external: subject.external || 0,
          pass_marks: subject.passMark || 0,
          is_average: subject.is_average ? 1 : 0,
          is_grade: subject.is_grade ? 1 : 0,
          add_in_grand: subject.add_in_grand ? 1 : 0,
        };
      }),

      topper_visible: topperVisibleStatus,
      rank_visible: rankVisibilityStatus,
      lock_report: formData.lock_report,
    };

    console.log("Structured Data:", structuredData);

    try {
      const response = await createexamconfig(structuredData);
      console.log("Response from API:", response);

      if (response.status === 200) {
        setApiMessage(response.data.data || response.message);
        showSuccessAlert("Success", apiMessage);
        setFormData(initialFormData);
      }
    } catch (error) {
      showErrorAlert(error.response.data.message);
      console.log("Error submitting form:", error);
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Report Card Configuration</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Report Card Configuration</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleFormSubmit}>
            <div className="row ml-2 mr-5 mt-3">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="class_id">Class *</label>
                  <select
                    name="class_id"
                    className="form-control"
                    value={formData.class_id}
                    onChange={(e) => {
                      const classId = e.target.value;
                      handleClassChange(classId);
                      setFormData((prev) => ({
                        ...prev,
                        class_id: classId,
                      }));
                    }}
                  >
                    <option value="">Select Class</option>
                    {classOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="lock_report">Exam lock *</label>
                  <select
                    name="lock_report"
                    className="form-control"
                    value={formData.lock_report}
                    onChange={(e) => {
                      const lock_reportId = e.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        lock_report: lock_reportId,
                      }));
                    }}
                  >
                    <option value="">Select Exam Lock</option>
                    {examlock.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="exam_">Exam Type *</label>
                  <select
                    name="exam_"
                    className="form-control"
                    value={formData.exam_}
                    onChange={(e) => {
                      const exam_Id = e.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        exam_id: exam_Id,
                      }));
                    }}
                  >
                    <option value="">Select Exam Type</option>
                    {examtypes.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group col-2 ml-2">
                <label htmlFor="topperVisibleStatus">
                  Topper Visible Status
                </label>
                <div className="d-flex mt-2">
                  <div className="form-check mr-2">
                    <input
                      type="radio"
                      className="form-check-input "
                      id="topper_visible_yes"
                      name="topper_visible"
                      value="1"
                      checked={topperVisibleStatus === "1"}
                      onChange={(e) => setTopperVisibleStatus(e.target.value)}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="topper_visible_yes"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input "
                      id="topper_visible_no"
                      name="topperVisibleStatus"
                      value="0"
                      checked={topperVisibleStatus === "0"}
                      onChange={(e) => setTopperVisibleStatus(e.target.value)}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="topper_visible_no"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group col-2 ml-2">
                <label htmlFor="rankVisibilityStatus">
                  Rank Visibility Status
                </label>
                <div className="d-flex mt-2">
                  <div className="form-check mr-2">
                    <input
                      type="radio"
                      className="form-check-input "
                      id="rank_visibility_yes"
                      name="rank_visible"
                      value="1"
                      checked={rankVisibilityStatus === "1"}
                      onChange={(e) => setRankVisibilityStatus(e.target.value)}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="rank_visibility_yes"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="rank_visibility_no"
                      name="rankVisibilityStatus"
                      value="0"
                      checked={rankVisibilityStatus === "0"}
                      onChange={(e) => setRankVisibilityStatus(e.target.value)}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="rank_visibility_no"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group ">
                <label>Navigation Mode</label>
                <div className="mt-2">
                  <label className="mr-2 ">
                    <input
                      className="mr-2"
                      type="radio"
                      name="navigationMode"
                      value="horizontal"
                      checked={navigationMode === "horizontal"}
                      onChange={(e) => setNavigationMode(e.target.value)}
                    />
                    Horizontal
                  </label>
                  <label className="mr-2">
                    <input
                      className="mr-2"
                      type="radio"
                      name="navigationMode"
                      value="vertical"
                      checked={navigationMode === "vertical"}
                      onChange={(e) => setNavigationMode(e.target.value)}
                    />
                    Vertical
                  </label>
                </div>
              </div>
              <div className="form-group col-2 ml-2">
                <label htmlFor="section_type">Apply to all Section</label>
                <div className="d-flex mt-2">
                  <div className="form-check mr-2">
                    <input
                      type="radio"
                      className="form-check-input "
                      id="sectiontype_yes"
                      name="section_type"
                      value="1"
                      checked={sectionType === "1"}
                      onChange={(e) => {
                        setSectionType(e.target.value);
                        setSubjectOptions(null);
                        setSelectedSectionId(null);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="sectiontype_yes"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input "
                      id="sectiontype_no"
                      name="section_type"
                      value="0"
                      checked={sectionType === "0"}
                      onChange={(e) => {
                        setSectionType(e.target.value);
                        setSubjectOptions(null);
                        setSelectedSectionId(null);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="sectiontype_no"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              {sectionType === "0" && (
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="section_id">Section *</label>
                    <Select
                      name="section_id"
                      options={sectionOptions}
                      value={sectionOptions.find(
                        (option) => formData.section_id === option.value
                      )} // Adjust to single select
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                          ? selectedOption.value
                          : null; // Single value
                        setFormData((prev) => ({
                          ...prev,
                          section_id: selectedValue, // Set single value
                        }));
                        setSelectedSectionId(selectedValue); // Update selectedSectionId
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {subjectOptions && subjectOptions.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="mainSubjects">
                  {(provided) => (
                    <div className="mt-3">
                      <h5 className="ml-2">Main Subjects</h5>
                      <table
                        className="table table-bordered mt-4 ml-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th style={{ width: "25%" }}>Subject Name</th>{" "}
                            <th>Enter as Grade</th>
                            <th style={{ width: "10%" }}>Internal</th>{" "}
                            <th style={{ width: "10%" }}>External</th>{" "}
                            <th>Max Mark</th>
                            <th>Pass Mark</th>
                            <th>Is Average</th>
                            <th>Add in Grand Total</th>
                            <th>Is Grade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subjectOptions.map((subject, index) => (
                            <Draggable
                              key={subject.subject_id}
                              draggableId={subject.subject_id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td>{index + 1}</td>
                                  <td style={{ width: "25%" }}>
                                    {subject.subject_name}
                                  </td>

                                  <td>
                                    <input
                                      id={`input-${index}-0`}
                                      type="checkbox"
                                      checked={subject.enterAsGrade || false}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "enterAsGrade",
                                          e.target.checked
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 0,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                    />
                                  </td>

                                  <td style={{ width: "10%" }}>
                                    <input
                                      id={`input-${index}-1`}
                                      type="text"
                                      className="form-control"
                                      style={{
                                        width: "80px",
                                        WebkitAppearance: "none",
                                        MozAppearance: "textfield",
                                      }}
                                      value={subject.internal || ""}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "internal",
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 1,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                      onKeyPress={(e) => {
                                        if (subject.enterAsGrade) {
                                          if (!/[a-zA-Z+-]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        } else {
                                          if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                    />
                                  </td>

                                  <td style={{ width: "10%" }}>
                                    <input
                                      id={`input-${index}-2`}
                                      type="text"
                                      className="form-control"
                                      style={{
                                        width: "80px",
                                        WebkitAppearance: "none",
                                        MozAppearance: "textfield",
                                      }}
                                      value={subject.external || ""}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "external",
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 2,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                      onKeyPress={(e) => {
                                        if (subject.enterAsGrade) {
                                          if (!/[a-zA-Z+-]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        } else {
                                          if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      id={`input-${index}-3`}
                                      type="text"
                                      className="form-control"
                                      value={subject.maxMark || ""}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "maxMark",
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 3,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                      onKeyPress={(e) => {
                                        if (subject.enterAsGrade) {
                                          if (!/[a-zA-Z+-]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        } else {
                                          if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                      style={{
                                        WebkitAppearance: "none",
                                        MozAppearance: "textfield",
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      id={`input-${index}-4`}
                                      type="text"
                                      className="form-control"
                                      value={subject.passMark || ""}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "passMark",
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 4,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                      onKeyPress={(e) => {
                                        if (subject.enterAsGrade) {
                                          if (!/[a-zA-Z+-]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        } else {
                                          if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                      style={{
                                        WebkitAppearance: "none",
                                        MozAppearance: "textfield",
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      id={`input-${index}-5`}
                                      type="checkbox"
                                      checked={subject.is_average || 0}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "is_average",
                                          e.target.checked
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 5,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      id={`input-${index}-6`}
                                      checked={subject.add_in_grand || 0}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "add_in_grand",
                                          e.target.checked
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 6,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={`input-${index}-7`}
                                      type="checkbox"
                                      checked={subject.is_grade || false}
                                      onChange={(e) =>
                                        handleMarksChange(
                                          index,
                                          "is_grade",
                                          e.target.checked
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        KeyNavigationHandler({
                                          e,
                                          rowIndex: index,
                                          colIndex: 7,
                                          subjectOptions,
                                          navigationMode,
                                        })
                                      }
                                    />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Droppable>

                <Droppable droppableId="optionalSubjects">
                  {(provided) => (
                    <div className="mt-3">
                      <h5 className="ml-2">Optional Subjects</h5>
                      <table
                        className="table table-bordered mt-4 ml-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Subject Name</th>
                            <th>Max Mark</th>
                            <th>Pass Mark</th>
                            <th>Is Average</th>
                            <th>Add in Grand Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subjectOptions
                            .filter((subject) => subject.type === "optional")
                            .map((subject, index) => (
                              <Draggable
                                key={subject.subject_id}
                                draggableId={subject.subject_id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{subject.subject_name}</td>
                                    <td>
                                      <input
                                        id={`input-${index}-0`}
                                        type="checkbox"
                                        className="form-control"
                                        value={subject.maxMark || ""}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "maxMark",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        id={`input-${index}-1`}
                                        type="checkbox"
                                        className="form-control"
                                        value={subject.passMark || ""}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "passMark",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={subject.is_average || false}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "is_average",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={subject.add_in_grand || 0}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "add_in_grand",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Droppable>

                {/* Not Required in Exam Droppable */}
                <Droppable droppableId="notRequired">
                  {(provided) => (
                    <div className="mt-3">
                      <h5 className="ml-2">Not Required in Exam</h5>
                      <table
                        className="table table-bordered mt-4 ml-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Subject Name</th>
                            <th>Max Mark</th>
                            <th>Pass Mark</th>
                            <th>Is Average</th>
                            <th>Add in Grand Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subjectOptions
                            .filter(
                              (subject) => subject.type === "not_required"
                            )
                            .map((subject, index) => (
                              <Draggable
                                key={subject.subject_id}
                                draggableId={subject.subject_id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{subject.subject_name}</td>
                                    <td>
                                      <input
                                        id={`input-${index}-0`}
                                        type="checkbox"
                                        className="form-control"
                                        value={subject.maxMark || ""}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "maxMark",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        id={`input-${index}-1`}
                                        type="checkbox"
                                        className="form-control"
                                        value={subject.passMark || ""}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "passMark",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={subject.is_average || false}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "is_average",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={subject.add_in_grand || 0}
                                        onChange={(e) =>
                                          handleMarksChange(
                                            index,
                                            "add_in_grand",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}

            <button type="submit" className="btn btn-primary mt-3 ml-3">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReportcardConfiguration;
