import React from 'react'
import Dashboard from '../CommonPages/Dashboard'

function AdminIndividualDashboard() {
  return (
    <div>
        <Dashboard/>
    </div>
  )
}

export default AdminIndividualDashboard