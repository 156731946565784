import React from "react";
import TransportDetails from "./TransportDetails";
import TransportDetailsCreate from "./TransportDetailsCreate";

function TransportDetailsManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" href="#classlist" data-toggle="tab">
              Transport Details List
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#createclass" data-toggle="tab">
              Create Transport
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="classlist">
            {<TransportDetails />}
          </div>
          <div className="tab-pane" id="createclass">
            {<TransportDetailsCreate />}
          </div>
        </div>
      </div>
    </>
  );
}

export default TransportDetailsManagement;
