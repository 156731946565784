import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { LoginValidation } from "../../Utility/CommonUtility/Validation";
import { login } from "../../Services/CommonApis/authApi";
import { getSession, setSession } from "../../Services/Storage";
import { Modal } from "react-bootstrap";
import LoginModal from "../../Modals/LoginModal";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";
import { getBranchId, getSchoolId, getUserRoleId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [branchId, setBranchId] = useState(null);

  useEffect(() => {
    const token = getSession(COOKIE.ADMIN_TOKEN);
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const response = await login(values.email, values.password);
      const adminToken = response.data.access_token;
      const roleId = getUserRoleId(adminToken)
      const schoolId = getSchoolId(adminToken)
      const branchId = getBranchId(adminToken);

      if (adminToken) {
        setSession(COOKIE.ADMIN_TOKEN, adminToken);
        setRoleId(roleId);
        setSchoolId(schoolId);
        setBranchId(branchId);
        switch (roleId) {
          case 1:
          case 2:
          case 3:
            setShowModal(true);
            break;
          case 4:
            navigate("/teacher/dashboard");
            break;
          case 5:
            navigate("/staff/dashboard");
            break;
          case USERROLEID.PARENT_ID:
            navigate("/parent/student-profile");
            break;
          case 7:
            navigate("/student/dashboard");
            break;
          default:
            navigate("/admin/dashboard");
            break;
        }
      } else {
        setError("No admin token received.");
        setShowError(true);
        setTimeout(() => setShowError(false), 2000);
      }
    } catch (err) {
      if (err.response) {
        const errorMessage =
          err.response.data.error || err.response.data.message;
        setError(errorMessage);
      } else if (err.request) {
        setError("No response from the server. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAdditionalInfoSubmit = (values) => {
    if (roleId === USERROLEID.ADMIN_ID) {
      navigate("/admin/dashboard");
    } else if (roleId === USERROLEID.SCHOOL_ID && values.branch) {
      setSession(COOKIE.SCHOOL_ID, schoolId);
      setSession(COOKIE.BRANCH_ID, values.branch);
      navigate(`/school/${schoolId}/branch/${values.branch}/dashboard`);
    } else if (roleId === USERROLEID.BRANCH_ID) {
      setSession(COOKIE.SCHOOL_ID, schoolId);
      setSession(COOKIE.BRANCH_ID, branchId);
      navigate(`/school/${schoolId}/branch/${branchId}/dashboard`);
    } else {
      const redirectPath = localStorage.getItem("redirectPath") || "/";
      localStorage.removeItem("redirectPath");
      navigate(redirectPath);
    }
    setShowModal(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="#" className="h1">
              <b><i class="fa-solid fa-school"></i> Login!</b>
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p>

            <Formik
              initialValues={initialValues}
              validationSchema={LoginValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="input-group mb-3">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-error"
                  />

                  <div className="input-group mb-3">
                    <Field
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <div className="input-group-append">
                      <div
                        className="input-group-text"
                        onClick={togglePasswordVisibility}
                      >
                        <span
                          className={`fa ${
                            passwordVisible ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-error"
                  />

                  <div className="row">
                    <div className="col-4">
                      {isSubmitting ? (
                        <button
                          className="btn btn-primary"
                          type="button"
                          style={{
                            width: "125px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                          ></span>
                          Submitting..
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={isSubmitting}
                          style={{ position: "relative" }}
                        >
                          Sign In
                        </button>
                      )}
                    </div>
                  </div>
                  {error && (
                    <div
                      className={`error-alert error-alert-danger ${
                        showError ? "fade-in" : "fade-out"
                      }`}
                      onAnimationEnd={() => {
                        if (!showError) setError("");
                      }}
                    >
                      {error}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              centered
              backdrop="static"
            >
              <LoginModal onSubmit={handleAdditionalInfoSubmit} />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
