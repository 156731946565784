import { React, useState } from "react";
import ParentFeePayment from "./ParentFeePayment";

function ParentFeeManagement() {
  const [activeTab, setActiveTab] = useState("feepay");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "feepay" ? "active" : ""}`}
              onClick={() => handleTabClick("feepay")}
            >
              Fee Paying
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "feeledger" ? "active" : ""
              }`}
              onClick={() => handleTabClick("feeledger")}
            >
              Fee Ledger
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div
            className={`tab-pane ${activeTab === "feepay" ? "active" : ""}`}
            id="feepay"
          >
            {activeTab === "feepay" && <ParentFeePayment/>}
          </div>

          <div
            className={`tab-pane ${activeTab === "feeledger" ? "active" : ""}`}
            id="feeledger"
          >
            {activeTab === "feeledger" && (
              <h1>Currently the feature is not available Comming Soon...!</h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ParentFeeManagement;
