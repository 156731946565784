import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";
import {
  updateAdmissionStatus,
  updateScheduleId,
} from "../../Services/AdminApis/ScheduleApi";

import {
  createEnquiry,
  getAllEnquirys,
  getEnquiryById,
  deleteEnquiryById,
  updateEnquiryById,
} from "../../Services/CommonApis/EnquiryApi";
import { getAllAnnouncements } from "../../Services/CommonApis/AnnouncementApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { createSchedule } from "../../Services/AdminApis/ScheduleApi";
import { getAllSchedules } from "../../Services/AdminApis/ScheduleApi";
const Schedule = () => {
  const [activeTab, setActiveTab] = useState("Allstudents");

  const [showForm, setShowForm] = useState(false);
  const [showstatusModal, setShowstatusModal] = useState(false);

  const [formData, setFormData] = useState(null);
  const [classes, setClasses] = useState([]);
  const [language, setLanguage] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState("");
  const [formAnnouncements, setFormAnnouncements] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [apiMessage, setApiMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedAnnouncementIdAll, setSelectedAnnouncementIdAll] =
    useState(null);
  const [selectedAnnouncementIdRejected, setSelectedAnnouncementIdRejected] =
    useState(null);
  const [modalDetails, setModalDetails] = useState({
    appNotification: "Dear Parent ,Your Child",
    venue: "",
    hallNo: "",
    additionalComments: "",
    admissionfee: "",
    interview_date: "",
    notificationcontent: "",
  });

  const { branchId } = useParams();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handlemodalSubmit = async (values) => {
    let data = { ...values };
    if (data.interview_date) {
      const formattedDate = new Intl.DateTimeFormat("en-GB").format(
        new Date(data.interview_date)
      );
      data.interview_date = formattedDate;
    }
    let dropdown = values.dropdown;
    if (dropdown === "reject") {
      data.schedule_status = 2;
    } else if (dropdown === "schedule") {
      data.schedule_status = 1;
    }

    if (selectedRows && selectedRows.length > 0) {
      data.enquiry_id = selectedRows;
    }

    console.log("Data to Send:", data);

    try {
      let response = await updateScheduleId(data);
      console.log("Response:", response.data);
      if (response) {
        tableInstance.current.ajax.reload(null, false);
        setApiMessage(response.data.message || response.message);
        showSuccessAlert("Scheduled", apiMessage);
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      console.log("Error:", error);
    }

    console.log("Modal Type:", modalType);
    console.log("Modal Data:", modalDetails);
    console.log("Selected Rows:", selectedRows);

    setShowModal(false);
  };

  const handleSubmit = async (values) => {
    let response;
    const id = values.id;
    try {
      const formattedValues = {
        ...values,
        dob: formatDate(values.dob),
        assesment_date: formatDate(values.assesment_date),
      };
      if (formData) {
        response = await updateEnquiryById(id, formattedValues);
      } else {
        response = await createSchedule(formattedValues);
      }
      if (response.status === 200) {
        setShowForm(false);
        tableInstance.current.ajax.reload(null, false);
        setApiMessage(response.data.message || response.message);
        showSuccessAlert("Success", apiMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getAllLanguages();
      setLanguage(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const buttonStyles = {
    display: "inline-flex",
    justifyContent: "center", // Center text horizontally
    alignItems: "center", // Center text vertically
    marginRight: "10px", // Spacing between buttons
    flex: "none", // Prevent flex-grow to avoid stretching
    width: "130px", // Set desired width
    height: "40px", // Set desired height
    textAlign: "center", // Center text
    border: "1px solid transparent", // Remove impact of border on width
    borderRadius: "4px", // Rounded corners
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflowing text
    textOverflow: "ellipsis", // Add ellipsis for overflow text
    padding: "0 10px", // Add padding to the left and right for better spacing
    lineHeight: "normal", // Ensure the text height doesn't stretch
  };

  const primaryStyle = {
    ...buttonStyles,
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    color: "#fff",
  };

  const warningStyle = {
    ...buttonStyles,
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
    color: "#212529",
  };

  const dangerStyle = {
    ...buttonStyles,
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#fff",
  };

  const handlePrintReceipt = (rowData) => {
    // Logic to handle print receipt
    console.log("Print Receipt for:", rowData);
  };

  const handleAdmitCard = (rowData) => {
    // Logic to handle admit card
    console.log("Admit Card for:", rowData);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    announcement_id: Yup.string().required("Announcement name is required"),
    application_fee: Yup.number().required("Application fee is required"),
    application_no: Yup.string().required("Application number is required"),
    class_applied: Yup.string().required("Class applied is required"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid Gmail address"
      )
      .required("Email is required"),
    contact_no: Yup.string().required("Mobile number is required"),
    father_name: Yup.string().required("Father's name is required"),
    comments: Yup.string(),
    dob: Yup.date().required("Date of birth is required"),
    payment_mode: Yup.string().required("Payment mode is required"),
  });
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  const { id } = useParams();
  const fetchEnquiryById = async (id) => {
    try {
      const response = await getEnquiryById(id);
      setFormData(response.data.data);
      setShowForm(true);
    } catch (error) {
      console.error("Error fetching announcement by ID:", error);
    }
  };
  const deleteEnquiry = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete this enquiry!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteEnquiryById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The enquiry has been deleted.");

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the enquiry.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The enquiry is safe 😊");
    }
  };

  const initialValues = {
    announcement_id: "",
  };
  const announcmentvalidationSchema = Yup.object({
    announcement_id: Yup.string().required("Announcement is required"),
  });

  const handleAnnouncementChange = (event, setFieldValue) => {
    console.log("called");
    const value = event.target.value;
    console.log(value);
    setFieldValue("announcement_id", value); // Update Formik field value
    setSelectedAnnouncementIdAll(value); // Update local state
  };
  console.log(selectedAnnouncementIdAll);
  const handlerejectAnnouncementChange = (event, setFieldValue) => {
    const value = event.target.value;
    setFieldValue("announcement_id", value); // Update Formik field value
    setSelectedAnnouncementIdRejected(value); // Update local state
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await getAllAnnouncements(branchId, {});
        setAnnouncements(response.data.data);
        setFormAnnouncements(response.data.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, [branchId]);
  useEffect(() => {
    getclassbybranchID(branchId);
  }, []);
  const getclassbybranchID = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRowSelection = () => {
    const selected = $(".select-row:checked")
      .map(function () {
        return $(this).data("id");
      })
      .get();
    setSelectedRows(selected);
  };

  useEffect(() => {
    if (activeTab === "Allstudents") {
      setSelectedAnnouncementIdRejected(null); // Reset Rejected ID when switching to Allstudents
    } else if (activeTab === "rejectedstudents") {
      setSelectedAnnouncementIdAll(null); // Reset Allstudents ID when switching to rejectedstudents
    }
    const schedule_status = selectedAnnouncementIdRejected ? 2 : 0;

    if (selectedAnnouncementIdRejected || selectedAnnouncementIdAll) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id" },
          {
            data: null,
            title: '<input type="checkbox" id="select-all" />',
            orderable: false,
            searchable: false,
            width: "50px",
            render: (data, type, row) => {
              return `<input type="checkbox" class="select-row" data-id="${row.id}" />`;
            },
          },
          { data: "application_no" },
          { data: "application_fee" },
          { data: "name" },
          { data: "father_name" },
          { data: "contact_no" },
          { data: "class_name" },
          { data: "dob" },
          { data: "email" },
          { data: "assesment_date" },
          { data: "payment_mode" },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              const editIcon = `<a title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>`;
              const deleteIcon = `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
              return `<div class="icon-group" style="display: flex; gap: 10px;">${editIcon}${deleteIcon}</div>`;
            },
          },
        ],
        lengthChange: true,
        ordering: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "asc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getAllSchedules(
              activeTab === "Allstudents"
                ? selectedAnnouncementIdAll
                : selectedAnnouncementIdRejected,
              schedule_status,
              {
                page: page,
                limit: length,
                sortBy: orderColumn,
                sortOrder: orderDirection,
                search: searchValue,
              }
            );

            const jsonData = response.data.data || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }
        },
      });

      // Handle "select all" functionality
      $("#select-all")
        .off("click")
        .on("click", function () {
          const isChecked = $(this).is(":checked");
          $(".select-row").prop("checked", isChecked);
          handleRowSelection();
        });

      $(tableRef)
        .off("click", ".select-row")
        .on("click", ".select-row", function () {
          handleRowSelection();

          const totalCheckboxes = $(".select-row").length;
          const checkedCheckboxes = $(".select-row:checked").length;
          $("#select-all").prop(
            "checked",
            totalCheckboxes === checkedCheckboxes
          );
        });

      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        fetchEnquiryById(id);
      });
      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        deleteEnquiry(id);
      });

      return () => {
        tableInstance.current.destroy();
      };
    }
  }, [
    selectedAnnouncementIdAll,
    selectedAnnouncementIdRejected,
    activeTab,
    refresh,
  ]);

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      venue: type === "schedule" ? prevDetails.venue : "",
      hallNo: type === "schedule" ? prevDetails.hallNo : "",
      additionalComments:
        type === "schedule" ? prevDetails.additionalComments : "",
    }));
    // Capture the selected rows when opening the modal
    setSelectedRows(
      $(".select-row:checked")
        .map(function () {
          return $(this).data("id");
        })
        .get()
    );
  };
  const handleStatusModalSubmit = async (values) => {
    let data = { ...values };

    try {
      const response = await updateAdmissionStatus(selectedRows, data);
      if (response) {
        tableInstance.current.ajax.reload(null, false);
        setApiMessage(response.data.message || response.message);
        showSuccessAlert("Scheduled", apiMessage);
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
    console.log("Selected Status:", data);
  };
  const openstatusModal = (type) => {
    setShowstatusModal(true);

    setSelectedRows(
      $(".select-row:checked")
        .map(function () {
          return $(this).data("id");
        })
        .get()
    );
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="custom-tabs-container">
        <ul className="custom-nav-tabs container" id="myTab" role="tablist">
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${
                activeTab === "Allstudents" ? "active" : ""
              }`}
              id="Allstudents-tab"
              data-toggle="tab"
              href="#Allstudents"
              role="tab"
              aria-controls="Allstudents"
              aria-selected={activeTab === "Allstudents"}
              onClick={() => setActiveTab("Allstudents")}
            >
              <i className="fas fa-book"></i> Allstudents
            </a>
          </li>
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${
                activeTab === "rejectedstudents" ? "active" : ""
              }`}
              id="rejectedstudents-tab"
              data-toggle="tab"
              href="#rejectedstudents"
              role="tab"
              aria-controls="rejectedstudents"
              aria-selected={activeTab === "rejectedstudents"}
              onClick={() => setActiveTab("rejectedstudents")}
            >
              <i className="fas fa-book"></i> Rejected Student List
            </a>
          </li>
        </ul>
      </div>
      {activeTab === "Allstudents" && (
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <Formik
                  initialValues={{ announcement_id: "" }}
                  validationSchema={announcmentvalidationSchema}
                  onSubmit={() => {}}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <div className="form-group row">
                      <label
                        htmlFor="announcement_id"
                        className="col-md-3 col-form-label mb-0"
                        style={{ paddingRight: "5px" }} // Reduce the padding between the label and input
                      >
                        Announcement <span className="mandatory">*</span>
                      </label>
                      <div className="col-md-9 pl-0">
                        <Field
                          name="announcement_id"
                          as="select"
                          className="form-control"
                          style={{
                            borderColor: "skyblue",
                            marginLeft: "-5px",
                          }} // Reduce left margin to bring input closer
                          onChange={(e) =>
                            handleAnnouncementChange(e, setFieldValue)
                          }
                        >
                          <option value="">Select Announcement</option>
                          {announcements.map((announcement) => (
                            <option
                              key={announcement.id}
                              value={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {showForm && (
            <div className="card">
              <div className="col-2 mt-3 ml-2">
                <button
                  className="btn btn-primary"
                  onClick={() => setShowForm(!showForm)}
                >
                  {showForm && (
                    <>
                      <i className="pi pi-arrow-left mr-2"></i> Back
                    </>
                  )}
                </button>
              </div>
              <div className="mb-3 p-4">
                <h5>Admission Form</h5>
                <Formik
                  initialValues={
                    formData || {
                      name: "",
                      announcement_id: "",
                      application_fee: "",
                      application_no: "",
                      class_applied: "",
                      email: "",
                      contact_no: "",
                      father_name: "",
                      comments: "",
                      dob: "",
                      second_language: "",
                      payment_mode: "",
                      assesment_date: "",
                      course_type: [],
                      applicationStatus: "",
                    }
                  }
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row ml-3 mr-5 mt-3">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                              Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="announcement_id"
                              className="form-label"
                            >
                              Select Announcement{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              name="announcement_id"
                              as="select"
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            >
                              <option value="">Select Announcement</option>
                              {announcements.map((announcement) => (
                                <option
                                  key={announcement.id}
                                  value={announcement.id}
                                >
                                  {announcement.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="announcement_id"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="application_fee"
                              className="form-label"
                            >
                              Application Fee{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="number"
                              name="application_fee"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="application_fee"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="application_no"
                              className="form-label"
                            >
                              Application Number{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="application_no"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="application_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="class_applied"
                              className="form-label"
                            >
                              Class Applied <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="class_applied"
                              className="form-control"
                            >
                              <option value="">Select Class</option>
                              {classes.map((cls) => (
                                <option key={cls.id} value={cls.id}>
                                  {cls.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="class_applied"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="contact_no" className="form-label">
                              Mobile Number <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="contact_no"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="contact_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="father_name" className="form-label">
                              Father's Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="father_name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="father_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="comments" className="form-label">
                              Comments
                            </label>
                            <Field
                              as="textarea"
                              name="comments"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="dob" className="form-label">
                              Date of Birth <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="date"
                              name="dob"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="second_language"
                              className="form-label"
                            >
                              Second Language{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              name="second_language"
                              as="select"
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            >
                              <option value="">Select Announcement</option>
                              {language.map((lang) => (
                                <option key={lang.id} value={lang.id}>
                                  {lang.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="second_language"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="payment_mode"
                              className="form-label"
                            >
                              Payment Mode <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="payment_mode"
                              className="form-control"
                            >
                              <option value="">Select Payment Mode</option>
                              <option value="creditCard">Credit Card</option>
                              <option value="debitCard">Debit Card</option>
                              <option value="cash">Cash</option>
                            </Field>
                            <ErrorMessage
                              name="payment_mode"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="course_type">
                              Course Type <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="course_type"
                              className="form-control"
                            >
                              <option value="">Select</option>
                              <option value="1">General</option>
                              <option value="2">Special</option>
                            </Field>
                            <ErrorMessage
                              name="course_type"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="assesment_date">
                              Assessment Date{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="date"
                              name="assesment_date"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="assesment_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="applicationStatus"
                              className="form-label"
                            >
                              Admission Status
                            </label>
                            <Field
                              as="select"
                              name="applicationStatus"
                              className="form-control"
                            >
                              <option value="">Select Status</option>
                              <option value="Submitted">Selected</option>
                              <option value="hold">On Hold</option>
                              <option value="rejected">Rejected</option>
                            </Field>
                            <ErrorMessage
                              name="applicationStatus"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
          {selectedAnnouncementIdAll && (
            <div className="card-body">
              <table
                ref={tableRef}
                id="announcementTable"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Select</th>
                    <th>Application No</th>
                    <th>Application Fees</th>
                    <th>Name</th>
                    <th>father Name</th>
                    <th>Contact No</th>
                    <th>Class Name</th>
                    <th>Date of birth</th>
                    <th>email</th>
                    <th>Assessment Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Select</th>
                    <th>Application No</th>
                    <th>Application Fees</th>
                    <th>Name</th>
                    <th>father Name</th>
                    <th>Contact No</th>
                    <th>Class Name</th>
                    <th>Date of birth</th>
                    <th>email</th>
                    <th>Assessment Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
              </table>
              <div className="pt-3" style={{ display: "flex" }}>
                <button
                  style={primaryStyle}
                  className="btn"
                  onClick={() => openModal("reject")}
                >
                  Schedule status
                </button>
                <button
                  style={warningStyle}
                  className="btn"
                  onClick={() => openstatusModal("updatestatus")}
                >
                  Admission Status
                </button>
              </div>
            </div>
          )}

          {showstatusModal && (
            <div
              className="modal fade show"
              id="statusModal"
              tabIndex="-1"
              aria-labelledby="statusModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <Formik
                    initialValues={{ status: "" }} // Initialize form with status field
                    onSubmit={async (values) => {
                      console.log(values); // Log selected status value
                      await handleStatusModalSubmit(values); // Call the submit handler
                    }}
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="modal-header">
                          <h5 className="modal-title" id="statusModalLabel">
                            Application Status
                          </h5>
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={() => setShowstatusModal(false)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* Dropdown for selecting status */}
                          <div className="form-group">
                            <label htmlFor="admission_status">
                              Select Status
                            </label>
                            <Field
                              as="select"
                              name="admission_status"
                              className="form-control"
                              id="admission_status"
                            >
                              <option value="">Select an option</option>
                              <option value="1">Selected</option>
                              <option value="2-">Rejected</option>
                            </Field>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowstatusModal(false)}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}

          {showModal && (
            <div
              className="modal fade show"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <Formik
                    initialValues={{
                      dropdown: "", // Add a dropdown field for status selection
                      venue: "",
                      interview_date: "",
                      comments: "",
                      appNotification: "",
                    }}
                    onSubmit={async (values) => {
                      console.log(values); // Log form values to the console
                      await handlemodalSubmit(values);
                    }}
                  >
                    {({ values, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Status Update
                          </h5>
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={() => setShowModal(false)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* Dropdown to select status */}
                          <div className="form-group">
                            <label htmlFor="dropdown">Select Status</label>
                            <Field
                              as="select"
                              name="dropdown"
                              className="form-control"
                              id="dropdown"
                            >
                              <option value="">Select an option</option>
                              <option value="schedule">Schedule</option>
                              <option value="hold">Hold</option>
                              <option value="reject">Reject</option>
                            </Field>
                          </div>

                          {/* Conditionally render fields based on dropdown value */}
                          {values.dropdown === "schedule" && (
                            <>
                              <div className="row">
                                <div className="form-group col-6">
                                  <label htmlFor="venue">Venue</label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    id="venue"
                                    name="venue"
                                    placeholder="Enter venue & hall NO"
                                  />
                                  <ErrorMessage
                                    name="venue"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="interview_date">
                                    Interview Date & Time
                                  </label>
                                  <Field
                                    type="date"
                                    className="form-control"
                                    id="interview_date"
                                    name="interview_date"
                                  />
                                  <ErrorMessage
                                    name="interview_date"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="comments">
                                  Additional Comments
                                </label>
                                <Field
                                  as="textarea"
                                  className="form-control"
                                  id="comments"
                                  name="comments"
                                  placeholder="Enter additional comments"
                                />
                              </div>
                            </>
                          )}

                          {values.dropdown === "hold" && (
                            <div className="form-group">
                              <label htmlFor="appNotification">
                                Application Notification
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="appNotification"
                                name="appNotification"
                                placeholder="Enter application notification"
                              />
                              <ErrorMessage
                                name="appNotification"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}

                          {values.dropdown === "reject" && (
                            <div className="form-group">
                              <label htmlFor="appNotification">
                                Rejection Reason
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="appNotification"
                                name="appNotification"
                                placeholder="Enter rejection reason"
                              />
                              <ErrorMessage
                                name="appNotification"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === "rejectedstudents" && (
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <Formik
                  initialValues={{ announcement_id: "" }}
                  validationSchema={announcmentvalidationSchema}
                  onSubmit={() => {}}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <div className="form-group row">
                      <label
                        htmlFor="announcement_id"
                        className="col-md-3 col-form-label mb-0"
                        style={{ paddingRight: "5px" }} // Reduce the padding between the label and input
                      >
                        Announcement <span className="mandatory">*</span>
                      </label>
                      <div className="col-md-9 pl-0">
                        <Field
                          name="announcement_id"
                          as="select"
                          className="form-control"
                          style={{
                            borderColor: "skyblue",
                            marginLeft: "-5px",
                          }} // Reduce left margin to bring input closer
                          onChange={(e) =>
                            handlerejectAnnouncementChange(e, setFieldValue)
                          }
                        >
                          <option value="">Select Announcement</option>
                          {announcements.map((announcement) => (
                            <option
                              key={announcement.id}
                              value={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {showForm && (
            <div className="card">
              <div className="col-2 mt-3 ml-2">
                <button
                  className="btn btn-primary"
                  onClick={() => setShowForm(!showForm)}
                >
                  {showForm && (
                    <>
                      <i className="pi pi-arrow-left mr-2"></i> Back
                    </>
                  )}
                </button>
              </div>
              <div className="mb-3 p-4">
                <h5>Admission Form</h5>
                <Formik
                  initialValues={
                    formData || {
                      name: "",
                      announcement_id: "",
                      application_fee: "",
                      application_no: "",
                      class_applied: "",
                      email: "",
                      contact_no: "",
                      father_name: "",
                      comments: "",
                      dob: "",
                      second_language: "",
                      payment_mode: "",
                      assesment_date: "",
                      course_type: [],
                      applicationStatus: "",
                    }
                  }
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row ml-3 mr-5 mt-3">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                              Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="announcement_id"
                              className="form-label"
                            >
                              Select Announcement{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              name="announcement_id"
                              as="select"
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            >
                              <option value="">Select Announcement</option>
                              {announcements.map((announcement) => (
                                <option
                                  key={announcement.id}
                                  value={announcement.id}
                                >
                                  {announcement.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="announcement_id"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="application_fee"
                              className="form-label"
                            >
                              Application Fee{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="number"
                              name="application_fee"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="application_fee"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="application_no"
                              className="form-label"
                            >
                              Application Number{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="application_no"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="application_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="class_applied"
                              className="form-label"
                            >
                              Class Applied <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="class_applied"
                              className="form-control"
                            >
                              <option value="">Select Class</option>
                              {classes.map((cls) => (
                                <option key={cls.id} value={cls.id}>
                                  {cls.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="class_applied"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="contact_no" className="form-label">
                              Mobile Number <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="contact_no"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="contact_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="father_name" className="form-label">
                              Father's Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="text"
                              name="father_name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="father_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="comments" className="form-label">
                              Comments
                            </label>
                            <Field
                              as="textarea"
                              name="comments"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="dob" className="form-label">
                              Date of Birth <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="date"
                              name="dob"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="second_language"
                              className="form-label"
                            >
                              Second Language{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              name="second_language"
                              as="select"
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            >
                              <option value="">Select Announcement</option>
                              {language.map((lang) => (
                                <option key={lang.id} value={lang.id}>
                                  {lang.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="second_language"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="payment_mode"
                              className="form-label"
                            >
                              Payment Mode <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="payment_mode"
                              className="form-control"
                            >
                              <option value="">Select Payment Mode</option>
                              <option value="creditCard">Credit Card</option>
                              <option value="debitCard">Debit Card</option>
                              <option value="cash">Cash</option>
                            </Field>
                            <ErrorMessage
                              name="payment_mode"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="course_type">
                              Course Type <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              name="course_type"
                              className="form-control"
                            >
                              <option value="">Select</option>
                              <option value="1">General</option>
                              <option value="2">Special</option>
                            </Field>
                            <ErrorMessage
                              name="course_type"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="assesment_date">
                              Assessment Date{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <Field
                              type="date"
                              name="assesment_date"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="assesment_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              htmlFor="applicationStatus"
                              className="form-label"
                            >
                              Admission Status
                            </label>
                            <Field
                              as="select"
                              name="applicationStatus"
                              className="form-control"
                            >
                              <option value="">Select Status</option>
                              <option value="Submitted">Selected</option>
                              <option value="hold">On Hold</option>
                              <option value="rejected">Rejected</option>
                            </Field>
                            <ErrorMessage
                              name="applicationStatus"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
          {selectedAnnouncementIdRejected && (
            <div className="card-body">
              <table
                ref={tableRef}
                id="announcementTable"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Select</th>
                    <th>Application No</th>
                    <th>Application Fees</th>
                    <th>Name</th>
                    <th>father Name</th>
                    <th>Contact No</th>
                    <th>Class Name</th>
                    <th>Date of birth</th>
                    <th>email</th>
                    <th>Assessment Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Select</th>
                    <th>Application No</th>
                    <th>Application Fees</th>
                    <th>Name</th>
                    <th>father Name</th>
                    <th>Contact No</th>
                    <th>Class Name</th>
                    <th>Date of birth</th>
                    <th>email</th>
                    <th>Assessment Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
              </table>
              <div className="pt-3" style={{ display: "flex" }}>
                <button
                  style={primaryStyle}
                  className="btn"
                  onClick={() => openModal("reject")}
                >
                  Schedule status
                </button>
                <button
                  style={warningStyle}
                  className="btn"
                  onClick={() => openstatusModal("updatestatus")}
                >
                  Admission Status
                </button>
              </div>
            </div>
          )}
          {showstatusModal && (
            <div
              className="modal fade show"
              id="statusModal"
              tabIndex="-1"
              aria-labelledby="statusModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <Formik
                    initialValues={{ status: "" }} // Initialize form with status field
                    onSubmit={async (values) => {
                      console.log(values); // Log selected status value
                      await handleStatusModalSubmit(values); // Call the submit handler
                    }}
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="modal-header">
                          <h5 className="modal-title" id="statusModalLabel">
                            Application Status
                          </h5>
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={() => setShowstatusModal(false)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* Dropdown for selecting status */}
                          <div className="form-group">
                            <label htmlFor="admission_status">
                              Select Status
                            </label>
                            <Field
                              as="select"
                              name="admission_status"
                              className="form-control"
                              id="admission_status"
                            >
                              <option value="">Select an option</option>
                              <option value="1">Selected</option>
                            </Field>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowstatusModal(false)}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}

          {showModal && (
            <div
              className="modal fade show"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <Formik
                    initialValues={{
                      dropdown: "", // Add a dropdown field for status selection
                      venue: "",
                      interview_date: "",
                      comments: "",
                      appNotification: "",
                    }}
                    onSubmit={async (values) => {
                      console.log(values); // Log form values to the console
                      await handlemodalSubmit(values);
                    }}
                  >
                    {({ values, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Status Update
                          </h5>
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={() => setShowModal(false)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* Dropdown to select status */}
                          <div className="form-group">
                            <label htmlFor="dropdown">Select Status</label>
                            <Field
                              as="select"
                              name="dropdown"
                              className="form-control"
                              id="dropdown"
                            >
                              <option value="">Select an option</option>
                              <option value="schedule">Schedule</option>
                            </Field>
                          </div>

                          {/* Conditionally render fields based on dropdown value */}
                          {values.dropdown === "schedule" && (
                            <>
                              <div className="row">
                                <div className="form-group col-6">
                                  <label htmlFor="venue">Venue</label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    id="venue"
                                    name="venue"
                                    placeholder="Enter venue & hall NO"
                                  />
                                  <ErrorMessage
                                    name="venue"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="interview_date">
                                    Interview Date & Time
                                  </label>
                                  <Field
                                    type="date"
                                    className="form-control"
                                    id="interview_date"
                                    name="interview_date"
                                  />
                                  <ErrorMessage
                                    name="interview_date"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="comments">
                                  Additional Comments
                                </label>
                                <Field
                                  as="textarea"
                                  className="form-control"
                                  id="comments"
                                  name="comments"
                                  placeholder="Enter additional comments"
                                />
                              </div>
                            </>
                          )}

                          {values.dropdown === "hold" && (
                            <div className="form-group">
                              <label htmlFor="appNotification">
                                Application Notification
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="appNotification"
                                name="appNotification"
                                placeholder="Enter application notification"
                              />
                              <ErrorMessage
                                name="appNotification"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}

                          {values.dropdown === "reject" && (
                            <div className="form-group">
                              <label htmlFor="appNotification">
                                Rejection Reason
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="appNotification"
                                name="appNotification"
                                placeholder="Enter rejection reason"
                              />
                              <ErrorMessage
                                name="appNotification"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Schedule;
