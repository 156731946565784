import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getSession, removeAllSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { showLoginAlert } from "../../Modals/SweetAlertModel";
import PageForbidden from "../../Components/CommonComponents/PageForBidden";
import { USERROLEID } from "../../Schemas/userRoles";
import { getUserRoleId } from "../CommonUtility/extractUserDetailsFromToken";

const ProtectedRoute = ({ allowedRoles = [] }) => {
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [expired, setExpired] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);

  const handleLoginAlert = () => {
    removeAllSession(COOKIE.ADMIN_TOKEN);
    localStorage.setItem("redirectPath", location.pathname);
    setRedirect(true);
  };

  useEffect(() => {
    if (expired && !isAlertShown) {
      setIsAlertShown(true);
      showLoginAlert(
        "Session Expired",
        "Your session has expired, please log in again.",
        "info",
        "Login"
      ).then((result) => {
        if (result.isConfirmed) {
          handleLoginAlert();
        }
      });
    }
  }, [expired, isAlertShown]);

  const adminToken = getSession(COOKIE.ADMIN_TOKEN);

  if (redirect) {
    return <Navigate to="/login" replace />;
  }

  if (!adminToken) {
    localStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/login" replace />;
  }

  const roleId = getUserRoleId(adminToken);
  if (!roleId) {
    console.log("No valid user role found, redirecting to login.");
    removeAllSession(COOKIE.ADMIN_TOKEN);
    localStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/login" replace />;
  }

  const sectionComplete = getSession(COOKIE.SECTION_COMPLETE);

  if (
    roleId === USERROLEID.ADMIN_ID ||
    roleId === USERROLEID.BRANCH_ID ||
    roleId === USERROLEID.SCHOOL_ID
  ) {
    if (sectionComplete !== true) {
      removeAllSession(COOKIE.ADMIN_TOKEN);
      localStorage.setItem("redirectPath", location.pathname);
      return <Navigate to="/login" replace />;
    }
  }

  const isAdminRoute = location.pathname.startsWith("/admin");
  if (
    isAdminRoute &&
    (roleId === USERROLEID.SCHOOL_ID ||
      roleId === USERROLEID.BRANCH_ID ||
      roleId === USERROLEID.PARENT_ID)
  ) {
    return <PageForbidden />;
  }

  return <Outlet />;
};

export default ProtectedRoute;