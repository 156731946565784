import React from "react";
import { useState, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import { getAllClasses } from "../../Services/CommonApis/SectionApi";
import { getAllBranches } from "../../Services/CommonApis/branchApi";
import { getgroupsbybranch } from "../../Services/CommonApis/StudentApi";
import { getmediums } from "../../Services/CommonApis/StudentApi";
import { useParams } from "react-router-dom";
import { getAllAnnouncements } from "../../Services/CommonApis/AnnouncementApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
const AdmissionDetails = ({ steps, current }) => {
  const { setFieldValue, values } = useFormikContext();
  const [academics, setAcademics] = useState([]);
  const [classes, setClasses] = useState([]);
  const [medium, setmedium] = useState([]);
  const [branch, setbranch] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    Academics();
    getclasses();
    getmedium();
  }, []);
  const { branchId } = useParams();
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await getAllAnnouncements(branchId, {});
        setAnnouncements(response.data.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, [branchId]);
  useEffect(() => {
    getclassbybranchID(branchId);
  }, []);
  const getclassbybranchID = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const Academics = async () => {
    const response = await getAllAcademics();
    setAcademics(response.data.data);
  };
  const getclasses = async () => {
    const response = await getAllClasses();
    setClasses(response.data.data);
  };

 
  const getmedium = async () => {
    const response = await getmediums();
    console.log(response.data);
    setmedium(response.data.data);
  };
  const getgroupbybranchid = async (id) => {
    const response = await getgroupbybranchid(id);
    console.log(response);
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 1 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Student Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 1 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="application_type" className="form-label">
                    Application Submission Type{" "}
                    <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    name="application_type"
                    className="form-control"
                  >
                    <option value="">Select Submission Type</option>
                    <option value="online">Online</option>
                    <option value="desk">Desk</option>
                  </Field>
                  <ErrorMessage
                    name="application_type"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="application_no" className="form-label">
                    Application Number <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="text"
                    name="application_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="application_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="fee_book_no" className="form-label">
                    Fee Book No <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="text"
                    name="fee_book_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="fee_book_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="announcement_id" className="form-label">
                    Announcement
                  </label>
                  <Field
                    name="announcement_id"
                    as="select"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select Announcement</option>
                    {announcements.map((announcement) => (
                      <option key={announcement.id} value={announcement.id}>
                        {announcement.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="announcement_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="academic_year_id" className="form-label">
                    Academic Year <span className="mandatory">*</span>
                  </label>
                  <Field
                    name="academic_year_id"
                    as="select"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select Academic Year</option>
                    {academics.map((year) => {
                      const startDate = new Date(year.start_date);
                      const endDate = new Date(year.end_date);

                      const startMonthYear = startDate.toLocaleString(
                        "default",
                        {
                          month: "short",
                          year: "numeric",
                        }
                      );
                      const endMonthYear = endDate.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                      });

                      const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                      return (
                        <option key={year.id} value={year.id}>
                          {formattedDateRange}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="academic_year_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="class_id" className="form-label">
                    Class <span className="mandatory">*</span>
                  </label>
                  <Field as="select" name="class_id" className="form-control">
                    <option value="">Select Class</option>
                    {classes.map((cls) => (
                      <option key={cls.id} value={cls.id}>
                        {cls.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="application_fee" className="form-label">
                    Application Fees
                  </label>
                  <Field
                    type="number"
                    name="application_fee"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="application_fee"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="emis_no" className="form-label">
                    EMIS Number
                  </label>
                  <Field type="text" name="emis_no" className="form-control" />
                  <ErrorMessage
                    name="emis_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="addhar_card_no" className="form-label">
                    Aadhar Number
                  </label>
                  <Field
                    type="text"
                    name="addhar_card_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="addhar_card_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="pan_card_no" className="form-label">
                    Pan Number
                  </label>
                  <Field
                    type="text"
                    name="pan_card_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pan_card_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="admission_no" className="form-label">
                    Admission Number
                  </label>
                  <Field
                    type="text"
                    name="admission_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="admission_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="reg_no" className="form-label">
                    Register Number
                  </label>
                  <Field type="text" name="reg_no" className="form-control" />
                  <ErrorMessage
                    name="reg_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="cse_no" className="form-label">
                    CSE Number
                  </label>
                  <Field type="text" name="cse_no" className="form-control" />
                  <ErrorMessage
                    name="cse_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="file_no" className="form-label">
                    File Number
                  </label>
                  <Field type="text" name="file_no" className="form-control" />
                  <ErrorMessage
                    name="file_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="admission_date" className="form-label">
                    Admission Date <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    name="admission_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="admission_date"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="joining_quota" className="form-label">
                    joining_quota
                  </label>
                  <Field
                    as="select"
                    name="joining_quota"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select joining_quota</option>
                    <option value="General">General</option>
                    <option value="2">SC/ST</option>
                    <option value="4">OBC</option>
                    <option value="1">Minority</option>
                  </Field>
                  <ErrorMessage
                    name="joining_quota"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="quota" className="form-label">
                    quota
                  </label>
                  <Field
                    as="select"
                    name="quota"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select quota</option>
                    <option value="GENERAL">General</option>
                    <option value="4">OBC</option>
                    <option value="1">Minority</option>
                    <option value="None">None</option>

                  </Field>
                  <ErrorMessage
                    name="quota"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="payment_status" className="form-label">
                    Payment Status <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    name="payment_status"
                    className="form-control"
                  >
                    <option value="">Select Submission Type</option>
                    <option value="unpaid">unpaid</option>
                    <option value="paid">paid</option>
                  </Field>
                  <ErrorMessage
                    name="payment_status"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="admission_fee" className="form-label">
                    Admission Fees
                  </label>
                  <Field
                    type="number"
                    name="admission_fee"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="admission_fee"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="admission_status" className="form-label">
                    Admission Status
                  </label>
                  <Field
                    as="select"
                    name="admission_status"
                    className="form-control"
                  >
                    <option value="">Select Status</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="Pending">Pending</option>
                  </Field>
                  <ErrorMessage
                    name="admission_status"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {/* <div className="col-4">
                <div className="form-group">
                  <label htmlFor="applicationStatus" className="form-label">
                    Application Status
                  </label>
                  <Field
                    as="select"
                    name="applicationStatus"
                    className="form-control"
                  >
                    <option value="">Select Status</option>
                    <option value="Submitted">Submitted</option>
                    <option value="In Process">In Process</option>
                  </Field>
                  <ErrorMessage
                    name="applicationStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default AdmissionDetails;
