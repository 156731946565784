import React from "react";
import {
  getStudentByParentId,
  getStudentAttendance,
} from "../../Services/CommonApis/ParentPortalApi";
import { useState, useEffect } from "react";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getBranchId, getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

const AttendanceCalendar = () => {
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const parentId = getParentId(token)
  const branchId = getBranchId(token)
  const [attendance, setAttendance] = useState("");
  const [studentId, setStudentId] = useState("");
  const [absentDays, setAbsentDays] = useState([]);
  console.log(absentDays);

  useEffect(() => {
    if (parentId) {
      fetchStudentByParent(parentId);
    }
  }, []);

  useEffect(() => {
    if (studentId) {
      fetchAttendance();
    }
  }, [studentId]);

  const fetchStudentByParent = async (parentId) => {
    try {
      const response = await getStudentByParentId({ parent_id: parentId });
      setStudentId(response.data.data[0].id);
    } catch (error) {
      console.error("Error fetching student:", error);
    }
  };

  const fetchAttendance = async () => {
    try {
      const response = await getStudentAttendance({
        branch_id: branchId,
        student_id: studentId,
      });

      console.log(response.data.data.absent_day[0].attendance_date);
      setAttendance(response.data.data);

      const absentData = response.data.data.absent_day.map((day) => {
        const [year, month, dayOfMonth] = day.attendance_date.split("-");
        return {
          date: parseInt(dayOfMonth),
          month: parseInt(month),
        };
      });

      console.log(absentData);
      setAbsentDays(absentData);
    } catch (error) {
      console.error("Error fetching attendance:", error);
    }
  };

  const months = [
    {
      name: "June 2024",
      startDay: 6,
      days: 30,
      monthIndex: 6,
      leaveDays: [15],
    },
    {
      name: "July 2024",
      startDay: 1,
      days: 31,
      monthIndex: 7,
      leaveDays: [9],
    },
    {
      name: "August 2024",
      startDay: 4,
      days: 31,
      monthIndex: 8,
      leaveDays: [8],
    },
    {
      name: "September 2024",
      startDay: 0,
      days: 30,
      monthIndex: 9,
      leaveDays: [19],
    },
  ];

  const renderCalendar = (startDay, days, monthIndex) => {
    const weeks = [];
    let currentDay = 1;

    // Create rows for weeks
    for (let i = 0; i < 6; i++) {
      const daysInWeek = [];
      // Create cells for each day of the week
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startDay) {
          daysInWeek.push(<td key={j}></td>);
        } else if (currentDay <= days) {
          // Check if the current day is an absent day for the current month
          const isAbsent = absentDays.some(
            (absentDay) =>
              absentDay.date == currentDay && absentDay.month === monthIndex
          );
          console.log(isAbsent, currentDay, monthIndex);
          daysInWeek.push(
            <td
              key={j}
              style={
                isAbsent
                  ? {
                      border: "2px solid red",
                      borderRadius: "10px",
                      color: "red",
                    }
                  : {}
              }
            >
              {currentDay}
            </td>
          );
          currentDay++;
        } else {
          daysInWeek.push(<td key={j}></td>);
        }
      }
      weeks.push(<tr key={i}>{daysInWeek}</tr>);
    }

    return weeks;
  };

  return (
    <div
      className="calendar-container-attendance"
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      {months.map((month, index) => (
        <div
          key={index}
          className="attendance-month"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            width: "370px",
            boxSizing: "border-box",
          }}
        >
          <h3>{month.name}</h3>
          <table>
            <thead>
              <tr>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
              </tr>
            </thead>
            <tbody>
              {renderCalendar(month.startDay, month.days, month.monthIndex)}
            </tbody>{" "}
          </table>
        </div>
      ))}
    </div>
  );
};

export default AttendanceCalendar;
