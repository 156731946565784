import Api from "../Api";
export const createIdcard = (formData) => {
    return Api.post("/create-id-card", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }); // No need for additional headers for multipart/form-data
  };
  export const getAllids = () => {
    return Api.get("/get-all-id/get-all");
  };
  export const getIdcardById = (id) => {
    return Api.get(`/get-all-id-card/${id}`);
  };
  export const updateIdcardById = (id, data) => {
    return Api.post(`/update-id-card/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  
  export const deleteIdcardById = (id) => {
    return Api.delete(`/delete-id-Card/${id}`);
  };
  export const getAllId = async (id_type) => {
    try {
      const response = await Api.get(`/get-all-id/${id_type}`);
      return response.data;
    } catch (error) {
      throw error;
    }
};