import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getParentsByNum } from "../../Services/CommonApis/StudentApi";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";

const StudentParentDetails = ({ steps, current }) => {
  
  const { setFieldValue, values } = useFormikContext();

  const handlePhoneChange = async (event) => {
    const inputValue = event.target.value;
    setFieldValue("num", inputValue);

    if (inputValue.length === 10) {
      try {
        setFieldValue("pfirst_name", "");
        setFieldValue("pmiddle_name", "");
        setFieldValue("plast_name", "");
        setFieldValue("phone", "");
        setFieldValue("email", "");
        setFieldValue("date_of_birth", "");
        setFieldValue("gender", "");
        setFieldValue("aadhaar_card_no", "");
        setFieldValue("pan_card_no", "");

        const response = await getParentsByNum(inputValue);
        if (response.data.data && response.data.data.id) {
          const parentData = response.data.data;
          const fullname =
            parentData.first_name +
            " " +
            parentData.last_name +
            " (#" +
            parentData.id +
            ")";

          const result = await showSweetAlert(
            "Parent Already Exists with #" + fullname,
            "Do you want to map with existing parents?",
            "You want to delete the Student!",
            "Yes",
            "No"
          );
          if (result.isConfirmed) {
            setFieldValue("parent_id", parentData.id || "");
            setFieldValue("pfirst_name", parentData.first_name || "");
            setFieldValue("pmiddle_name", parentData.middle_name || "");
            setFieldValue("plast_name", parentData.last_name || "");
            setFieldValue("phone", parentData.phone || "");
            setFieldValue("email", parentData.alt_email || "");
            setFieldValue("date_of_birth", parentData.date_of_birth || "");
            setFieldValue("gender", parentData.gender || "");
            setFieldValue("aadhaar_card_no", parentData.aadhaar_card_no || "");
            setFieldValue("pan_card_no", parentData.pan_card_no || "");
          }
        }
      } catch (error) {
        console.error("Error fetching parent data: ", error);
        // Handle the error accordingly (e.g., show an alert or message)
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 3 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Parent Details:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="num">
                  Phone No <span className="mandatory">*</span>
                </label>
                <Field
                  name="num"
                  type="text"
                  className="form-control mr-2"
                  onChange={handlePhoneChange} // Trigger API call when typing
                />
                <ErrorMessage
                  name="num"
                  component="div"
                  className="field-error text-danger mr-2"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="email">
                  Email <span className="mandatory">* </span>
                </label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              {false && (
                <div className="form-group col-4">
                  <label htmlFor="parent_id">
                    Parent ID <span className="mandatory">* </span>
                  </label>
                  <Field
                    name="parent_id"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="parent_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
              )}
              <div className="form-group col-4">
                <label htmlFor="pfirst_name">
                  First Name <span className="mandatory">* </span>
                </label>
                <Field
                  name="pfirst_name"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="pfirst_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="pmiddle_name">
                  Middle Name <span className="mandatory">* </span>
                </label>
                <Field
                  name="pmiddle_name"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="pmiddle_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="plast_name">
                  Last Name <span className="mandatory">* </span>
                </label>
                <Field name="plast_name" type="text" className="form-control" />
                <ErrorMessage
                  name="plast_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="phone">
                  Alt Phone <span className="mandatory">* </span>
                </label>
                <Field
                  name="phone"
                  type="number"
                  className="form-control"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                  }}
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="date_of_birth">
                  Date of Birth <span className="mandatory">* </span>
                </label>
                <Field
                  name="date_of_birth"
                  type="date"
                  className="form-control"
                />
                <ErrorMessage
                  name="date_of_birth"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="gender">
                  Gender <span className="mandatory">* </span>
                </label>
                <Field as="select" name="gender" className="form-control">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="image">
                  Image <span className="mandatory">* </span>
                </label>
                <input
                  name="image"
                  type="file"
                  className="form-control"
                  onChange={handleFileUpload}
                />
                <ErrorMessage
                  name="image"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="aadhaar_card_no">
                  Aadhaar Card No <span className="mandatory">* </span>
                </label>
                <Field
                  name="aadhaar_card_no"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="aadhaar_card_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="pan_card_no">
                  PAN Card No <span className="mandatory">* </span>
                </label>
                <Field
                  name="pan_card_no"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="pan_card_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default StudentParentDetails;
