import React from "react";
import { Field, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { getRoles } from "../../Services/AdminApis/StaffApi";

const StaffInformation = ({
  current,
  steps,
  setUseSchoolAddress,
  setFieldValue,
  values,
}) => {
  const { handleChange, touched, errors } = useFormikContext();
  const [roles, setRoles] = useState([]);

  const getRole = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data); // Ensure response.data is used here
    } catch (err) {
      console.error("API error:", err);
    }
  };

  useEffect(() => {
    getRole();
  }, []);

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 1 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Staff Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 1 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="role_id">
                  Role <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="role_id"
                  name="role_id"
                  style={{ borderColor: getBorderColor("role_id") }}
                >
                  <option value="" label="Select Role" />
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="role_id"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-6">
                <label htmlFor="employee_no">
                  Employee No <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="employee_no"
                  name="employee_no"
                  placeholder="Enter employee code"
                  style={{ borderColor: getBorderColor("employee_no") }}
                />
                <ErrorMessage
                  name="employee_no"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="first_name">
                  First Name <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="first_name"
                  name="first_name"
                  placeholder="Enter first name"
                  style={{ borderColor: getBorderColor("first_name") }}
                />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="middle_name">
                  Middle Name <span className="optional">(Optional)</span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="middle_name"
                  name="middle_name"
                  placeholder="Enter middle name"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="middle_name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="last_name">
                  {" "}
                  Last Name <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="last_name"
                  name="last_name"
                  placeholder="Enter Last Name"
                  style={{ borderColor: getBorderColor("last_name") }}
                />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="email">
                  Email <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                  style={{ borderColor: getBorderColor("email") }}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="epf_no">
                  EPF No <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="epf_no"
                  name="epf_no"
                  placeholder="Enter PF No"
                  style={{ borderColor: getBorderColor("epf_no") }}
                />
                <ErrorMessage
                  name="epf_no"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="uan_no">
                  UAN No <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="uan_no"
                  name="uan_no"
                  placeholder="Enter UAN No"
                  style={{ borderColor: getBorderColor("uan_no") }}
                />
                <ErrorMessage
                  name="uan_no"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="esi_no">
                  ESI No <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="esi_no"
                  name="esi_no"
                  placeholder="Enter ESI No"
                  style={{ borderColor: getBorderColor("esi_no") }}
                />
                <ErrorMessage
                  name="esi_no"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default StaffInformation;
