import React from "react";
import "../../Styles/PageNotFound.css"

function PageNotFound() {
  return (
    <div class="pagenotfound-container">
        <div class="pagenotfound-error">
            <div class="pagenotfound-title">404</div>
            <div class="pagenotfound-subtitle">WE ARE SORRY, PAGE NOT FOUND!</div>
            <div class="pagenotfound-text">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</div>
            <a href="/" class="pagenotfound-btn">Back to Previous Page</a>
        </div>
    </div>
  );
}

export default PageNotFound;
