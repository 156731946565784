import Api from "../Api";

export const createMedium = async (data) => {
  try {
    const response = await Api.post("/create-medium", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMediums = async () => {
  try {
    const response = await Api.get("/get-medums");
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteMediumById = async (id) => {
  try {
    const response = await Api.get(`/delete-medium/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMediumById = async (id, data) => {
  try {
    const response = await Api.post(`/update-medium/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMediumById = async (id) => {
  try {
    const response = await Api.get(`/get-medium/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
