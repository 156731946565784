import React, { useEffect, useState } from "react";
import vinayak from "../../Assests/vinayak.jpg";
import christ from "../../Assests/crist.jpg";
import ram from "../../Assests/rama.webp";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  CreateHoliday,
  updateHoliday,
  deleteHoliday,
  getHolidayById,
  getAllHolidays,
} from "../../Services/CommonApis/ParentPortalApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { COOKIE } from "../../Schemas/cookieNames";
import { getUserRoleId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import { getSession } from "../../Services/Storage";

const holidaysData = [
  {
    name: "New Year's Day",
    day: "Monday",
    holiday_date: "01/01/2024",
    bgColor: "#FFEECC",
  },
  {
    name: "Makar Sankranti",
    day: "Sunday",
    holiday_date: "14/01/2024",
    bgColor: "#FFDAB9",
  },
  {
    name: "Republic Day",
    day: "Friday",
    holiday_date: "26/01/2024",
    bgColor: "#FFCCCB",
  },
  {
    name: "Maha Shivaratri",
    day: "Friday",
    holiday_date: "08/03/2024",
    bgColor: "#E6E6FA",
  },
  { name: "Ugadi", day: "Monday", date: "08/04/2024", bgColor: "#F0E68C" },
  {
    name: "Good Friday",
    day: "Friday",
    holiday_date: "29/03/2024",
    bgColor: "#98FB98",
  },
  { name: "May Day", day: "Wednesday", date: "01/05/2024", bgColor: "#FFDEAD" },
  {
    name: "Independence Day",
    day: "Thursday",
    holiday_date: "15/08/2024",
    bgColor: "#B0E0E6",
  },
  {
    name: "Vinayaka Chavithi",
    day: "Friday",
    holiday_date: "06/09/2024",
    bgColor: "#FFA07A",
    image: vinayak,
  },
  {
    name: "Mahatma Gandhi Jayanti",
    day: "Wednesday",
    holiday_date: "02/10/2024",
    bgColor: "#F5F5DC",
  },
  {
    name: "Dasara",
    day: "Saturday",
    holiday_date: "12/10/2024",
    bgColor: "#FFD700",
    image: ram,
  },
  {
    name: "Deepavali",
    day: "Thursday",
    holiday_date: "31/10/2024",
    bgColor: "#FFFACD",
  },
  {
    name: "Christmas Day",
    day: "Wednesday",
    holiday_date: "25/12/2024",
    bgColor: "#FFE4E1",
    image: christ,
  },
];

function HolidayCarousel() {
  const { branchId } = useParams();
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const role_id = getUserRoleId(token);

  const [holidays, setHolidays] = useState(holidaysData);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editHoliday, setEditHoliday] = useState(null);

  useEffect(() => {
    if (branchId) {
      fetchHolidays(branchId);
    }
  }, []);

  const fetchHolidays = async (branchId) => {
    try {
      const response = await getAllHolidays(branchId);
      console.log(response.data);
      setHolidays(
        response.data.data.length > 0 ? response.data.data : holidaysData
      );
    } catch (error) {
      console.log(error);
      setHolidays(holidaysData);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Holiday name is required")
      .min(3, "Holiday name should be at least 3 characters long"),
    holiday_date: Yup.date()
      .required("Holiday date is required")
      .nullable()
      .min(new Date(), "Holiday date cannot be in the past"),
    image: Yup.mixed().required("Image is required"),
  });

  const handleCreateHoliday = async (values) => {
    try {
      showLoadingAlert();
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }
      await CreateHoliday(formData);
      Swal.close();
      showSuccessAlert("Success!", "The holiday has been created.");
      setShowCreateModal(false);
      // Reload holidays from API
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error creating the holiday.");
      console.error(error);
    }
  };

  const handleEditHoliday = async (id) => {
    try {
      const response = await getHolidayById(id);
      setEditHoliday(response.data);
      setShowEditModal(true);
    } catch (error) {
      showErrorAlert(
        "Error!",
        "There was an error fetching the holiday details."
      );
      console.error(error);
    }
  };

  const handleUpdateHoliday = async (values) => {
    try {
      showLoadingAlert();

      const formData = new FormData();

      for (const key in values) {
        formData.append(key, values[key]);
      }

      await updateHoliday(editHoliday.id, formData);

      Swal.close();
      showSuccessAlert("Success!", "The holiday has been updated.");
      setShowEditModal(false);
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the holiday.");
      console.error(error);
    }
  };

  const handleDeleteHoliday = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteHoliday(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The holiday has been deleted.");
        // Reload holidays from API
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the holiday.");
        console.error(error);
      }
    }
  };

  // Generate random color
  const generateRandomColor = () => {
    const letters = "CDEF"; // Only use high values to get lighter colors
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const holidayTemplate = (holiday) => (
    <div
      className="border-1 surface-border border-round m-2 text-center p-3"
      style={{
        backgroundColor: generateRandomColor(),
        height: "300px",
        position: "relative",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h4>{holiday.name}</h4>
          <p>Date: {holiday.holiday_date}</p>
        </div>
        {holiday.image && (
          <img
            src={holiday.image}
            alt={holiday.name}
            className="mt-5"
            style={{ height: "200px", width: "200px", objectFit: "cover" }}
          />
        )}
      </div>

      {(role_id === 1 || role_id === 2) && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            display: "flex",
          }}
        >
          <button className="btn" onClick={() => handleEditHoliday(holiday.id)}>
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteHoliday(holiday.id)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex justify-content-center align-items-center min-h-screen">
      <h3 className="text-center mt-2 mb-4">Holiday List</h3>

      {(role_id === 1 || role_id === 2) && (
        <div className="d-flex justify-content-end mt-4 mr-3">
          <button
            className="btn btn-primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create Holiday
          </button>
        </div>
      )}

      <div className="d-flex flex-row flex-wrap gap-3 justify-content-center">
        {holidays.map((holiday, index) => (
          <div key={index} className="col-6">
            {holidayTemplate(holiday)}
          </div>
        ))}
      </div>

      {/* Create Holiday Modal */}
      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Holiday</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ name: "", holiday_date: "" }}
          onSubmit={handleCreateHoliday}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Holiday Name</label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Ex: Diwali, New Year"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="holiday_date">Holiday Date</label>
                  <Field
                    type="date"
                    name="holiday_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="holiday_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="image">Holiday Image</label>
                  <input
                    type="file"
                    name="image"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-error"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Edit Holiday Modal */}
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Holiday</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={
            editHoliday || { name: "", holiday_date: "", image: "" }
          }
          validationSchema={validationSchema}
          onSubmit={handleUpdateHoliday}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Holiday Name</label>
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="holiday_date">Holiday Date</label>
                  <Field
                    type="date"
                    name="holiday_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="holiday_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="image">Holiday Image</label>
                  <input
                    type="file"
                    name="image"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-error"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default HolidayCarousel;
