import React, { useState } from "react";
import AdminBookCreate from "./AdminBookCreate";
import AdminBooksList from "./AdminBooksList";

function AdminLibraryManagement() {
  const [activeTab, setActiveTab] = useState("listview");
  const [isEditing, setIsEditing] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setActiveTab("editparent");
  };

  return (
    <>
      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "listview" ? "active" : ""}`}
              onClick={() => handleTabClick("listview")}
            >
              Books List
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createview" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createview")}
            >
              Create Book
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <div class="tab-content">
          <div
            className={`tab-pane ${activeTab === "listview" ? "active" : ""}`}
            id="listview"
          >
            {activeTab === "listview" && (
              <AdminBooksList onEditClick={handleEditClick} />
            )}
          </div>

          <div
            className={`tab-pane ${activeTab === "createview" ? "active" : ""}`}
            id="createview"
          >
            {activeTab === "createview" && <AdminBookCreate />}
          </div>

          {isEditing && (
            <div className={`tab-pane active`} id="editparent">
              SHiv
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminLibraryManagement;
