import React from "react";
import TransportPilotDetails from "./TransportRoutes";
import TransportRoutesList from "./TransportRoutesList";

function TransportRouteManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" href="#classlist" data-toggle="tab">
              Route List
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#createclass" data-toggle="tab">
              Create Route
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="classlist">
            {<TransportRoutesList />}
          </div>
          <div className="tab-pane" id="createclass">
            {<TransportPilotDetails />}
          </div>
        </div>
      </div>
    </>
  );
}

export default TransportRouteManagement;
