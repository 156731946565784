import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import {
  createTransportRoute,
  getTransportRouteById,
  updateTransportRouteById,
} from "../../Services/CommonApis/TransportApi";

const TransportRoutes = ({ editrouteId }) => {
  const { branchId } = useParams();
  const libraries = ["places"];
  const [editStartPoint, setEditStartPoint] = useState("");
  const [editEndPoint, setEditEndPoint] = useState("");
  const [editDistance, setEditDistance] = useState("");
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(0);
  };

  useEffect(() => {
    if (editrouteId) {
      fetchRoute(editrouteId);
    }
  }, [editrouteId]);

  const fetchRoute = async (editrouteId) => {
    try {
      const response = await getTransportRouteById(editrouteId);
      const { start_point, end_point, distance } = response.data;
      setEditStartPoint(start_point);
      setEditEndPoint(end_point);
      setEditDistance(distance);
      setStartLocation(start_point);
      setEndLocation(end_point);
    } catch (error) {
      console.error("Error fetching route:", error);
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyD5JCUIe7Ig7tKEOkHHYE9TNkybW_JKilY",
    libraries,
  });

  const [startLocation, setStartLocation] = useState("");
  const [endLocation, setEndLocation] = useState("");
  const [startCoords, setStartCoords] = useState(null);
  const [endCoords, setEndCoords] = useState(null);

  const startAutocompleteRef = useRef(null);
  const endAutocompleteRef = useRef(null);

  const onPlaceChanged = (locationType, setFieldValue) => {
    if (locationType === "start" && startAutocompleteRef.current) {
      const place = startAutocompleteRef.current.getPlace();
      const formattedAddress = place.formatted_address || "";
      setStartLocation(formattedAddress);
      setFieldValue("start_point", formattedAddress);

      if (place.geometry) {
        const newStartCoords = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setStartCoords(newStartCoords);

        if (endCoords) {
          const distance = calculateDistance(newStartCoords, endCoords).toFixed(
            2
          );
          setFieldValue("distance", distance);
        }
      }
    } else if (locationType === "end" && endAutocompleteRef.current) {
      const place = endAutocompleteRef.current.getPlace();
      const formattedAddress = place.formatted_address || "";
      setEndLocation(formattedAddress);
      setFieldValue("end_point", formattedAddress);

      if (place.geometry) {
        const newEndCoords = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setEndCoords(newEndCoords);

        if (startCoords) {
          const distance = calculateDistance(startCoords, newEndCoords).toFixed(
            2
          );
          setFieldValue("distance", distance);
        }
      }
    }
  };

  const calculateDistance = (startCoords, endCoords) => {
    const { lat: lat1, lng: lon1 } = startCoords;
    const { lat: lat2, lng: lon2 } = endCoords;

    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371;

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const handleCreateOrUpdateTransportRoute = async (values) => {
    const transportRouteData = {
      start_point: startLocation,
      end_point: endLocation,
      distance: values.distance,
      start_latitude: startCoords ? startCoords.lat : "",
      start_logitude: startCoords ? startCoords.lng : "",
      end_latitude: endCoords ? endCoords.lat : "",
      end_logitude: endCoords ? endCoords.lng : "",
    };

    try {
      if (editrouteId) {
        await updateTransportRouteById(editrouteId, {
          ...transportRouteData,
          branch_id: branchId,
        });
        Swal.fire(
          "Success!",
          "The Transport Route has been updated.",
          "success"
        );
      } else {
        await createTransportRoute({
          ...transportRouteData,
          branch_id: branchId,
        });
        Swal.fire(
          "Success!",
          "The Transport Route has been created.",
          "success"
        );
      }
      handleBackClick();
    } catch (error) {
      console.log(error);
      Swal.fire(
        "Error!",
        "There was an error saving the Transport Route.",
        "error"
      );
    }
  };

  const validationSchema = Yup.object({
    start_point: Yup.string().required("Start point is required"),
    end_point: Yup.string().required("End point is required"),
    distance: Yup.string().required("Distance is required"),
  });

  const autocompleteOptions = {
    componentRestrictions: { country: "IN" },
  };
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              {editrouteId ? (
                <h1> Edit Transport Route</h1>
              ) : (
                <h1>Add New Transport Route</h1>
              )}
            </div>
            {editrouteId ? (
              <div className="col-sm-6 text-right">
                <button onClick={handleBackClick} className="btn btn-primary ">
                  Back
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={{
                  start_point: editStartPoint || "",
                  end_point: editEndPoint || "",
                  distance: editDistance || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleCreateOrUpdateTransportRoute}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <div className="d-flex flex-row flex-wrap">
                      <div className="form-group col-6">
                        <label htmlFor="start_point">Start Point</label>
                        <Autocomplete
                          options={autocompleteOptions}
                          onLoad={(autocomplete) =>
                            (startAutocompleteRef.current = autocomplete)
                          }
                          onPlaceChanged={() => {
                            onPlaceChanged("start", setFieldValue);
                          }}
                        >
                          <input
                            type="text"
                            name="start_point"
                            className="form-control"
                            placeholder="Enter Start Point"
                            value={startLocation}
                            onChange={(e) => setStartLocation(e.target.value)}
                          />
                        </Autocomplete>
                        <ErrorMessage
                          name="start_point"
                          component="div"
                          className="text-error"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label htmlFor="end_point">End Point</label>
                        <Autocomplete
                          options={autocompleteOptions}
                          onLoad={(autocomplete) =>
                            (endAutocompleteRef.current = autocomplete)
                          }
                          onPlaceChanged={() => {
                            onPlaceChanged("end", setFieldValue);
                          }}
                        >
                          <input
                            type="text"
                            name="end_point"
                            className="form-control"
                            placeholder="Enter End Point"
                            value={endLocation}
                            onChange={(e) => setEndLocation(e.target.value)}
                          />
                        </Autocomplete>
                        <ErrorMessage
                          name="end_point"
                          component="div"
                          className="text-error"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label htmlFor="distance">Distance (km)</label>
                        <Field
                          type="text"
                          name="distance"
                          className="form-control"
                          placeholder="Enter Distance"
                          value={values.distance}
                        />
                        <ErrorMessage
                          name="distance"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="form-group ml-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TransportRoutes;
