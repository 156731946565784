import Api from "../Api";

export const createQualification = async (data) => {
  try {
    const response = await Api.post("/create-qualification", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getQualifications = async (params) => {
  return await Api.get("/get-qualifications",{params});
};

export const deleteQualificationById = async (id) => {
  try {
    const response = await Api.delete(`/delete-qualification/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateQualificationById = async (id, data) => {
  try {
    const response = await Api.post(`/update-qualification/${id}`, data, {
      params: {
        id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getQualificationById = async (id) => {
  try {
    const response = await Api.get(`/get-qualification/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
