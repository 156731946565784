import Api from "../Api";
export const createFees = (name) => {
  return Api.post("/create-fees-type", { name });
};
export const getAllFees = (params) => {
  return Api.get("/get-fees-types", { params });
};
export const getFeesById = async (id) => {
  const response = await Api.get(`/get-fees-type/${id}`);
  return response.data;
};
export const updateFeesById = async (id, data) => {
  const response = await Api.post(`/update-fees-type/${id}`, data);
  return response.data;
};
export const deleteFees = (id) => {
  return Api.delete(`/delete-fees-type/${id}`);
};
// api for fees timeline
export const createFeesTimeline = (name, installments) => {
  return Api.post("/create-fees-timeline", { name, installments });
};
export const getAllFeesTimelines = (params) => {
  return Api.get("/get-fees-timelines", { params });
};
export const getFeesTimeLinesById = async (id) => {
  const response = await Api.get(`/get-fees-timeline/${id}`);
  return response.data;
};
export const updateFeesTimeLinesById = async (id, data) => {
  const response = await Api.post(`/update-fees-timeline/${id}`, data);
  return response.data;
};
export const deleteFeesTimeLines = (id) => {
  return Api.delete(`/delete-fees-timeline/${id}`);
};
// api for feesPayTypes
export const createFeesPay = (name) => {
  return Api.post("/create-fees-pay-types", { name });
};
export const getAllFeesPay = (params) => {
  return Api.get("/get-fees-pay-types", { params });
};
export const getFeesPayById = async (id) => {
  const response = await Api.get(`/get-fees-pay-types/${id}`);
  return response.data;
};
export const updateFeesPayById = async (id, data) => {
  const response = await Api.post(`/update-fees-pay-types/${id}`, data);
  return response.data;
};
export const deleteFeesPay = (id) => {
  return Api.delete(`/destroy-fees-pay-types/${id}`);
};
// api for feeDiscount
export const createFeesDiscount = (data) => {
  return Api.post("/create-fees-discount-type", data);
};
export const getAllFeesDiscount = (params) => {
  return Api.get("/get-fees-discount-types", { params });
};
export const getFeesDiscountsById = async (id) => {
  const response = await Api.get(`/get-fees-discount-type/${id}`);
  return response.data;
};
export const updateFeesDiscountById = async (id, data) => {
  const response = await Api.post(`/update-fees-discount-type/${id}`, data);
  return response.data;
};
export const deleteFeesDiscount = (id) => {
  return Api.delete(`/destroy-fees-discount-type/${id}`);
};

export const createAcademicSetup = (data) => {
  return Api.post(`/create-academic-setup`, data);
};

export const getAcademicSetups = async (params) => {
  return Api.get(`/get-academic-setups`, { params });
};

export const getAcademicSetup = async (id) => {
  return Api.get(`/get-academic-setup/${id}`);
};

export const updateAcademicSetup = async (id, data) => {
  return Api.post(`/update-academic-setup/${id}`, data);
};

export const createAcademicPayments = async (data) => {
  return Api.post(`/create-academic-payments`, data);
};

export const getAcademicPayments = async (params) => {
  return Api.get(`/get-academic-payments`, { params });
};

export const getStudentAcademicFee = async (params) => {
  return Api.get(`/get-student-academic-fees`, { params });
};

export const getPaymentMethod = async (params) => {
  return Api.get(`/get-academic-payment-method`, { params });
};

export const getFeesDashboardData = async (params) => {
  return await Api.get("/get-fees-dashboard", { params });

};
