import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";

import * as Yup from "yup";

const ConcernsList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [concerns, setConcerns] = useState([
    {
      id: 1,
      date: "01-09-2024",
      studentName: "John Doe",
      concern: "Bullying incident in playground",
      status: "Resolved",
    },
    {
      id: 2,
      date: "10-09-2024",
      studentName: "Jane Smith",
      concern: "Lack of concentration in class",
      status: "Pending",
    },
  ]);

  // Initialize DataTable
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      data: concerns,
      columns: [
        { data: "date", title: "Date" },
        { data: "studentName", title: "Student Name" },
        { data: "concern", title: "Concern" },
        { data: "status", title: "Status" },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      paging: true,
      pageLength: 5,
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, [concerns]);

  // Formik initial values and validation schema
  const initialValues = {
    date: "",
    studentName: "",
    concern: "",
    status: "Pending",
  };

  const handleCreateConcern = (values) => {
    const newConcern = { ...values, id: concerns.length + 1 };
    setConcerns([...concerns, newConcern]);
    setShowCreateModal(false);
    if (tableInstance.current) {
      tableInstance.current
        .clear()
        .rows.add([...concerns, newConcern])
        .draw();
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Concerns List</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Concerns List Table
                  </h3>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConcernsList;
