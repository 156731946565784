import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

// Custom Option component with modified styles
const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} style={{ backgroundColor: 'transparent' }}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          marginRight: "10px",
          accentColor: "#007bff", // This will ensure the tick is blue
        }}
      />{" "}
      <label>{children}</label>
    </components.Option>
  );
};

const MultiSelectDropdown = ({ options, name, label, setFieldValue, value }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (selectedValues.length === options.length) {
      setFieldValue(name, options);
    }
  }, [selectedValues, options, setFieldValue, name]);

  const handleChange = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedValues(values);
    setFieldValue(name, selectedOptions);
  };

  const handleSelectAll = () => {
    if (selectedValues.length === options.length) {
      setSelectedValues([]);
      setFieldValue(name, []);
    } else {
      const allValues = options.map(option => option.value);
      setSelectedValues(allValues);
      setFieldValue(name, options);
    }
  };

  const customOptions = [
    { value: "selectAll", label: "Select All" },
    ...options
  ];

  // Custom styles to prevent the entire option from turning blue
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'transparent' : 'white',
      color: 'black',
      ':hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: '#ced4da',
      ':hover': {
        borderColor: '#007bff',
      },
    }),
  };

  return (
    <div className="form-group">
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <Select
        isMulti
        name={name}
        options={customOptions}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option }}
        onChange={(selectedOptions) => {
          if (selectedOptions.some(option => option.value === "selectAll")) {
            handleSelectAll();
          } else {
            handleChange(selectedOptions);
          }
        }}
        value={value}
        styles={customStyles} // Apply custom styles here
      />
    </div>
  );
};

export default MultiSelectDropdown;
