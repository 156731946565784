import Api from "../Api";
export const createAcademic = (data) => {
  return Api.post(`create-academic`, data);
};
export const getAllAcademics = (params) => {
  return Api.get(`get-academics`,{params});
};
export const deleteAcademicById = (id) => {
  return Api.delete(`/delete-academic/${id}`);
};
export const getAcademicById = (id) => {
  return Api.get(`/get-academic/${id}`);
};
export const updateAcademicById = (id, data) => {
  return Api.post(`/update-academic/${id}`, data);
};
