import React, { useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";

const BranchInformation = ({ current, steps }) => {
  const { values, handleChange, setFieldValue, touched, errors } =
    useFormikContext();

  // Function to generate branch code
  const generateBranchCode = (branchName) => {
    if (!branchName) return "";

    const words = branchName.trim().split(" ");

    if (words.length === 1) {
      return words[0].substring(0, 3).toUpperCase();
    }

    return words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  useEffect(() => {
    const branchCode = generateBranchCode(values.branch_name);
    setFieldValue("branch_code", branchCode);
  }, [values.branch_name, setFieldValue]);

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  // Handle the change event for the offline_payments checkbox
  const handleperiodAttendanceChange = (event) => {
    const isChecked = event.target.checked;
    setFieldValue("period_attendance", isChecked ? "1" : "0");
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 2 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Branch Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 2 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="branch_name">
                  Branch Name <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="branchName"
                  name="branch_name"
                  placeholder="Enter branch name"
                  style={{ borderColor: getBorderColor("branch_name") }}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="branch_name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="branch_code">
                  Branch Code <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="branchCode"
                  name="branch_code"
                  placeholder="Enter branch code"
                  style={{ borderColor: getBorderColor("branch_code") }}
                />
                <ErrorMessage
                  name="branch_code"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="branch_email">
                  Email <span className="mandatory">* </span>
                </label>
                <Field
                  type="email"
                  className="form-control"
                  id="email"
                  name="branch_email"
                  placeholder="Enter email"
                  style={{ borderColor: getBorderColor("branch_email") }}
                />
                <ErrorMessage
                  name="branch_email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="branch_phone">
                  Mobile No <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="mobileNo"
                  name="branch_phone"
                  placeholder="Enter mobile number"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                  }}
                  style={{
                    borderColor: getBorderColor("branch_phone"),
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage
                  name="branch_phone"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6 d-flex ml-0 ">
                <Field
                  type="checkbox"
                  id="period_attendance"
                  name="period_attendance"
                  className="form-control mt-2 "
                  checked={values.period_attendance === "1"}
                  onChange={handleperiodAttendanceChange}
                  style={{ width: "20px", height: "20px" }}
                />
                <label htmlFor="period_attendance" className="mt-1 ml-2">
                  Enable Period wise attendance?
                </label>
              </div>
              <div className="form-group col-6 d-flex  flex-row">
                <label className="mt-1">
                  Select Subject for<span className="mandatory">* </span>
                </label>
                <div className="d-flex align-items-center ml-3 mb-1">
                  <div className="form-check mr-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="subject_select"
                      name="subject_select"
                      value="1"
                    />
                    <label
                      className="form-check-label mt-1 "
                      htmlFor="subject_select"
                    >
                      Class
                    </label>
                  </div>
                  <div className="form-check mr-3 ">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="married"
                      name="subject_select"
                      value="0"
                      checked={values.subject_select === "0"}
                    />
                    <label className="form-check-label mt-1" htmlFor="married">
                      Section
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group col-6 d-flex  flex-row">
                <label className="mt-1">
                  Personality Traits<span className="mandatory">* </span>
                </label>
                <div className="d-flex align-items-center ml-3 mb-1">
                  <div className="form-check mr-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="subject_select"
                      name="personality_traits"
                      value="1"
                      checked={values.personality_traits === "1"}
                    />
                    <label
                      className="form-check-label mt-1 "
                      htmlFor="subject_select"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check mr-3 ">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="married"
                      name="personality_traits"
                      value="0"
                    />
                    <label className="form-check-label mt-1" htmlFor="married">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default BranchInformation;
