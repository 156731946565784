import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  deleteSubjectById,
  getAllSubjects,
  createSubject,
  getSubjectById,
  updateSubjectById,
  getSubjectTypes,
} from "../../Services/CommonApis/subjectApi";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SubjectList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editSubjectId, setEditSubjectId] = useState(null);
  const [editSubjectName, setEditSubjectName] = useState("");
  const [subjectTypes, setSubjectTypes] = useState([]);
  const [editSubjectType, setEditSubjectType] = useState("");
  const [editIsOptional, setEditIsOptional] = useState("0");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSubjectTypes = async () => {
      try {
        const response = await getSubjectTypes();
        setSubjectTypes(response.data);
      } catch (error) {
        console.error("Error fetching subject types:", error);
      }
    };
    fetchSubjectTypes();
  }, []);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteSubjectById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The Subject has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the subject.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The subject is safe :)");
    }
  };

  const handleCreateSubject = async (values) => {
    const subjectData = {
      name: values.name,
      type: values.type,
      isoptional: values.isoptional,
    };

    try {
      showLoadingAlert();
      await createSubject(subjectData);
      Swal.close();
      showSuccessAlert("Success!", "The Subject has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        if (errors && errors.name && errors.name.length > 0) {
          showErrorAlert("Error!", errors.name[0]);
        } else {
          showErrorAlert(
            "Error!",
            message || "There was an error creating the subject."
          );
        }
      } else {
        showErrorAlert("Error!", "An unexpected error occurred.");
      }
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getSubjectById(id);
      const { name, type, isoptional } = response.data;
      setEditSubjectId(id);
      setEditSubjectName(name);
      setEditSubjectType(type);
      setEditIsOptional(isoptional);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching subject details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the subject details."
      );
    }
  };

  const handleUpdateSubject = async (values) => {
    try {
      showLoadingAlert();
      await updateSubjectById(editSubjectId, {
        name: values.name,
        type: values.type,
        isoptional: values.isoptional,
      });
      Swal.close();
      showSuccessAlert("Success!", "The Subject has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        if (errors && errors.name && errors.name.length > 0) {
          showErrorAlert("Error!", errors.name[0]);
        } else if (message) {
          showErrorAlert("Error!", message);
        } else {
          showErrorAlert(
            "Error!",
            "An unexpected error occurred while fetching subject details."
          );
        }
      } else {
        showErrorAlert("Error!", "An unexpected error occurred.");
      }
    }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      processing: false,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        loadingRecords: "",
        zeroRecords: "No matching records found",
        processing: "",
      },
      ajax: async function (data, callback) {
        setIsLoading(true);
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllSubjects({
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          if (jsonData.length >= 0) {
            setIsLoading(false);
          }

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false); // Hide skeleton on error
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Subject name is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Subject Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Subject Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Subject List Table
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={() => setShowCreateModal(true)}
          >
            Create New
          </button>
        </div>
        <div className="card-body">
          {isLoading ? (
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>
                    <Skeleton height={20} width={50} />
                  </th>
                  <th>
                    <Skeleton height={20} width={150} />
                  </th>
                  <th>
                    <Skeleton height={20} width={100} />
                  </th>
                  <th>
                    <Skeleton height={20} width={80} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array(10)
                  .fill()
                  .map((_, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton height={20} width={50} />
                      </td>
                      <td>
                        <Skeleton height={20} width={150} />
                      </td>
                      <td>
                        <Skeleton height={20} width={100} />
                      </td>
                      <td>
                        <Skeleton height={20} width={80} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <table
              ref={tableRef}
              className="table table-striped table-bordered"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>

      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Subject</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreateSubject}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Subject Name</label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter subject name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <div className="d-flex flex-row">
                    <div className="form-check mr-3">
                      <Field
                        type="radio"
                        name="textCase"
                        value="uppercase"
                        className="form-check-input"
                        id="uppercase"
                        onChange={() => {
                          setFieldValue("name", values.name.toUpperCase());
                          setFieldValue("textCase", "uppercase");
                        }}
                      />
                      <label className="form-check-label" htmlFor="uppercase">
                        UPPERCASE
                      </label>
                    </div>
                    <div className="form-check mr-3">
                      <Field
                        type="radio"
                        name="textCase"
                        value="lowercase"
                        className="form-check-input"
                        id="lowercase"
                        onChange={() => {
                          setFieldValue("name", values.name.toLowerCase());
                          setFieldValue("textCase", "lowercase");
                        }}
                      />
                      <label className="form-check-label" htmlFor="lowercase">
                        lowercase
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="radio"
                        name="textCase"
                        value="titlecase"
                        className="form-check-input"
                        id="titlecase"
                        onChange={() => {
                          const titleCasedName = values.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ");
                          setFieldValue("name", titleCasedName);
                          setFieldValue("textCase", "titlecase");
                        }}
                      />
                      <label className="form-check-label" htmlFor="titlecase">
                        Title Case
                      </label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Subject</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            name: editSubjectName,
            type: editSubjectType,
            isoptional: editIsOptional,
          }}
          validationSchema={validationSchema}
          onSubmit={handleUpdateSubject}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Subject Name</label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter subject name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex flex-row">
                    <div className="form-check mr-3">
                      <Field
                        type="radio"
                        name="textCase"
                        value="uppercase"
                        className="form-check-input"
                        id="uppercase"
                        onChange={() => {
                          setFieldValue("name", values.name.toUpperCase());
                          setFieldValue("textCase", "uppercase");
                        }}
                      />
                      <label className="form-check-label" htmlFor="uppercase">
                        UPPERCASE
                      </label>
                    </div>
                    <div className="form-check mr-3">
                      <Field
                        type="radio"
                        name="textCase"
                        value="lowercase"
                        className="form-check-input"
                        id="lowercase"
                        onChange={() => {
                          setFieldValue("name", values.name.toLowerCase());
                          setFieldValue("textCase", "lowercase");
                        }}
                      />
                      <label className="form-check-label" htmlFor="lowercase">
                        lowercase
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="radio"
                        name="textCase"
                        value="titlecase"
                        className="form-check-input"
                        id="titlecase"
                        onChange={() => {
                          const titleCasedName = values.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ");
                          setFieldValue("name", titleCasedName);
                          setFieldValue("textCase", "titlecase");
                        }}
                      />
                      <label className="form-check-label" htmlFor="titlecase">
                        Title Case
                      </label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SubjectList;
