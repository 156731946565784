import Api from "../Api";

export const getStudentByParentId = (params) => {
  return Api.get(`/get-student-byparent`, { params });
};

export const getStudentAttendance = (params) => {
  return Api.get(`/get-attendance-report-bystudentid`, { params });
};

export const getStudentById = (id) => {
  return Api.get(`/get-student/${id}`);
};

export const CreateLeave = (data) => {
  return Api.post(`/create-leave`, data);
};

export const getAllLeaves = (params) => {
  return Api.get(`/get-leaves`, { params });
};

export const updateLeave = (id, data) => {
  return Api.post(`/update-leave/${id}`, data);
};

export const deleteLeave = (id) => {
  return Api.delete(`/delete-leave/${id}`);
};

export const getLeave = (params) => {
  console.log(params);
  return Api.get(`/get-leave-bystudent`, { params });
};

export const getLeaveById = (id) => {
  return Api.get(`/get-leave/${id}`);
};

export const CreateHoliday = async (data) => {
  try {
    const response = await Api.post("/create-holidays", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateHoliday = async (id,data) => {
  try {
    const response = await Api.post(`/update-holidays/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllHolidays = () => {
  return Api.get(`/get-holidays`);
};


export const deleteHoliday = (id) => {
  return Api.delete(`/delete-holidays/${id}`);
};

export const getHolidayById = (id) => {
  return Api.get(`/get-holidays/${id}`);
};
