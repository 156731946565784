import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../Styles/AdmissionCreate.css";
import Spinner from "react-bootstrap/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import StudentInformation from "../../Components/CommonComponents/StudentAcademicInformation";
import StudentDetails from "../../Components/CommonComponents/StudentDetails";

import PersonalInfo from "../../Components/CommonComponents/StudentPersonalInformation";
import UserDetails from "../../Components/CommonComponents/StudentUserDetails";
import { StudentValidation } from "../../Utility/CommonUtility/Validation";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import {
  createStudent,
  getstudentByid,
  updatestudent,
} from "../../Services/CommonApis/StudentApi";
import StudentParentDetails from "../../Components/CommonComponents/StudentParentDetails";
import AdmissionDetails from "../../Components/CommonComponents/AdmissionDetails";
import AdmissionPersonalInfo from "../../Components/CommonComponents/Personalinfo";
import AdmissionContactInfo from "../../Components/CommonComponents/AdmissionContactInfo";
import AdmissionOtherDetails from "../../Components/CommonComponents/AdmissionOtherDetails";
import { createAdmission, getAdmissionById, updateAdmissionById } from "../../Services/CommonApis/AdmissionApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
const AdmissionCreate = ({ admissionId }) => {
  console.log("seclected if",admissionId)

  const { branchId, id } = useParams();
  console.log(id)
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = 4;
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [student, setStudent] = useState("");


  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  useEffect(() => {
    if (admissionId) {
      getAdmissiondetailsbyid(admissionId);
    }
  }, [admissionId]);
 
  const getAdmissiondetailsbyid=async(admissionId)=>{
    try{
      const response=await getAdmissionById(admissionId);
      console.log(response.data.data)
      setStudent(response.data.data)

    }
    catch(error){
      console.log(error)

    }
  }
  
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (values) => {
    console.log(values)
    let formatteddate;
    console.log(branchId)
    const updatedValues = { ...values, branch_id: branchId };
    console.log(updatedValues);
    if (updatedValues.admission_date) {
      formatteddate=updatedValues.admission_date = formatDate(updatedValues.admission_date);
    }
    if (updatedValues.date_of_birth) {
      formatteddate=updatedValues.date_of_birth = formatDate(updatedValues.date_of_birth);
    }

    try {
      let response;
      if (admissionId) {
        const id=admissionId
        response = await updateAdmissionById(id, updatedValues);
      } else {
        
        response = await createAdmission(updatedValues);
      }

      setApiMessage(response.data.message || response.message);
      showSuccessAlert("Success", apiMessage);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    branch_id: "", // Added for branch_id
    announcement_id: "", // Added for announcement_id
    application_type: "", // No change
    academic_year_id: "", // No change
    first_name: "", // No change
    middle_name: "", // No change
    last_name: "", // No change
    fee_book_no: "", // No change
    place_of_birth: "", // No change
    mother_tongue: "", // Changed to match mother_tongue
    physically_challenged: "no", // Updated from physically_challenge
    neet_applicable: "no", // No change
    transport_required: "", // No change
    class_id: "", // No change
    reg_no: "", // No change
    emis_no: "", // No change
    cse_no: "", // No change
    file_no: "", // No change
    admission_no: "", // No change
    admission_fee: "", // Added for admission_fee
    admission_status: "", // Added for admission_status
    application_no: "", // No change
    application_fee: "", // No change
    application_status: "", // Added for application_status
    admission_date: "", // No change
    joining_quota: "", // No change
    first_lang_id: "", // No change
    second_lang_id: "", // No change
    third_lang_id: "", // No change
    achievements: "", // No change
    area_of_interest: "", // No change
    additional_skills: "", // No change
    previous_school: "", // Updated from previous_school_name
    last_study_course: "", // Updated from previous_class
    last_exam_marks: "", // Updated from previous_class_percentage
    reason_change: "", // Updated from reason_for_change
    reason_gap: "", // No change
    date_of_birth: "", // No change
    gender: "", // No change
    blood_group: "", // No change
    religion: "", // No change
    caste: "", // No change
    image: "", // No change
    nationality: "", // Added for nationality
    aadhaar_card_no: "", // Updated from addhar_card_no
    pan_card_no: "", // No change
    address: "", // Added for address
    city: "", // Added for city
    state: "", // Added for state
    country: "", // Added for country
    pin: "", // Added for pin
    payment_status: "", // Added for payment_status
    extra_curricular_activities: "", // Updated from extra_curricular_activites
    school_enquiry: "", // No change
    hostel_required: "", // No change
    identification_mark_1: "", // Updated from identification_mark
    identification_mark_2: "", // No change
    sports: "", // No change
    volunteer: "", // No change
    father_name: "", // No change
    father_last_name: "", // No change
    father_phone: "", // No change
    father_email: "", // No change
    father_education: "", // No change
    father_occupation: "", // No change
    annual_income: "", // No change
    father_aadhaar_no: "", // No change
    father_pan_card: "", // No change
    mother_name: "", // No change
    mother_phone: "", // No change
    mother_email: "", // No change
    mother_education: "", // No change
    mother_occupation: "", // No change
    mother_annual_income: "", // No change
    mother_aadhaar_no: "", // No change
    mother_pan_card: "", // No change
    status: "", // No change
  };
  

  const getInitialValues = () => {
  if (student) {
    return {
      branch_id: student?.branch_id || "",
      announcement_id: student?.announcement_id || "",
      application_type: student?.application_type || "",
      academic_year_id: student?.academic_year_id || "",
      mother_phone:student?.mother_phone||"",
      first_name: student?.first_name || "",
      middle_name: student?.middle_name || "",
      phone:student?.phone||"",
      last_name: student?.last_name || "",
      fee_book_no: student?.fee_book_no || "",
      place_of_birth: student?.place_of_birth || "",
      mother_tongue: student?.mother_tongue || "",
      physically_challenged: student?.physically_challenge || "no",
      neet_applicable: student?.neet_applicable || "no",
      transport_required: student?.transport_required || "",
      class_id: student?.class_id || "",
      reg_no: student?.reg_no || "",
      emis_no: student?.emis_no || "",
      cse_no: student?.cse_no || "",
      file_no: student?.file_no || "",
      admission_no: student?.admission_no || "",
      admission_fee: student?.admission_fee || "",
      admission_status: student?.admission_status || "",
      application_no: student?.application_no || "",
      application_fee: student?.application_fee || "",
      application_status: student?.application_status || "",
      admission_date: student?.admission_date || "",
      joining_quota: student?.joining_quota || "",
      first_lang_id: student?.first_lang_id || "",
      second_lang_id: student?.second_lang_id || "",
      third_lang_id: student?.third_lang_id || "",
      achievements: student?.achievements || "",
      area_of_interest: student?.area_of_interest || "",
      additional_skills: student?.additional_skills || "",
      previous_school: student?.previous_school || "",
      last_study_course: student?.last_study_course || "",
      last_exam_marks: student?.last_exam_marks || "",
      reason_change: student?.reason_change || "",
      reason_gap: student?.reason_gap || "",
      date_of_birth: student?.date_of_birth || "",
      gender: student?.gender || "",
      blood_group: student?.blood_group || "",
      religion: student?.religion || "",
      cast: student?.cast || "",
      image: student?.image || "",
      nationality: student?.nationality || "",
      addhar_card_no: student?.addhar_card_no || "",
      pan_card_no: student?.pan_card_no || "",
      address: student?.address || "",
      city: student?.city || "",
      state: student?.state || "",
      country: student?.country || "",
      pin: student?.pin || "",
      payment_status: student?.payment_status || "",
      extra_curricular_activities: student?.extra_curricular_activites || "",
      school_enquiry: student?.school_enquiry || "",
      hostel_required: student?.hostel_required || "",
      identification_mark: student?.identification_mark || "",
      identification_mark_two: student?.identification_mark_two || "",
      sports: student?.sports || "",
      volunteer: student?.volunteer || "",
      father_name: student?.father_name || "",
      father_last_name: student?.father_last_name || "",
      father_phone: student?.father_phone || "",
      father_email: student?.father_email || "",
      father_education: student?.father_education || "",
      father_occupation: student?.father_occupation || "",
      annual_income: student?.annual_income || "",
      father_aadhaar_no: student?.father_aadhaar_no || "",
      father_pan_card: student?.father_pan_card || "",
      mother_name: student?.mother_name || "",
      mother_phone: student?.mother_phone || "",
      mother_email: student?.mother_email || "",
      mother_education: student?.mother_education || "",
      mother_occupation: student?.mother_occupation || "",
      mother_annual_income: student?.mother_annual_income || "",
      mother_aadhaar_no: student?.mother_aadhaar_no || "",
      mother_pan_card: student?.mother_pan_card || "",
      status: student?.status || "",
      extra_curricular_activites:student?.extra_curricular_activites||"",
    };
  } else {
    return initialValues;
  }
};

  return (
    <div className="head-card">
      <div className="row ml-4 mr-6">
        <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
          <Formik
            initialValues={getInitialValues()}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, setFieldValue, errors }) => (
              <Form id="msform">
                <ul id="progressbar">
                  <li
                    className={current >= 1 ? "active" : ""}
                    id="account"
                    onClick={() => goToStep(1)}
                    style={{ width: "  250px" }}
                  >
                    <strong>Student Admission Details</strong>
                  </li>
                  <li
                    className={current >= 2 ? "active" : ""}
                    id="personal"
                    onClick={() => goToStep(2)}
                    style={{ width: "  250px" }}
                  >
                    <strong>Personal Details</strong>
                  </li>

                  <li
                    className={current >= 3 ? "active" : ""}
                    id="payment"
                    onClick={() => goToStep(3)}
                    style={{ width: "  250px" }}
                  >
                    <strong>Contact Info</strong>
                  </li>
                  <li
                    className={current >= 4 ? "active" : ""}
                    id="settings"
                    onClick={() => goToStep(4)}
                    style={{ width: "  250px" }}
                  >
                    <strong>Additional Details</strong>
                  </li>
                </ul>
                <div className="progress">
                  <div
                    ref={progressBarRef}
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <br />
                {current === 1 && (
                  <AdmissionDetails
                    steps={steps}
                    current={1}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                )}
                {current === 2 && (
                  <AdmissionPersonalInfo steps={steps} current={2} />
                )}
                {current === 3 && (
                  <AdmissionContactInfo steps={steps} current={3} />
                )}
                {current === 4 && (
                  <AdmissionOtherDetails steps={steps} current={current} />
                )}

                {/* {current >= steps && (
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title mt-3 ml-3">Finish:</h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps mr-3 mt-3">
                            Step {steps} - {steps}
                          </h2>
                        </div>
                      </div>
                      <h2 className="purple-text text-center">
                        <strong>Success!</strong>
                      </h2>
                      <br />
                      <br />
                      <div className="row justify-content-center">
                        <div className="col-7 text-center">
                          {apiMessage && <h5>{apiMessage}</h5>}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                )} */}
                <div className="button-group">
                  {current > 1 && current < steps && (
                    <button
                      type="button"
                      className="previous action-button-previous"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                  )}
                  {current < 4 && (
                    <button
                      type="button"
                      className="next action-button"
                      onClick={() => setCurrent(current + 1)}
                    >
                      Next
                    </button>
                  )}
                  {current === 4 && (
                    <button
                      type="submit"
                      className="action-button"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {loading && <Spinner animation="border" variant="primary" />}
        </div>
      </div>
    </div>
  );
};
export default AdmissionCreate;
