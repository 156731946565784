import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getGroups } from "../../Services/CommonApis/GroupApi";



const notificationMethods = [
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
  { label: "App", value: "app" },
];

// Yup validation schema
const validationSchema = Yup.object().shape({
  selectedGroup: Yup.array().min(1, "At least one group must be selected"),
  notificationMethods: Yup.array().min(
    1,
    "At least one notification method must be selected"
  ),
  message: Yup.string().required("Message is required"),
});

function GroupNotifications() {

  const [groups,setGroups]=useState([])

  useEffect(()=>{
    fetchGroups()
  },[])

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      setGroups(response.data.data);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Group Notifications</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Send Notifications</h3>
                </div>

                <div className="card-body">
                  <Formik
                    initialValues={{
                      selectedGroup: [],
                      notificationMethods: [],
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit=""
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          {/* MultiSelect for group selection */}
                          <div className="form-group col-md-6 mb-4 d-flex flex-column">
                            <label
                              htmlFor="group"
                              className="form-label fw-bold"
                            >
                              Select Group
                            </label>
                            <MultiSelect
                              value={values.selectedGroup}
                              options={groups}
                              onChange={(e) =>
                                setFieldValue("selectedGroup", e.value)
                              }
                              optionLabel="name"
                              placeholder="Select Group"
                              className="form-group"
                              filter
                              maxSelectedLabels={3}
                            />
                            <ErrorMessage
                              name="selectedGroup"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          {/* MultiSelect for notification methods */}
                          <div className="form-group col-md-6 mb-4 d-flex flex-column">
                            <label
                              htmlFor="notificationMethods"
                              className="form-label fw-bold"
                            >
                              Notification Methods
                            </label>
                            <MultiSelect
                              value={values.notificationMethods}
                              options={notificationMethods}
                              onChange={(e) =>
                                setFieldValue("notificationMethods", e.value)
                              }
                              optionLabel="label"
                              placeholder="Select Notification Methods"
                              className="form-group"
                              filter
                              maxSelectedLabels={3}
                            />
                            <ErrorMessage
                              name="notificationMethods"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          {/* Message box */}
                          <div className="form-group col-12 mb-4">
                            <label
                              htmlFor="message"
                              className="form-label fw-bold"
                            >
                              Notification Message
                            </label>
                            <InputTextarea
                              value={values.message}
                              onChange={(e) =>
                                setFieldValue("message", e.target.value)
                              }
                              rows={5}
                              placeholder="Enter your notification message..."
                              className="form-control shadow-sm rounded"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="form-group text-left">
                          <Button
                            label="Submit"
                            type="submit"
                            className="btn btn-primary"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GroupNotifications;
