import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getStates } from "../../Services/AdminApis/StateandDistrictApi";

const UserDetails = ({ current, steps }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const [states, setStates] = useState([]);

  const [selectedFileName, setSelectedFileName] = useState("");
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsSameAddress(checked);

    if (checked) {
      setFieldValue("temp_city", values.city);
      setFieldValue("temp_state", values.state);
      setFieldValue("temp_pin", values.pin);
      setFieldValue("tmp_address", values.address);
    } else {
      setFieldValue("temp_city", "");
      setFieldValue("temp_state", "");
      setFieldValue("temp_pin", "");
      setFieldValue("tmp_address", "");
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setSelectedFileName(file.name);
      setFieldValue("image", file);
    }
  };

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      <fieldset>
        <div className="form-card">
          <div className="row">
            <div className="col-7">
              <h2 className="fs-title mt-3 ml-3">User Details:</h2>
            </div>
            <div className="col-5">
              <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="aadhaar_card_no">
                Aadhaar Card No <span className="mandatory">* </span>{" "}
              </label>
              <Field
                type="text"
                className="form-control"
                id="aadhaar_card_no"
                name="aadhaar_card_no"
                placeholder="Enter Aadhaar Card number"
                style={{
                  borderColor: getBorderColor("aadhaar_card_no"),
                }}
              />
              <ErrorMessage
                name="aadhaar_card_no"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="pan_card_no">
                Pan Card No <span className="mandatory">* </span>{" "}
              </label>
              <Field
                type="text"
                className="form-control"
                id="pan_card_no"
                name="pan_card_no"
                placeholder="Enter PAN Card number"
                style={{
                  borderColor: getBorderColor("pan_card_no"),
                }}
              />
              <ErrorMessage
                name="pan_card_no"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="city">
                City <span className="mandatory">* </span>{" "}
              </label>
              <Field
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="Enter city"
                style={{ borderColor: getBorderColor("city") }}
              />
              <ErrorMessage
                name="city"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="state">
                State <span className="mandatory">* </span>{" "}
              </label>
              <Field
                as="select"
                className="form-control"
                id="state"
                name="state"
                style={{
                  borderColor: getBorderColor("state"),
                  borderRadius: "0px",
                }}
              >
                {isStateLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value="" label="Select State" />
                    {states.map((state) => (
                      <option
                        key={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage
                name="state"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="pin">
                Pin <span className="mandatory">* </span>{" "}
              </label>
              <Field
                type="number"
                className="form-control"
                id="pin"
                name="pin"
                placeholder="Enter pin"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 6);
                }}
                style={{
                  borderColor: getBorderColor("pin"),
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
              <ErrorMessage
                name="pin"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-12">
              <label htmlFor="address">
                Address <span className="mandatory">* </span>{" "}
              </label>
              <Field
                as="textarea"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter address"
                style={{ borderColor: getBorderColor("address") }}
              />
              <ErrorMessage
                name="address"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="form-group col-12 ml-3">
            <input
              type="checkbox"
              id="sameAsPermanent"
              checked={isSameAddress}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="sameAsPermanent" className="ml-2">
              Same as Permanent Address
            </label>
          </div>

          <h5 className="ml-4 mt-2">Temporary Address</h5>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="temp_city">
                City <span className="optional">(Optional)</span>
              </label>
              <Field
                type="text"
                className="form-control"
                id="temp_city"
                name="temp_city"
                placeholder="Enter temporary city"
                style={{ borderColor: "skyblue" }}
              />
              <ErrorMessage
                name="temp_city"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="temp_state">
                State<span className="optional">(Optional)</span>
              </label>
              <Field
                as="select"
                className="form-control"
                id="temp_state"
                name="temp_state"
                placeholder="Enter temporary state"
                style={{ borderColor: "skyblue" }}
              >
                {isStateLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value="" label="Select State" />
                    {states.map((state) => (
                      <option
                        key={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage
                name="temp_state"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="temp_pin">
                Pin <span className="optional">(Optional)</span>
              </label>
              <Field
                type="text"
                className="form-control"
                id="temp_pin"
                name="temp_pin"
                placeholder="Enter temporary pin"
                style={{ borderColor: "skyblue" }}
              />
              <ErrorMessage
                name="temp_pin"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-12">
              <label htmlFor="tmp_address">
                Address <span className="optional">(Optional)</span>
              </label>
              <Field
                as="textarea"
                className="form-control"
                id="tmp_address"
                name="tmp_address"
                placeholder="Enter temporary address"
                style={{ borderColor: "skyblue" }}
              />
              <ErrorMessage
                name="tmp_address"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="image">
                Upload Image<span className="optional">(Optional)</span>
              </label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="image"
                    name="image"
                    onChange={handleFileChange}
                    style={{ borderColor: "skyblue" }}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="image"
                    style={{ borderColor: "skyblue" }}
                  >
                    {selectedFileName || "Choose file"}
                  </label>
                </div>
              </div>
              <ErrorMessage
                name="image"
                component="div"
                className="text-error"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default UserDetails;
