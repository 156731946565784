import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { createAttendance } from "../../Services/AdminApis/AttendanceApi";
import { useParams } from "react-router-dom";
import { COOKIE } from "../../Schemas/cookieNames";
import { setSession, getSession, removeSession } from "../../Services/Storage";
import { getUserEmail } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

function IndividualStudentAttendance() {
  const { branchId } = useParams();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [sections, setSections] = useState([]);
  const [disabledClasses, setDisabledClasses] = useState(new Set());
  const [isStateloading, setIsStateloading] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [formikValues, setFormikValues] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);

  const [studentsByClass, setStudentsByClass] = useState([]);

  const token = getSession(COOKIE.ADMIN_TOKEN);
  const email = getUserEmail(token);

  useEffect(() => {
    fetchClasses(branchId);
  }, []);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
    }
  };

  const validationSchema = Yup.object({
    date: Yup.date().required("Date is required"),
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
  });

  const filteredClasses = classes.map(({ id, name, branch_id }) => ({
    id,
    name,
    branch_id,
  }));

  useEffect(() => {
    if (date) {
      const formattedDate = formatDate(date);
    }
  }, [date]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    setEnteredOtp(pastedData.padEnd(6, ""));
  };

  const handleVerifyOTP = async () => {
    try {
      await submitAttendance(formikValues, enteredOtp, otpToken); // Submit form with OTP
    } catch (error) {
      console.error("Error during OTP verification:", error);
    }
  };

  const handleStudentSelection = (isChecked, studentId) => {
    setSelectedStudentIds((prevSelected) =>
      isChecked
        ? [...prevSelected, studentId]
        : prevSelected.filter((id) => id !== studentId)
    );
  };

  const generateOtpToken = () => {
    const characters =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const length = 10;
    let otpToken = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      otpToken += characters[randomIndex];
    }
    return otpToken;
  };

  const Attendance = async (data) => {
    try {
      const response = await createAttendance(data);
    } catch (error) {
      console.error("Error:", error.data.message);
    }
  };

  const submitAttendance = async (values, otp = null, otpToken) => {
    const attendanceData = {
      [values.sectionSelection]: JSON.stringify(selectedStudentIds),
    };
    const data = {
      branch_id: branchId,
      class_id: values.class_id,
      attendance: attendanceData,
      date: formatDate(values.date),
      otp_token: otpToken,
      otp: otp,
      notification: values.notification,
      notification_type: "Attendance",
      subject_id: "",
    };

    try {
      await Attendance(data);

      if (!otp) {
        setShowOtpPopup(true);
      } else {
        setShowOtpPopup(false);
        setStudentsByClass([]);
        setSelectedStudents([]);
        setDate("");
        setOtp(null);
        setEnteredOtp("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchSections = async (classId, branchId) => {
    try {
      const response = await getSectionByClassId(classId, branchId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
    }
  };

  const fetchStudentsByClass = async (branchId, classId, sectionId) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );
      setStudentsByClass(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          date: date,
          class_id: "",
          section_id: "",
          notification: [],
        }}
        onSubmit={async (values, formikHelpers) => {
          try {
            setFormikValues(values);
            const otpToken = generateOtpToken();
            setOtpToken(otpToken);

            await submitAttendance(values, null, otpToken);
          } catch (error) {
            console.error("Error during submission:", error);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                border: "1px solid skyblue",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <div style={{ backgroundColor: "skyblue", padding: "10px" }}>
                <h5
                  style={{
                    marginTop: "0px",
                    color: "white",
                  }}
                >
                  <i className="fas fa-calendar-check"></i> Add Attendance
                </h5>
              </div>

              <div className="d-flex flex-row justify-content-center mt-3 mb-3">
                <div className="col-4">
                  <label htmlFor="date">
                    Select Date<span className="mandatory">* </span>
                  </label>
                  <Field
                    type="date"
                    name="date"
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue("date", e.target.value);
                      setDate(e.target.value);
                    }}
                    style={{ borderColor: "skyblue" }}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="col-4">
                  <label>
                    Select Class<span className="mandatory">* </span>
                  </label>
                  <Field
                    as="select"
                    name="class_id"
                    className="form-control"
                    style={{
                      borderColor: "skyblue",
                      paddingBottom: "2px",
                      paddingTop: "0px",
                    }}
                    onChange={(event) => {
                      const selectedClassId = event.target.value;
                      setFieldValue("class_id", selectedClassId);

                      const selectedClass = filteredClasses.find(
                        (classItem) => classItem.id == selectedClassId
                      );
                      if (selectedClass) {
                        const classId = selectedClass.id;
                        const branchId = selectedClass.branch_id;

                        fetchSections(classId, branchId);
                        setSections([]);
                        setFieldValue("sectionSelection", []);
                        setStudentsByClass({});
                        setSelectedStudents({});
                      } else {
                        console.warn("No class selected");
                      }
                    }}
                  >
                    {isStateloading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select class</option>
                        {filteredClasses.map((classItem) => (
                          <option
                            key={classItem.id}
                            value={classItem.id}
                            disabled={disabledClasses.has(classItem.id)}
                            style={{
                              cursor: disabledClasses.has(classItem.id)
                                ? "not-allowed"
                                : "default",
                            }}
                          >
                            {classItem.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="col-4">
                  <label htmlFor="sectionSelection">
                    Select Section<span className="mandatory">* </span>
                  </label>
                  <Field
                    as="select"
                    name="sectionSelection"
                    className="form-control"
                    style={{
                      borderColor: "skyblue",
                      paddingBottom: "2px",
                      paddingTop: "0px",
                    }}
                    onChange={(event) => {
                      const selectedSectionId = event.target.value;
                      setFieldValue("sectionSelection", selectedSectionId);
                      fetchStudentsByClass(
                        branchId,
                        values.class_id,
                        selectedSectionId
                      );
                    }}
                  >
                    {isStateloading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Section</option>
                        {sections.map((sectionItem) => (
                          <option key={sectionItem.id} value={sectionItem.id}>
                            {sectionItem.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="sectionSelection"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>
            </div>
            <div className="card mt-5">
              <DataTable value={studentsByClass}>
                <Column
                  field="sno"
                  header="S.No"
                  body={(rowData, { rowIndex }) => rowIndex + 1}
                ></Column>

                <Column
                  header="Mark as Absent"
                  body={(rowData) => (
                    <input
                      type="checkbox"
                      checked={selectedStudentIds.includes(rowData.id)}
                      onChange={(e) =>
                        handleStudentSelection(e.target.checked, rowData.id)
                      }
                    />
                  )}
                ></Column>

                <Column field="roll_no" header="Roll No"></Column>
                <Column
                  body={(rowData) =>
                    `${rowData.first_name} ${rowData.last_name}`
                  }
                  header="Student Name"
                ></Column>
              </DataTable>
            </div>

            <div
              className="d-flex flex-row justify-content-between align-items-center mt-3"
              style={{
                border: "1px solid skyblue",
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "15px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "20px",
                }}
              >
                Send By:
              </p>
              <div className="d-flex justify-content-around flex-grow-1 ml-3">
                {[
                  {
                    id: "whatsapp-notification",
                    label: "WhatsApp",
                    value: "1",
                  },
                  { id: "email-notification", label: "Email", value: "4" },
                  { id: "sms-notification", label: "SMS", value: "3" },
                  { id: "app-notification", label: "App", value: "2" },
                ].map((checkbox) => (
                  <div key={checkbox.id}>
                    <input
                      type="checkbox"
                      id={checkbox.id}
                      name="notification"
                      className="mr-2"
                      value={checkbox.value}
                      checked={values.notification.includes(checkbox.value)}
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        let newNotifications = [...values.notification];

                        if (checked) {
                          newNotifications.push(value);
                        } else {
                          newNotifications = newNotifications.filter(
                            (notification) => notification !== value
                          );
                        }

                        setFieldValue("notification", newNotifications);
                      }}
                    />
                    <label htmlFor={checkbox.id}>{checkbox.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary mb-2 mt-3"
                style={{
                  width: "100px",
                }}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showOtpPopup && (
        <>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              zIndex: 1000,
            }}
          />
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1001,
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            <div className="text-center">
              <h5>
                Please verify the attendance data
                <br />
                and enter the code sent to
                <br />
                {email}
              </h5>
            </div>
            <div className="d-flex justify-content-center mb-3">
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  className="form-control mx-1 text-center"
                  style={{ width: "40px" }}
                  maxLength="1"
                  value={enteredOtp[index] || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setEnteredOtp((prev) => {
                      const newOtp = [...prev];
                      newOtp[index] = value;
                      return newOtp;
                    });
                  }}
                  onPaste={handlePaste}
                />
              ))}
            </div>
            <button onClick={handleVerifyOTP} className="btn btn-primary w-75">
              Verify OTP
            </button>
            <button
              onClick={() => setShowOtpPopup(false)}
              style={{
                backgroundColor: "red",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Close
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default IndividualStudentAttendance;
