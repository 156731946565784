import React, { useEffect, useState } from "react";
import { getSchoolDetailsById } from "../../Services/AdminApis/schoolApi";
import { useParams } from "react-router-dom";
import { getBranchById } from "../../Services/CommonApis/branchApi";
import ParentList from "./ParentList";
import ParentEdit from "./ParentEdit"; // Import ParentEdit component

function ParentManagement() {
  const [activeTab, setActiveTab] = useState("listview");
  const [isEditing, setIsEditing] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setActiveTab("editparent");
  };

  return (
    <>
      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "listview" ? "active" : ""}`}
              onClick={() => handleTabClick("listview")}
            >
              Parent List
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "gridview" ? "active" : ""}`}
              onClick={() => handleTabClick("gridview")}
            >
              Grid View
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "incompleteparents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("incompleteparents")}
            >
              Incomplete Parents
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "mappedparents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("mappedparents")}
            >
              Mapped Parents
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <div class="tab-content">
          <div
            className={`tab-pane ${activeTab === "listview" ? "active" : ""}`}
            id="listview"
          >
            {activeTab === "listview" && <ParentList onEditClick={handleEditClick} />}
          </div>

          <div
            className={`tab-pane ${activeTab === "gridview" ? "active" : ""}`}
            id="gridview"
          >
            {activeTab === "gridview" && (
              <h1>Currently the feature is not available Comming Soon...!</h1>
            )}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "incompleteparents" ? "active" : ""
            }`}
            id="incompleteparents"
          >
            {activeTab === "incompleteparents" && (
              <h1>Currently the feature is not available Comming Soon...!</h1>
            )}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "mappedparents" ? "active" : ""
            }`}
            id="mappedparents"
          >
            {activeTab === "mappedparents" && (
              <h1>Currently the feature is not available Comming Soon...!</h1>
            )}
          </div>

          {isEditing && (
            <div className={`tab-pane active`} id="editparent">
              <ParentEdit />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ParentManagement;
