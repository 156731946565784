import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import {
  getSectionByClassId,
} from "../../Services/CommonApis/SectionApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import {
  GetCertificateType,
  getCertificateFieldsByType,
  generateCertificate,
} from "../../Services/AdminApis/CertificateApi";
import { useParams } from "react-router-dom";

function GenerateCertificate() {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const tableInstance = useRef(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const { schoolId, branchId } = useParams();

  useEffect(() => {
    fetchClasses();
    getCertificates();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const fetchStudents = async (branchId, classId, sectionId) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );
      setStudents(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const fetchFields = async (id) => {
    try {
      const response = await getCertificateFieldsByType(id);
      setFields(response.data.certificate_fields);
    } catch (error) {
      console.error("Error fetching certificate fields:", error);
    }
  };

  const getCertificates = async () => {
    try {
      const response = await GetCertificateType();
      setCertificateTypes(response.data.certificate_types);
    } catch (error) {
      console.error("Error fetching certificate types:", error);
    }
  };

  const createHandlerCertificate = async (values) => {
    try {
      showLoadingAlert();
      const payload = {
        certificate_type: values.certificate,
        school_id: schoolId,
        branch_id: branchId,
        student_id: values.student,
        certificate_data: [
          {
            class_id: values.class,
            section_id: values.section,
          },
        ],
      };

      const response = await generateCertificate(payload);
      Swal.close();
      showSuccessAlert("Success!", "The Certificate is Generated.");

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error generating certificate:", error.response.data);
      alert(`Error: ${error.response.data.message}`);
    }
  };

  const handleClassChange = (event, setFieldValue) => {
    const selectedClassId = event.target.value;
    setSelectedClass(selectedClassId);
    setFieldValue("class", selectedClassId);
    fetchSections(selectedClassId);
  };

  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);

    if (selectedClass && selectedSectionId) {
      fetchStudents("1", selectedClass, selectedSectionId);
    }
  };

  const handleStudentChange = (event, setFieldValue) => {
    const selectedStudentId = event.target.value;
    setSelectedStudent(selectedStudentId);
    setFieldValue("student", selectedStudentId);
  };

  const textAreaBodyTemplate = () => <input type="text" className="form-control" />;
  const checkboxBodyTemplate = () => <input type="checkbox" />;

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Certificate Generation</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <Formik
                  initialValues={{
                    class: "",
                    section: "",
                    student: "",
                    certificate: "",
                  }}
                  onSubmit={(values) => createHandlerCertificate(values)}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <div className="d-flex flex-row flex-wrap p-5">
                        <div className="col-4">
                          <label>Select Class:</label>
                          <Field
                            as="select"
                            name="class"
                            className="form-control"
                            value={selectedClass}
                            onChange={(e) => handleClassChange(e, setFieldValue)}
                          >
                            <option value="">Select Class</option>
                            {classes.map((cls) => (
                              <option key={cls.id} value={cls.id}>
                                {cls.name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-4">
                          <label>Select Section:</label>
                          <Field
                            as="select"
                            name="section"
                            className="form-control"
                            value={selectedSection}
                            onChange={(e) => handleSectionChange(e, setFieldValue)}
                          >
                            <option value="">Select Section</option>
                            {sections.map((sec) => (
                              <option key={sec.id} value={sec.id}>
                                {sec.name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-4">
                          <label>Select Student:</label>
                          <Field
                            as="select"
                            name="student"
                            className="form-control"
                            value={selectedStudent}
                            onChange={(e) => handleStudentChange(e, setFieldValue)}
                          >
                            <option value="">Select Student</option>
                            {students.map((stu) => (
                              <option key={stu.id} value={stu.id}>
                                {stu.first_name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-4 mt-2">
                          <label>Certificate Type:</label>
                          <Field
                            as="select"
                            name="certificate"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("certificate", e.target.value);
                              fetchFields(e.target.value);
                            }}
                          >
                            <option value="">Select Certificate</option>
                            {certificateTypes.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.certificate_type}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-12 mt-4">
                          <DataTable value={fields} showGridlines stripedRows>
                            <Column field="field_label" header="Field Name" />
                            <Column body={checkboxBodyTemplate} header="Pdf Download" />
                            <Column body={textAreaBodyTemplate} header="Details" />
                          </DataTable>
                        </div>

                        <button type="submit" className="btn btn-primary mt-3 ml-2">
                          Generate Certificate
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GenerateCertificate;
