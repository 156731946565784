import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getStaffByBranchId } from "../../Services/AdminApis/StaffApi";
import { useParams } from "react-router-dom";

// Staff type options
const staffTypeOptions = [
  { label: "Teaching", value: "teaching" },
  { label: "Non-Teaching", value: "non-teaching" },
  { label: "All", value: "all" },
];

// Notification methods
const notificationMethods = [
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
  { label: "APP", value: "app" },
];

// Yup validation schema
const validationSchema = Yup.object().shape({
  staffType: Yup.string().required("Staff type is required"),
  selectedStaff: Yup.array().min(1, "At least one staff must be selected"),
  notificationMethods: Yup.array().min(
    1,
    "At least one notification method must be selected"
  ),
  message: Yup.string().required("Notification message is required"),
});

function ShiftAssignment() {
  const { branchId } = useParams();
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    fetchStaff(branchId, "all"); 
  }, [branchId]);

  const fetchStaff = async (branchId, staffType) => {
    try {
      const response = await getStaffByBranchId({ branch_id: branchId });
      let staffData = response.data.data;

      if (staffType === "teaching") {
        staffData = staffData.filter((staff) => staff.role_id === 4);
      } else if (staffType === "non-teaching") {
        staffData = staffData.filter((staff) => staff.role_id === 5);
      }

    

      setStaff(
        staffData.map((staff) => ({
          label: staff.first_name,
          value: staff.id,
        }))
      );
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const handleSubmit = (values) => {
    alert("Staff selected and notification sent!");
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Staff Notifications</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">staff-notifications</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title"> Staff</h3>
                </div>

                <div className="card-body">
                  <Formik
                    initialValues={{
                      staffType: "all", // Default to "All"
                      selectedStaff: [],
                      notificationMethods: [],
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          {/* Dropdown for staff type selection */}
                          <div className="form-group col-md-4 mb-4 d-flex flex-column">
                            <label
                              htmlFor="staffType"
                              className="form-label fw-bold"
                            >
                              Select Staff Type
                            </label>
                            <Field
                              as="select"
                              name="staffType"
                              className="form-control"
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setFieldValue("staffType", selectedType);
                                setFieldValue("selectedStaff", []); // Reset staff when type changes
                                fetchStaff(branchId, selectedType); // Fetch staff based on selected type
                              }}
                            >
                              <option value="all">All</option>
                              <option value="teaching">Teaching</option>
                              <option value="non-teaching">Non-Teaching</option>
                            </Field>
                            <ErrorMessage
                              name="staffType"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group col-md-4 mb-4 d-flex flex-column">
                            <label
                              htmlFor="staff"
                              className="form-label fw-bold"
                            >
                              Select Staff
                            </label>
                            <MultiSelect
                              value={values.selectedStaff}
                              options={staff} 
                              onChange={(e) =>
                                setFieldValue("selectedStaff", e.value)
                              }
                              placeholder="Select Staff"
                              className="form-group"
                              filter
                              maxSelectedLabels={3}
                            />
                            <ErrorMessage
                              name="selectedStaff"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          {/* MultiSelect for notification methods */}
                          <div className="form-group col-md-4 mb-4 d-flex flex-column">
                            <label
                              htmlFor="notificationMethods"
                              className="form-label fw-bold"
                            >
                              Notification Methods
                            </label>
                            <MultiSelect
                              value={values.notificationMethods}
                              options={notificationMethods}
                              onChange={(e) =>
                                setFieldValue("notificationMethods", e.value)
                              }
                              optionLabel="label"
                              placeholder="Select Notification Methods"
                              className="form-group"
                              filter
                              maxSelectedLabels={3}
                            />
                            <ErrorMessage
                              name="notificationMethods"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          {/* Message box */}
                          <div className="form-group col-12 mb-4">
                            <label
                              htmlFor="message"
                              className="form-label fw-bold"
                            >
                              Notification Message
                            </label>
                            <InputTextarea
                              value={values.message}
                              onChange={(e) =>
                                setFieldValue("message", e.target.value)
                              }
                              rows={5}
                              placeholder="Enter your notification message..."
                              className="form-control shadow-sm rounded"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="form-group text-left">
                          <Button
                            label="Submit"
                            type="submit"
                            className="btn btn-primary"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShiftAssignment;
