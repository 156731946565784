import Swal from "sweetalert2";

export const showSweetAlert = async (
  title,
  text,
  icon,
  confirmButtonText,
  cancelButtonText
) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
    customClass: {
      confirmButton: "btn btn-success swal2-custom-btn",
      cancelButton: "btn btn-danger swal2-custom-btn",
      popup: "swal2-blur-popup",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
  });
};

export const showLoadingAlert = () => {
  Swal.fire({
    title: "Please wait...",
    text: "We are uploading your data",
    icon: "info",
    allowOutsideClick: false,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    customClass: {
      cancelButton: "btn btn-danger",
      popup: "swal2-blur-popup",
    },
    buttonsStyling: false,
    didOpen: () => {
      Swal.showLoading();
    },
    allowOutsideClick: false,
  });
};

export const showSuccessAlert = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "success",
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "btn btn-success",
      popup: "swal2-blur-popup",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
  });
};

export const showErrorAlert = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "error",
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "btn btn-danger",
      popup: "swal2-blur-popup",
    },
    buttonsStyling: false,
    timer: 5000,
    allowOutsideClick: false,
  });
};

export const showLoginAlert = async (title, text, icon, confirmButtonText) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: confirmButtonText,
    customClass: {
      confirmButton: "btn btn-success swal2-custom-btn",
      popup: "swal2-blur-popup",
    },
    buttonsStyling: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
};
