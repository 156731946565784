import Api from "../Api";

export const createSchedule = (data) => {
  return Api.post(`/create-admission-schedule`, data);
};
export const getAllSchedules = async (announcement_id, schedule_status) => {
  return Api.get("get-admission-schedules", {
    params: {
      announcement_id: announcement_id,
      schedule_status: schedule_status,
    },
  });
};

export const getAllAnnouncements = (branchId, params) => {
  return Api.get("/get-admission-announcements", {
    params: { branch_id: branchId, ...params },
  });
};
export const getAnnouncementById = (id) => {
  return Api.get(`/get-admission-announcement-byid/${id}`);
};
export const updateScheduleId = (data) => {
  return Api.post(`/update-admission-schedule`, data);
};
export const updateAdmissionStatus = (selectedRows,data) => {
    console.log(data)
    
    const id=selectedRows
    return Api.post(`/update-admission-status/${id}`, data);
  };
export const deleteAnnouncementById = (id) => {
  return Api.delete(`/delete-admission-announcement/${id}`);
};
export const getClassByBranchId = async (branchId) => {
  console.log(branchId);
  const id = branchId;
  return await Api.get(`/get-classes-branch/${id}`);
};
