import React, { useState, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getDepartments } from "../../Services/AdminApis/departmentApi";
import { getSubjects } from "../../Services/CommonApis/subjectApi";
import { getQualifications } from "../../Services/AdminApis/QualificationApi";

const StaffDetails = ({ current, steps }) => {
  const { values, handleChange, touched, errors, setFieldValue } =
    useFormikContext();
  const [departments, setDepartments] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [filteredQualifications, setFilteredQualifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [issubjectsLoading, setIssubjectsLoading] = useState(false);
  const [isqualificationLoading, setIsqualificationLoading] = useState(false);

  const getAllDepartments = async () => {
    try {
      setIsLoading(true);
      const response = await getDepartments();
      setDepartments(response.data.data);
    } catch (err) {
      console.error("API error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSubjects = async () => {
    try {
      setIssubjectsLoading(true);
      const response = await getSubjects();
      setSubjects(response.data.data);
    } catch (err) {
      console.error("API error:", err);
    } finally {
      setIssubjectsLoading(false);
    }
  };

  const getAllQualifications = async () => {
    try {
      setIsLoading(true);
      const response = await getQualifications();
      setQualifications(response.data.qualifications);
    } catch (err) {
      console.error("API error:", err);
    } finally {
      setIsqualificationLoading(false);
    }
  };

  useEffect(() => {
    getAllDepartments();
    getAllSubjects();
    getAllQualifications();
  }, []);

  useEffect(() => {
    const selectedQualificationId = values.qualification;
    if (selectedQualificationId) {
      setFilteredQualifications(
        qualifications.filter(
          (qualification) => qualification.id !== selectedQualificationId
        )
      );
    }
  }, [values.qualification, qualifications]);

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 2 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Staff Details:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 2 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="joining_date">
                  Joining Date<span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="date"
                  className="form-control"
                  id="joining_date"
                  name="joining_date"
                  placeholder="Enter joining date"
                  style={{ borderColor: getBorderColor("joining_date") }}
                />
                <ErrorMessage
                  name="joining_date"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="department">
                  Department <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="department"
                  name="department"
                  // placeholder="Select Department"
                  style={{
                    borderColor: getBorderColor("department"),
                    borderRadius: "0px",
                  }}
                >
                  {isLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option label="Select department" disabled />
                      {departments.map((department) => (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="department"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="work_location">
                  Work Location <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="work_location"
                  name="work_location"
                  placeholder="Enter work location"
                  style={{ borderColor: getBorderColor("work_location") }}
                />
                <ErrorMessage
                  name="work_location"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="specialized">
                  Specialized in <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="specialized"
                  name="specialized"
                  // placeholder="Select specialized subject"
                  style={{
                    borderColor: getBorderColor("specialized"),
                    borderRadius: "0px",
                  }}
                >
                  {issubjectsLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option label="Select specialized subject" disabled />
                      {subjects.map((subject) => (
                        <option key={subject.id} value={subject.id}>
                          {subject.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="specialized"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="qualification">
                  Qualification <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="qualification"
                  name="qualification"
                  // placeholder="Select Qualification"
                  style={{
                    borderColor: getBorderColor("qualification"),
                    borderRadius: "0px",
                  }}
                >
                  {isqualificationLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option label="Select qualification" disabled />
                      {qualifications.map((qualification) => (
                        <option key={qualification.id} value={qualification.id}>
                          {qualification.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="qualification"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="extra_qualification">
                  Extra Qualification{" "}
                  <span className="optional">(Optional)</span>
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="extra_qualification"
                  name="extra_qualification"
                  // placeholder="Select any extra Qualification"
                  style={{ borderColor: "skyblue", borderRadius: "0px" }}
                >
                  <option label="Select any extra Qualification" />
                  {filteredQualifications.map((qualification) => (
                    <option key={qualification.id} value={qualification.id}>
                      {qualification.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="extra_qualification"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="previous_school">
                  Previous School <span className="optional">(Optional)</span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="previous_school"
                  name="previous_school"
                  placeholder="Enter previous school name"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="previous_school"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="reason_change">
                  Reason for change <span className="optional">(Optional)</span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="reason_change"
                  name="reason_change"
                  placeholder="Enter reason for the change"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="reason_change"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default StaffDetails;
