import Api from "../Api";

export const createAttendance = async (data) => {
  try {
    const response = await Api.post("/create-attendance", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAttendanceByDate = async (branch_id, date) => {
  try {
    const response = await Api.get("/get-attendance-date", {
      params: {
        branch_id: branch_id,
        date: date,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getConsolidatedAttendance = async (
  branchId,
  startDate,
  endDate
) => {
  try {
    const response = await Api.get("get-attendance-consolidated", {
      params: {
        branch_id: branchId,
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDaywiseAttendance = async (branchId, datewise) => {
  try {
    const response = await Api.get("get-attendance-report", {
      params: {
        branch_id: branchId,
        date: datewise,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
