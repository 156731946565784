// AttendanceHeader.js
import React from "react";
import "../../Styles/AttendanceManagement.css";
import {
  getStudentByParentId,
  getStudentAttendance,
} from "../../Services/CommonApis/ParentPortalApi";
import { useState, useEffect } from "react";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getBranchId, getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

const AttendanceHeader = () => {
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const parentId = getParentId(token)
  const branchId = getBranchId(token)
  const [attendance, setAttendance] = useState("");
  const [studentId, setStudentId] = useState("");
  console.log(attendance);

  useEffect(() => {
    if (parentId) {
      fetchStudentByParent(parentId);
    }
  }, []);

  useEffect(() => {
    if (studentId) {
      fetchAttendance();
    }
  }, [studentId]);

  const fetchStudentByParent = async (parentId) => {
    try {
      const response = await getStudentByParentId({ parent_id: parentId });
      setStudentId(response.data.data[0].id);
    } catch (error) {
      console.error("Error fetching student:", error);
    }
  };

  const fetchAttendance = async () => {
    try {
      const response = await getStudentAttendance({
        branch_id: branchId,
        student_id: studentId,
      });

      setAttendance(response.data.data);
    } catch (error) {
      console.error("Error fetching attendance:", error);
    }
  };
  return (
    <div className="p-3">
      <div className="attendance-header">
        <div className="stats">
          <div>Total Working Days: 305</div>
          <div>Attendance Percentage: 22.95%</div>
        </div>
        <div className="legend">
          <div className="legend-item present">
            {attendance.totalpresent} Present
          </div>
          <div className="legend-item absent">
            {attendance.totalabsent} Absent
          </div>
          <div className="legend-item holiday">51 Holiday</div>
          <div className="legend-item leave">0 Leave</div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceHeader;
