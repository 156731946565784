import { React, useEffect, useState } from "react";
import { getSchoolDetailsById } from "../../Services/AdminApis/schoolApi";
import { useParams } from "react-router-dom";
import { getBranchById } from "../../Services/CommonApis/branchApi";
import StaffList from "./StaffList";
import ViewExamConfig from "./ViewExamConfig";
import ReportcardConfiguration from "./Reportcardconfiguration"
function ReportCardManagement() {
  const { schoolId, branchId } = useParams();
  const [schoolName, setSchoolName] = useState(null);
  const [branchName, setBranchName] = useState(null);
  const [activeTab, setActiveTab] = useState("stafflist");

  useEffect(() => {
    if (schoolId) {
      fetchSchool(schoolId);
    }
    if (branchId) {
      fetchBranch(branchId);
    }
  }, [schoolId, branchId]);

  const fetchSchool = async (id) => {
    try {
      const response = await getSchoolDetailsById(id);
      setSchoolName(response.school.name);
    } catch (error) {
      console.error("Error fetching school details:", error);
    }
  };
  const fetchBranch = async (id) => {
    try {
      const response = await getBranchById(id);
      setBranchName(response.data.branch.branch_name);
    } catch (error) {
      console.error("Error fetching branch details:", error);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "stafflist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("stafflist")}
            >
              View Exam Config
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "staffgrid" ? "active" : ""
              }`}
              onClick={() => handleTabClick("staffgrid")}
            >
              Create ReportCard Config
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "stafflist" ? "active" : ""}`}
            id="stafflist"
          >
            {activeTab === "stafflist" && <ViewExamConfig />}
          </div>

          <div
            className={`tab-pane ${activeTab === "staffgrid" ? "active" : ""}`}
            id="staffgrid"
          >
            {activeTab === "staffgrid" && <ReportcardConfiguration/>}
          </div>

        
        </div>
      </div>
    </>
  );
}

export default ReportCardManagement;
