import Api from "../Api";
import qs from "qs";

export const createexamconfig = (data) => {
  return Api.post(`/create-exam-config`, data);
};
export const getAllSchedules = async (announcement_id, schedule_status) => {
  return Api.get("get-admission-schedules", {
    params: {
      announcement_id: announcement_id,
      schedule_status: schedule_status,
    },
  });
};

export const EnterMarks = (data) => {
  return Api.post(`/create-exam-marks`, data);
};
export const UpdateMarks = (student_id, data) => {
  console.log(data);
  return Api.post(`/update-exam-mark/${student_id}`, data);
};

export const getStudentMarks = async (branchId, studentId) => {
  return Api.get("get-student-exam-marks", {
    params: {
      branch_id: branchId,
      student_id: studentId,
    },
  });
};
export const getsubjectsbybranchidclasssection = ({
  branchId,
  classId,
  sectionId,
}) => {
  return Api.get("/get-subject-bybranch-class-section", {
    params: {
      branch_id: branchId,
      class_id: classId,
      section_id: sectionId,
    },
  });
};
export const getsubjectsbybranchIdandClassid = ({ branchId, classId }) => {
  return Api.get(`get-subject-bybranch-class`, {
    params: {
      branch_id: branchId,
      class_id: classId,
    },
  });
};

export const getExamConfigs = (exam_id, class_id, section_id) => {
  return Api.get("/get-exam-configs", {
    params: {
      exam_id: exam_id,
      class_id: class_id,
      section_id: section_id,
    },
  });
};

export const getReportCardDashboardDetails = (branch_id) => {
  return Api.get("/marks/aggregated-report", {
    params: {
      branch_id: branch_id,
    },
  });
};

// Function to call the API with all required parameters
export const getExamMarks = (
  branch_id,
  class_id,
  section_id,
  exam_id,
  page,
  _limit,
  _sort,
  _order,
  q
) => {
  return Api.get("/get-exam-marks", {
    params: {
      branch_id,
      class_id,
      section_id,
      exam_id,
      page,
      _limit,
      _sort,
      _order,
      q,
    },
  });
};

export const generatePdf = async (
  branch_id,
  selectedStudentIds,
  selectedHeaderType
) => {
  if (!Array.isArray(selectedStudentIds) || selectedStudentIds.length === 0) {
    return;
  }
  const studentIdsParam = selectedStudentIds
    .map((id) => `student_id[]=${id}`)
    .join("&");
  const url = `/generate-pdf?branch_id=${branch_id}&${studentIdsParam}&header_type=${selectedHeaderType}`;
  return Api.get(url, {
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;

    const filename =
      selectedStudentIds.length > 1
        ? `fee_invoices_${selectedStudentIds.join(",")}.pdf`
        : `fee_invoice_${selectedStudentIds[0]}.pdf`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const generateOverallPdf = async (
  branch_id,
  section_id,
  class_id,
  exam_id
) => {
  console.log(branch_id, section_id, class_id, exam_id);
  return Api.get("/exam-marks-download-pdf", {
    params: {
      branch_id: branch_id,
      class_id: class_id,
      section_id: section_id,
      exam_id: exam_id,
    },
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `fee_invoice_${section_id}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const generateOverallExcel = async (
  branch_id,
  section_id,
  class_id,
  exam_id
) => {
  console.log(branch_id, section_id, class_id, exam_id);
  return Api.get("/export-exam-marks", {
    params: {
      branch_id: branch_id,
      class_id: class_id,
      section_id: section_id,
      exam_id: exam_id,
    },
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `exam_marks_${section_id}.xlsx`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const previewPdf = async (
  branch_id,
  selectedStudentIds,
  selectedHeaderType
) => {
  const studentIdsParam = selectedStudentIds
    .map((id) => `student_id[]=${id}`)
    .join("&");
  const url = `/generate-pdf?branch_id=${branch_id}&${studentIdsParam}&header_type=${selectedHeaderType}`;
  try {
    const response = await Api.get(url, {
      responseType: "blob",
    });
    if (response && response.data) {
      return response;
    } else {
      throw new Error("No response data");
    }
  } catch (error) {
    console.error("Error generating preview PDF:", error);
    throw error;
  }
};
export const deleteexamconfig = (id) => {
  return Api.delete(`/delete-exam-config/${id}`);
};
export const editexamconfig = (ExamConfigId) => {
  const id = ExamConfigId;
  console.log(id);
  return Api.get(`/get-exam-config/${id}`);
};
export const updateConfigById = (id, formData) => {
  console.log(formData);
  return Api.post(`/update-exam-config/${id}`, formData);
};
