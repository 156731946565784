import React from "react";

const KeyNavigationHandler = ({
  e,
  rowIndex,
  colIndex,
  subjectOptions,
  navigationMode,
  handleFocusChange,
}) => {
  const rowCount = subjectOptions.length;
  const maxColIndex = 7;

  let newRowIndex = rowIndex;
  let newColIndex = colIndex;

  // Prevent default behavior for arrow keys to stop increment/decrement in number inputs
  if (
    ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
  ) {
    e.preventDefault();
  }

  if (navigationMode === "horizontal") {
    if (e.key === "ArrowRight" || e.key === "Tab") {
      if (colIndex < maxColIndex) {
        // Move to the next column in the same row
        newColIndex = colIndex + 1;
      } else if (rowIndex < rowCount - 1) {
        // Move to the first column of the next row
        newRowIndex = rowIndex + 1;
        newColIndex = 0;
      } else {
        // Move to the first row, first column if at the last row, last column
        newRowIndex = 0;
        newColIndex = 0;
      }
    } else if (e.key === "ArrowLeft") {
      if (colIndex > 0) {
        // Move to the previous column in the same row
        newColIndex = colIndex - 1;
      } else if (rowIndex > 0) {
        // Move to the last column of the previous row
        newRowIndex = rowIndex - 1; // Move to the previous row
        newColIndex = maxColIndex; // Move to the last column of the previous row
      }
    }
  } else if (navigationMode === "vertical") {
    if (e.key === "ArrowDown" || e.key === "Tab") {
      if (rowIndex === rowCount - 1 && colIndex === maxColIndex) {
        // Move to the first row, first column if at the last row and last column
        newRowIndex = 0; // First row
        newColIndex = 0; // First column
      } else if (rowIndex < rowCount - 1) {
        // Move to the same column in the next row
        newRowIndex = rowIndex + 1;
      } else if (colIndex === 0) {
        // If at the last row and in the first column, move to the next column of the first row
        newRowIndex = 0; // First row
        newColIndex = 1; // Next column
      }
    } else if (e.key === "ArrowUp") {
      if (rowIndex === 0 && colIndex === 0) {
        // Move to the last row of the first column when in the first row, first column
        newRowIndex = rowCount - 1; // Last row
        newColIndex = 0; // First column
      } else if (colIndex === 1 && rowIndex === 0) {
        // Move to the last row of the previous column if in the first row, second column
        newRowIndex = rowCount - 1; // Last row
        newColIndex = 0; // Previous column
      } else {
        // Move to the same column in the previous row
        if (rowIndex > 0) {
          newRowIndex = rowIndex - 1;
        }
      }
    }
  }

  // Focus on the new target input
  const targetInput = document.querySelector(
    `#input-${newRowIndex}-${newColIndex}`
  );
  if (targetInput) {
    targetInput.focus();
  }
};

export default KeyNavigationHandler;
