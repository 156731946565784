import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import "../../Styles/StudentProfile.css";
import "../../Styles/StudentProfile.css";
import {
  getStudentByParentId,
  getStudentById,
} from "../../Services/CommonApis/ParentPortalApi";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

export default function TCRequest() {
  const [tcRequest, setTcRequest] = useState({
    studentName: "",
    class: "",
    reason: "",
    additionalInfo: "",
  });
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const parentId = getParentId(token);
  const [activeTab, setActiveTab] = useState("StudentProfile");

  const [student, setStudent] = useState([]);
  const [studentId, setStudentId] = useState("");

  useEffect(() => {
    if (parentId) {
      fetchStudentByParent(parentId);
    }
  }, [parentId]);

  useEffect(() => {
    if (studentId) {
      fetchStudents(studentId);
    }
  }, [studentId]);

  const fetchStudentByParent = async (parentId) => {
    try {
      const response = await getStudentByParentId({ parent_id: parentId });
      console.log(response.data.data[0].id);
      setStudentId(response.data.data[0].id);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchStudents = async (studentId) => {
    console.log("i", studentId);
    try {
      const response = await getStudentById(studentId);
      console.log(response.data.student);
      setStudent(response.data.student);
    } catch (error) { }
  };

  // Handle form submission
  const submitTcRequest = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Here you would typically send the `tcRequest` data to your server/API
    console.log("TC Request:", tcRequest);
    // Reset the form
    setTcRequest({
      studentName: "",
      class: "",
      reason: "",
      additionalInfo: "",
    });
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Request Transfer Certificate</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">TC Request</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                    Transfer Certificate Request Form
                  </h3>
                </div>
                <div className="card-body">
                  <Form onSubmit={submitTcRequest}>
                    <div className="form-card">
                      <div
                        className="row mb-3"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <div className="flex-item" style={{ flex: 1, marginLeft: "10px" }}>
                          <p>
                            <strong>Name:</strong> {student?.first_name || "N/A"} {student?.last_name || ""}
                          </p>
                        </div>

                        <div className="flex-item" style={{ flex: 1 }}>
                          <p>
                            <strong>Section Name:</strong> {student?.section_name || "N/A"}
                          </p>
                        </div>

                        <div className="flex-item" style={{ flex: 1 }}>
                          <p>
                            <strong>Class Name:</strong> {student?.class_name || "N/A"}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <Form.Group controlId="reason">
                            <Form.Label>Reason for Request</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter the reason for the TC request"
                              value={tcRequest.reason}
                              onChange={(e) =>
                                setTcRequest({
                                  ...tcRequest,
                                  reason: e.target.value,
                                })
                              }
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <Form.Group controlId="additionalInfo">
                            <Form.Label>Additional Information</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              placeholder="Any additional information"
                              value={tcRequest.additionalInfo}
                              onChange={(e) =>
                                setTcRequest({
                                  ...tcRequest,
                                  additionalInfo: e.target.value,
                                })
                              }
                              className="form-control"
                              style={{ borderColor: "skyblue" }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end">
                        <div className="">
                          <Button
                            variant="primary "
                            type="submit"
                            className="btn-block"
                          >
                            Submit Request
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
