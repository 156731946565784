import React from "react";
import StoppagesList from "./StoppagesList";
import Transport from "../CommonPages/CreateStoppages";
function TransportManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className="nav-link active"
              href="#stoppagelist"
              data-toggle="tab"
            >
              Stoppages List
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#createstoppage" data-toggle="tab">
              Create Stoppage
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="stoppagelist">
            <StoppagesList />
          </div>

          <div className="tab-pane" id="createstoppage">
            <Transport />
          </div>
        </div>
      </div>
    </>
  );
}

export default TransportManagement;
