// App.js
import React from "react";
import AttendanceHeader from "./AttendanceHeader";
import AttendanceCalendar from "./AttendanceCalendar";

function AttendanceManagement() {
  return (
    <div className="App">
      <AttendanceHeader />
      <AttendanceCalendar />
    </div>
  );
}

export default AttendanceManagement;
