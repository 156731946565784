import Api from "../Api"

export const dashboardGetStudentCount = async()=>{
    const payload = {
        school_id:1,
        branch_id:1
        
    }
    return await Api.get(`/get-dashboard-details/`,{payload},)
}

export const getDashboardAttendanceClassCount = async (params) => {
  const response = await Api.get(`/dashboard/attendanceclass-count`, { params });
  return response; 
};

export const getDashboardHomeworkCount = async (params) => {
  const response = await Api.get(`/dashboard/homeworkclass-count`, { params });
  return response; 
};

export const getDashboardBirthdayCount = async (params) => {
  const response = await Api.get(`/dashboard/birthday-count`, { params });
  return response; 
};

export const getDashboardDetails = async (params) => {
  const response = await Api.get(`/get-dashboard-details`, { params });
  return response;
};

