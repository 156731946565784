import React, { useEffect, useState } from "react";

import { Field, ErrorMessage, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { getStates } from "../../Services/AdminApis/StateandDistrictApi";

const UserDetails = ({ steps, current }) => {
  console.log(steps,current,"blouse")
  const { setFieldValue, values, errors, touched } = useFormikContext();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [states, setStates] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsSameAddress(checked);

    if (checked) {
      setFieldValue("sttemp_city", values.stcity);
      setFieldValue("sttemp_state", values.ststate);
      setFieldValue("sttemp_pin", values.stpin);
      setFieldValue("sttemp_country", values.stcountry);
      setFieldValue("sttmp_address", values.staddress); // Corrected field name
    } else {
      setFieldValue("sttemp_city", "");
      setFieldValue("sttemp_state", "");
      setFieldValue("sttemp_pin", "");
      setFieldValue("sttemp_country", "");
      setFieldValue("sttmp_address", ""); // Corrected field name
    }
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === (id ? 4 : 5) && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Address :</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
              </div>
            </div>
            <h4 className="fs-title mt-2 ml-3">Permanent Address</h4>

            <div className="row ml-3 mr-3 mt-3">
              {/* <div className="form-group col-4">
                <label htmlFor="name">
                  Name <span className="mandatory">* </span>{" "}
                </label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="username">
                  Username <span className="mandatory">* </span>{" "}
                </label>
                <Field name="username" type="text" className="form-control" />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="field-error text-danger"
                />
              </div> */}

              {/* <div className="form-group col-4">
                <label htmlFor="email">
                  Email <span className="mandatory">* </span>{" "}
                </label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="field-error text-danger"
                />
              </div> */}

              {/* <div className="form-group col-4">
                <label htmlFor="password">
                  Password <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="field-error text-danger"
                />
              </div> */}

              {/* <div className="form-group col-4">
                <label htmlFor="role_id">
                  Role ID <span className="mandatory">* </span>{" "}
                </label>
                <Field name="role_id" type="number" className="form-control" />
                <ErrorMessage
                  name="role_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
               */}

              <div className="form-group col-4">
                <label htmlFor="stcity">
                  City <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="stcity"
                  name="stcity"
                  placeholder="Enter city"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="stcity"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="ststate">
                  State <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="ststate"
                  name="ststate"
                  placeholder="Enter state"
                  style={{
                    borderColor: getBorderColor("state"),
                    borderRadius: "0px",
                  }}
                >
                  <option value="" label="Select State" />
                  {states.map((state) => (
                    <option
                      key={state.id}
                      value={state.id}
                      label={state.name}
                    />
                  ))}
                </Field>
                <ErrorMessage
                  name="ststate"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="stpin">
                  Pin <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="stpin"
                  name="stpin"
                  placeholder="Enter pin"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="stpin"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="stcountry">
                  Country <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="stcountry"
                  name="stcountry"
                  placeholder="Enter country"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="stcountry"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-12">
                <label htmlFor="address">
                  Address <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  id="staddress"
                  name="staddress"
                  placeholder="Enter address"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="staddress"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-12">
                <input
                  type="checkbox"
                  id="sameAsPermanent"
                  checked={isSameAddress}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="sameAsPermanent" className="ml-2">
                  Same as Permanent Address
                </label>
              </div>

              <div className="form-group col-12">
                <h3 className="fs-title mt-2">Temporary Address</h3>
              </div>

              <div className="form-group col-4">
                <label htmlFor="temp_city">City</label>
                <Field
                  type="text"
                  className="form-control"
                  id="sttemp_city"
                  name="sttemp_city"
                  placeholder="Enter city"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="sttemp_city"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="sttemp_state">
                  State <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="sttemp_state"
                  name="sttemp_state"
                  placeholder="Enter state"
                  style={{
                    borderColor: getBorderColor("sttemp_state"),
                    borderRadius: "0px",
                  }}
                >
                  <option value="" label="Select State" />
                  {states.map((state) => (
                    <option
                      key={state.id}
                      value={state.id}
                      label={state.name}
                    />
                  ))}
                </Field>
                <ErrorMessage
                  name="sttemp_state"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="sttemp_pin">Pin</label>
                <Field
                  type="text"
                  className="form-control"
                  id="sttemp_pin"
                  name="sttemp_pin"
                  placeholder="Enter pin"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="sttemp_pin"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="sttemp_country">Country</label>
                <Field
                  type="text"
                  className="form-control"
                  id="sttemp_country"
                  name="sttemp_country"
                  placeholder="Enter country"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="sttemp_country"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-12">
                <label htmlFor="sttmp_address">Address</label>
                <Field
                  as="textarea"
                  className="form-control"
                  id="sttmp_address"
                  name="sttmp_address"
                  placeholder="Enter address"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="sttmp_address"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default UserDetails;
