import React, { useEffect, useState } from "react";
import "../../Styles/StudentProfile.css";
import "../../Styles/StudentProfile.css";
import {
  getStudentByParentId,
  getStudentById,
} from "../../Services/CommonApis/ParentPortalApi";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import defaultAvatar from "../../Assests/images/student.png";


const StudentProfile = () => {
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const parentId = getParentId(token);
  const [activeTab, setActiveTab] = useState("StudentProfile");

  const [student, setStudent] = useState([]);
  const [studentId, setStudentId] = useState("");

  useEffect(() => {
    if (parentId) {
      fetchStudentByParent(parentId);
    }
  }, [parentId]);

  useEffect(() => {
    if (studentId) {
      fetchStudents(studentId);
    }
  }, [studentId]);

  const fetchStudentByParent = async (parentId) => {
    try {
      const response = await getStudentByParentId({ parent_id: parentId });
      console.log(response.data.data[0].id);
      setStudentId(response.data.data[0].id);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchStudents = async (studentId) => {
    console.log("i", studentId);
    try {
      const response = await getStudentById(studentId);
      console.log(response.data.student);
      setStudent(response.data.student);
    } catch (error) {}
  };

  const renderTabContent = () => {
    if (!student) {
      return <p>No student data available.</p>; // Return a fallback message if no student is available
    }

    const dottedLineStyle = {
      border: "none",
      borderTop: "1px dotted #ccc",
      margin: "5px 0",
    };

    switch (activeTab) {
      case "StudentProfile":
        return (
          <div
            style={{
              width: "900px",
              height: "400px",
              backgroundColor: "white",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <h3>Student Profile</h3>
            <hr style={dottedLineStyle} />
            <div className="d-flex flex-wrap align-items-center">
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Name:</p>
                <p>
                  {student?.first_name || "N/A"} {student?.last_name || ""}
                </p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Place of Birth:</p>
                <p>{student?.place_of_birth || "N/A"}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Achievements:</p>
                <p>{student?.achievements || "N/A"}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Area of Interest:</p>
                <p>{student?.area_of_interest || "N/A"}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Additional Skills:</p>
                <p>{student?.additional_skills || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
            </div>
          </div>
        );
      case "AcademicDetails":
        return (
          <div
            style={{
              width: "900px",
              height: "400px",
              backgroundColor: "white",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <h3>Academic Details</h3>
            <hr style={dottedLineStyle} />
            <div className="d-flex flex-wrap align-items-center">
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Academic year:</p>
                <p>{student?.academic_years || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Admission No:</p>
                <p>{student?.admission_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Admission Date:</p>
                <p>{student?.admission_date || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Application No:</p>
                <p>{student?.application_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>CSE No:</p>
                <p>{student?.cse_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>EMIS No:</p>
                <p>{student?.emis_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Joining Quota:</p>
                <p>{student?.joining_quota || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Registration No:</p>
                <p>{student?.reg_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
            </div>
          </div>
        );
      case "ParentDetails":
        return (
          <div
            style={{
              width: "900px",
              height: "400px",
              backgroundColor: "white",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <h3>Additional Information</h3>
            <hr style={dottedLineStyle} />
            <div className="d-flex flex-wrap align-items-center">
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Parent Name:</p>
                <p>{student?.parent_name || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Medium name:</p>
                <p>{student?.medium_name || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Group Name:</p>
                <p>{student?.group_name || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Aadhar Card No</p>
                <p>{student?.aadhaar_card_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Pan Card No</p>
                <p>{student?.pan_card_no || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>First language</p>
                <p>{student?.first_language_name || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Second language</p>
                <p>{student?.second_language_name || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
              <div
                className="col-5 mr-5"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p style={{ color: "grey" }}>Third language</p>
                <p>{student?.third_language_name || ""}</p>
                <hr style={dottedLineStyle} />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="header d-flex flex-column p-5">
        <div
          className="top-header d-flex"
          style={{
            backgroundColor: "#3b3f5c",
            padding: "10px",
          }}
        >
          {student && (
            <>
              <img
                style={{ width: "80px", height: "80px", borderRadius: "80px" }}
                src={student?.image || defaultAvatar}
                alt="Student"
              />
              <p
                style={{
                  marginLeft: "30px",
                  marginTop: "25px",
                  fontSize: "20px",
                  color: "white",
                }}
              >
                {student?.first_name || ""}
              </p>
            </>
          )}
        </div>
        <div
          className="bottom-header d-flex justify-content-between"
          style={{ backgroundColor: "white", padding: "15px" }}
        >
          {student && (
            <>
              <p>
                Admission No:{" "}
                <span style={{ fontSize: "15px", color: "grey" }}>
                  {student?.admission_no || "N/A"}
                </span>
              </p>
              <p>
                Class:{" "}
                <span style={{ fontSize: "15px", color: "grey" }}>
                  {student?.class_name || ""}
                </span>
              </p>
              <p>
                Section:{" "}
                <span style={{ fontSize: "15px", color: "grey" }}>
                  {student?.section_name || ""}
                </span>
              </p>
              <p>
                Roll no:{" "}
                <span style={{ fontSize: "15px", color: "grey" }}>
                  {student?.roll_no || ""}
                </span>
              </p>
              <p>
                Date of Birth:{" "}
                <span style={{ fontSize: "15px", color: "grey" }}>
                  {student?.date_of_birth || ""}
                </span>
              </p>
            </>
          )}
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between mt-5">
        <div
          className="sidebar d-flex flex-column align-items-start"
          style={{
            width: "400px",
            height: "250px",
            backgroundColor: "#f8f9fa",
            borderRight: "1px solid #dee2e6",
            borderRadius: "10px",
            marginLeft: "10px",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: "10px",
              width: "100%",
              marginTop: "10px",
            }}
          >
            {["StudentProfile", "AcademicDetails", "ParentDetails"].map(
              (tab) => (
                <li
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={activeTab === tab ? "active-tab" : ""}
                  style={{
                    padding: "15px 20px",
                    backgroundColor:
                      activeTab === tab ? "#0d6efd" : "transparent",
                    margin: "5px 0",
                    color: activeTab === tab ? "white" : "black",
                    fontSize: "18px",
                    cursor: "pointer",
                    position: "relative", // Required for the triangle positioning
                    transition: "all 0.3s",
                  }}
                >
                  {tab.replace(/([A-Z])/g, " $1").trim()}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="details" style={{ marginLeft: "20px", width: "100%" }}>
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
