import React from "react";
import { useNavigate } from "react-router-dom";
import { removeAllSession, removeSession } from "../../Services/Storage";

const RightSidebar = () => {
  const handleLogout = () => {
    removeAllSession();
  };
  return (
    <>
      <aside className="control-sidebar control-sidebar-dark">
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="custom-nav custom-nav-pills custom-nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="custom-nav-item" onClick={handleLogout}>
                <a href="" className="custom-nav-link">
                  <i
                    className="custom-nav-icon fa fa-power-off"
                    aria-hidden="true"
                  ></i>
                  <p>
                    Logout
                    <i className="fas fa-angle-left custom-right-icon"></i>
                  </p>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default RightSidebar;
