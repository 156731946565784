import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Spinner from "react-bootstrap/Spinner";
import { AcademicValidationSchema } from "../../Utility/AdminUtility/AdminValidation";
import {
  createAcademic,
  getAcademicById,
  updateAcademicById,
} from "../../Services/AdminApis/AcademicApi";
import {
  showSuccessAlert,
  showLoadingAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import { useParams, useNavigate } from "react-router-dom";

const AcademicDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [academic, setAcademic] = useState({
    academic_years: "",
    start_date: "",
    end_date: "",
    academic_description: "",
  });

  useEffect(() => {
    if (id) {
      getAcademic(id);
    }
  }, [id]);

  const getAcademic = async (id) => {
    try {
      const response = await getAcademicById(id);
      const academicdata = response.data.academicDetails;
      if (academicdata) {
        setAcademic({
          academic_years: academicdata.academic_years || "",
          start_date: academicdata.start_date || "",
          end_date: academicdata.end_date || "",
          academic_description: academicdata.academic_description || "",
        });
      } else {
        console.error("No academic details found");
      }
    } catch (error) {
      console.error("Error fetching academic details:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    showLoadingAlert();
    try {
      const transformedValues = {
        academic_years: values.academic_years,
        start_date: values.start_date,
        end_date: values.end_date,
        academic_description: values.academic_description,
        status: values.status,
      };

      let response;
      if (id) {
        response = await updateAcademicById(id, transformedValues);
      } else {
        response = await createAcademic(values);
      }

      setApiMessage(response.data.message || response.message);
      showSuccessAlert("Success", apiMessage);
      navigate("/admin/academic-list");
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred. Please try again.";

      setApiMessage(errorMessage);
      showErrorAlert("Error", apiMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Academic Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Academic Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ color: "skyblue" }}>
                    {id ? "Update Academic" : "Add Academic"}
                  </h3>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={academic}
                    validationSchema={AcademicValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="form-card ml-4">
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-6">
                              <label htmlFor="academic_years" className="form-label">
                                Academic Years <span className="mandatory">*</span>
                              </label>
                              <Field
                                type="text"
                                name="academic_years"
                                className="form-control"
                                id="academic_years"
                                placeholder="2024"
                                style={{ borderColor: "skyblue" }}
                              />
                              <ErrorMessage
                                name="academic_years"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="start_date" className="form-label">
                                  Start Date <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="start_date"
                                  className="form-control"
                                  id="start_date"
                                  style={{ borderColor: "skyblue" }}
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="end_date" className="form-label">
                                  End Date <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="end_date"
                                  className="form-control"
                                  id="end_date"
                                  style={{ borderColor: "skyblue" }}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="academic_description" className="form-label">
                                  Description <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  name="academic_description"
                                  className="form-control"
                                  id="academic_description"
                                  placeholder="Academic Description"
                                  style={{ borderColor: "skyblue" }}
                                />
                                <ErrorMessage
                                  name="academic_description"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row ml-3 mr-5 mt-3 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              style={{
                                width: loading ? "150px" : "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Submitting...
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AcademicDetails;
