import React, { useState, useEffect } from "react";
import {
  getStudentMarks,
  UpdateMarks,
} from "../../Services/CommonApis/ReportcardApi";
import { useParams, useNavigate } from "react-router-dom";
import { getAllRemarks } from "../../Services/CommonApis/RemarkApi";
import { getAllPersonalityTraits } from "../../Services/AdminApis/PersonalityTraitsApi";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";

const me = "https://via.placeholder.com/150";

function ViewMarksEntry({ onBack }) {
  const { branchId, id } = useParams();
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(null);
  const [remarks, setRemarks] = useState([]);
  const [traits, setTraits] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedMarks, setEditedMarks] = useState([]);
  const [totalPresentDays, setTotalPresentDays] = useState("");
  const [selectedTrait, setSelectedTrait] = useState("");
  const [selectedRemark, setSelectedRemark] = useState("");
  const [studentResult, setStudentResult] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");

  const [selectedPrincipalRemark, setSelectedPrincipalRemark] = useState("");
  const [principalRemarks, setPrincipalRemarks] = useState([]); // Assuming you fetch this data from an API

  useEffect(() => {
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);

  const handleCancelAttendance = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    fetchStudentMarks(branchId, id);
  }, [branchId, id]);

  useEffect(() => {
    fetchRemarks();
    fetchPersonalityTraits(branchId);
  }, [branchId]);

  const fetchRemarks = async () => {
    try {
      const response = await getAllRemarks();
      setRemarks(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPersonalityTraits = async (branchId) => {
    try {
      const response = await getAllPersonalityTraits(branchId);
      setTraits(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStudentMarks = async (branchId, id) => {
    try {
      const response = await getStudentMarks(branchId, id);
      setStudentData(response.data.data);
      setEditedMarks(response.data.data.marks_data.marks);
      setStudentResult(response.data.data.marks_data.student_result);
      setTotalPresentDays(response.data.data.marks_data.present_days);
      setSelectedClassId(response.data.marks_data.promoted_to.id);
      setSelectedTrait(response.data.data.marks_data.personality_trait);
      setSelectedRemark(response.data.data.marks_data.class_teacher_remarks);
      setSelectedPrincipalRemark(
        response.data.data.marks_data.principal_remarks
      );
    } catch (error) {
      console.error("Error fetching student marks:", error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.log(error);
    }
  };

  const updateStudentMarks = async (data) => {
    try {
      await UpdateMarks(id, data);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating student marks:", error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBack = () => {
    navigate("/school/1/branch/1/report", { state: { branchId } });
  };

  const handleSave = () => {
    const payload = {
      branch_id: branchId,
      student_id: id,
      students: [
        {
          class_info: {
            class_id: studentData.marks_data.class_id.id,
            section_id: studentData.marks_data.section_id.id,
            academic_id: 1, // Adjust if academic_id varies
            entry_type: "classwise", // Adjust if entry_type varies
            exam_id: studentData.marks_data.exam_id.id,
            present_days: totalPresentDays,
          },
          remarks: {
            class_teacher_remarks: selectedRemark, // Adjust based on your form fields
            principal_remarks: selectedPrincipalRemark, // Adjust as needed
            personality_trait: selectedTrait,
            promoted_to: { id: selectedClassId, name: "" },
            detained_to: { id: "", name: "" },
            result: studentResult, // Adjust as needed
          },
          marks: editedMarks.map((mark) => ({
            subject_id: mark.subject_id,
            isabsent: mark.isabsent || 0,
            is_absent_reason: mark.is_absent_reason || "",
            internal: mark.internal,
            external: mark.external,
          })),
        },
      ],
    };

    updateStudentMarks(payload);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMarks = [...editedMarks];
    updatedMarks[index][field] = value;
    setEditedMarks(updatedMarks);
  };

  if (!studentData) {
    return <div></div>;
  }

  const cardStyle = {
    borderTop: "6px solid skyblue",
  };

  const headingStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
    padding: "10px 0",
  };

  const tableStyle = {
    width: "100%",
    textAlign: "center",
  };

  const thStyle = {
    fontWeight: "bold",
    padding: "10px",
    textAlign: "left",
  };

  const tdStyle = {
    padding: "10px",
    textAlign: "left",
  };

  return (
    <div className="row p-4" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "1200px",
          zIndex: 1,
        }}
      >
        <button className="btn btn-primary" onClick={handleBack}>
          Back
        </button>
      </div>
      <div className="col-md-4" style={{ marginTop: "30px" }}>
        <div className="card" style={cardStyle}>
          <div className="card-body box-profile">
            <div className="text-center">
              <img
                className="profile-user-img img-fluid img-circle"
                src={me}
                alt="User profile picture"
              />
            </div>
            <h3 className="profile-username text-center">
              {studentData.student_name}
            </h3>
            <p className="text-center text-muted">
              {studentData.branch_name} School
            </p>
            <ul className="list-group list-group-unbordered mb-3">
              <li className="list-group-item">
                <b>Roll Number</b>{" "}
                <span className="float-right">{studentData.student_roll}</span>
              </li>
              <li className="list-group-item">
                <b>Admission No</b>{" "}
                <span className="float-right">{studentData.admission_no}</span>
              </li>
              <li className="list-group-item">
                <b>Parent Name</b>{" "}
                <span className="float-right">{studentData.parent_name}</span>
              </li>
              <li className="list-group-item">
                <b>Class</b>{" "}
                <span className="float-right">
                  {studentData.marks_data.class_id.name}
                </span>
              </li>
              <li className="list-group-item">
                <b>Section</b>{" "}
                <span className="float-right">
                  {studentData.marks_data.section_id.name}
                </span>
              </li>
              <li className="list-group-item">
                <b>Exam</b>{" "}
                <span className="float-right">
                  {studentData.marks_data.exam_id.name}
                </span>
              </li>
              <li className="list-group-item">
                <b>Academic Year</b>{" "}
                <span className="float-right">
                  {studentData.marks_data.academic_year}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-md-8" style={{ marginTop: "30px" }}>
        <div className="card mb-4" style={cardStyle}>
          <h5
            className="p-2 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Subjects and Marks
            {!isEditing && (
              <i
                className="fas fa-edit"
                style={{ cursor: "pointer" }}
                onClick={handleEdit}
              ></i>
            )}
          </h5>
          <div className="card-body">
            {isEditing ? (
              <div>
                <table style={tableStyle} className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={thStyle}>Subject</th>
                      <th style={thStyle}>Internal Marks</th>
                      <th style={thStyle}>External Marks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editedMarks.map((subject, index) => (
                      <tr key={index}>
                        <td style={tdStyle}>{subject.subject_name}</td>
                        <td style={tdStyle}>
                          <input
                            type="number"
                            value={subject.internal}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "internal",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td style={tdStyle}>
                          <input
                            type="number"
                            value={subject.external}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "external",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="list-group-item d-flex justify-content-end">
                  <button className="btn btn-primary" onClick={handleSave}>
                    Save
                  </button>
                  <button
                    className="btn btn-secondary ml-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <table style={tableStyle} className="table table-bordered">
                <thead>
                  <tr>
                    <th style={thStyle}>Subject</th>
                    <th style={thStyle}>Internal Marks</th>
                    <th style={thStyle}>External Marks</th>
                  </tr>
                </thead>
                <tbody>
                  {studentData.marks_data.marks.map((subject, index) => (
                    <tr key={index}>
                      <td style={tdStyle}>{subject.subject_name}</td>
                      <td style={tdStyle}>{subject.internal}</td>
                      <td style={tdStyle}>{subject.external}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="card" style={{ borderTop: "6px solid skyblue" }}>
          <h5
            className="p-2 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Attendance and Personality Traits
            {!isEditing && (
              <i
                className="fas fa-edit"
                style={{ cursor: "pointer" }}
                onClick={handleEdit}
              ></i>
            )}
          </h5>
          <hr></hr>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              {/* Attendance Data */}
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <strong>Attendance:</strong>
                <div style={{ flex: 1, textAlign: "right" }}>
                  {isEditing ? (
                    <input
                      type="number"
                      value={totalPresentDays}
                      className="mx-3"
                      style={{
                        width: "50px",
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onChange={(e) => setTotalPresentDays(e.target.value)}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                  ) : (
                    <span>
                      {studentData.marks_data.present_days}/
                      {studentData.total_working_day}
                    </span>
                  )}
                </div>
              </li>
              <li className="list-group-item">
                <strong>Personality Traits:</strong>
                {isEditing ? (
                  <select
                    className="form-control col-3 float-right"
                    value={selectedTrait}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const selectedTraitObj = traits.find(
                        (trait) => String(trait.id) === String(selectedId) // Ensure both are strings for comparison
                      );
                      setSelectedTrait(
                        selectedTraitObj ? selectedTraitObj.name : ""
                      );
                    }}
                  >
                    <option value="">Select Trait</option>
                    {traits.map((trait) => (
                      <option key={trait.id} value={trait.id}>
                        {trait.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span className="float-right">
                    {studentData.marks_data.personality_trait || "None"}
                  </span>
                )}
              </li>
              <li className="list-group-item">
                <strong>Remarks:</strong>
                {isEditing ? (
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label className="d-block">Class Teacher Remarks:</label>
                      <select
                        className="form-control mb-2"
                        value={selectedRemark}
                        onChange={(e) => setSelectedRemark(e.target.value)}
                      >
                        <option value="">Select Remark</option>
                        {remarks
                          .filter(
                            (remark) => remark.remarks_by === "Class Teacher"
                          ) // Filter for class teacher remarks
                          .map((remark) => (
                            <option key={remark.id} value={remark.id}>
                              {remark.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label className="d-block">Principal Remarks:</label>
                      <select
                        className="form-control"
                        value={selectedPrincipalRemark}
                        onChange={(e) =>
                          setSelectedPrincipalRemark(e.target.value)
                        }
                      >
                        <option value="">Select Remark</option>
                        {remarks
                          .filter((remark) => remark.remarks_by === "Principle") // Filter for principal remarks
                          .map((remark) => (
                            <option key={remark.id} value={remark.id}>
                              {remark.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <strong>Class Teacher:</strong>{" "}
                      {studentData.marks_data.class_teacher_remarks || "None"}
                    </div>
                    <div className="col-md-6">
                      <strong>Principal:</strong>{" "}
                      {studentData.marks_data.principal_remarks || "None"}
                    </div>
                  </div>
                )}
              </li>

              <li className="list-group-item">
                <strong>Promoted to:</strong>

                {isEditing ? (
                  <div className="form-group col-4 float-right">
                    <select
                      id="class_id"
                      name="class_id"
                      className="form-control"
                      value={selectedClassId}
                      onChange={(e) => {
                        const selectedClassId = e.target.value;
                        setSelectedClassId(selectedClassId);
                      }}
                    >
                      <option value="">Select Class</option>
                      {classes.map((cls) => (
                        <option key={cls.id} value={cls.id}>
                          {cls.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <span className="float-right">
                    {classes.find(
                      (cls) => cls.id == studentData.marks_data.promoted_to.id
                    )?.name || "None"}
                  </span>
                )}
              </li>

              <li className="list-group-item">
                <strong>Result:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    className="form-control col-3 float-right"
                    value={studentResult}
                    onChange={(e) => setStudentResult(e.target.value)}
                  />
                ) : (
                  <span className="float-right">
                    {studentData.marks_data.student_result || "None"}
                  </span>
                )}
              </li>

              {isEditing && (
                <li className="list-group-item d-flex justify-content-end">
                  <button className="btn btn-primary mr-2" onClick={handleSave}>
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleCancelAttendance}
                  >
                    Cancel
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewMarksEntry;
