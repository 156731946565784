import React from "react";
import { useLocation } from "react-router-dom";
import SchoolSideBar from "./SchoolSideBar";
import AdminSideBar from "../AdminPages/AdminSideBar";
import ParentSideBar from "../AdminPages/ParentSideBar";

const LeftSidebar = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname.startsWith("/school") ? (
        <SchoolSideBar />
      ) : location.pathname.startsWith("/parent") ? (
        <ParentSideBar />
      ) : (
        <AdminSideBar />
      )}
    </>
  );
};

export default LeftSidebar;
