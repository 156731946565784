import React, { useState, useEffect, useRef } from "react";
import { getEnquiryById } from "../../Services/CommonApis/EnquiryApi";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../../Styles/Reciept.css";

const FeeReceipt = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const receiptRef = useRef();

  useEffect(() => {
    if (id) {
      fetchEnquiryById(id);

    }
  }, [id]);

  const fetchEnquiryById = async (id) => {
    try {
      const response = await getEnquiryById(id);
      setData(response.data.data);
      
      viewPDF(response.data.data);
    } catch (error) {
      console.error("Error fetching enquiry by ID:", error);
    }
  };

  const viewPDF = () => {
    html2canvas(receiptRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // Width of the image
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Generate the PDF blob and create a URL
      const pdfBlob = pdf.output("blob");
      const pdfURL = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfURL);
    });
  };

  return (
    <div className="fee-container" ref={receiptRef}>
      {/* Header with logo and text */}
      <div className="fee-header">
        <img src="admit.png" alt="School Logo" />
        <div className="fee-header-text">
          <h1>DE PAUL SCHOOL</h1>
          <p>UKKUNAGARAM, VISAKHAPATNAM</p>
          <p>Email: depaul45@gmail.com</p>
          <p>Ph: 9492172721 / 9492157271</p>
          <p>Website: www.depaulsvizag.com</p>
        </div>
      </div>

      {/* Fee Receipt Title */}
      <div className="fee-receipt-title">FEE RECEIPT</div>

      {/* Details Section */}
      <div className="details">
        <div className="details-row">
          <p>
            <strong>Name:</strong> {data.name}
          </p>
          <p className="date">
            <strong>Date:</strong> {new Date().toLocaleDateString()}
          </p>
        </div>
        <p>
          <strong>F Name:</strong> {data.father_name}
        </p>
        <p>
          <strong>Rec No:</strong> {data.application_no}
        </p>
        <p>
          <strong>Payment Mode:</strong>{" "}
          {data.payment_mode === "1" ? "Cash" : "Online"}
        </p>
        <p>
          <strong>Student Class:</strong> {data.class_applied}
        </p>
        <p>
          <strong>Mobile No:</strong> {data.contact_no}
        </p>
      </div>

      {/* Receipt Table */}
      <table className="receipt-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Head of Account</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Sample Item</td>
            <td>{data.application_fee}</td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong>Total Amount</strong>
            </td>
            <td>
              <strong>{data.application_fee}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Signature Section */}
      <div className="signature">Signature</div>
    </div>
  );
};

export default FeeReceipt;
