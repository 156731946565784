import Api from "../Api";

export const createStaff = async (data) => {
  try {
    const response = await Api.post("/create-staff", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStaffById = async (id) => {
  try {
    const response = await Api.get(`/get-staff/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStaffById = async (id) => {
  try {
    const response = await Api.delete(`delete-staff/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStaffById = async (id, data) => {
  try {
    const response = await Api.post(`/update-staff/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        staff_id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRoles = async (data) => {
  try {
    const response = await Api.get("/get-roles", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getStaffByBranchId = async (params) => {
  return await Api.get("/get-staff-branch", { params });
};
