import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form } from "formik";
import "../../Styles/SchoolCreate.css";
import SchoolInformation from "../../Components/AdminComponents/SchoolInformation";
import BranchInformation from "../../Components/CommonComponents/BranchInformation";
import BranchAddress from "../../Components/CommonComponents/BranchAddress";
import SchoolBranchSettings from "../../Components/AdminComponents/SchoolandBranchSettings";
import { SchoolValidation } from "../../Utility/AdminUtility/AdminValidation";
import {
  createSchool,
  getSchoolById,
  updateSchoolById,
} from "../../Services/AdminApis/schoolApi";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import {
  showLoadingAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";

const Branch = () => {
  const { id } = useParams();
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = 5;
  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const getSchool = async (id) => {
    try {
      const response = await getSchoolById(id);

      setSchoolData(response.school);
      console.log(response.school);
    } catch (err) {
      console.error("API error:", err);
    }
  };

  useEffect(() => {
    if (id) {
      getSchool(id);
    }
  }, [id]);

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  const handleSubmit = async (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      if (values[key] !== null && values[key] !== undefined) {
        if (
          key.endsWith("_file") ||
          key.endsWith("_card") ||
          key.endsWith("_logo")
        ) {
          const file = values[key];
          if (file instanceof File) {
            formData.append(key, file, file.name);
          } else {
            formData.append(key, file);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    });

    showLoadingAlert(); // Show loading alert

    try {
      let response;
      if (id) {
        response = await updateSchoolById(id, formData);
        showSuccessAlert("Success", response.message);
        navigate("/admin/school-list");
      } else {
        response = await createSchool(formData);
        showSuccessAlert("Success", response.message);
        navigate("/admin/school-list");
      }
      setCurrent(steps);
    } catch (err) {
      console.error("API error:", err);
      let errorMessage = "An unexpected error occurred. Please try again.";

      if (err.response) {
        if (err.response.data.error) {
          setError(err.response.data.error);
          errorMessage = err.response.data.error;
        } else if (err.response.data.message) {
          errorMessage = err.response.data.message;
        }
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }
      showErrorAlert("Error", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    name: "",
    school_code: "",
    city: "",
    dist: "",
    state: "",
    pin: "",
    affialiation_no: "",
    address: "",
    branch_name: "",
    branch_code: "",
    branch_email: "",
    branch_phone: "",
    branch_city: "",
    branch_dist: "",
    branch_state: "",
    branch_pin: "",
    branch_address: "",
    useSchoolAddress: false,
    stud_grade: "",
    reg_start_from: "",
    reg_prefix_digit: "",
    cal_fees_fine: 0,
    fees_due_days: "",
    offline_payments: 0,
    print_file: null,
    report_card: null,
    logo_file: null,
    text_logo: null,
    period_attendance: "0",
    subject_select: "0",
    academic_id: "",
    personality_traits: "1",
  };

  const getInitialValues = () => {
    if (schoolData) {
      console.log(schoolData.affialiation_no)
      return {
        name: schoolData.name || "",
        school_code: schoolData.school_code || "",
        affialiation_no: schoolData.affialiation_no || "",
        city: schoolData.city || "",
        dist: schoolData.dist || "",
        state: schoolData.state || "",
        pin: schoolData.pin || "",
        address: schoolData.address || "",
        branch_name: schoolData.branches[0].branch_name || "",
        branch_code: schoolData.branches[0].branch_code || "",
        branch_email: schoolData.branches[0].email || "",
        branch_phone: schoolData.branches[0].phone || "",
        branch_city: schoolData.branches[0].city || "",
        branch_dist: schoolData.branches[0].dist || "",
        branch_state: schoolData.branches[0].state || "",
        branch_pin: schoolData.branches[0].pin || "",
        branch_address: schoolData.branches[0].address || "",
        useSchoolAddress: false,
        stud_grade: schoolData.schools_settings.stud_grade || "",
        reg_start_from: schoolData.schools_settings.reg_start_from || "",
        reg_prefix_digit: schoolData.schools_settings.reg_prefix_digit || "",
        cal_fees_fine: schoolData.schools_settings.cal_fees_fine || 0,
        fees_due_days: schoolData.schools_settings.fees_due_days || "",
        offline_payments: schoolData.schools_settings.offline_payments || 0,
        print_file: schoolData.branches[0].branch_meta.print_file || null,
        report_card: schoolData.branches[0].branch_meta.report_card || null,
        logo_file: schoolData.branches[0].branch_meta.logo_file || null,
        text_logo: schoolData.branches[0].branch_meta.text_logo || null,
        // affialiation_no: schoolData.schools_settings.affialiation_no || "",
        academic_id: schoolData.academic_id||"",
        period_attendance: "0",
        subject_select: "0",
        personality_traits: "1",
      };
    } else {
      return initialValues;
    }
  };

  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>{id ? "School Edit" : "School Create"}</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">
                {id ? "School Edit" : "School Create"}
              </li>
            </ol>
          </div>
        </div>
        <div className="card">
          <div className="head-card">
            <div className="row ml-5 text-center">
              <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                <Formik
                  initialValues={getInitialValues()}
                  enableReinitialize
                  validationSchema={SchoolValidation[`tab${current}`]}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue, validateForm, setTouched }) => (
                    <Form id="msform">
                      <ul id="progressbar">
                        <li
                          className={current >= 1 ? "active" : ""}
                          id="account"
                          onClick={() => goToStep(1)}
                          style={{ width: "260px" }}
                        >
                          <strong>School Information</strong>
                        </li>
                        <li
                          className={current >= 2 ? "active" : ""}
                          id="personal"
                          onClick={() => goToStep(2)}
                          style={{ width: "260px" }}
                        >
                          <strong>Branch Information</strong>
                        </li>

                        <li
                          className={current >= 3 ? "active" : ""}
                          id="payment"
                          onClick={() => goToStep(3)}
                          style={{ width: "260px" }}
                        >
                          <strong>Branch Address</strong>
                        </li>
                        <li
                          className={current >= 4 ? "active" : ""}
                          id="settings"
                          onClick={() => goToStep(4)}
                          style={{ width: "260px" }}
                        >
                          <strong>School & Branch Settings</strong>
                        </li>
                      </ul>
                      <div className="progress">
                        <div
                          ref={progressBarRef}
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <br />

                      <>
                        {current === 1 && (
                          <SchoolInformation
                            steps={steps}
                            setUseSchoolAddress={(checked) =>
                              setFieldValue("useSchoolAddress", checked)
                            }
                            current={1}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        )}
                        {current === 2 && (
                          <BranchInformation steps={steps} current={2} />
                        )}
                        {current === 3 && (
                          <BranchAddress steps={steps} current={current} />
                        )}
                        {current === 4 && (
                          <SchoolBranchSettings
                            steps={steps}
                            useSchoolAddress={values.useSchoolAddress}
                            current={current}
                          />
                        )}
                      </>

                      <div className="button-group">
                        {current > 1 && current < steps && (
                          <button
                            type="button"
                            className="previous action-button-previous"
                            onClick={prevStep}
                          >
                            Previous
                          </button>
                        )}
                        {current < 4 && (
                          <button
                            type="button"
                            className="btn btn-primary action-button"
                            onClick={() => {
                              const currentTabValidationSchema =
                                SchoolValidation[`tab${current}`];
                              currentTabValidationSchema
                                .validate(values, { abortEarly: false })
                                .then(() => {
                                  setCurrent(current + 1);
                                  setTouched({});
                                })
                                .catch((validationErrors) => {
                                  const errors = validationErrors.inner.reduce(
                                    (acc, err) => {
                                      acc[err.path] = err.message;
                                      return acc;
                                    },
                                    {}
                                  );
                                  setTouched(errors);
                                });
                            }}
                          >
                            Next
                          </button>
                        )}
                        {current === 4 && (
                          <button
                            type="submit"
                            className="action-button"
                            style={{
                              width: loading ? "150px" : "100px", // Adjust width based on loading state
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                &nbsp;Submitting...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Branch;
