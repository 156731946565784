import React from "react";
import { useNavigate } from "react-router-dom";

function PageForBidden() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="pagenotfound-container">
      <div className="pagenotfound-error">
        <div className="pagenotfound-title">403</div>
        <div className="pagenotfound-subtitle">ACCESS FORBIDDEN!</div>
        <div className="pagenotfound-text">
          You do not have permission to access this page.
        </div>
        <button onClick={handleBackClick} className="pagenotfound-btn">
          Back to Previous Page
        </button>
      </div>
    </div>
  );
}

export default PageForBidden;
