import Api from "../Api";
export const createLanguage = (data) => {
  return Api.post(`/create-languages`, data);
};
export const getAllLanguages = (params) => {
  return Api.get(`/get-languages`,{params});
};
export const deleteLanguageById = (id) => {
  return Api.delete(`/delete-languages/${id}`);
};
export const getLanguageById = (id) => {
  return Api.get(`/get-language/${id}`);
};
export const updateLanguageById = (id, data) => {
  return Api.post(`/update-languages/${id}`, data);
};
