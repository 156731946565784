import React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FieldArray } from "formik";

import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PrimeIcons } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import SchoolGrid from "../AdminPages/SchoolGrid";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";
import {
  createAnnouncement,
  deleteAnnouncementById,
  getAllAnnouncements,
  getAnnouncementById,
  updateAnnouncementById,
} from "../../Services/CommonApis/AnnouncementApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import AnnouncementGrid from "./AnnouncementGrid";
const Announcement = () => {
  const [activeTab, setActiveTab] = useState("announcements");
  const [apiMessage, setApiMessage] = useState("");
  const [classes, setClasses] = useState([]);
  const [showannouncementForm, setShowannouncementForm] = useState(false);
  const [showeditannouncementForm, setEditShowannouncementForm] =
    useState(false);

  const [formData, setFormData] = useState(null);
  const [academics, setAcademics] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  console.log(branchId);

  const { id } = useParams();
  const fetchAnnouncementById = async (id) => {
    try {
      const response = await getAnnouncementById(id);
      setFormData(response.data.data);
      setEditShowannouncementForm(true);
    } catch (error) {
      console.error("Error fetching announcement by ID:", error);
    }
  };
  useEffect(() => {
    Academics();
    getclassbybranchID(branchId);
  }, []);
  const Academics = async () => {
    const response = await getAllAcademics();
    setAcademics(response.data.data);
  };
  const getclassbybranchID = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAnnouncement = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete this announcement!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteAnnouncementById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The announcement has been deleted.");

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert(
          "Error!",
          "There was an error deleting the announcement."
        );
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The announcement is safe 😊");
    }
  };

  useEffect(() => {
    if (activeTab === "newannouncements") {
      // Initialize DataTable only if the tab is active
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id" },
          { data: "name" },
          { data: "academic_year_id" },
          { data: "application_fee" },
          { data: "start_date" },
          { data: "end_date" },
          { data: "last_submission_date" },
          { data: "class_name" },
          { data: "admission_fees" },
          { data: "quota" },
          { data: "seats_available" },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              const editIcon = `
              <a title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
            `;
              const deleteIcon = `
                <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              `;
              return `
                <div class="icon-group" style="display: flex; gap: 10px;">
                  ${editIcon}
                  ${deleteIcon}
                </div>
              `;
            },
          },
        ],
        lengthChange: true,
        ordering: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        processing: true,
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "asc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getAllAnnouncements(branchId, {
              page: page,
              limit: length,
              sortBy: orderColumn,
              sortOrder: orderDirection,
              search: searchValue,
            });

            const jsonData = response.data.data || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }
        },
      });

      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        fetchAnnouncementById(id); // Fetch the announcement details by ID and show the form
      });
      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        deleteAnnouncement(id);
      });

      return () => {
        tableInstance.current.destroy();
      };
    }
  }, [activeTab, branchId]);

  const handleSubmit = async (values) => {
    const formatDate = (date) => {
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const id = values.id;
    const formattedValues = {
      ...values,
      branch_id: branchId,
      start_date: formatDate(values.start_date),
      end_date: formatDate(values.end_date),
      last_submission_date: formatDate(values.last_submission_date),
    };

    try {
      let response;
      if (formData) {
        response = await updateAnnouncementById(id, formattedValues);
      } else {
        response = await createAnnouncement(formattedValues);
      }
      if (response.status === 200) {
        setShowannouncementForm(false);
        tableInstance.current.ajax.reload(null, false);
        setApiMessage(response.data.message || response.message);
        showSuccessAlert("Success", apiMessage);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Announcement Name is required"),
    academic_year_id: Yup.string().required("Academic Year is required"),
    application_fee: Yup.number().required("Application Fees are required"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
    last_submission_date: Yup.date().required(
      "Last Date of Submission is required"
    ),
  });
  return (
    <>
      <div className="custom-tabs-container">
        <ul className="custom-nav-tabs container" id="myTab" role="tablist">
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${
                activeTab === "announcements" ? "active" : ""
              }`}
              id="announcements-tab"
              data-toggle="tab"
              href="#announcements"
              role="tab"
              aria-controls="announcements"
              aria-selected={activeTab === "announcements"}
              onClick={() => setActiveTab("announcements")}
            >
              <i className="fas fa-book"></i> Announcements
            </a>
          </li>
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${
                activeTab === "newannouncements" ? "active" : ""
              }`}
              id="newannouncements-tab"
              data-toggle="tab"
              href="#newannouncements"
              role="tab"
              aria-controls="newannouncements"
              aria-selected={activeTab === "newannouncements"}
              onClick={() => setActiveTab("newannouncements")}
            >
              <i className="fas fa-book"></i> New Announcements
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-contentt">
        {activeTab === "announcements" && (
          <div id="announcements-tab" className="tab-pane fade show active">
            <div className="d-flex justify-content-between ml-3 mt-4 mb-3">
              <h5>Announcements </h5>
            </div>
            <AnnouncementGrid />
          </div>
        )}
      </div>

      <div className="tab-contentt">
        {activeTab === "newannouncements" && (
          <div id="newannouncements-tab" className="tab-pane fade show active">
            <div className="card ml-3 mr-3 mt-3">
              <div className="d-flex justify-content-between ml-3 mt-4 mb-3">
                <button
                  className="btn btn-primary mr-4"
                  onClick={() => {
                    if (showeditannouncementForm || showannouncementForm) {
                      // Clicking "Back" should hide both forms and reset the id or form data
                      setShowannouncementForm(false);
                      setEditShowannouncementForm(false);
                      setFormData(null); // Reset the id so it won't load the edit form again
                    } else {
                      // When no forms are shown, clicking "+New Announcement" will show the new form
                      setShowannouncementForm(true);
                      setFormData(null); // Ensure id is reset for a fresh new form
                    }
                  }}
                >
                  {showeditannouncementForm || showannouncementForm ? (
                    <>
                      <i className="fas fa-arrow-left mr-2"></i> Back
                    </>
                  ) : (
                    "+New Announcement"
                  )}
                </button>
              </div>

              {showeditannouncementForm && (
                <div className="card ml-3 mr-3">
                  <div className="mt-3 ml-3 mb-3">
                    <h5>Announcement Creation Form</h5>

                    <Formik
                      initialValues={
                        formData || {
                          name: "",
                          academic_year_id: "",
                          application_fee: "",
                          start_date: "",
                          end_date: "",
                          last_submission_date: "",
                          class: "",
                          admission_fees: "",
                          quota: "",
                          seats_available: "",
                          exam_required: "",
                          branch_id: branchId,
                        }
                      }
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, isSubmitting }) => (
                        <Form>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="name" className="form-label">
                                  Announcement Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="name"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="academic_year_id"
                                  className="form-label"
                                >
                                  Academic Year{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  name="academic_year_id"
                                  as="select"
                                  className="form-control"
                                  style={{ borderColor: "skyblue" }}
                                >
                                  <option value="">Select Academic Year</option>
                                  {academics.map((year) => {
                                    const startDate = new Date(year.start_date);
                                    const endDate = new Date(year.end_date);

                                    const startMonthYear =
                                      startDate.toLocaleString("default", {
                                        month: "short",
                                        year: "numeric",
                                      });
                                    const endMonthYear = endDate.toLocaleString(
                                      "default",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    );

                                    const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                                    return (
                                      <option key={year.id} value={year.id}>
                                        {formattedDateRange}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="academic_year_id"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="application_fee"
                                  className="form-label"
                                >
                                  Application Fees{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="application_fee"
                                  className="form-control"
                                  placeholder="Enter application fee"
                                />
                                <ErrorMessage
                                  name="application_fee"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="start_date"
                                  className="form-label"
                                >
                                  Start Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="start_date"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="end_date"
                                  className="form-label"
                                >
                                  End Date <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="end_date"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="last_submission_date"
                                  className="form-label"
                                >
                                  Last Date of Submission{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="last_submission_date"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="last_submission_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 mb-3">
                            <h5>Seat Allocation</h5>
                            <div className="row ml-3 mr-5 mt-3">
                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="class" className="form-label">
                                    Class <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    name="class"
                                    className="form-control"
                                  >
                                    <option value="">Select Class</option>
                                    {classes.map((cls) => (
                                      <option key={cls.id} value={cls.id}>
                                        {cls.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="class"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="admission_fees"
                                    className="form-label"
                                  >
                                    Admission Fees{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="admission_fees"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="admission_fees"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="quota" className="form-label">
                                    Quota <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    name="quota"
                                    className="form-control"
                                  >
                                    <option value="">Select Quota</option>
                                    <option value="general">General</option>
                                    <option value="special">Special</option>
                                  </Field>
                                  <ErrorMessage
                                    name="quota"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="seats_available"
                                    className="form-label"
                                  >
                                    Seats Available{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="seats_available"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="seats_available"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    Exam Required
                                  </label>
                                  <div
                                    role="group"
                                    aria-labelledby="exam_required"
                                    className="d-flex"
                                  >
                                    <div className="form-check form-check-inline">
                                      <Field
                                        type="radio"
                                        name="exam_required"
                                        value="no"
                                        className="form-check-input"
                                        defaultChecked
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="exam_requiredNo"
                                      >
                                        No
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <Field
                                        type="radio"
                                        name="exam_required"
                                        value="yes"
                                        className="form-check-input"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="exam_requiredYes"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name="exam_required"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-12 text-right">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
              {showannouncementForm && (
                <div className="card ml-3 mr-3">
                  <div className="mt-3 ml-3 mb-3">
                    <h5>Announcement Creation Form</h5>

                    <Formik
                      initialValues={
                        formData || {
                          name: "",
                          academic_year_id: "",
                          application_fee: "",
                          start_date: "",
                          end_date: "",
                          last_submission_date: "",
                          class: "",
                          admission_fees: "",
                          quota: "",
                          announcement_data: [
                            {
                              class: "",
                              quota: "",
                              seats_available: "",
                              exam_required: "no",
                            },
                          ],
                        }
                      }
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, isSubmitting }) => (
                        <Form>
                          {/* Your other fields */}
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="name" className="form-label">
                                  Announcement Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  placeholder="Enter Announcement Name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            {/* Add other fields as needed */}
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="academic_year_id"
                                  className="form-label"
                                >
                                  Academic Year{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  name="academic_year_id"
                                  as="select"
                                  className="form-control"
                                  style={{ borderColor: "skyblue" }}
                                >
                                  <option value="">Select Academic Year</option>
                                  {academics.map((year) => {
                                    const startDate = new Date(year.start_date);
                                    const endDate = new Date(year.end_date);

                                    const startMonthYear =
                                      startDate.toLocaleString("default", {
                                        month: "short",
                                        year: "numeric",
                                      });
                                    const endMonthYear = endDate.toLocaleString(
                                      "default",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    );

                                    const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                                    return (
                                      <option key={year.id} value={year.id}>
                                        {formattedDateRange}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="academic_year_id"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="application_fee"
                                  className="form-label"
                                >
                                  Application Fees{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="application_fee"
                                  className="form-control"
                                  placeholder="Enter application fee"
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="application_fee"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="start_date"
                                  className="form-label"
                                >
                                  Start Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="start_date"
                                  className="form-control"
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="end_date"
                                  className="form-label"
                                >
                                  End Date <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="end_date"
                                  className="form-control"
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="last_submission_date"
                                  className="form-label"
                                >
                                  Last Date of Submission{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="last_submission_date"
                                  className="form-control"
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                                <ErrorMessage
                                  name="last_submission_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          {/* Seat Allocation Section */}
                          <div className="card p-2 mr-4">
                            <div className="mt-3 mb-3">
                              <h5>Seat Allocation</h5>
                              <FieldArray name="announcement_data">
                                {({ remove, push }) => (
                                  <div className="row ">
                                    {values.announcement_data &&
                                    values.announcement_data.length > 0
                                      ? values.announcement_data.map(
                                          (seat, index) => (
                                            <div
                                              className="row ml-3 mt-3"
                                              key={index}
                                            >
                                              <div className="col-2">
                                                <div className="form-group">
                                                  <label
                                                    htmlFor={`announcement_data.${index}.class`}
                                                    className="form-label"
                                                  >
                                                    Class{" "}
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <Field
                                                    as="select"
                                                    name={`announcement_data.${index}.class`}
                                                    className="form-control"
                                                  >
                                                    <option value="">
                                                      Select Class
                                                    </option>
                                                    {classes.map((cls) => (
                                                      <option
                                                        key={cls.id}
                                                        value={cls.id}
                                                      >
                                                        {cls.name}
                                                      </option>
                                                    ))}
                                                  </Field>
                                                  <ErrorMessage
                                                    name={`announcement_data.${index}.class`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-2">
                                                <div className="form-group">
                                                  <label
                                                    htmlFor={`announcement_data.${index}.quota`}
                                                    className="form-label"
                                                  >
                                                    Quota{" "}
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <Field
                                                    as="select"
                                                    name={`announcement_data.${index}.quota`}
                                                    className="form-control"
                                                  >
                                                    <option value="">
                                                      Select Quota
                                                    </option>
                                                    <option value="general">
                                                      General
                                                    </option>
                                                    <option value="special">
                                                      Special
                                                    </option>
                                                  </Field>
                                                  <ErrorMessage
                                                    name={`announcement_data.${index}.quota`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-2">
                                                <div className="form-group">
                                                  <label
                                                    htmlFor={`announcement_data.${index}.admission_fee`}
                                                    className="form-label"
                                                  >
                                                    Admission Fee{" "}
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <Field
                                                    type="number"
                                                    name={`announcement_data.${index}.admission_fee`}
                                                    className="form-control"
                                                    placeholder="Enter Admission fee"
                                                    style={{
                                                      WebkitAppearance: "none",
                                                      MozAppearance:
                                                        "textfield",
                                                    }}
                                                    onKeyPress={(e) => {
                                                      if (
                                                        !/[0-9]/.test(e.key) &&
                                                        e.key !== "Backspace"
                                                      ) {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                  />
                                                  <ErrorMessage
                                                    name={`announcement_data.${index}.admission_fee`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-3">
                                                <div className="form-group">
                                                  <label
                                                    htmlFor={`announcement_data.${index}.seats_available`}
                                                    className="form-label"
                                                  >
                                                    Seats Available{" "}
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <Field
                                                    type="number"
                                                    name={`announcement_data.${index}.seats_available`}
                                                    className="form-control"
                                                    placeholder="Enter no of seats"
                                                    style={{
                                                      WebkitAppearance: "none",
                                                      MozAppearance:
                                                        "textfield",
                                                    }}
                                                    onKeyPress={(e) => {
                                                      if (
                                                        !/[0-9]/.test(e.key) &&
                                                        e.key !== "Backspace"
                                                      ) {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                  />
                                                  <ErrorMessage
                                                    name={`announcement_data.${index}.seats_available`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-2">
                                                <div className="form-group">
                                                  <label className="form-label">
                                                    Exam Required
                                                  </label>
                                                  <div
                                                    role="group"
                                                    aria-labelledby="exam_required"
                                                    className="d-flex"
                                                  >
                                                    <div className="form-check form-check-inline">
                                                      <Field
                                                        type="radio"
                                                        name={`announcement_data.${index}.exam_required`}
                                                        value="no"
                                                        className="form-check-input"
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="exam_requiredNo"
                                                      >
                                                        No
                                                      </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                      <Field
                                                        type="radio"
                                                        name={`announcement_data.${index}.exam_required`}
                                                        value="yes"
                                                        className="form-check-input"
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="exam_requiredYes"
                                                      >
                                                        Yes
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ErrorMessage
                                                    name={`announcement_data.${index}.exam_required`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-1">
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  onClick={() => remove(index)}
                                                >
                                                  -
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        )
                                      : null}

                                    <div className="col-1">
                                      <button
                                        type="button"
                                        className="btn btn-primary mt-4"
                                        onClick={() =>
                                          push({
                                            class: "",
                                            quota: "",
                                            seats_available: "",
                                            admission_fee: "",
                                            exam_required: "no",
                                          })
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            </div>
                          </div>

                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-12 text-right">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
              <div className="mt-3 ml-3">
                <div className="card-body">
                  <table
                    ref={tableRef}
                    id="announcementTable"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Year</th>
                        <th>Fees</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Submission Date</th>
                        <th>Class</th>
                        <th>AdmissionFees</th>
                        <th>Quota</th>
                        <th>No Seats</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>{/* Data will be populated by DataTable */}</tbody>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Year</th>
                        <th>Fees</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Submission Date</th>
                        <th>Class</th>
                        <th>AdmissionFees</th>
                        <th>Quota</th>
                        <th>No Seats</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Announcement;
