import React from "react";
import CreateBook from "./CreateBook";
import BooksList from "./BooksList";

function LibraryManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" href="#bookslist" data-toggle="tab">
              Books List
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#createbook" data-toggle="tab">
              Create Books
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#uploadbook" data-toggle="tab">
              Upload Books
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="bookslist">
            <BooksList/>
          </div>

          <div className="tab-pane" id="uploadbook">
            <h1>Currently, the feature is not available. Coming soon.</h1>
          </div>
          <div className="tab-pane" id="createbook">
            <CreateBook />
          </div>
        </div>
      </div>
    </>
  );
}

export default LibraryManagement;
