import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { useParams } from "react-router-dom";
import { getStaffByBranchId } from "../../Services/AdminApis/StaffApi";

const Enroll = () => {
  const tableRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    studentId: "",
    studentName: "",
    fatherName: "",
    fatherMobile: "",
    motherMobile: "",
    admissionId: "",
  });
  const { schoolId, branchId } = useParams();

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "first_name", title: "ID" },

        { data: "first_name", title: "Student Name" },
        { data: "first_name", title: "Temporary student" },
        { data: "last_name", title: "Class" },
        { data: "email", title: "Section" },
        { data: "epf_no", title: "Fees Assigned" },
        { data: "uan_no", title: "Fees paid" },
        { data: "esi_no", title: "Enroll student" },
        {
          data: "status",
          title: "Status",
          render: (data) => {
            return data === "1" ? "Active" : "Inactive";
          },
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                  <a href="/school/${schoolId}/branch/${branchId}/staff-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>
                  <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l><"col-md-6 text-right"f>>' +
        '<"row mb-3"<"col-md-6 text-left"B>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getStaffByBranchId({
            branch_id: branchId,
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
            ...filters,
          });

          let jsonData = response.data.data;
          let totalCount = response.data.total;

          if (jsonData === "No Data Found") {
            jsonData = [];
            totalCount = 0;
          }

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    });

    return () => {
      table.destroy();
    };
  }, [refresh, filters]);

  const handleSearch = () => {
    setRefresh((prev) => !prev); // Trigger table reload
  };

  const handleClear = () => {
    setFilters({
      studentId: "",
      studentName: "",
      fatherName: "",
      fatherMobile: "",
      motherMobile: "",
      admissionId: "",
    });
    setRefresh((prev) => !prev); // Trigger table reload
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const inputStyle = {
    borderColor: "skyblue",
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Enroll Students</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Enroll</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Enroll</h3>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Student Name</th>
                        <th>Temporary Student</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Fees assigned</th>
                        <th>Fees paid</th>
                        <th>Enroll student</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tfoot>
                      <tr>
                        <th>Id</th>
                        <th>Student Name</th>
                        <th>Temporary Student</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Fees assigned</th>
                        <th>Fees paid</th>
                        <th>Enroll student</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Enroll;