import * as XLSX from "xlsx";

const handleDownload = () => {
    const fileName = "schools.xlsx";
    const headers = [
        "name",
        "school_code",
        "address",
        "city",
        "district",
        "state",
        "pin",
        "Status",
        "branch_name",
        "branch_code",
        "email",
        "phone",
        "branch_address",
        "branch_city",
        "branch_district",
        "pincode",
        "status",
      ];
      const data = [
        [
          "School A",
          "SCH001",
          "123 Main St",
          "New York",
          "NY",
          "telamgana",
          "10001",
          "active",
          "Branch A",
          "Br101",
          "branch1@gmail.com",
          "9390404583",
          "124 Main St",
          "New York",
          "NY",
          "100901",
          "active",
        ],
        [
          "School B",
          "SCH002",
          "456 Elm St",
          "Los Angeles",
          "CA",
          "California",
          "90001",
          "inactive",
          "Branch B",
          "Br102",
          "branch2@gmail.com",
          "9390404584",
          "457 Elm St",
          "Los Angeles",
          "California",
          "900802",
          "inactive",
        ],
      ];
    

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Schools");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  export default handleDownload;