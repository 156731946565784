import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import {
  getAllStudent,
  deletestudent,
} from "../../Services/CommonApis/StudentApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";

const StudentList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [sections, setSections] = useState([]);
  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);
  const { schoolId, branchId } = useParams();
  const [selectedClassId, setSelectedClassId] = useState("");
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);
  const fetchClasses = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  useEffect(() => {
    setShowTable(selectedClassId && selectedSection);
  }, [selectedClassId, selectedSection]);
  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the Student!",
      "warning",
      "Yes, Delete !",
      "No, Cancel !"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deletestudent(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The Student has been deleted.");

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the Student.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "This Student  is safe 😊");
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (showTable) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id" },
          { data: "first_name" },
          { data: "last_name" },
          { data: "fee_book_no" },
          { data: "place_of_birth" },
          { data: "mother_tongue" },
          {
            data: "transport_required",
            render: function (data) {
              return data ==="1" ? "Yes" : "No";
            },
          },
          { data: "medium_name" },
          { data: "class_name" },
          { data: "section_name" },
          {
            data: "status",
            render: function (data, type, row) {
              return data === "1" ? "Active" : "Inactive";
            },
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              const deleteIcon =
                row.status === "0"
                  ? ""
                  : `
              <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
            `;
              return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="/school/view/${row.id}" title="View"><i class="fas fa-eye"></i></a>
                <a href="/school/${schoolId}/branch/${branchId}/student-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>

                ${deleteIcon}
              </div>
            `;
            },
          },
        ],
        lengthChange: true,
        ordering: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          {
            extend: "copy",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csv",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "asc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;
            const classId = selectedClassId;
            const section_id = selectedSection;
            const response = await getAllStudent({
              branch_id: branchId,
              class_id: selectedClassId,
              section_id: selectedSection,
              sort_by: orderColumn || "first_name",
              sort_order: orderDirection || "asc",
              per_page: length,
              page: page,
              q: searchValue,
            });

            const jsonData = response.student || [];
            const totalCount = response.total || 0;
            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }
        },
      });

      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        handleDelete(id);
      });

      return () => {
        tableInstance.current.destroy();
      };
    }
  }, [showTable, selectedClassId, selectedSection]);
  useEffect(() => {
    fetchSections(selectedClassId);
  }, [selectedClassId]);

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };
  console.log(selectedClassId, selectedSection);
  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Student List Table
          </h3>
        </div>
        <Formik initialValues={{ class_id: "" }}>
          {({ setFieldValue, values }) => (
            <div className="card-body">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const classId = e.target.value;

                      setFieldValue("class_id", classId);

                      setSelectedClassId(e.target.value);
                    }}
                  >
                    {isClassLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Class</option>
                        {classOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section"
                    name="section"
                    className="form-control"
                    value={selectedSection}
                    onChange={(e) => handleSectionChange(e, setFieldValue)}
                    disabled={!selectedClassId}
                  >
                    {isSectionLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Section</option>
                        {sections.map((sec) => (
                          <option key={sec.id} value={sec.id}>
                            {sec.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>

              {showTable ? (
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table
                        ref={tableRef}
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Fee Book No</th>
                            <th>PLace of Birth</th>
                            <th>Mother Tongue</th>
                            <th>Transport Required</th>
                            <th>Medium</th>
                            <th>Class</th>
                            <th>Section</th>
                            <th>status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Fee Book No</th>
                            <th>PLace of Birth</th>
                            <th>Mother Tongue</th>
                            <th>Transport Required</th>
                            <th>Medium</th>
                            <th>Class</th>
                            <th>Section</th>
                            <th>status</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alert alert-info">
                  Please select both class and section to view the student list.
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default StudentList;
