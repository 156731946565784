import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import {
  deleteBranchSubjectById,
  getBranchSubjects,
} from "../../Services/CommonApis/subjectApi";
import { ErrorMessage, Field, Formik } from "formik";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import * as Yup from "yup";

const BranchSubjectList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [selectedClassId, setClassId] = useState(null);
  const [selectedSectionId, setSectionId] = useState(null);
  const [showTable, setShowTable] = useState(false);

  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);

  useEffect(() => {
    fetchClassByBranchId(branchId);
  }, [branchId]);

  useEffect(() => {
    if (selectedClassId) {
      fetchSectionByClassId(selectedClassId);
      setSectionId(null);
    }
  }, [selectedClassId]);

  useEffect(() => {
    setShowTable(selectedClassId && selectedSectionId);
  }, [selectedClassId, selectedSectionId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      setClassLoading(true);
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setClassLoading(false);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      setSectionLoading(true);
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    } finally {
      setSectionLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the branch!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteBranchSubjectById(id);
        showSuccessAlert("Deleted!", "The branch has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        showErrorAlert("Error!", "There was an error deleting the branch.");
        console.error(error);
      } finally {
        Swal.close();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The branch is safe 😊");
    }
  };

  useEffect(() => {
    if (showTable) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id", title: "S.No" },
          { data: "subject_name", title: "Subject Name" },
          {
            data: "status",
            title: "Status",
            render: (data) => (data === "1" ? "Active" : "Inactive"),
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              </div>
            `,
          },
        ],
        lengthChange: true,
        ordering: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Subject Found Based On The Selected Class & Section",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "asc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getBranchSubjects({
              branch_id: branchId,
              class_id: selectedClassId,
              section_id: selectedSectionId,
              page: page,
              _limit: length,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            const jsonData = response.data.branchsubjects || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            showErrorAlert("No response from the server. Please try again.");
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        handleDelete(id);
      });

      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy(true);
        }
      };
    }
  }, [branchId, selectedClassId, selectedSectionId, showTable]);

  const initialValues = {
    class_id: "",
    section_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
  });

  return (
    <div className="card">
      <div className="card-header">
        <h3 style={{ paddingTop: "5px" }} className="card-title">
          Subject List Table
        </h3>
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ setFieldValue, values }) => (
          <div className="card-body">
            <div className="row">
              <div className="form-group col-4">
                <label htmlFor="class_id">
                  Class Name <span className="mandatory">*</span>
                </label>
                <Field
                  as="select"
                  id="class_id"
                  name="class_id"
                  className="form-control"
                  onChange={(e) => {
                    const selectedClassId = e.target.value;
                    setFieldValue("class_id", selectedClassId);
                    setClassId(selectedClassId);
                  }}
                >
                  {isClassLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option value="">Select Class</option>
                      {classList.map((cls) => (
                        <option key={cls.id} value={cls.id}>
                          {cls.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="class_id"
                  component="div"
                  className="text-error"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="section_id">
                  Section Name <span className="mandatory">*</span>
                </label>
                <Field
                  as="select"
                  id="section_id"
                  name="section_id"
                  className="form-control"
                  value={selectedSectionId || ""}
                  onChange={(e) => {
                    const selectedSectionId = e.target.value;
                    setFieldValue("section_id", selectedSectionId);
                    setSectionId(selectedSectionId);
                  }}
                  disabled={!selectedClassId}
                >
                  {isSectionLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option value="">Select Section</option>
                      {sectionList.map((section) => (
                        <option key={section.id} value={section.id}>
                          {section.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="section_id"
                  component="div"
                  className="text-error"
                />
              </div>
            </div>

            {showTable ? (
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    zzz
                  </div>
                </div>
              </div>
            ) : (
              <div className="alert alert-info">
                Please select both class and section to view the subject list.
              </div>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default BranchSubjectList;
