import React, { useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import {
  getDistByStateId,
  getStates,
} from "../../Services/AdminApis/StateandDistrictApi";
import { useState } from "react";

const BranchAddress = ({ current, steps }) => {
  const { values, handleChange, touched, errors, setFieldValue } =
    useFormikContext();
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (values.state) {
      fetchDistrict(values.state);
    }
  }, [values.state]);

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  const fetchStates = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const fetchDistrict = async (stateId) => {
    try {
      setIsDistrictLoading(true);
      const response = await getDistByStateId(stateId);
      setDistricts(response.data.data);

      if (values.branch_dist) {
        setFieldValue("dist", values.branch_dist);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 3 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Branch Address:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 3 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="branch_city">
                  City <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="branchCity"
                  name="branch_city"
                  placeholder="Enter branch city"
                  style={{ borderColor: getBorderColor("branch_city") }}
                />
                <ErrorMessage
                  name="branch_city"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="branch_state">
                  State <span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="branchState"
                  name="branch_state"
                  placeholder="Enter branch state"
                  style={{ borderColor: getBorderColor("branch_state") }}
                  onChange={(e) => {
                    const stateId = e.target.value;
                    setFieldValue("branch_state", stateId);
                    fetchDistrict(stateId);
                  }}
                >
                  <option value="" label="Select State" />
                  {isStateLoading ? (
                    <option>Loading states...</option>
                  ) : (
                    states.map((state) => (
                      <option
                        key={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))
                  )}
                </Field>
                <ErrorMessage
                  name="branch_state"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="branch_dist">
                  District <span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="branchDist"
                  name="branch_dist"
                  placeholder="Enter branch district"
                  style={{ borderColor: getBorderColor("branch_dist") }}
                >
                  <option value="" label="Select Districts" />
                  {isDistrictLoading ? (
                    <option>Loading districts...</option>
                  ) : (
                    districts.map((district) => (
                      <option
                        key={district.id}
                        value={district.id}
                        label={district.name}
                      />
                    ))
                  )}
                </Field>
                <ErrorMessage
                  name="branch_dist"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-6">
                <label htmlFor="branch_pin">
                  Pin <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="branchPin"
                  name="branch_pin"
                  max="6"
                  placeholder="Enter branch pin"
                  style={{
                    borderColor: getBorderColor("branch_pin"),
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage
                  name="branch_pin"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-12">
                <label htmlFor="branch_address">
                  Address <span className="mandatory">* </span>
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  id="branchAddress"
                  name="branch_address"
                  placeholder="Enter branch address"
                  style={{ borderColor: getBorderColor("branch_address") }}
                />
                <ErrorMessage
                  name="branch_address"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default BranchAddress;
