import React from "react";
import ClassList from "../CommonPages/ClassList";
import CreateClass from "../CommonPages/CreateClass";

function ClassManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" href="#classlist" data-toggle="tab">
              Class List
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#createclass" data-toggle="tab">
              Create Class
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#uploadclass" data-toggle="tab">
              Upload Class
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="classlist">
            <ClassList />
          </div>

          <div className="tab-pane" id="uploadclass">
            <h1>Currently, the feature is not available. Coming soon.</h1>
          </div>
          <div className="tab-pane" id="createclass">
            <CreateClass />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClassManagement;
