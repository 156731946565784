import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";
import {
  createSection,
  getAllClasses,
  getSectionById,
  getClassById,
  updateSectionById,
} from "../../Services/CommonApis/SectionApi";
import { useParams, useNavigate } from "react-router-dom";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
} from "../../Modals/SweetAlertModel";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";

const SectionCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState({
    class_id: "",
    name: "",
  });
  const {branchId}=useParams()

  const [isClassLoading, setClassLoading] = useState(false);

  const statusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const SectionValidationSchema = Yup.object().shape({
    class_id: Yup.string().required("Class name is required"),
    name: Yup.string().required("Section name is required"),
  });

  const getSection = async (id) => {
    try {
      const response = await getSectionById(id);
      const sectionData = response.data;
      setSection({
        class_id: sectionData.class_id,
        name: sectionData.name,
        status: sectionData.status,
      });
      getclassnamebyid(sectionData.class_id);
    } catch (err) {
      console.error("API error:", err);
    }
  };

  const getclassnamebyid = async (id) => {
    try {
      const response = await getClassById(id);
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getSection(id);
    }
  }, [id]);

  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);
  const fetchClasses = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    showLoadingAlert();
    try {
      const transformedValues = {
        class_id: values.class_id,
        name: values.name,
      };

      let response;
      if (id) {
        response = await updateSectionById(id, transformedValues);
      } else {
        response = await createSection(transformedValues);
      }
      setApiMessage(response.data.message || response.message);
      showSuccessAlert("Success", apiMessage);
      window.location.reload();
    } catch (err) {
      console.error("API error:", err);
      let errorMessage = "An unexpected error occurred. Please try again.";

      if (err.response) {
        errorMessage = err.response.data.error || err.response.data.message;
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }
      showErrorAlert("Error", errorMessage);
      setTimeout(() => {
        navigate("/section/list");
      }, 5000);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">
            Section Creation <small> Form</small>
          </h3>
        </div>
        <Formik
          enableReinitialize
          initialValues={section}
          validationSchema={SectionValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="form-card ml-4">
              <div className="row mr-5 mt-3">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="class_id" className="form-label">
                      Class Name <span className="mandatory">* </span>{" "}
                    </label>

                    <Field
                      as="select"
                      name="class_id"
                      className="form-control"
                      style={{ borderColor: "skyblue" }}
                    >
                      <option value="" label="Select class" />
                      {classOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Section Name{""}
                      <span className="mandatory">* </span>{" "}
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Section Name"
                      style={{ borderColor: "skyblue" }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary mr-2"
                  style={{
                    width: loading ? "150px" : "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Submitting...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SectionCreate;
