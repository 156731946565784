import * as Yup from "yup";

export const SchoolValidation = {
  tab1: Yup.object({
    name: Yup.string().required("School Name is required"),
    school_code: Yup.string().required("School Code is required"),
    city: Yup.string().required("City is required"),
    dist: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
    address: Yup.string().required("Address is required"),
  }),

  tab2: Yup.object({
    branch_name: Yup.string().required("Branch Name is required"),
    branch_code: Yup.string().required("Branch Code is required"),
    branch_email: Yup.string()
      .email("Invalid email format")
    .matches( /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, "Email must be a valid Gmail address")
      .required("Email is required"),
    branch_phone: Yup.string()
      .length(10, "Mobile No must be exactly 10 digits")
      .required("Mobile No is required"),
  }),

  tab3: Yup.object({
    branch_city: Yup.string().required("City is required"),
    branch_dist: Yup.string().required("District is required"),
    branch_state: Yup.string().required("State is required"),
    branch_pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
    branch_address: Yup.string().required("Address is required"),
  }),
  tab4: Yup.object({
    print_file: Yup.mixed().required("Print file is required"),
    report_card: Yup.mixed().required("Report card is required"),
    logo_file: Yup.mixed().required("Logo file is required"),
    text_logo: Yup.mixed().required("Text logo is required"),
  }),
};

// SchoolUpload Excel Validation for SchoolUpload.js Component
export const SchoolExcelValidation = (data) => {
  const errors = [];
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\d{10}$/;

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    if (!row || row.length < 16) {
      rowErrors.missingFields = "Missing required fields.";
    } else {
      const email = row[10];
      if (email && !emailPattern.test(email)) {
        rowErrors.email = "Invalid email format.";
      }

      const pin = row[6];
      if (!pin || !/^\d{6}$/.test(pin)) {
        rowErrors.pin = "Pin must be a 6-digit number.";
      }

      const phone = row[11];
      if (phone && !phonePattern.test(phone)) {
        rowErrors.phone = "Mobile No must be exactly 10 digits.";
      }
    }

    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  return errors;
};

export const AcademicValidationSchema = Yup.object({
  academic_years: Yup.string().required("Academic Year is required"),
  start_date: Yup.date().required("Start Date is required"),
  end_date: Yup.date()
    .required("End Date is required")
    .test(
      "is-greater",
      "End Date must be after Start Date",
      function (value) {
      const { start_date } = this.parent;
      return value && start_date ? value > start_date : true;
      }
    ),
  academic_description: Yup.string().required("Description is required"),
});

export const LanguageValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const DepartmentValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const SubjectValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const QualificationValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});
