import * as Yup from "yup";

export const LoginValidation = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      "Email must be a valid Gmail address"
    )
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const BranchValidation = {
  tab2: Yup.object({
    branch_city: Yup.string().required("city is required"),
    branch_dist: Yup.string().required("district is required"),
    branch_state: Yup.string().required("state is required"),
    branch_pin: Yup.string()
      .length(6, "pin must be exactly 6 digits")
      .required("pin is required"),
    branch_address: Yup.string().required("address is required"),
  }),
  tab1: Yup.object({
    branch_name: Yup.string().required("branch name is required"),
    branch_code: Yup.string().required("branch code is required"),
    branch_email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid Gmail address"
      )
      .required("Email is required"),
    branch_phone: Yup.string()
      .length(10, "mobile No must be exactly 10 digits")
      .required("mobile No is required"),
    academic_id: Yup.string().required("Select the academic year"),
  }),
};

export const BranchExcelValidation = (data) => {
  const errors = [];
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\d{10}$/;
  const pincodePattern = /^\d{6}$/;

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    if (!row || row.length < 10) {
      rowErrors.missingFields = "Missing required fields.";
    } else {
      const email = row[3];
      if (email && !emailPattern.test(email)) {
        rowErrors.email = "Invalid email format.";
      }

      const pincode = row[8];
      if (!pincode || !pincodePattern.test(pincode)) {
        rowErrors.pincode = "Pincode must be a 6-digit number.";
      }

      const phone = row[4];
      if (phone && !phonePattern.test(phone)) {
        rowErrors.phone = "Phone must be exactly 10 digits.";
      }

      const status = row[9].toLowerCase();
      if (status && status !== "active" && status !== "inactive") {
        rowErrors.status = "Status must be 'active' or 'inactive'.";
      }
    }

    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  return errors;
};

export const StaffValidation = {
  tab1: Yup.object({
    // Staff table validation for Tab 1
    role_id: Yup.number().required("Role is required"),
    school: Yup.string().required("School is required"),
    employee_no: Yup.string().required("Employee No is required"),
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().nullable(),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid Gmail address"
      )
      .required("Email is required"),
    epf_no: Yup.string().required("EPF No is required"),
    uan_no: Yup.string().required("UAN No is required"),
    esi_no: Yup.string().required("ESI No is required"),
  }),

  tab2: Yup.object({
    // Staff table validation for Tab 2

    joining_date: Yup.date().required("Joining Date is required"),
    specialized: Yup.number().required("Specialization is required"),
    department: Yup.string().required("Department is required"),
    work_location: Yup.string().required("Work Location is required"),
    qualification: Yup.string().required("Qualification is required"),
    extra_qualification: Yup.string().nullable(),
    previous_school: Yup.string().nullable(),
    reason_change: Yup.string().nullable(),
  }),

  tab3: Yup.object({
    // User Details table validation for Tab 3
    marital_status: Yup.string()
      .oneOf(["single", "married", "divorced", "widowed"])
      .required("Marital Status is required"),
    anniversary_date: Yup.date().required("Anniversary is required"),
    spouse_name: Yup.string().nullable(),
    assigned_activity: Yup.string().required("Assigned Activity is required"),
    date_of_birth: Yup.date().required("Date of Birth is required"),
    gender: Yup.string()
      .oneOf(["male", "female"])
      .required("Gender is required"),
    blood_group: Yup.string().required("Blood Group is required"),
    religion: Yup.string().nullable(),
    cast: Yup.string().nullable(),
    kid_studying: Yup.string().nullable(),
    nationality: Yup.string().required("Nationality is required"),
    mother_tongue: Yup.string().nullable(),
  }),

  tab4: Yup.object({
    // User Details table validation for Tab 4
    aadhaar_card_no: Yup.string()
      .length(12, "Aadhaar Card No must be exactly 12 digits")
      .required("Aadhaar Card No is required"),
    pan_card_no: Yup.string()
      .length(10, "Pan Card No must be exactly 10 digits")
      .required("Pan Card No is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
    tmp_address: Yup.string().nullable(),
    temp_city: Yup.string().nullable(),
    temp_state: Yup.string().nullable(),
    temp_pin: Yup.string()
      .length(6, "Temporary Pin must be exactly 6 digits")
      .nullable(),
    image: Yup.string().nullable(),
  }),
};

export const StudentValidation = {
  tab1: Yup.object({
    academic_year_id: Yup.number().required("Academic Year ID is required"),

    roll_no: Yup.string().required("Roll Number is required"),
    class_id: Yup.number()
      .required("Class ID is required")
      .integer("Class ID must be an integer"),

    section_id: Yup.number()
      .required("Section ID is required")
      .integer("Section ID must be an integer"),
    medium_id: Yup.number()
      .required("Medium ID is required")
      .integer("Medium ID must be an integer"),
  }),

  tab2: Yup.object({
    stfirst_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters long")
      .max(50, "First Name can't be longer than 50 characters"),

    stlast_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters long")
      .max(50, "Last Name can't be longer than 50 characters"),
   
    staadhaar_card_no: Yup.string()
      .matches(/^[0-9]{12}$/, "Aadhaar Card Number must be exactly 12 digits")
      .required("Aadhaar Card Number is required"),
    stpan_card_no: Yup.string()
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "PAN Card Number must be in the format: 5 letters, 4 digits, and 1 letter (e.g., ABCDE1234F)"
      )
      .required("PAN Card Number is required"),
  }),
  tab3: Yup.object({
    pfirst_name: Yup.string().required("First Name is required"),
  }),
  tab4: Yup.object({
    stdate_of_birth: Yup.string().required("Date of birth is required"),
  }),

};

export const StudentEditValidation = {
  tab1: Yup.object({
    academic_year_id: Yup.number().required("Academic Year ID is required"),

    roll_no: Yup.string().required("Roll Number is required"),
    class_id: Yup.number()
      .required("Class ID is required")
      .integer("Class ID must be an integer"),

    section_id: Yup.number()
      .required("Section ID is required")
      .integer("Section ID must be an integer"),
    medium_id: Yup.number()
      .required("Medium ID is required")
      .integer("Medium ID must be an integer"),
  }),

  tab2: Yup.object({
    stfirst_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters long")
      .max(50, "First Name can't be longer than 50 characters"),

    stlast_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters long")
      .max(50, "Last Name can't be longer than 50 characters"),
   
    staadhaar_card_no: Yup.string()
      .matches(/^[0-9]{12}$/, "Aadhaar Card Number must be exactly 12 digits")
      .required("Aadhaar Card Number is required"),
    stpan_card_no: Yup.string()
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "PAN Card Number must be in the format: 5 letters, 4 digits, and 1 letter (e.g., ABCDE1234F)"
      )
      .required("PAN Card Number is required"),
  }),
 
  tab3: Yup.object({
    stdate_of_birth: Yup.string().required("Date of birth is required"),
  }),

};