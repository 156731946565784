import React, { useState, useEffect } from "react";
import {  getStaffByBranchId } from "../../Services/AdminApis/StaffApi";
import { useParams } from "react-router-dom";
import { showErrorAlert } from "../../Modals/SweetAlertModel";

function StaffGridView() {
  const [staffList, setStaffList] = useState([]);
  const {branchId} = useParams()

  useEffect(() => {
    fetchStaffList();
  }, []);


  const fetchStaffList = async () => {
    try {
      const branch_id = branchId
      const response = await getStaffByBranchId({branch_id});
      setStaffList(response.data.data);
    } catch (error) {
      console.error(error);
      showErrorAlert("No response from the server, Please try again")
    }
  };

  return (
    <>
      <div className="card-body">
        <div className="row">
          {staffList.map((staff) => (
            <div
              key={staff.id}
              className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column"
            >
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  {staff.employee_no}
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>{staff.first_name}{" "}{staff.middle_name}{" "}{staff.last_name}</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Email: </b> {staff.email}
                      </p>
                      <ul className="ml-4 mb-0 fa-ul text-muted">
                        <li className="small">
                          <span className="fa-li">
                          <i className="fas fa-lg fa-map-marker-alt"></i>

                          </span>{" "}
                          Work Location:{" "}
                          {staff.work_location}
                        </li>
                        <li className="small">
                          <span className="fa-li">
                          <i className="fas fa-lg fa-calendar-alt"></i>
                          </span>{" "}
                          Joining Date: {staff.joining_date}
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 text-center">
                      <img
                        src="/plugins/dist/img/user2-160x160.jpg"
                        alt="user-avatar"
                        className="img-circle img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <a href="" class="btn btn-sm bg-teal">
                      <i class="fas fa-edit"></i>Edit
                    </a>
                    <a href="" className="btn btn-sm btn-primary ml-2">
                      <i className="fas fa-user"></i> View Profile
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default StaffGridView;
