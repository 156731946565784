import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllClasses } from "../../Services/CommonApis/SectionApi";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import Select from "react-select";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import {
  showErrorAlert,
} from "../../Modals/SweetAlertModel";

import { useState, useEffect } from "react";
import { gethomeworkdetails } from "../../Services/CommonApis/HomeworkApi";
const Homeworkreport = () => {
  const [classOptions, setClassOptions] = useState([]);

  const [sectionOptions, setSectionOptions] = useState([]);
  const { branchId } = useParams();
  const [expandedRows, setExpandedRows] = useState(null);
  const [homeworkData, setHomeworkData] = useState([]);
  const handleclasssection = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      const sections = response.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };
  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);
  const fetchClasses = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  const handleSubmit = async (values) => {
    const formatDate = (dateString) => {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    };
    const dateString = formatDate(values.HomeWork_date);
    const sectionIds = values.section_id.join(", ");

    try {
      const response = await gethomeworkdetails({
        branch_id: branchId,
        class_id: values.class_id,
        section_id: sectionIds,
        date: dateString,
      });
      setHomeworkData(response.data.data);
    } catch (error) {
      showErrorAlert(error.response.data.message);
    }
  };

  const HomeWorkValidationSchema = Yup.object().shape({
    class_id: Yup.string().required("Class is required"),
  });
  const subjectTooltipContent = (rowData) => {
    return rowData.homework
      .map(
        (hw) =>
          `<strong>${hw.subject_name}</strong><br/>
           Homework: ${hw.homework_data || "N/A"}<br/>
           Classwork: ${hw.classwork_data || "N/A"}<br/>
           Books to Carry: ${hw.books_carry || "N/A"}`
      )
      .join("<hr/>");
  };

  const subjectSummaryTemplate = (rowData) => {
    const subjects = rowData.homework.map((hw) => hw.subject_name).join(", ");
    return (
      <>
        <span
          data-pr-tooltip={subjectTooltipContent(rowData)}
          data-pr-position="top"
          data-pr-html
          className="subject-summary"
        >
          {subjects}
        </span>
        <Tooltip target=".subject-summary" />
      </>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {data.homework.map((hw, index) => (
          <div key={index}>
            <strong>{hw.subject_name}</strong>
            <div>Homework: {hw.homework_data || "N/A"}</div>
            <div>Classwork: {hw.classwork_data || "N/A"}</div>
            <div>Books to Carry: {hw.books_carry || "N/A"}</div>
            <hr />
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <h4>HomeWork Creation</h4>
      </div>
      <Formik
        initialValues={{
          HomeWork_date: "",
          class_id: "",
          section_id: "",
        }}
        validationSchema={HomeWorkValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="row ml-3 mr-5 mt-3">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="HomeWork_date" className="form-label">
                    HomeWork Date <span className="mandatory">* </span>
                  </label>
                  <Field
                    type="date"
                    name="HomeWork_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="HomeWork_date"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="class_id" className="form-label">
                    Class <span className="mandatory">* </span>
                  </label>
                  <Field
                    as="select"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const classId = e.target.value;
                      handleclasssection(classId);
                      setFieldValue("class_id", classId);
                    }}
                  >
                    <option value="">Select Class</option>
                    {classOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="section_id" className="form-label">
                    Section <span className="mandatory">* </span>
                  </label>
                  <Field name="section_id">
                    {({ field, form }) => (
                      <Select
                        isMulti
                        name={field.name}
                        options={sectionOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          form.setFieldValue(field.name, selectedValues);
                        }}
                        value={sectionOptions.filter((option) =>
                          field.value.includes(option.value)
                        )}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {homeworkData.length > 0 && (
        <div className="mt-4">
          <DataTable
            value={homeworkData}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="date"
            responsiveLayout="scroll"
          >
            <Column expander style={{ width: "3em" }} />
            <Column field="branch_name" header="Branch Name"></Column>
            <Column field="class_name" header="Class Name"></Column>
            <Column field="section_name" header="Section Name"></Column>
            <Column field="date" header="Date"></Column>
            <Column field="homework_type" header="Homework Type"></Column>
            <Column header="Subjects" body={subjectSummaryTemplate}></Column>
          </DataTable>
        </div>
      )}
      </div>

    </>
  );
};
export default Homeworkreport;
