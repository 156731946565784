import React from 'react';
import Dashboard from '../CommonPages/Dashboard';

function SchoolDashboard() {

  return (
    <div>
        <Dashboard/>
    </div>
  );
}

export default SchoolDashboard;
