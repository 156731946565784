import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  getAcademicSetup,
  getAllFeesTimelines,
  updateAcademicSetup,
  getAllFeesDiscount,
} from "../../Services/CommonApis/FeesApi";

function AcademicFeeEdit() {
  const { branchId, id } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [academic, setAcademic] = useState([]);
  const [timelineList, setTimelineList] = useState([]);
  const [academicSetupData, setAcademicSetupData] = useState({});
  const [classId, setClassId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(true);
  const [isSectionLoading, setSectionLoading] = useState(false);
  const [isAcademicLoading, setIsAcademicLoading] = useState(true);
  const [isTimelineLoading, setIsTimelineLoading] = useState(true);
  const [discountList, setDiscountList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDiscountAmount, setSelectedDiscountAmount] = useState("");

  useEffect(() => {
    fetchAcademicSetupById(id);
    fetchClassByBranchId(branchId);
    getAllAcademic();
    fetchTimeline();
    fetchDiscounts();
  }, [branchId]);

  useEffect(() => {
    if (classId) {
      setSectionLoading(true);
      fetchSectionByClassId(classId);
    }
  }, [classId]);

  const fetchAcademicSetupById = async (id) => {
    try {
      const response = await getAcademicSetup(id);
      setAcademicSetupData(response.data.data);
      setClassId(response.data.data.class_id);
      fetchDiscounts();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setClassLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSectionByClassId = async (selectedClassId) => {
    try {
      const response = await getSectionByClassId(selectedClassId);
      setSectionList(response.data.sections);
      setSectionLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllAcademic = async () => {
    try {
      const response = await getAllAcademics();
      setAcademic(response.data.data);
      setIsAcademicLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTimeline = async () => {
    try {
      const response = await getAllFeesTimelines();
      setTimelineList(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsTimelineLoading(false);
    }
  };

  const fetchDiscounts = async () => {
    try {
      const response = await getAllFeesDiscount();
      let discounts = response.data.data || [];
      setDiscountList(discounts);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setDiscountList([]);
      setIsLoading(false);
    }
  };

  const handleDiscountChange = (e, setFieldValue) => {
    const selectedDiscountId = e.target.value;
    setFieldValue("discount", selectedDiscountId);
    const selectedDiscount = discountList.find(
      (discount) => discount.id === parseInt(selectedDiscountId)
    );
    if (selectedDiscount) {
      setSelectedDiscountAmount(selectedDiscount.amount);
    } else {
      setSelectedDiscountAmount("");
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to update the academic setup",
      "info",
      "Yes, Update",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();

      try {
        const selectedDiscount = discountList.find(
          (discount) => discount.id === parseInt(values.discount)
        );
        const payload = {
          amount: values.amount,
          discount: selectedDiscount ? selectedDiscount.amount : 0,
          discount_type: values.discount,
        };
        const response = await updateAcademicSetup(id, payload);

        if (response.status === 200) {
          showSuccessAlert(
            "Updated!",
            "The academic setup has been updated successfully."
          );
          resetForm();
        } else {
          const errorMessage =
            response.data?.message ||
            "Academic setup updation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        "Academic setup updation has been cancelled."
      );
    }
  };

  const initialValues = {
    class_id: academicSetupData.class_id || "",
    section_id: academicSetupData.section_id || "",
    academic_id: academicSetupData.academic_id || "",
    amount: academicSetupData.amount || "",
    discount: academicSetupData.discount || "",
    pay_timeline: academicSetupData.pay_timeline || "",
    pay_timeline_date: academicSetupData.pay_timeline_date || "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    academic_id: Yup.string().required("Academic year is required"),
    amount: Yup.number()
      .required("Amount is required")
      .min(0, "Amount must be a positive number"),
    discount: Yup.number()
      .required("Discount is required")
      .min(0, "Discount must be a positive number"),
    pay_timeline: Yup.number()
      .required("Pay timeline is required")
      .min(1, "Pay timeline must be at least 1"),
    pay_timeline_date: Yup.date().required("Pay timeline date is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Academic Fee Edit</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Academic Fee Edit</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Academic Fee Edit <small>Form</small>
            </h3>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form id="form">
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-4">
                      <label htmlFor="class_id">
                        Class Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="class_id"
                        name="class_id"
                        className="form-control"
                        disabled
                        onChange={(e) => {
                          const selectedClassId = e.target.value;
                          setFieldValue("class_id", selectedClassId);
                          setClassId(selectedClassId);
                        }}
                      >
                        {isClassLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Class</option>
                            {classList.map((cls) => (
                              <option key={cls.id} value={cls.id}>
                                {cls.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="class_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="section_id">
                        Section Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="section_id"
                        name="section_id"
                        className="form-control"
                        disabled
                      >
                        {isSectionLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Section</option>
                            {sectionList.map((section) => (
                              <option key={section.id} value={section.id}>
                                {section.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="section_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="academic_id">
                        Academic Year <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="academic_id"
                        name="academic_id"
                        className="form-control"
                        disabled
                      >
                        <option value="">Select Academic Year</option>
                        {isAcademicLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          academic.map((year) => (
                            <option key={year.id} value={year.id}>
                              {year.academic_years}
                            </option>
                          ))
                        )}
                      </Field>
                      <ErrorMessage
                        name="academic_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="amount">
                        Amount <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="number"
                        id="amount"
                        name="amount"
                        className="form-control"
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="discount">
                        Discount Type <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="discount"
                        name="discount"
                        className="form-control"
                        onChange={(e) => handleDiscountChange(e, setFieldValue)}
                      >
                        <option value="">Select Discount</option>
                        {discountList.map((discount) => (
                          <option key={discount.id} value={discount.id}>
                            {`${discount.name} - ${discount.amount}`}{" "}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="discount"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div style={{ display: "none" }}>
                      <Field
                        type="text"
                        id="discountAmount"
                        name="discountAmount"
                        className="form-control"
                        value={selectedDiscountAmount}
                        readOnly
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="pay_timeline">
                        Payment Timeline <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="pay_timeline"
                        name="pay_timeline"
                        className="form-control"
                        disabled
                      >
                        <option value="">Select Payment Timeline</option>
                        {isTimelineLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          timelineList.map((timeline) => (
                            <option key={timeline.id} value={timeline.id}>
                              {timeline.name}
                            </option>
                          ))
                        )}
                      </Field>
                      <ErrorMessage
                        name="pay_timeline"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="pay_timeline_date">
                        Payment Timeline Date{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        id="pay_timeline_date"
                        name="pay_timeline_date"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="pay_timeline_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default AcademicFeeEdit;
