import Api from "../Api";

export const getParents = (params) => {
  return Api.get(`/get-parents`, { params });
};

export const getParentById = (id) => {
  return Api.get(`/get-parent/${id}`);
};

export const updateParentById = async (id, data) => {
  const formData = new FormData();

  formData.append("image", data.image);
  formData.append("aadhaar_card_no", data.aadhaar_card_no);
  formData.append("address", data.address);
  formData.append("city", data.city);
  formData.append("state", data.state);
  formData.append("country", data.country);
  formData.append("pin", data.pin);
  formData.append("alt_phone", data.alt_phone);
  formData.append("pan_card_no", data.pan_card_no);
  formData.append("first_name", data.first_name);
  formData.append("middle_name", data.middle_name);
  formData.append("last_name", data.last_name);
  formData.append("phone", data.phone);
  formData.append("email", data.email);
  formData.append("alt_email", data.alt_email);
  formData.append("education", data.education);
  formData.append("occupation", data.occupation);
  formData.append("annual_income", data.annual_income);
  formData.append("mother_name", data.mother_name);
  formData.append("mother_phone", data.mother_phone);
  formData.append("mother_email", data.mother_email);
  formData.append("mother_education", data.mother_education);
  formData.append("mother_occupation", data.mother_occupation);
  formData.append("mother_annual_income", data.mother_annual_income);
  formData.append("mother_aadhaar_no", data.mother_aadhaar_no);
  formData.append("mother_pan_card", data.mother_pan_card);
  formData.append("mother_dob", data.mother_dob);
  formData.append("date_of_birth", data.date_of_birth);
  formData.append("gender", data.gender);
  formData.append("blood_group", data.blood_group);
  formData.append("religion", data.religion);
  formData.append("cast", data.cast);
  formData.append("nationality", data.nationality);

  try {
    const response = await Api.post(`/update-parent/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      console.error("Validation errors:", error.response.data.errors);
    } else {
      console.error("An error occurred:", error.message);
    }
    throw error;
  }
};
