import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getParentsByNum } from "../../Services/CommonApis/StudentApi";
import Swal from "sweetalert2";

const AdmissionContactInfo = ({ steps, current }) => {
  const { setFieldValue, values } = useFormikContext();

  const handlePhoneChange = async (event) => {
    const inputValue = event.target.value;
    setFieldValue("num", inputValue);

    if (inputValue.length === 10) {
      try {
        setFieldValue("pfirst_name", "");
        setFieldValue("pmiddle_name", "");
        setFieldValue("plast_name", "");
        setFieldValue("phone", "");
        setFieldValue("email", "");
        setFieldValue("date_of_birth", "");
        setFieldValue("gender", "");
        setFieldValue("aadhaar_card_no", "");
        setFieldValue("pan_card_no", "");

        const response = await getParentsByNum(inputValue);
        if (response.data.data && response.data.data.id) {
          const parentData = response.data.data;
          setFieldValue("parent_id", parentData.id || "");
          setFieldValue("pfirst_name", parentData.first_name || "");
          setFieldValue("pmiddle_name", parentData.middle_name || "");
          setFieldValue("plast_name", parentData.last_name || "");
          setFieldValue("phone", parentData.phone || "");
          setFieldValue("email", parentData.alt_email || "");
          setFieldValue("date_of_birth", parentData.date_of_birth || "");
          setFieldValue("gender", parentData.gender || "");
          setFieldValue("aadhaar_card_no", parentData.aadhaar_card_no || "");
          setFieldValue("pan_card_no", parentData.pan_card_no || "");

          Swal.fire({
            title: "Parent Already Exists!",
            text: `Parent data retrieved successfully for phone Number: ${parentData.phone}`,
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error("Error fetching parent data: ", error);
        // Handle the error accordingly (e.g., show an alert or message)
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 3 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Contact Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
            <div className="col-4">
                <div className="form-group">
                  <label htmlFor="phone" className="form-label">
                  phone
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="father_phone" className="form-label">
                    Father Mobile No
                  </label>
                  <Field
                    type="text"
                    name="father_phone"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="father_phone"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_phone" className="form-label">
                    Mother Mobile No
                  </label>
                  <Field
                    type="text"
                    name="mother_phone"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_phone"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="father_email" className="form-label">
                    Father Email
                  </label>
                  <Field
                    type="email"
                    name="father_email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="father_email"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_email" className="form-label">
                    Mother Email
                  </label>
                  <Field
                    type="email"
                    name="mother_email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_email"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              

              
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default AdmissionContactInfo;
