import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  createVehicle,
  getVehicleById,
  getVehicles,
  updateVehicleById,
  deleteVehicleById,
} from "../../Services/CommonApis/TransportVehicleApi";
import { useParams } from "react-router-dom";

const TransportVehiclesDetails = () => {
  const { branchId } = useParams();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");
  const [editVehicleType, setEditVehicleType] = useState("");
  const [editInsurance, setEditInsurance] = useState("");
  const [editCapacity, setEditCapacity] = useState("");

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteVehicleById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The vehicle has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the group.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The group is safe :)");
    }
  };

  const handleCreateVehicle = async (values) => {
    try {
      showLoadingAlert();
      await createVehicle({
        ...values,
        branch_id: branchId,
        insurance_expire: formatDate(values.insurance_expire),
      });
      Swal.close();
      showSuccessAlert("Success!", "The Vehicle has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error creating the Vehicle.");
      console.error(error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getVehicleById(id);
      const { vehicle_no, vehicle_type, insurance_expire, capacity } =
        response.data;
      setEditGroupId(id);
      setEditGroupName(vehicle_no);
      setEditCapacity(capacity);
      setEditInsurance(insurance_expire);
      setEditVehicleType(vehicle_type);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the Vehicle details."
      );
    }
  };

  const handleUpdateVehicle = async (values) => {
    try {
      showLoadingAlert();
      await updateVehicleById(editGroupId,{
        ...values,
        branch_id: branchId,
        insurance_expire: formatDate(values.insurance_expire),
      });
      Swal.close();
      showSuccessAlert("Success!", "The vehicle has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the exam.");
      console.error(error);
    }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "vehicle_type" },
        { data: "vehicle_no" },
        { data: "capacity" },
        { data: "insurance_expire" },
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "0"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
        <div class="icon-group" style="display: flex; gap: 10px;">
          <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
          ${deleteIcon}
        </div>
      `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getVehicles({
            branch_id: branchId,
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          console.log(response, "hiii");
          const jsonData = response.data.data;
          const totalCount = response.data.total;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    vehicle_type: "",
    vehicle_no: "",
    capacity: "",
    insurance_expire: "",
  };

  const validationSchema = Yup.object({
    vehicle_type: Yup.string().required("Vehicle type is required"),
    vehicle_no: Yup.string().required("Vehicle No is required"),
    capacity: Yup.string().required("Capacity is required"),
    insurance_expire: Yup.date().required("Insurance Expiration is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Vehicles Details</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Vehicles List
                  </h3>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New
                  </button>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Vehicle Type</th>
                        <th>Vehicle No</th>
                        <th>Capacity</th>
                        <th>Insurance Expiration</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Vehicle Type</th>
                        <th>Vehicle No</th>
                        <th>Capacity</th>
                        <th>Insurance Expiration</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Group Modal */}
        <Modal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          backdrop="static"
          className="swal2-blur-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Vehicle</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateVehicle}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="vehicle_type">Vehicle Type</label>
                    <Field
                      as="select"
                      name="vehicle_type"
                      className="form-control"
                    >
                      <option value="">Select Vehicle Type</option>
                      <option value="car">Car</option>
                      <option value="bus">Bus</option>
                      <option value="mini-bus">Mini Bus</option>
                    </Field>
                    <ErrorMessage
                      name="vehicle_type"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="vehicle_no">Vehicle No</label>
                    <Field
                      type="text"
                      name="vehicle_no"
                      className="form-control"
                      placeholder="Enter Vehicle No"
                    />
                    <ErrorMessage
                      name="vehicle_no"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="capacity">Capacity</label>
                    <Field
                      type="number"
                      name="capacity"
                      className="form-control"
                      placeholder="Enter vehicle capacity"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage
                      name="capacity"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="insurance_expire">
                      Insurance Expiration
                    </label>
                    <Field
                      type="date"
                      name="insurance_expire"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="insurance_expire"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Vehicle</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              vehicle_no: editGroupName,
              vehicle_type: editVehicleType,
              capacity: editCapacity,
              insurance_expire: editInsurance,
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateVehicle}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="vehicle_type">Vehicle Type</label>
                    <Field
                      as="select"
                      name="vehicle_type"
                      className="form-control"
                    >
                      <option value="">Select Vehicle Type</option>
                      <option value="car">Car</option>
                      <option value="bus">Bus</option>
                      <option value="mini-bus">Mini Bus</option>
                    </Field>
                    <ErrorMessage
                      name="vehicle_type"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="vehicle_no">Vehicle No</label>
                    <Field
                      type="text"
                      name="vehicle_no"
                      className="form-control"
                      placeholder="Enter vehicle No"
                    />
                    <ErrorMessage
                      name="vehicle_no"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="capacity">Capacity</label>
                    <Field
                      type="number"
                      name="capacity"
                      className="form-control"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage
                      name="capacity"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="insurance_expire">
                      Insurance Expiration
                    </label>
                    <Field
                      type="date"
                      name="insurance_expire"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="insurance_expire"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default TransportVehiclesDetails;
