import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";

const AdmissionOtherDetails = ({ steps, current }) => {
  const { id } = useParams();
  const { setFieldValue, values } = useFormikContext();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [FileName, setFileName] = useState("");
  const [language, setLanguage] = useState([]);

  const customFileInputStyle = {
    display: "none",
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getAllLanguages();
      setLanguage(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const customButtonStyle = {
    border: "1px solid lightskyblue",
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("stimage", file);
      setSelectedFileName(file.name);
    } else {
      setFieldValue("stimage", null);
      setSelectedFileName("");
    }
  };

  const handleachievementFileChange = (event) => {
    const file = event.target.files[1];
    if (file) {
      setFieldValue("achievements", file);
      setFileName(file.name);
    } else {
      setFieldValue("achievements", null);
      setFileName("");
    }
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 4 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Additional Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 3 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
            <div className="col-4">
                <div className="form-group">
                  <label htmlFor="last_study_course" className="form-label">
                    last Study Course
                  </label>
                  <Field
                    type="text"
                    name="last_study_course"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="last_study_course"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="last_exam_marks" className="form-label">
                    last Exam Marks
                  </label>
                  <Field
                    type="text"
                    name="last_exam_marks"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="last_exam_marks"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>


              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="previous_school" className="form-label">
                    Previous School Name
                  </label>
                  <Field
                    type="text"
                    name="previous_school"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="previous_school"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

             

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="reason_change" className="form-label">
                    Reason for Change
                  </label>
                  <Field
                    type="text"
                    name="reason_change"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="reason_change"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="reason_gap" className="form-label">
                    Reason Gap
                  </label>
                  <Field
                    type="text"
                    name="reason_gap"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="reason_gap"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label
                    htmlFor="extra_curricular_activites"
                    className="form-label"
                  >
                    Extra Curricular Activities
                  </label>
                  <Field
                    type="text"
                    name="extra_curricular_activites"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="extra_curricular_activites"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="school_enquiry" className="form-label">
                    School Enquiry
                  </label>
                  <Field
                    type="text"
                    name="school_enquiry"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="school_enquiry"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

             
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="anySiblingStudying" className="form-label">
                    Any Sibling Studying
                  </label>
                  <Field
                    type="text"
                    name="anySiblingStudying"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="anySiblingStudying"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="identification_mark" className="form-label">
                    Identification Mark 1
                  </label>
                  <Field
                    type="text"
                    name="identification_mark"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="identification_mark"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="identification_mark_two" className="form-label">
                    Identification Mark 2
                  </label>
                  <Field
                    type="text"
                    name="identification_mark_two"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="identification_mark_two"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="sports" className="form-label">
                    Sports
                  </label>
                  <Field type="text" name="sports" className="form-control" />
                  <ErrorMessage
                    name="sports"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="achievements" className="form-label">
                    achievements
                  </label>
                  <Field
                    type="text"
                    name="achievements"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="achievements"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="area_of_interest" className="form-label">
                    area_of_interest
                  </label>
                  <Field
                    type="text"
                    name="area_of_interest"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="area_of_interest"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="additional_skills" className="form-label">
                    additional_skills
                  </label>
                  <Field
                    type="text"
                    name="additional_skills"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="additional_skills"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="volunteer" className="form-label">
                    Volunteer
                  </label>
                  <Field
                    type="text"
                    name="volunteer"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="volunteer"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

             
              <div className="col-4">
                <div className="form-group">
                  <label
                    htmlFor="isParentWorkingInSameSchool"
                    className="form-label"
                  >
                    Is Your Parent Working in the Same School?
                  </label>
                  <Field
                    as="select"
                    name="isParentWorkingInSameSchool"
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Field>
                  <ErrorMessage
                    name="isParentWorkingInSameSchool"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="stimage" className="d-block">
                    Upload Image <span className="mandatory">* </span>
                  </label>
                  <div style={customButtonStyle}>
                    <label htmlFor="stimage" style={{ cursor: "pointer" }}>
                      <i
                        className="fas fa-upload"
                        style={{ marginRight: "8px" }}
                      ></i>
                      Browse
                    </label>
                    <input
                      type="file"
                      id="stimage"
                      name="stimage"
                      onChange={handleFileChange} // Handle file change
                      style={customFileInputStyle} // Hide the default file input
                    />
                  </div>
                  {selectedFileName && (
                    <div style={{ marginTop: "8px" }}>{selectedFileName}</div> // Display the filename
                  )}
                  <ErrorMessage
                    name="stimage"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="physically_challenge">
                  Physically challenged?
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="physically_challenge_yes"
                    name="physically_challenge"
                    value="yes"
                    onChange={(e) => {
                      setFieldValue("physically_challenge", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="pphysically_challenge_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="physically_challenge_no"
                    name="physically_challenge"
                    value="no"
                    checked={values.physically_challenge === "no"}
                    onChange={(e) => {
                      setFieldValue("physically_challenge", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="physically_challenge_no"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="transport_required">
                  Transport Required?
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="transport_required_yes"
                    name="transport_required"
                    value="yes"
                    onChange={(e) => {
                      setFieldValue("transport_required", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="transport_required_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="transport_required_no"
                    name="transport_required"
                    value="no"
                    checked={values.transport_required === "no"}
                    onChange={(e) => {
                      setFieldValue("transport_required", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="transport_required_no"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="neet_applicable">
                  Neet Applicable
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="neet_applicable_yes"
                    name="neet_applicable"
                    value="yes"
                    onChange={(e) => {
                      setFieldValue("neet_applicable", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="neet_applicable_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="neet_applicable_no"
                    name="neet_applicable"
                    value="no"
                    checked={values.neet_applicable === "no"}
                    onChange={(e) => {
                      setFieldValue("neet_applicable", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="neet_applicable_no"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="hostel_required">
                  Hostel Required
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="hostel_required_yes"
                    name="hostelRequired"
                    value="yes"
                    onChange={(e) => {
                      setFieldValue("hostelRequired", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="hostel_required_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="hostel_required_no"
                    name="hostelRequired"
                    value="no"
                    checked={values.hostelRequired === "no"}
                    onChange={(e) => {
                      setFieldValue("hostelRequired", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="hostel_required_no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default AdmissionOtherDetails;
