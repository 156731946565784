import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useParams } from "react-router-dom";
import { getFeesDashboardData } from "../../Services/CommonApis/FeesApi";

const FeesDashboard = () => {
  const { schoolId, branchId } = useParams();
  const [dashboardData, setDashboardData] = useState({
    total_branches: 0,
    total_class: 0,
    total_setup: 0,
    total_fee_collection: 0,
    total_online_collection: 0,
    students_with_due_balance: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = schoolId ? { school_id: schoolId, branch_id: branchId } : {};
        const response = await getFeesDashboardData(params);
        if (response.data && response.data.data) {
          setDashboardData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching fees dashboard data:", error);
      }
    };
    fetchData();
  }, [schoolId, branchId]);

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Fees Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Fees Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content-header">
        <div className="container-fluid">
          <div className="row">

            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>
                    {schoolId ? dashboardData.total_class : dashboardData.total_branches}
                  </h3>
                  <p>{schoolId ? "Total Classes" : "Total Branches"}</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{dashboardData.total_setup}</h3>
                  <p>Total Setup</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{dashboardData.total_fee_collection}</h3>
                  <p>Total Fee Collection</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{dashboardData.total_online_collection}</h3>
                  <p>Total Online Collection</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>


          <div className="row">
            <section className="col-lg-6 connectedSortable">
              <div className="card">
                <div className="card-header" style={{ backgroundColor: "#e27070" }}>
                  <h3 className="card-title">Due Payment</h3>
                </div>
                <div className="card-body">
                  <div className="events-widget">
                    <div className="header">
                      <h2>Fees Details</h2>
                      <button className="view-more">VIEW MORE</button>
                    </div>

                    {dashboardData.students_with_due_balance.length > 0 ? (
                      dashboardData.students_with_due_balance.map((student, index) => (
                        <div key={index} className="event">
                          <div className="event-date">
                            <span className="DuePayment">{`₹${student.balance}`}</span>
                          </div>
                          <div className="event-details">
                            <h3 className="event-title">
                              {student.first_name} {student.middle_name || ""} {student.last_name}
                            </h3>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No students with due balance.</p>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeesDashboard;
