import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { getAcademicSetups } from "../../Services/CommonApis/FeesApi";
import { Link, useParams } from "react-router-dom";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { getUserRoleId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import { USERROLEID } from "../../Schemas/userRoles";
import { Tooltip } from "primereact/tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

const AcademicFeeList = () => {
  const tableRef = useRef(null);
  const { branchId, schoolId } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const sessionData = getSession(COOKIE.ADMIN_TOKEN);
    if (sessionData) {
      const fetchedRoleId = getUserRoleId(sessionData);
      const table = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id" },
          { data: "class_name" },
          { data: "section_name" },
          { data: "academic_id" },
          { data: "amount" },
          { data: "discount" },
          { data: "pay_timeline" },
          { data: "pay_timeline_date" },
          {
            data: "status",
            title: "Status",
            render: (data) => (data === "1" ? "Active" : "Inactive"),
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              const isAdmin = fetchedRoleId === USERROLEID.ADMIN_ID;
              return `
                <div class="icon-group" style="display: flex; gap: 10px;">
                  ${
                    isAdmin
                      ? `<a href="/school/${schoolId}/branch/${branchId}/academic-fee-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>`
                      : `
                      <div>
                        <Tooltip title="If any changes, please contact admin" placement="top">
                          <IconButton>
                            <i class="fas fa-info-circle" style="color: blue; font-size: 20px;"></i>
                          </IconButton>
                        </Tooltip>
                      </div>
                      `
                  }
                </div>
              `;
            },
          },
        ],
        lengthChange: true,
        ordering: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          {
            extend: "copy",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csv",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Academic Fee Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "asc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getAcademicSetups({
              school_id: schoolId,
              branch_id: branchId,
              page: page,
              _limit: length,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            const jsonData = response.data.data || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (err) {
            console.error("Error fetching data:", err);
            showErrorAlert(err.response.data.message || "Data Not Found");
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      return () => {
        table.destroy();
      };
    }
  }, [refresh]);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Academic Fee List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Academic Fee List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Academic List Table</h3>
          <div className="card-tools">
            <ul className="nav nav-pills ml-auto">
              <li className="nav-item p-1">
                <Link
                  className="btn btn-primary active"
                  to={`/school/${schoolId}/branch/${branchId}/academic-fee-create`}
                >
                  Create New
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Class Name</th>
                <th>Section Name</th>
                <th>Academic Name</th>
                <th>Amount</th>
                <th>Discount</th>
                <th>Pay Timeline</th>
                <th>Pay Timeline Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Class Name</th>
                <th>Section Name</th>
                <th>Academic Name</th>
                <th>Amount</th>
                <th>Discount</th>
                <th>Pay Timeline</th>
                <th>Pay Timeline Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
          {showError && (
            <div
              className={`error-alert error-alert-danger ${
                showError ? "fade-in" : "fade-out"
              }`}
              onAnimationEnd={() => {
                if (!showError) setError("");
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AcademicFeeList;