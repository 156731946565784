import Api from "../Api";
export const createHomework = async (data) => {
    try {
      const response = await Api.post("/create-homework", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  export const getbranchSubject=async(id)=>{
    try{
   const response=await Api.post(`/get-branchsubjects/${id}`);
   return response.data;
    }
    catch(error){
  console.log(error)
    }
  }
 
  export const gethomeworkdetails=async({branch_id,class_id,section_id,date})=>{
    return Api.get(`/get-homeworks`,{
      params:{
        branch_id,
        class_id,
        section_id,
        date
      }
     })
    }