import Api from "../Api";

export const createCertificateType = async (data) => {
  return await Api.post(`/create-certificate`, data);
};

export const GetCertificateType = async () => {
  return await Api.get(`/get-all-certificates`);
};

export const GetSingleCertificateType = async (id) => {
  return await Api.get(`/get-certificate/${id}`);
};

export const DeleteCertificateType = async (id) => {
  return await Api.get(`/delete-certificate/${id}`);
};

export const UpdateCertificateType = async (id, data) => {
  return await Api.post(`/update-certificate/${id}`, data);
};

export const createCertificateFields = async (data) => {
  return await Api.post(`/create-certificate-fields`, data);
};

export const GetCertificateFields = async () => {
  return await Api.get(`/get-all-certificate-fields`);
};

export const UpdateCertificateField = async (id, data) => {
  return await Api.post(`/update-certificate-fields/${id}`, data);
};

export const DeleteCertificateField = async (id) => {
  return await Api.delete(`/delete-certificate-fields/${id}`);
};

export const getCertificateFieldsByType = async (id) => {
  return await Api.get(`/get-certificate-fields/${id}`);
};

export const getCertificateFieldById = async (id) => {
  return await Api.get(`/get-certificate-fieldbyid/${id}`);
};
export const getcertificatestudentbyclassandsection = async (
  branchId,
  selectedClass,

  selectedSection
) => {
  try {
    const response = await Api.get(`/get-certificate-list`, {
      params: {
        branch_id: branchId,
        class_id: selectedClass,
        section_id: selectedSection,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const generateCertificatePdf = async (student_id, certificate_type) => {
  return Api.get("/generate-certificate-pdf", {
    params: {
      student_id: student_id,
      certificate_type: certificate_type,
    },
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `fee_invoice_${student_id}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const certificatepreviewPdf = async (certificate_type, student_id) => {
  try {
    const response = await Api.get("/generate-certificate-pdf", {
      params: {
        student_id,
        certificate_type,
      },
      responseType: "blob",
    });

    return response.data; // or handle the blob as needed
  } catch (error) {
    console.error("Error generating certificate PDF:", error);
    throw error; // rethrow or handle the error as needed
  }
};
//generate-certificate
export const generateCertificate = async (data) => {
  return await Api.post(`/generate-certificate`, data);
};

export const DeleteCertificateList = async (id) => {
  return await Api.get(`/delete-certificate/${id}`);
};
