import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { getAllClasses } from "../../Services/CommonApis/SectionApi";
import { deleteClassById, getClassById, updateClassById } from "../../Services/CommonApis/classApi";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


const ClassList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editClassId, setEditClassId] = useState(null);
  const [editClassName, setEditClassName] = useState("");


  useEffect(() => {
    if (branchId) {
      initializeDataTable();
    }
    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy(true);
        tableInstance.current = null;
      }
    };
  }, [branchId]);

  const handleEdit = async (id) => {
    try {
      const response = await getClassById(id); 
      const { name } = response.data.data; 
      setEditClassId(id); 
      setEditClassName(name); 
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching class details:", error);
      showErrorAlert("Error!", "There was an error fetching the class details.");
    }
  };
    const handleUpdateClass = async (values) => {
    try {
      showLoadingAlert();
     
      await updateClassById(editClassId, { branch_id: branchId, name: values.name });
      Swal.close();
      showSuccessAlert("Success!", "The class has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the class.");
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the branch!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteClassById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The class has been deleted.");

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the class.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Your class is safe");
    }
  };

  const initializeDataTable = () => {
    if (tableInstance.current) {
      tableInstance.current.destroy(true);
      tableInstance.current = null;
    }

    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        {
          data: "status",
          render: function (data, type, row) {
            return data === "1" ? "Active" : "Inactive";
          },
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `
              <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
            `;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        if (!branchId) {
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
          return;
        }

        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllClasses({
            branch_id: branchId,
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });
  };
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Class name is required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("Form submitted:", values);
        }}
      >
        {({ setFieldValue, values }) => (
          <>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Class List Table</h3>
              </div>
              <div className="card-body">
                <table ref={tableRef} className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Class Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                    <tr>
                      <th>ID</th>
                      <th>Class Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

           
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Class</Modal.Title>
              </Modal.Header>
              <Formik
                enableReinitialize 
                initialValues={{ name: editClassName }} 
                validationSchema={Yup.object({
                  name: Yup.string().required("Class name is required"),
                })}
                onSubmit={handleUpdateClass}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="name">Class Name</label>
                        <Field name="name" className="form-control" />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>

          </>
        )}
      </Formik>
    </>
  );

};

export default ClassList;