import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Dashboard.css";
import { getReportCardDashboardDetails } from "../../Services/CommonApis/ReportcardApi";

const ReportCardDashboard = () => {
  const { branchId } = useParams(); // Get branchId from the URL params
  const [dashboardData, setDashboardData] = useState({
    total_branch_count: 0,
    total_setup_count: 0,
    total_marks_report: {
      total_branch_student_count: 0,
      added_student_ids_count: 0,
    },
    incomplete_marks_report: {
      total_incomplete_reports: 0,
      subject_wise_incomplete: [],
    },
    promoted_student: {
      total_promoted: 0,
    },
    failed_student: {
      total_failed: 0,
    },
  });

  useEffect(() => {
    if (branchId) {
      getReportCardDashboardDetails(branchId)
        .then((response) => {
          setDashboardData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching dashboard data:", error);
        });
    }
  }, [branchId]);

  const {
    total_branch_count,
    total_setup_count,
    total_marks_report,
    incomplete_marks_report,
    promoted_student,
    failed_student,
  } = dashboardData;

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard v1</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>
                    {total_setup_count} / {total_branch_count}
                  </h3>
                  <p>Total School (Setups / Branches)</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{total_marks_report.total_branch_student_count || 0}</h3>
                  <p>Total Reports Entered</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{incomplete_marks_report.total_incomplete_reports}</h3>
                  <p>Total Incomplete Reports</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{promoted_student.total_promoted}</h3>
                  <p>Total Promoted to Next Class</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-purple">
                <div className="inner">
                  <h3>{failed_student.total_failed}</h3>
                  <p>Total Failed Count</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportCardDashboard;
