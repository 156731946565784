import Api from "../Api";

export const createVehicle = async (data) => {
  try {
    const response = await Api.post("/create-transport-vehicles", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getVehicles = async (params) => {
  return await Api.get("/get-all-transport-vehicles", { params });
};

export const deleteVehicleById = async (id) => {
  try {
    const response = await Api.delete(`/delete-transport-vehicles/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateVehicleById = async (id, data) => {
  try {
    const response = await Api.post(`/update-transport-vehicles/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getVehicleById = async (id) => {
  try {
    const response = await Api.get(`/get-transport-vehicles/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
