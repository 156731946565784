import React from "react";
import { Field, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import {
  getDistByStateId,
  getStates,
} from "../../Services/AdminApis/StateandDistrictApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";

const SchoolInformation = ({
  current,
  steps,
  setUseSchoolAddress,
  setFieldValue,
  setFieldTouched,
  values,
}) => {
  const { handleChange, touched, errors } = useFormikContext();
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [academic, setAcademic] = useState([]);

  // Loading states for spinners
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [isAcademicLoading, setIsAcademicLoading] = useState(false);

  useEffect(() => {
    fetchStates();
    fetchAcademicYear();
  }, []);

  useEffect(() => {
    if (values.state) {
      fetchDistrict(values.state);
    }
  }, [values.state]);

  // Update school code based on school name
  useEffect(() => {
    const schoolCode = generateSchoolCode(values.name);
    setFieldValue("school_code", schoolCode);
  }, [values.name, setFieldValue]);

  const fetchStates = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsStateLoading(false); // Stop loading spinner
    }
  };

  const fetchAcademicYear = async () => {
    try {
      setIsAcademicLoading(true);
      const response = await getAllAcademics();

      const academicYearsArray = response.data.data;
      setAcademic(academicYearsArray);
    } catch (error) {
      console.error("Error fetching academic years:", error);
      setAcademic([]);
    } finally {
      setIsAcademicLoading(false);
    }
  };

  const fetchDistrict = async (stateId) => {
    try {
      setIsDistrictLoading(true);
      const response = await getDistByStateId(stateId);
      setDistricts(response.data.data);

      if (values.dist) {
        setFieldValue("dist", values.dist);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };

  useEffect(() => {
    if (values.useSchoolAddress) {
      setFieldValue("branch_city", values.city);
      setFieldValue("branch_dist", values.dist);
      setFieldValue("branch_state", values.state);
      setFieldValue("branch_pin", values.pin);
      setFieldValue("branch_address", values.address);
    } else {
      setFieldValue("branch_city", "");
      setFieldValue("branch_dist", "");
      setFieldValue("branch_state", "");
      setFieldValue("branch_pin", "");
      setFieldValue("branch_address", "");
    }
  }, [
    values.useSchoolAddress,
    values.city,
    values.dist,
    values.state,
    values.pin,
    values.address,
    setFieldValue,
  ]);

  const generateSchoolCode = (schoolName) => {
    if (!schoolName) return "";

    // Split the school name into words
    const words = schoolName.trim().split(" ");

    // If it's a single word, use the first 3 characters (or more if desired)
    if (words.length === 1) {
      return words[0].substring(0, 3).toUpperCase();
    }
    // If multiple words, use the first letter of each word
    return words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 1 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">School Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 1 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-6">
                <label htmlFor="academic_id">
                  Academic Year <span className="mandatory">*</span>
                </label>
                <Field
                  as="select"
                  id="academic_id"
                  name="academic_id"
                  className="form-control"
                >
                  <option value="">Select Year</option>
                  {isAcademicLoading ? (
                    <option>Loading ...</option>
                  ) : (
                    academic.map((year) => {
                      const startDate = new Date(year.start_date);
                      const endDate = new Date(year.end_date);

                      const startMonthYear = startDate.toLocaleString(
                        "default",
                        {
                          month: "short",
                          year: "numeric",
                        }
                      );
                      const endMonthYear = endDate.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                      });

                      const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                      return (
                        <option key={year.id} value={year.id}>
                          {formattedDateRange}
                        </option>
                      );
                    })
                  )}
                </Field>
                <ErrorMessage
                  name="academic_id"
                  component="div"
                  className="text-error"
                />
              </div>

              <div className="form-group col-6">
                <label htmlFor="schoolName">
                  School Name <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="schoolName"
                  name="name"
                  placeholder="Enter school name"
                  style={{ borderColor: getBorderColor("name") }}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      "school_code",
                      generateSchoolCode(e.target.value)
                    );
                  }}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="schoolCode">
                  School Code <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="schoolCode"
                  name="school_code"
                  placeholder="Enter school code"
                  style={{ borderColor: getBorderColor("school_code") }}
                />
                <ErrorMessage
                  name="school_code"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="affialiation_no">
                  Affiliation No <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="affialiation_no"
                  name="affialiation_no"
                  placeholder="Enter Affiliation No"
                  style={{ borderColor: getBorderColor("affialiation_no") }}
                />
                <ErrorMessage
                  name="affialiation_no"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-6">
                <label htmlFor="state">
                  State <span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="state"
                  name="state"
                  placeholder="Select state"
                  style={{
                    borderColor: getBorderColor("state"),
                    borderRadius: "0px",
                  }}
                  onChange={(e) => {
                    const stateId = e.target.value;
                    setFieldValue("state", stateId);
                    fetchDistrict(stateId);
                  }}
                >
                  <option value="" label="Select State" />
                  {isStateLoading ? (
                    <option>Loading states...</option>
                  ) : (
                    states.map((state) => (
                      <option
                        key={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))
                  )}
                </Field>
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="district">
                  District <span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="district"
                  name="dist"
                  placeholder="Enter district"
                  style={{
                    borderColor: getBorderColor("dist"),
                    borderRadius: "0px",
                  }}
                >
                  <option value="" label="Select Districts" />
                  {isDistrictLoading ? (
                    <option>Loading districts...</option>
                  ) : (
                    districts.map((district) => (
                      <option
                        key={district.id}
                        value={district.id}
                        label={district.name}
                      />
                    ))
                  )}
                </Field>
                <ErrorMessage
                  name="dist"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="city">
                  City <span className="mandatory">* </span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder="Enter city"
                  style={{ borderColor: getBorderColor("city") }}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="pin">
                  Pin <span className="mandatory">* </span>
                </label>
                <Field
                  type="number"
                  className="form-control"
                  id="pin"
                  placeholder="Enter pin"
                  name="pin"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 6);
                  }}
                  style={{
                    borderColor: getBorderColor("pin"),
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage
                  name="pin"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="address">
                  Address <span className="mandatory">* </span>
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="Enter address"
                  style={{ borderColor: getBorderColor("address") }}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-6 d-flex ml-0">
                <input
                  type="checkbox"
                  className="form-control mt-2 ml-0"
                  id="useSchoolAddress"
                  name="useSchoolAddress"
                  checked={values.useSchoolAddress}
                  style={{ width: "20px", height: "20px" }}
                  onChange={(e) => {
                    setFieldValue("useSchoolAddress", e.target.checked);
                    setUseSchoolAddress(e.target.checked);
                  }}
                />
                <label className="mt-1 ml-2" htmlFor="useSchoolAddress">
                  Use School Address for Branch?
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default SchoolInformation;
