import Api from "../Api";

export const getAllRemarks = (params) => {
  return Api.get(`/get-remarks`, { params });
};

export const createRemark = (remarkData) => {
  return Api.post(`/create-report-remarks`, remarkData);
};

export const getRemarkById = async (id) => {
  try {
    const response = await Api.get(`/get-remarks/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRemarkById = async (id, data) => {
  try {
    const response = await Api.post(`/update-remarks/${id}`, data, {
      params: {
        id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteRemarkById = async (id) => {
  try {
    const response = await Api.delete(`/delete-remarks/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
