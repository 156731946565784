import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  createAcademicSetup,
  getAllFeesTimelines,
  getAllFeesDiscount,
  getFeesDiscountsById,
} from "../../Services/CommonApis/FeesApi";

function AcademicFeeCreate() {
  const { schoolId, branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [academic, setAcademic] = useState([]);
  const [timelineList, setTimelineList] = useState([]);
  const [classId, setClassId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(true);
  const [isSectionLoading, setSectionLoading] = useState(null);
  const [isAcademicLoading, setIsAcademicLoading] = useState(true);
  const [isTimelineLoading, setIsTimelineLoading] = useState(true);
  const [isDiscountLoading, setIsDiscountLoading] = useState(true);
  const [discountList, setDiscountList] = useState([]);

  useEffect(() => {
    fetchClassByBranchId(branchId);
    getAllAcademic();
    fetchTimeline();
    fetchDiscounts();
  }, [branchId]);

  useEffect(() => {
    if (classId) {
      setSectionLoading(true);
      fetchSectionByClassId(classId);
    }
  }, [classId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setClassLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
      setSectionLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllAcademic = async () => {
    try {
      const response = await getAllAcademics();
      setAcademic(response.data.data);
      setIsAcademicLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTimeline = async () => {
    try {
      const response = await getAllFeesTimelines();
      setTimelineList(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsTimelineLoading(false);
    }
  };

  const fetchDiscounts = async () => {
    try {
      const response = await getAllFeesDiscount();
      const discounts = response.data.data;
      setDiscountList(Array.isArray(discounts) ? discounts : []);
    } catch (error) {
      console.log(error);
      setDiscountList([]);
    }
  };

  const handleDiscountChange = async (e, setFieldValue) => {
    const selectedDiscountId = e.target.value;
    setFieldValue("discount_type", selectedDiscountId);

    if (selectedDiscountId) {
      try {
        const discountData = await getFeesDiscountsById(selectedDiscountId);
        setFieldValue("discount", discountData.data.amount);
      } catch (error) {
        console.error("Failed to fetch discount amount:", error);
      }
    } else {
      setFieldValue("discount", "");
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the academic setup",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();

      try {
        const formattedDate = new Date(
          values.pay_timeline_date
        ).toLocaleDateString("en-GB");

        const response = await createAcademicSetup({
          school_id: schoolId,
          branch_id: branchId,
          class_id: values.class_id,
          section_id: values.section_id,
          academic_id: values.academic_id,
          amount: values.amount,
          discount_type: values.discount_type,
          discount: values.discount,
          pay_timeline: values.pay_timeline,
          pay_timeline_date: formattedDate,
        });

        if (response.status === 200) {
          showSuccessAlert(
            "Created!",
            "The academic setup has been created successfully."
          );
          resetForm();
        } else {
          const errorMessage =
            response.data?.message ||
            "Academic setup creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        "Academic setup creation has been cancelled."
      );
    }
  };

  const initialValues = {
    class_id: "",
    section_id: "",
    academic_id: "",
    amount: "",
    discount_type: "",
    discount: "",
    pay_timeline: "",
    pay_timeline_date: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    academic_id: Yup.string().required("Academic year is required"),
    amount: Yup.number()
      .required("Amount is required")
      .min(0, "Amount must be a positive number"),
    discount_type: Yup.number()
      .required("Discount is required")
      .min(0, "Discount must be a positive number"),
    pay_timeline: Yup.number()
      .required("Pay timeline is required")
      .min(1, "Pay timeline must be at least 1"),
    pay_timeline_date: Yup.date().required("Pay timeline date is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Academic Fee Create</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Academic Fee Create</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Academic Fee Creation <small>Form</small>
            </h3>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form id="form">
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-4">
                      <label htmlFor="class_id">
                        Class Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="class_id"
                        name="class_id"
                        className="form-control"
                        onChange={(e) => {
                          const selectedClassId = e.target.value;
                          setFieldValue("class_id", selectedClassId);
                          setClassId(selectedClassId);
                        }}
                      >
                        {isClassLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Class</option>
                            {classList.map((cls) => (
                              <option key={cls.id} value={cls.id}>
                                {cls.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="class_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="section_id">
                        Section Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="section_id"
                        name="section_id"
                        className="form-control"
                        disabled={!classId}
                      >
                        {isSectionLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Section</option>
                            {sectionList.map((section) => (
                              <option key={section.id} value={section.id}>
                                {section.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="section_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="academic_id">
                        Select Academic Year{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="academic_id"
                        className="form-control"
                      >
                        <option value="">Select Year</option>
                        {isAcademicLoading ? (
                          <option>Loading ...</option>
                        ) : (
                          academic.map((year) => (
                            <option key={year.id} value={year.id}>
                              {year.academic_years}
                            </option>
                          ))
                        )}
                      </Field>
                      <ErrorMessage
                        name="academic_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="amount">
                        Amount <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="number"
                        name="amount"
                        className="form-control"
                        placeholder="Enter amount"
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="discount_type">
                        Discount Type<span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="discount_type"
                        name="discount_type"
                        className="form-control"
                        onChange={(e) => handleDiscountChange(e, setFieldValue)}
                      >
                        {discountList.length === 0 ? (
                          <option value="" disabled>
                            No Discounts Available
                          </option>
                        ) : (
                          <>
                            <option value="">Select Discount</option>
                            {discountList.map((discount) => (
                              <option key={discount.id} value={discount.id}>
                                {`${discount.name} - (${discount.amount}`}{")"}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="discount_type"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div style={{ display: "none" }}>
                      <Field
                        type="text"
                        id="discount"
                        name="discount"
                        className="form-control"
                        value={values.discount}
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="pay_timeline">
                        Pay Timeline <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="pay_timeline"
                        className="form-control"
                        placeholder="Enter pay timeline"
                      >
                        {isTimelineLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Section</option>
                            {timelineList.map((timeline) => (
                              <option key={timeline.id} value={timeline.id}>
                                {timeline.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="pay_timeline"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    <div className="form-group col-4">
                      <label htmlFor="pay_timeline_date">
                        Pay Timeline Date <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        name="pay_timeline_date"
                        class="form-control"
                        placeholder="Enter date"
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                      />
                      <ErrorMessage
                        name="pay_timeline_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default AcademicFeeCreate;
