import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  getAllTransportroutes,
  getTransportrouteById,
  createStops,
} from "../../Services/CommonApis/TransportApi";
import { create } from "@mui/material/styles/createTransitions";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
} from "../../Modals/SweetAlertModel";
import { useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";
import {
  Autocomplete,
  LoadScript,
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const Transport = ({ onCreateStoppageSuccess }) => {
  const [routes, setRoutes] = useState([]);
  const [selectedRouteDetails, setSelectedRouteDetails] = useState(null);
  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [placesAlongRoute, setPlacesAlongRoute] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [inputValue, setInputValue] = useState("8:30 AM");

  const [selectedroute, setSelectedRoute] = useState([]);
  const [stops, setStops] = useState([
    {
      stop_name: "",
      pickup_latitude: "",
      pickup_longitude: "",
      drop_latitude: "",
      drop_longitude: "",
      pickup_distance: "",
      drop_distance: "",
      amount: "",
      pickup_time: "08:00", // Default value
      drop_time: "14:00", // Default value
    },
  ]);
  const [locationOptions, setLocationOptions] = useState([]);
  const { branchId } = useParams();
  const mapRef = useRef(null);
  const branch_id = branchId ? branchId : 1;

  useEffect(() => {
    fetchAllRoutes(branch_id);
  }, []);

  const fetchAllRoutes = async (branch_id) => {
    try {
      const response = await getAllTransportroutes(branch_id);
      const routeOptions = response.data.data.map((route) => ({
        label: `${route.start_point} - ${route.end_point}`,
        value: route.id,
      }));
      setRoutes(routeOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRouteById = async (routeId) => {
    try {
      const response = await getTransportrouteById(routeId);
      const routeData = response.data.data;
      setSelectedRouteDetails({
        startLat: parseFloat(routeData.start_latitude),
        startLng: parseFloat(routeData.start_logitude),
        endLat: parseFloat(routeData.end_latitude),
        endLng: parseFloat(routeData.end_logitude),
        routeLabel: `${routeData.start_point} - ${routeData.end_point}`,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const dropplaceRef = useRef(null); // New ref for dropplace
  const clearMarkers = () => {
    if (markers) {
      markers.forEach((marker) => marker.setMap(null));
    }
    setMarkers([]);
  };
  const [location, setLocation] = useState("");
  const autocompleteRef = useRef(null);

  const onPlaceChanged = (index, fieldType) => {
    const place =
      fieldType === "stop"
        ? autocompleteRef.current.getPlace() // Refers to pickup location
        : dropplaceRef.current.getPlace(); // Refers to drop location

    if (place && place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      const updatedStops = stops.map((stop, idx) => {
        if (idx === index) {
          const updatedStop = {
            ...stop,
            // Use the fieldType to decide which field to update
            [fieldType === "stop" ? "stop_name" : "dropplace"]:
              place.formatted_address,
            // Assign latitude and longitude based on the field type
            [fieldType === "stop" ? "pickup_latitude" : "drop_latitude"]: lat,
            [fieldType === "stop" ? "pickup_longitude" : "drop_longitude"]: lng,
          };
          if (fieldType === "stop" && selectedRouteDetails) {
            updatedStop.pickup_distance = calculateDistance(
              selectedRouteDetails.startLat,
              selectedRouteDetails.startLng,
              lat,
              lng
            ).toFixed(2);
          } else if (fieldType === "drop" && selectedRouteDetails) {
            updatedStop.drop_distance = calculateDistance(
              selectedRouteDetails.startLat,
              selectedRouteDetails.startLng,
              lat,
              lng
            ).toFixed(2);
          }

          // Update markers
          setMarkers((prevMarkers) => [
            ...prevMarkers,
            {
              position: { lat, lng },
              title: place.formatted_address,
              type: fieldType,
            },
          ]);

          return updatedStop;
        }
        return stop;
      });

      setStops(updatedStops);
    } else {
      console.warn("No valid place selected or place geometry missing.");
    }
  };

  {
    stops.map((stop, index) => (
      <tr key={index}>
        <td style={{ width: "400px" }}>
          <div className="flex-grow-1" style={{ position: "relative" }}>
            <label htmlFor="new-location">Location Name</label>
            <Autocomplete
              onLoad={(ref) => (autocompleteRef.current = ref)}
              onPlaceChanged={() => onPlaceChanged(index)} // Pass index here
              style={{
                position: "absolute",
                zIndex: 9999,
              }}
            >
              <input
                type="text"
                id="new-location"
                placeholder="Search for a location"
                className="form-control"
                value={stop.stop_name} // Bind to stop.stop_name for display
                onChange={(e) =>
                  handleStopChange(index, "stop_name", e.target.value)
                } // Optional: Allow manual input
              />
            </Autocomplete>
          </div>
        </td>
        {/* Other table cells for latitude, longitude, etc. */}
      </tr>
    ));
  }
  const handleRoute = () => {
    if (selectedRouteDetails) {
      const { startLat, startLng, endLat, endLng } = selectedRouteDetails;
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { lat: startLat, lng: startLng },
          destination: { lat: endLat, lng: endLng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
            showStopsOnMap(stops);
          } else {
            console.error("Error fetching directions:", result);
          }
        }
      );
    }
  };
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
  };

  const handleSubmit = async () => {
    console.log(stops);
    // Prepare data to be submitted
    const data = {
      route_id: selectedroute, // Use the selected route ID as needed
      stop_data: stops.map((stop) => ({
        stop_id:
          stop.stop_id ||
          `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`, // Generate stop_id if it doesn’t exist
        pickup_point: stop.stop_name || stop.pickup_point, // Use correct field names
        drop_point: stop.dropplace || stop.drop_point,
        pickup_latitude: parseFloat(stop.pickup_latitude),
        pickup_longitude: parseFloat(stop.pickup_longitude),
        drop_latitude: parseFloat(stop.drop_latitude),
        drop_longitude: parseFloat(stop.drop_longitude),
        pickup_distance: stop.pickup_distance,
        drop_distance: stop.drop_distance,
        amount: parseFloat(stop.amount),
        pickup_time: stop.pickup_time,
        drop_time: stop.drop_time,
      })),
    };

    console.log("Submitting stops:", data);

    try {
      const response = await createStops(data);
      setApiMessage(response.message);
      showSuccessAlert("Success", apiMessage);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const libraries = ["places"];

  const [selectedMarker, setSelectedMarker] = useState(null);

  const showStopsOnMap = (updatedStops) => {
    return updatedStops.map((stop) => {
      if (stop.latitude && stop.longitude) {
        return (
          <Marker
            key={stop.stop_name} // Ensure each marker has a unique key
            position={{
              lat: parseFloat(stop.latitude),
              lng: parseFloat(stop.longitude),
            }}
            title={stop.stop_name}
            onClick={() => {
              setSelectedMarker(stop); // Set the selected marker to show InfoWindow
            }}
          />
        );
      }
      return null;
    });
  };

  const showMarkersOnMap = () => {
    return markers.map((marker, index) => (
      <Marker
        key={index}
        position={marker.position}
        title={marker.title}
        onClick={() => setSelectedMarker(marker)}
      />
    ));
  };
  const handleAddStop = () => {
    setStops([
      ...stops,
      {
        stop_name: "",
        pickup_latitude: "",
        pickup_longitude: "",
        drop_latitude: "",
        drop_longitude: "",
        pickup_distance: "",
        drop_distance: "",
        amount: "",
      },
    ]);
  };

  const handleRemoveStop = (index) => {
    const updatedStops = stops.filter((_, idx) => idx !== index);
    setStops(updatedStops);
  };

  const handleStopChange = (index, field, value) => {
    const updatedStops = stops.map((stop, idx) =>
      idx === index ? { ...stop, [field]: value } : stop
    );
    setStops(updatedStops);
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey="AIzaSyD5JCUIe7Ig7tKEOkHHYE9TNkybW_JKilY"
        libraries={libraries}
      >
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">create Stoppages</h3>
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="col-4 mb-3">
                <label>Select Route</label>

                <Select
                  options={routes}
                  onChange={(option) => {
                    fetchRouteById(option.value);
                    setSelectedRoute(option.value);
                  }}
                  placeholder="Select Route"
                />
              </div>
              <div>
                <button
                  className="btn btn-primary mt-4  ml-2"
                  onClick={handleRoute}
                >
                  Plan Route
                </button>
              </div>
            </div>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "400px" }}
              center={{ lat: 17.385044, lng: 78.486671 }}
              zoom={10}
              onLoad={(map) => setMap(map)}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
              {showMarkersOnMap()}
              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker.position}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div>
                    <h4>{selectedMarker.title}</h4>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>

            {/* Stops Section */}
            <div className="card p-3 mt-2">
              <div className="mt-4">
                <h5>Stops:</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Pickup point</th>
                      <th>pickup_time</th>
                      <th>pickupdistance</th>

                      <th>Drop point</th>
                      <th style={{ display: "none" }}>Latitude</th>
                      <th style={{ display: "none" }}>Longitude</th>
                      <th>dropDistance</th>
                      <th>Amount</th>

                      <th>drop_time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stops.map((stop, index) => (
                      <tr key={index}>
                        <td>
                          <Autocomplete
                            onLoad={(ref) => (autocompleteRef.current = ref)}
                            onPlaceChanged={() => onPlaceChanged(index, "stop")}
                            options={{
                              componentRestrictions: { country: "IN" },
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search pickup location"
                              className="form-control"
                              value={stop.stop_name}
                              onChange={(e) =>
                                setStops(
                                  stops.map((s, i) =>
                                    i === index
                                      ? { ...s, stop_name: e.target.value }
                                      : s
                                  )
                                )
                              }
                            />
                          </Autocomplete>
                        </td>
                        <td>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              value={
                                stop.pickup_time
                                  ? dayjs(stop.pickup_time) // Use the existing pickup time
                                  : dayjs().hour(8).minute(30) // Set default to 8:30 AM
                              }
                              sx={{
                                width: "120px",
                                "& .MuiInputBase-input": {
                                  height: "5px",
                                },
                              }}
                              onChange={(value) => {
                                const formattedTime = value
                                  ? value.format("h:mm A") // Format to 'h:mm A'
                                  : null;
                                handleStopChange(
                                  index,
                                  "pickup_time",
                                  formattedTime
                                );
                              }}
                              renderInput={(params) => (
                                <input
                                  {...params}
                                  value={inputValue}
                                  className="form-control custom-time-input"
                                  style={{
                                    width: "100px",
                                    height: "20px", // Adjusted height for visibility
                                  }}
                                />
                              )}
                              amPmAriaLabel="Select AM/PM"
                              // Add a format for display in the input
                              displayFormat={(value) =>
                                value ? value.format("h:mm A") : "8:30 AM"
                              }
                            />
                          </LocalizationProvider>
                        </td>

                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="4km"
                            value={stop.pickup_distance}
                            onChange={(e) =>
                              handleStopChange(
                                index,
                                "pickup_distance",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Autocomplete
                            onLoad={(ref) => (dropplaceRef.current = ref)}
                            onPlaceChanged={() => onPlaceChanged(index, "drop")}
                            options={{
                              componentRestrictions: { country: "IN" },
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search drop location"
                              className="form-control"
                              value={stop.dropplace}
                              onChange={(e) =>
                                setStops(
                                  stops.map((s, i) =>
                                    i === index
                                      ? { ...s, dropplace: e.target.value }
                                      : s
                                  )
                                )
                              }
                            />
                          </Autocomplete>
                        </td>

                        <td style={{ display: "none" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pickup Latitude"
                            value={stop.pickup_latitude}
                            onChange={(e) =>
                              handleStopChange(
                                index,
                                "pickup_latitude",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td style={{ display: "none" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pickup Longitude"
                            value={stop.pickup_longitude}
                            onChange={(e) =>
                              handleStopChange(
                                index,
                                "pickup_longitude",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td style={{ display: "none" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Drop Latitude"
                            value={stop.drop_latitude}
                            onChange={(e) =>
                              handleStopChange(
                                index,
                                "drop_latitude",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td style={{ display: "none" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Drop Longitude"
                            value={stop.drop_longitude}
                            onChange={(e) =>
                              handleStopChange(
                                index,
                                "drop_longitude",
                                e.target.value
                              )
                            }
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="drop_distance"
                            value={stop.drop_distance}
                            onChange={(e) =>
                              handleStopChange(
                                index,
                                "drop_distance",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            value={stop.amount}
                            onChange={(e) =>
                              handleStopChange(index, "amount", e.target.value)
                            }
                          />
                        </td>

                        <td>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              value={
                                stop.drop_time ? dayjs(stop.drop_time) : null
                              }
                              sx={{
                                width: "120px",
                                "& .MuiInputBase-input": {
                                  height: "5px",
                                },
                              }}
                              onChange={(value) => {
                                const formattedTime = value
                                  ? value.format("h:mm A")
                                  : null;
                                handleStopChange(
                                  index,
                                  "drop_time",
                                  formattedTime
                                );
                              }}
                              renderInput={(params) => (
                                <input
                                  {...params}
                                  className="form-control"
                                  placeholder="Select Drop Time" // Placeholder for better UX
                                />
                              )}
                              amPmAriaLabel="Select AM/PM"
                            />
                          </LocalizationProvider>
                        </td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "#dc3545",
                                borderColor: "#dc3545",
                              }} // Keeping the red color
                              onClick={() => handleRemoveStop(index)}
                            >
                              -
                            </button>
                            {index === stops.length - 1 && ( // Show "Add Stop" button only on the last row
                              <button
                                className="btn btn-primary ml-2"
                                onClick={handleAddStop}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadScript>
    </>
  );
};

export default Transport;
