import Api from "../Api";

export const createClass = async (data) => {
  const payload = {
    branch_id: data.branch_id,
    name: data.name,
  };
  return await Api.post(`/create-class`, payload);
};

export const getClasses = async (id) => {
  return await Api.post(`/getall-classes/${id}`);
};
export const getClassByBranchId = async (id) => {
  return await Api.get(`/get-classes-branch/${id}`);
};

export const deleteClassById = async(id)=>{
  return await Api.get(`/delete-class/${id}`);
}
export const updateClassById = async(id, data)=>{
  return await Api.post(`/update-class/${id}`, data);
}
export const getClassById = async(id)=>{
  return await Api.get(`/get-class/${id}`);
}
