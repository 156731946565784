import React, { useState, useEffect } from "react";
import { getAllAnnouncements } from "../../Services/CommonApis/AnnouncementApi";
import { Modal, Button } from "react-bootstrap"; // Ensure you have react-bootstrap installed
import { useParams } from "react-router-dom";

function AnnouncementGrid() {
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { branchId } = useParams();
  console.log(branchId);

  useEffect(() => {
    fetchAnnouncements(branchId);
  }, []);

  const fetchAnnouncements = async (branchId) => {
    try {
      const response = await getAllAnnouncements(branchId);
      setAnnouncements(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowModal = (announcement) => {
    setSelectedAnnouncement(announcement);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAnnouncement(null);
  };

  const handleSubmit = () => {
    // Handle submit logic here
    console.log("Submit clicked");
  };

  const handleSendApp = () => {
    // Handle send application logic here
    console.log("Send Application clicked");
  };

  return (
    <>
      <div className="card-body">
        <div className="row">
          {announcements.map((announcement) => (
            <div
              key={announcement.id}
              className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column"
            >
              <div
                className="card bg-light d-flex flex-fill"
                onClick={() => handleShowModal(announcement)}
              >
                <div className="card-header text-muted border-bottom-0">
                  {announcement.name}
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>{announcement.name}</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Application Fee: </b> {announcement.application_fee}
                      </p>
                      <p className="text-muted text-sm">
                        <b>Seats Available: </b> {announcement.seats_available}
                      </p>
                      <p className="text-muted text-sm">
                        <b>Start Date: </b>{" "}
                        {new Date(announcement.start_date).toLocaleDateString()}
                      </p>
                      <p className="text-muted text-sm">
                        <b>End Date: </b>{" "}
                        {new Date(announcement.end_date).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">View Announcement</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedAnnouncement && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Announcement Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Name:</b> {selectedAnnouncement.name}
            </p>
            <p>
              <b>Start Date:</b>{" "}
              {new Date(selectedAnnouncement.start_date).toLocaleDateString()}
            </p>
            <p>
              <b>End Date:</b>{" "}
              {new Date(selectedAnnouncement.end_date).toLocaleDateString()}
            </p>
            <p>
              <b>Academic Year:</b> {selectedAnnouncement.academic_years}
            </p>
            {/* Add more details as needed */}

            <div className="form-group">
              <label htmlFor="academicYearSelect">Academic Year</label>
              <select id="academicYearSelect" className="form-control">
                {/* Populate options as needed */}
                <option value={selectedAnnouncement.academic_year_id}>
                  {selectedAnnouncement.academic_years}
                </option>
                {/* Add more options here */}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSendApp}>
              Send Application
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default AnnouncementGrid;
