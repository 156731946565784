import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Select, { components } from "react-select";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { useParams } from "react-router-dom";
import {
  getDropDownRoute,
  getAllStopsByRouteId,
  createTransportDetails,
} from "../../Services/CommonApis/TransportApi";
import { showSuccessAlert } from "../../Modals/SweetAlertModel";
import { getTransportDetailsById } from "../../Services/CommonApis/TransportApi";

function TransportDetailsCreate({ detailId }) {
  const { branchId } = useParams();
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [pickupStops, setPickupStops] = useState([]);
  const [dropStops, setDropStops] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [dynamicSections, setDynamicSections] = useState([
    {
      students: [],
      pickupSameAsDrop: "Yes",
      pickup_route: "",
      drop_route: "",
      pickup_point: "",
      drop_point: "",
    },
  ]);

  useEffect(() => {
    if (branchId) {
      fetchClasses();
      fetchRoutes(branchId);
      fetchDetails(detailId);
    }
  }, [branchId]);

  const fetchClasses = async () => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchDetails = async (detailId) => {
    try {
      const response = await getTransportDetailsById(detailId);
      console.log(response);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const fetchRoutes = async () => {
    try {
      const response = await getDropDownRoute(branchId);
      setRoutes(response.data.data);
    } catch (error) {
      console.error("Error fetching routes:", error);
    }
  };

  const fetchStops = async (routeId, type) => {
    try {
      const response = await getAllStopsByRouteId(routeId);
      if (type === "pickup") {
        setPickupStops(response.data.data);
      } else {
        setDropStops(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stops:", error);
    }
  };

  const CustomOption = (props) => {
    const { isSelected, label, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          cursor: "pointer",
          backgroundColor: isSelected ? "lightgray" : "white",
          margin: "0",
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          style={{ marginRight: "10px" }}
        />
        <p style={{ marginLeft: "5px", marginTop: "8px" }}>{label}</p>
      </div>
    );
  };

  const CustomMenuList = ({ options, selectProps, ...props }) => {
    const {
      setFieldValue,
      name: fieldName,
      value: selectedValues = [],
    } = selectProps;

    // Check if all options are selected
    const isAllSelected = selectedValues.length === options.length;

    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        const allValues = options.map((option) => option.value);
        setFieldValue(fieldName, allValues); // Set all student IDs in Formik field
      } else {
        setFieldValue(fieldName, []); // Clear all student IDs in Formik field
      }
    };

    return (
      <components.MenuList {...props}>
        <div
          style={{
            padding: "5px",
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <label className="ml-3 mt-2">Select All</label>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const fetchStudents = async (branchId, classId, sectionId) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );
      setStudents(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const handleAddSection = () => {
    setDynamicSections([
      ...dynamicSections,
      {
        students: [],
        pickupSameAsDrop: "yes",
        pickup_route: "",
        drop_route: "",
        pickup_point: "",
        drop_point: "",
      },
    ]);
  };

  const handleFieldChange = (index, field, value, setFieldValue) => {
    const updatedSections = dynamicSections.map((section, i) =>
      i === index ? { ...section, [field]: value } : section
    );
    setDynamicSections(updatedSections);
    setFieldValue(`sections.${index}.${field}`, value);

    // Fetch stops based on the route selected
    if (field === "pickup_route") {
      fetchStops(value, "pickup"); // Fetch pickup stops
    } else if (field === "drop_route") {
      fetchStops(value, "drop"); // Fetch drop stops
    }
  };

  const handleCreateTransportDetails = async (values) => {
    const transportData = values.sections.map((section) => {
      if (section.pickupSameAsDrop === "Yes") {
        return {
          student_ids: section.students,
          route_id: section.pickup_route,
          pickup_point: section.pickup_point,
          drop_point: section.pickup_point,
          is_same_drop: 0,
        };
      } else {
        return {
          student_ids: section.students,
          route_id: section.pickup_route,
          pickup_point: section.pickup_point,
          is_same_drop: {
            route_id: section.drop_route,
            drop_point: section.drop_point,
          },
        };
      }
    });

    const payload = {
      branch_id: branchId,
      transport: transportData,
    };

    try {
      await createTransportDetails(payload);
      showSuccessAlert("Success!", "The Details has been created.");
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveSection = (index) => {
    const updatedSections = dynamicSections.filter((_, i) => i !== index);
    setDynamicSections(updatedSections);
  };

  const studentOptions = students.map((student) => ({
    value: student.id,
    label: `${student.first_name} (${student.roll_no})`,
  }));

  return (
    <>
      <section className="content-header">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              {detailId ? "Transport Edit" : "Transport Creation"}
              <small>Form</small>
            </h3>
          </div>

          <div className="col-12 row">
            <Formik
              initialValues={{
                class: "",
                section: "",
                sections: dynamicSections.map((section) => ({
                  ...section,
                  students: [],
                })),
              }}
              onSubmit={(values) => {
                handleCreateTransportDetails(values);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="d-flex flex-row flex-wrap p-5">
                    <div className="col-4">
                      <label>Select Class:</label>
                      <Field
                        as="select"
                        name="class"
                        className="form-control"
                        value={selectedClass}
                        onChange={(e) => {
                          setSelectedClass(e.target.value);
                          fetchSections(e.target.value);
                        }}
                      >
                        <option value="">Select Class</option>
                        {classes.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </Field>
                    </div>

                    <div className="col-4">
                      <label>Select Section:</label>
                      <Field
                        as="select"
                        name="section"
                        className="form-control"
                        value={selectedSection}
                        onChange={(e) => {
                          setSelectedSection(e.target.value);
                          fetchStudents(
                            branchId,
                            selectedClass,
                            e.target.value
                          );
                        }}
                      >
                        <option value="">Select Section</option>
                        {sections.map((sec) => (
                          <option key={sec.id} value={sec.id}>
                            {sec.name}
                          </option>
                        ))}
                      </Field>
                    </div>

                    {dynamicSections.map((section, index) => (
                      <div
                        key={index}
                        className="row p-3 mt-2"
                        style={{ border: "1px solid skyblue" }}
                      >
                        <div className="col-4">
                          <label>Select Students:</label>
                          <Select
                            isMulti
                            options={studentOptions}
                            name={`sections.${index}.students`}
                            value={studentOptions.filter((option) =>
                              values.sections[index]?.students?.includes(
                                option.value
                              )
                            )}
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions.map(
                                (option) => option.value
                              );
                              setFieldValue(
                                `sections.${index}.students`,
                                selectedValues
                              );
                            }}
                            placeholder="Select Students"
                            components={{
                              Option: CustomOption,
                              MenuList: (props) => (
                                <CustomMenuList
                                  {...props}
                                  selectProps={{
                                    ...props.selectProps,
                                    setFieldValue,
                                  }}
                                />
                              ),
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Select Route:</label>
                          <Field
                            as="select"
                            className="form-control"
                            name={`sections.${index}.pickup_route`}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "pickup_route",
                                e.target.value,
                                setFieldValue
                              )
                            }
                          >
                            <option value="">Select Pickup Route</option>
                            {routes.map((route) => (
                              <option key={route.id} value={route.id}>
                                {route.route}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-4">
                          <label>Pickup Point:</label>
                          <Field
                            as="select"
                            name={`sections.${index}.pickup_point`}
                            className="form-control"
                          >
                            <option value="">Select Pickup Point</option>
                            {pickupStops.map((stop) => (
                              <option key={stop.id} value={stop.pickup_point}>
                                {stop.pickup_point}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-4 mt-3">
                          <label>Pickup Same as Drop?</label>
                          <div className="form-check row">
                            <Field
                              type="radio"
                              name={`sections.${index}.pickupSameAsDrop`}
                              value="Yes"
                              checked={section.pickupSameAsDrop === "Yes"}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "pickupSameAsDrop",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="form-check-input"
                            />
                            <label className="form-check-label mr-4">Yes</label>

                            <Field
                              type="radio"
                              name={`sections.${index}.pickupSameAsDrop`}
                              value="No"
                              checked={section.pickupSameAsDrop === "No"}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "pickupSameAsDrop",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="form-check-input"
                            />
                            <label className="form-check-label">No</label>
                          </div>
                        </div>

                        {section.pickupSameAsDrop === "No" && (
                          <>
                            <div className="col-4">
                              <label>Select Route:</label>
                              <Field
                                as="select"
                                className="form-control"
                                name={`sections.${index}.drop_route`}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    "drop_route",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                              >
                                <option value="">Select Drop Route</option>
                                {routes.map((route) => (
                                  <option key={route.id} value={route.id}>
                                    {route.route}
                                  </option>
                                ))}
                              </Field>
                            </div>

                            <div className="col-4">
                              <label>Drop Point:</label>
                              <Field
                                as="select"
                                name={`sections.${index}.drop_point`}
                                className="form-control"
                              >
                                <option value="">Select Drop Point</option>
                                {dropStops.map((stop) => (
                                  <option key={stop.id} value={stop.drop_point}>
                                    {stop.drop_point}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </>
                        )}

                        <div className="col-4">
                          {index === dynamicSections.length - 1 && (
                            <button
                              type="button"
                              className="btn btn-success mt-4"
                              onClick={handleAddSection}
                            >
                              +
                            </button>
                          )}

                          {dynamicSections.length > 1 && index > 0 && (
                            <button
                              type="button"
                              className="btn btn-danger mt-4 ml-3"
                              onClick={() => handleRemoveSection(index)}
                            >
                              -
                            </button>
                          )}
                        </div>
                      </div>
                    ))}

                    <div className="col-12 mt-4 text-left">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
}

export default TransportDetailsCreate;
