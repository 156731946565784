import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  getAllTransportroutes,
  getTransportrouteById,
  createStops,
  getstoproutesbyid,
} from "../../Services/CommonApis/TransportApi";
import { showSuccessAlert } from "../../Modals/SweetAlertModel";
import { useParams } from "react-router-dom";
import { updatestop } from "../../Services/CommonApis/TransportApi";
import {
  Autocomplete,
  LoadScript,
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const Stoppagesedit = () => {
  const [routes, setRoutes] = useState([]);
  const [selectedRouteDetails, setSelectedRouteDetails] = useState(null);
  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [placesAlongRoute, setPlacesAlongRoute] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [routeoption, setRouteOption] = useState(null);
  const [apiMessage, setApiMessage] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const dropplaceRef = useRef(null); // New ref for dropplace
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const { id, branchId } = useParams();

  const [selectedroute, setSelectedRoute] = useState(null);
  const [stops, setStops] = useState([
    { stop_name: "", latitude: "", longitude: "", distance: "", amount: "" },
  ]);

  const mapRef = useRef(null);
  const branch_id = branchId ? branchId : 1;

  useEffect(() => {
    fetchAllRoutes(branch_id);
  }, []);

  const fetchAllRoutes = async (branch_id) => {
    try {
      const response = await getAllTransportroutes(branch_id);
      const routeOptions = response.data.data.map((route) => ({
        label: `${route.start_point} - ${route.end_point}`,
        value: route.id,
      }));
      setRoutes(routeOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchstopsedit(id);
    }
  }, [id]);

  const fetchstopsedit = async (id) => {
    try {
      const response = await getstoproutesbyid(id);
      console.log(response.data.data);
  
      const stopData = response.data.data.stop_data;
  
      const formattedStops = stopData.map((stop) => ({
        pickup_point: stop.pickup_point || "",
        drop_point: stop.drop_point || "",
        pickup_latitude: stop.pickup_latitude ? parseFloat(stop.pickup_latitude) : null,
        pickup_longitude: stop.pickup_longitude ? parseFloat(stop.pickup_longitude) : null,
        drop_latitude: stop.drop_latitude ? parseFloat(stop.drop_latitude) : null,
        drop_longitude: stop.drop_longitude ? parseFloat(stop.drop_longitude) : null,
        pickup_distance: stop.pickup_distance || "",
        drop_distance: stop.drop_distance || "",
        amount: stop.amount !== null ? stop.amount : "",
        pickup_time: stop.pickup_time || "",
        drop_time: stop.drop_time || "",
      }));
  
      setStops(formattedStops);
      setRouteOption(response.data.data.route_id);
  
      setSelectedRouteDetails({
        startLat: parseFloat(response.data.data.start_latitude),
        startLng: parseFloat(response.data.data.start_logitude),
        endLat: parseFloat(response.data.data.end_latitude),
        endLng: parseFloat(response.data.data.end_logitude),
      });
  
      const newMarkers = formattedStops.reduce((acc, stop, idx) => {
        if (stop.pickup_latitude && stop.pickup_longitude) {
          acc.push({
            position: { lat: stop.pickup_latitude, lng: stop.pickup_longitude },
            title: `Pickup Point: ${stop.pickup_point || idx + 1}`,
            type: "pickup",
          });
        }
        if (stop.drop_latitude && stop.drop_longitude) {
          acc.push({
            position: { lat: stop.drop_latitude, lng: stop.drop_longitude },
            title: `Drop Point: ${stop.drop_point || idx + 1}`,
            type: "drop",
          });
        }
        return acc;
      }, []);
  
      console.log("Setting new markers:", newMarkers);
      setMarkers(newMarkers); // Set markers last, to avoid overwriting

  
    } catch (error) {
      console.error("Error fetching stops:", error);
    }
  };

  const fetchRouteById = async (routeId) => {
    try {
      const response = await getTransportrouteById(routeId);
      const routeData = response.data.data;
      setSelectedRouteDetails({
        startLat: parseFloat(routeData.start_latitude),
        startLng: parseFloat(routeData.start_logitude),
        endLat: parseFloat(routeData.end_latitude),
        endLng: parseFloat(routeData.end_logitude),
        routeLabel: `${routeData.start_point} - ${routeData.end_point}`,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const autocompleteRef = useRef(null);

  const onPlaceChanged = (index, fieldType) => {
    const place = autocompleteRef.current.getPlace();

    if (place && place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      const updatedStops = stops.map((stop, idx) => {
        if (idx === index) {
          return {
            ...stop,
            [fieldType === "pickup" ? "pickup_latitude" : "drop_latitude"]: lat,
            [fieldType === "pickup" ? "pickup_longitude" : "drop_longitude"]:
              lng,
          };
        }
        return stop;
      });

      setStops(updatedStops);
     
    } else {
      console.warn("No valid place selected or place geometry missing.");
    }
  };

  const handleStopChange = (index, field, value) => {
    const updatedStops = stops.map((stop, idx) =>
      idx === index ? { ...stop, [field]: value } : stop
    );
    setStops(updatedStops);
  };

  const showMarkersOnMap = () => {
    console.log("Markers currently in state:", markers);
    return markers.map((marker, index) => (
      <Marker
        key={index}
        position={marker.position}
        title={marker.title}
        onClick={() => setSelectedMarker(marker)}
      />
    ));
  };
  useEffect(() => {
    if (selectedRouteDetails) {
      handleRoute();
    }
  }, [selectedRouteDetails]);
  useEffect(() => {
    if (stops.length > 0) {
      showStopsOnMap(stops); // Call this to ensure markers are updated when stops are loaded
    }
  }, [stops]);

  useEffect(() => {
    if (stops && map) {
      showStopsOnMap(stops);
    }
  }, [stops, map]);

  const clearMarkers = () => {
    if (markers) {
      markers.forEach((marker) => marker.setMap(null));
    }
  };

  const handleRoute = () => {
    if (selectedRouteDetails) {
      const { startLat, startLng, endLat, endLng } = selectedRouteDetails;
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { lat: startLat, lng: startLng },
          destination: { lat: endLat, lng: endLng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
          } else {
            console.error("Error fetching directions:", result);
          }
        }
      );
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
  };

  const handleSinglePlaceSelect = (selectedOption, index) => {
    if (!selectedOption) return;

    // Use placesAlongRoute to find the selected place
    const selectedPlace = placesAlongRoute.find(
      (place) => place.label === selectedOption.label
    );

    if (!selectedPlace) return;

    const { startLat, startLng } = selectedRouteDetails;
    const distance = calculateDistance(
      startLat,
      startLng,
      selectedPlace.location.lat(),
      selectedPlace.location.lng()
    );

    const updatedStops = stops.map((stop, idx) =>
      idx === index
        ? {
            ...stop,
            stop_name: selectedPlace.label,
            latitude: selectedPlace.location.lat(),
            longitude: selectedPlace.location.lng(),
            distance: distance.toFixed(2),
          }
        : stop
    );

    setStops(updatedStops);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const formattedStopData = stops.map((stop) => ({
      stop_id: stop.stop_id || `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`, // Generate stop_id if it doesn’t exist
      pickup_point: stop.stop_name || stop.pickup_point, // Use correct field names
      drop_point: stop.dropplace || stop.drop_point,
      pickup_latitude: parseFloat(stop.pickup_latitude),
      pickup_longitude: parseFloat(stop.pickup_longitude),
      drop_latitude: parseFloat(stop.drop_latitude),
      drop_longitude: parseFloat(stop.drop_longitude),
      pickup_distance: stop.pickup_distance,
      drop_distance: stop.drop_distance,
      amount: parseFloat(stop.amount),
      pickup_time: stop.pickup_time,
      drop_time: stop.drop_time,
    }))
    const data = {
      route_id: routeoption, // Assuming route_id is stored in `selectedRouteId`
      stop_data: formattedStopData,
    };

    try {
      const response = await updatestop(id, data); // Call the API function to save the data
      if (response.status === 200) {
        setApiMessage(response.data.message);
        showSuccessAlert("Success", apiMessage);
        console.log("Stoppages saved successfully!");
      } else {
        console.error("Error saving stoppages:", response.data);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const showStopsOnMap = (stops) => {

    const newMarkers = stops
      .map((stop) => {
        if (stop.latitude && stop.longitude) {
          const marker = new window.google.maps.Marker({
            position: {
              lat: parseFloat(stop.latitude),
              lng: parseFloat(stop.longitude),
            },
            map: map,
            title: stop.stop_name, // Show stop name in marker title
          });

          const infoWindow = new window.google.maps.InfoWindow({
            content: `<div><strong>${stop.stop_name}</strong><br>Distance: ${stop.distance} km</div>`,
          });

          marker.addListener("click", () => {
            infoWindow.open(map, marker);
          });

          return marker;
        }
        return null;
      })
      .filter((marker) => marker !== null);

    setMarkers(newMarkers);
  };

  const handleAddStop = () => {
    setStops([
      ...stops,
      { stop_name: "", latitude: "", longitude: "", distance: "", amount: "" },
    ]);
  };

  const handleRemoveStop = (index) => {
    const updatedStops = stops.filter((_, idx) => idx !== index);
    setStops(updatedStops);
  };

  const libraries = ["places"];

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyD5JCUIe7Ig7tKEOkHHYE9TNkybW_JKilY"
      libraries={libraries}
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Edit Stoppages</h3>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="col-4 mb-3">
              <label>Select Route</label>

              <Select
                options={routes}
                value={routes.find((route) => route.value === routeoption)}
                onChange={(option) => {
                  fetchRouteById(option.value);
                  setSelectedRoute(option.value);
                }}
                placeholder="Select Route"
              />
            </div>
            <div>
              <button
                className="btn btn-primary mt-4  ml-2"
                onClick={handleRoute}
              >
                Plan Route
              </button>
            </div>
          </div>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            center={{ lat: 17.385044, lng: 78.486671 }}
            zoom={10}
            onLoad={(map) => setMap(map)}
          >
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
            {showMarkersOnMap()}
            
            {selectedMarker && (
              <InfoWindow
                position={selectedMarker.position}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div>
                  <h4>{selectedMarker.title}</h4>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>

          {/* Stops Section */}
          <div className="card p-3 mt-2">
            <div className="mt-4">
              <h5>Stops:</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Pickup point</th>
                    <th>pickup_time</th>
                    <th>pickupdistance</th>

                    <th>Drop point</th>
                    <th style={{ display: "none" }}>Latitude</th>
                    <th style={{ display: "none" }}>Longitude</th>
                    <th>dropDistance</th>
                    <th>Amount</th>

                    <th>drop_time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {stops.map((stop, index) => (
                    <tr key={index}>
                      <td>
                        <Autocomplete
                          onLoad={(ref) => (autocompleteRef.current = ref)}
                          onPlaceChanged={() => onPlaceChanged(index, "stop")}
                        >
                          <input
                            type="text"
                            placeholder="Search pickup location"
                            className="form-control"
                            value={stop.pickup_point}
                            onChange={(e) =>
                              setStops(
                                stops.map((s, i) =>
                                  i === index
                                    ? { ...s, stop_name: e.target.value }
                                    : s
                                )
                              )
                            }
                          />
                        </Autocomplete>
                      </td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            value={
                              stop.pickup_time
                                ? dayjs(stop.pickup_time, "h:mm A")
                                : null
                            }
                            sx={{
                              width: "120px",
                              "& .MuiInputBase-input": {
                                height: "5px",
                              },
                            }}
                            onChange={(value) => {
                              const formattedTime = value
                                ? value.format("h:mm A")
                                : null;
                              handleStopChange(
                                index,
                                "pickup_time",
                                formattedTime
                              );
                            }}
                            renderInput={(params) => (
                              <input
                                {...params}
                                className="form-control custom-time-input"
                                style={{
                                  width: "100px",
                                  height: "2px",
                                }}
                              />
                            )}
                            amPmAriaLabel="Select AM/PM"
                          />
                        </LocalizationProvider>
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="4km"
                          value={stop.pickup_distance}
                          onChange={(e) =>
                            handleStopChange(
                              index,
                              "pickup_distance",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <Autocomplete
                          onLoad={(ref) => (dropplaceRef.current = ref)}
                          onPlaceChanged={() => onPlaceChanged(index, "drop")}
                        >
                          <input
                            type="text"
                            placeholder="Search drop location"
                            className="form-control"
                            value={stop.drop_point}
                            onChange={(e) =>
                              setStops(
                                stops.map((s, i) =>
                                  i === index
                                    ? { ...s, drop_point: e.target.value }
                                    : s
                                )
                              )
                            }
                          />
                        </Autocomplete>
                      </td>

                      <td style={{ display: "none" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pickup Latitude"
                          value={stop.pickup_latitude}
                          onChange={(e) =>
                            handleStopChange(
                              index,
                              "pickup_latitude",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td style={{ display: "none" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pickup Longitude"
                          value={stop.pickup_longitude}
                          onChange={(e) =>
                            handleStopChange(
                              index,
                              "pickup_longitude",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td style={{ display: "none" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Drop Latitude"
                          value={stop.drop_latitude}
                          onChange={(e) =>
                            handleStopChange(
                              index,
                              "drop_latitude",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td style={{ display: "none" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Drop Longitude"
                          value={stop.drop_longitude}
                          onChange={(e) =>
                            handleStopChange(
                              index,
                              "drop_longitude",
                              e.target.value
                            )
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="drop_distance"
                          value={stop.drop_distance}
                          onChange={(e) =>
                            handleStopChange(
                              index,
                              "drop_distance",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Amount"
                          value={stop.amount}
                          onChange={(e) =>
                            handleStopChange(index, "amount", e.target.value)
                          }
                        />
                      </td>

                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            value={
                              stop.drop_time
                                ? dayjs(stop.drop_time, "h:mm A")
                                : null
                            }
                            sx={{
                              width: "120px",
                              "& .MuiInputBase-input": {
                                height: "5px",
                              },
                            }}
                            onChange={(value) => {
                              const formattedTime = value
                                ? value.format("h:mm A")
                                : null;
                              handleStopChange(
                                index,
                                "drop_time",
                                formattedTime
                              );
                            }}
                            renderInput={(params) => (
                              <input
                                {...params}
                                className="form-control"
                                placeholder="Select Drop Time" // Placeholder for better UX
                              />
                            )}
                            amPmAriaLabel="Select AM/PM"
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <div className="d-flex">
                          <button
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#dc3545",
                              borderColor: "#dc3545",
                            }} // Keeping the red color
                            onClick={() => handleRemoveStop(index)}
                          >
                            -
                          </button>
                          {index === stops.length - 1 && ( // Show "Add Stop" button only on the last row
                            <button
                              className="btn btn-primary ml-2"
                              onClick={handleAddStop}
                            >
                              +
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadScript>
  );
};

export default Stoppagesedit;
