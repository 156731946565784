import { data } from "jquery";
import Api from "../Api";
export const createExam = (data) => {
  return Api.post("/create-exam", data);
};
export const getAllExams = () => {
  return Api.get("/get-exams");
};
export const getExamById = async (id) => {
  const response = await Api.get(`/get-exam/${id}`);
  return response.data;
};
export const updateExamById = async (id, data) => {
  const response = await Api.post(`/update-exam/${id}`, data);
  return response.data;
};
export const deleteExam = (id) => {
  return Api.delete(`/delete-exam/${id}`);
};
//Exam Report Card Lock
export const createExamReportLock = (name, value) => {
  return Api.post("/create-exam-report-lock", { name, value });
};
export const getAllExamReportLock = (params) => {
  return Api.get("/get-all-exam-report-lock", { params });
};
export const getExamReportLockById = async (id) => {
  const response = await Api.get(`/get-exam-report-lock/${id}`);
  return response.data;
};
export const updateExamReportLockById = async (id, data) => {
  const response = await Api.post(`/update-exam-report-lock/${id}`, data);
  return response.data;
};
export const deleteExamReportLock = (id) => {
  return Api.delete(`/delete-exam-report-lock/${id}`);
};
