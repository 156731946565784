import Api from "../Api";

export const createDepartment = async (data) => {
  try {
    const response = await Api.post("/create-department", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDepartments = async (params) => {
  return await Api.get("/getall-departments",{params});
};

export const deleteDepartmentById = async (id) => {
  try {
    const response = await Api.get(`delete-department/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDepartmentById = async (id, data) => {
  try {
    const response = await Api.post(`/update-department/${id}`, data, {
      params: {
        department_id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDepartmentById = async (id) => {
  try {
    const response = await Api.get(`/get-department/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
