import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createClass } from "../../Services/CommonApis/classApi";
import { useParams } from "react-router-dom";

import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

const validationSchema = Yup.object({
  name: Yup.string().required("Class Name is required"),
});

const CreateClass = () => {
  const { branchId } = useParams();
  const handleSubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the class",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createClass({
          branch_id: branchId,
          name: values.name,
        });

        if (response.status === 201) {
          showSuccessAlert(
            "Created!",
            "The class has been created successfully."
          );
          window.location.reload();
        } else {
          const errorMessage =
            response.data?.message ||
            "Class creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Class creation has been cancelled.");
    }
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Class Creation <small> Form</small>
            </h3>
          </div>

          <Formik
            initialValues={{
              branch_id: branchId,
              name: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div style={{ marginTop: "20px" }}>
                  <div className="form-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-6">
                          <label htmlFor="name">
                            Class Name <span className="mandatory">*</span>
                          </label>
                          <Field
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Class Name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-error"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateClass;
