import Api from "../Api";

export const CreateLibraryBook = async (data) => {
  return await Api.post(`/create-library-book`,data);
};

export const getLibraryBooksList = async(params) => {
  return await Api.get(`/getall-lib-books`,{params})
}

export const getLibraryBookById = async(id)=>{
  return await Api.get(`/get-lib-book/${id}`)
}

export const updateLibraryBookById = async(id,data)=>{
  return await Api.post( `/update-lib-book/${id}`,data)
}

export const deleteLibraryBookById = async(id) => {
  return await Api.delete(`/delete-lib-book/${id}`)
}