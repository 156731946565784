import Api from "../Api";

export const createPilot = async (data) => {
  try {
    const response = await Api.post("/create-pilot", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPilots = async (params) => {
  return await Api.get("/get-all-Pilot", { params });
};

export const deletePilotById = async (id) => {
  try {
    const response = await Api.delete(`/delete-pilot/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePilotById = async (id, data) => {
  try {
    const response = await Api.post(`/update-pilot/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPilotById = async (id) => {
  try {
    const response = await Api.get(`/get-pilot/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
