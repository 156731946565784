import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../Styles/SchoolCreate.css";
import { BranchValidation } from "../../Utility/CommonUtility/Validation";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import MultiStepForm from "../../Components/CommonComponents/Multistepform";
import { useNavigate, useParams } from "react-router-dom";
import { createBranch } from "../../Services/CommonApis/branchApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import {
  getDistByStateId,
  getStates,
} from "../../Services/AdminApis/StateandDistrictApi";

const BranchCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [academic, setAcademic] = useState([]);
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = 3;

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [isAcademicLoading, setIsAcademicLoading] = useState(false);

  useEffect(() => {
    fetchAcademicYear();
    fetchStates();
  }, []);

  const generateBranchCode = (schoolName) => {
    if (!schoolName) return "";
    const words = schoolName.trim().split(" ");
    if (words.length === 1) {
      return words[0].substring(0, 3).toUpperCase();
    }
    return words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const fetchStates = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      console.log(response);
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const fetchDistrict = async (stateId) => {
    try {
      setIsDistrictLoading(true);
      const response = await getDistByStateId(stateId);
      setDistricts(response.data.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };

  const fetchAcademicYear = async () => {
    try {
      setIsAcademicLoading(true);
      const response = await getAllAcademics();

      const academicYearsArray = response.data.data;
      setAcademic(academicYearsArray);
    } catch (error) {
      console.error("Error fetching academic years:", error);
      setAcademic([]);
    } finally {
      setIsAcademicLoading(false);
    }
  };

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  useEffect(() => {}, [current]);

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key] !== null && values[key] !== undefined) {
        if (
          key.endsWith("_file") ||
          key.endsWith("_card") ||
          key.endsWith("_logo")
        ) {
          const file = values[key];
          if (file instanceof File) {
            formData.append(key, file, file.name);
          } else {
            formData.append(key, file);
          }
        } else if (
          key === "report_card_template" ||
          key === "id_card_template" ||
          key === "receipt_template"
        ) {
          formData.append(key, values[key]);
        } else {
          formData.append(key, values[key]);
        }
      }
    });
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the branch",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createBranch(values, id);
        if (response.status === 201) {
          showSuccessAlert(
            "Created!",
            "The branch has been created successfully."
          );
          navigate(`/admin/school/${id}/branch-list`);
        } else {
          const errorMessage =
            response.data?.message ||
            "Branch creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API error:", error);
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during branch creation.";
        showErrorAlert("Error!", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Branch creation has been cancelled.");
    }
  };

  const initialValues = {
    branch_name: "",
    branch_code: "",
    branch_email: "",
    branch_phone: "",
    branch_city: "",
    branch_dist: "",
    branch_state: "",
    branch_pin: "",
    branch_address: "",
    academic_id: "",
    period_attendance: "",
    subject_select: "",
    personality_traits: "1",
    report_card_template: "",
    id_card_template: "",
    receipt_template: "",
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Branch Create</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Branch Create</li>
              </ol>
            </div>
          </div>
          <div className="card">
            <div className="head-card">
              <div className="row ml-5 text-center">
                <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={BranchValidation[`tab${current}`]}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue, setTouched }) => (
                      <Form id="msform">
                        <ul id="progressbar">
                          <li
                            className={current >= 1 ? "active" : ""}
                            id="account"
                            onClick={() => goToStep(1)}
                            style={{ width: "350px" }}
                          >
                            <strong>Branch Information</strong>
                          </li>
                          <li
                            className={current >= 2 ? "active" : ""}
                            id="payment"
                            onClick={() => goToStep(2)}
                            style={{ width: "350px" }}
                          >
                            <strong>Branch Address</strong>
                          </li>
                          <li
                            className={current >= 3 ? "active" : ""}
                            id="images"
                            onClick={() => goToStep(3)}
                            style={{ width: "350px" }}
                          >
                            <strong>Logo and Templates</strong>
                          </li>
                        </ul>
                        <div className="progress">
                          <div
                            ref={progressBarRef}
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <br />
                        {current === 1 && (
                          <div
                            className="form-step"
                            style={{
                              border: "2px solid #007bff",
                              borderRadius: "8px",
                            }}
                          >
                            <fieldset>
                              <div className="form-card">
                                <div className="row">
                                  <div className="col-7">
                                    <h2 className="fs-title mt-3 ml-3">
                                      Branch Information:
                                    </h2>
                                  </div>
                                  <div className="col-5">
                                    <h2 className="steps mr-3 mt-3">
                                      Step 1 - {steps - 2}
                                    </h2>
                                  </div>
                                </div>
                                <div className="row ml-3 mr-3 mt-3">
                                  <div className="form-group col-6">
                                    <label htmlFor="branchName">
                                      Branch Name{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="branchName"
                                      name="branch_name"
                                      placeholder="Enter branch name"
                                      style={{ borderColor: "skyblue" }}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setFieldValue("branch_name", value);
                                        const branchCode =
                                          generateBranchCode(value);
                                        setFieldValue(
                                          "branch_code",
                                          branchCode
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name="branch_name"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label htmlFor="branchCode">
                                      Branch Code{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="branchCode"
                                      name="branch_code"
                                      placeholder="Enter branch code"
                                      style={{ borderColor: "skyblue" }}
                                    />
                                    <ErrorMessage
                                      name="branch_code"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                </div>
                                <div className="row ml-3 mr-3 mt-3">
                                  <div className="form-group col-6">
                                    <label htmlFor="email">
                                      Email <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="branch_email"
                                      placeholder="Enter email"
                                      style={{ borderColor: "skyblue" }}
                                    />
                                    <ErrorMessage
                                      name="branch_email"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label htmlFor="mobileNo">
                                      Mobile No{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="mobileNo"
                                      name="branch_phone"
                                      placeholder="Enter mobile number"
                                      style={{ borderColor: "skyblue" }}
                                    />
                                    <ErrorMessage
                                      name="branch_phone"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label htmlFor="academic_id">
                                      Academic Year{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="academic_id"
                                      className="form-control"
                                    >
                                      <option value="">Select Year</option>
                                      {isAcademicLoading ? (
                                        <option>Loading ...</option>
                                      ) : (
                                        academic.map((year) => {
                                          const startDate = new Date(
                                            year.start_date
                                          );
                                          const endDate = new Date(
                                            year.end_date
                                          );

                                          const startMonthYear =
                                            startDate.toLocaleString(
                                              "default",
                                              {
                                                month: "short",
                                                year: "numeric",
                                              }
                                            );
                                          const endMonthYear =
                                            endDate.toLocaleString("default", {
                                              month: "short",
                                              year: "numeric",
                                            });

                                          const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                                          return (
                                            <option
                                              key={year.id}
                                              value={year.id}
                                            >
                                              {formattedDateRange}
                                            </option>
                                          );
                                        })
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="academic_id"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>

                                  <div className="form-group col-6 d-flex mt-4">
                                    <Field
                                      type="checkbox"
                                      id="period_attendance"
                                      name="period_attendance"
                                      className="form-control mt-2"
                                      checked={values.period_attendance === "1"} // Ensure this comparison matches the form value
                                      style={{ width: "20px", height: "20px" }}
                                      onChange={(e) => {
                                        // Manually handle the change to toggle the value between "1" and ""
                                        setFieldValue(
                                          "period_attendance",
                                          e.target.checked ? "1" : ""
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor="period_attendance"
                                      className="mt-1 ml-2"
                                    >
                                      Enable Period wise attendance?
                                    </label>
                                  </div>

                                  <div className="form-group col-6 d-flex  flex-row">
                                    <label className="mt-1">
                                      Select Subject for
                                      <span className="mandatory">* </span>
                                    </label>
                                    <div className="d-flex align-items-center ml-3 mb-1">
                                      <div className="form-check mr-3">
                                        <Field
                                          type="radio"
                                          className="form-check-input mt-2"
                                          id="subject_select"
                                          name="subject_select"
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="subject_select"
                                        >
                                          Class
                                        </label>
                                      </div>
                                      <div className="form-check mr-3 ">
                                        <Field
                                          type="radio"
                                          className="form-check-input mt-2"
                                          name="subject_select"
                                          id="married"
                                          value="0"
                                          checked={
                                            values.subject_select === "0"
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="married"
                                        >
                                          Section
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-6 d-flex  flex-row mb-3">
                                    <label className="mt-1">
                                      Personality Traits
                                      <span className="mandatory">* </span>
                                    </label>
                                    <div className="d-flex align-items-center ml-3 mb-1">
                                      <div className="form-check mr-3">
                                        <Field
                                          type="radio"
                                          className="form-check-input mt-2"
                                          id="subject_select"
                                          name="personality_traits"
                                          value="1"
                                          checked={
                                            values.personality_traits === "1"
                                          }
                                        />
                                        <label
                                          className="form-check-label mt-1 "
                                          htmlFor="subject_select"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                      <div className="form-check mr-3 ">
                                        <Field
                                          type="radio"
                                          className="form-check-input mt-2"
                                          id="married"
                                          name="personality_traits"
                                          value="0"
                                        />
                                        <label
                                          className="form-check-label mt-1"
                                          htmlFor="married"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        )}
                        {current === 2 && (
                          <div
                            className="form-step"
                            style={{
                              border: "2px solid #007bff",
                              borderRadius: "8px",
                            }}
                          >
                            <fieldset>
                              <div className="form-card">
                                <div className="row">
                                  <div className="col-7">
                                    <h2 className="fs-title mt-3 ml-3">
                                      Branch Address:
                                    </h2>
                                  </div>
                                  <div className="col-5">
                                    <h2 className="steps mr-3 mt-3">
                                      Step 2 - {steps - 1}
                                    </h2>
                                  </div>
                                </div>
                                <div className="row ml-3 mr-3 mt-3">
                                  <div className="form-group col-6">
                                    <label htmlFor="branchCity">
                                      Branch City{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="branchCity"
                                      name="branch_city"
                                      placeholder="Enter branch city"
                                      style={{ borderColor: "skyblue" }}
                                    />
                                    <ErrorMessage
                                      name="branch_city"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label htmlFor="branchState">
                                      Branch State{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      id="branchState"
                                      name="branch_state"
                                      placeholder="Select state"
                                      onChange={(e) => {
                                        const stateId = e.target.value;
                                        setFieldValue("branch_state", stateId);
                                        fetchDistrict(stateId);
                                      }}
                                    >
                                      <option value="" label="Select State" />
                                      {isStateLoading ? (
                                        <option>Loading states...</option>
                                      ) : (
                                        states.map((state) => (
                                          <option
                                            key={state.id}
                                            value={state.id}
                                            label={state.name}
                                          />
                                        ))
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="branch_state"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label htmlFor="branchDist">
                                      Branch District{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      id="branchDist"
                                      name="branch_dist"
                                      placeholder="Enter district"
                                    >
                                      <option
                                        value=""
                                        label="Select Districts"
                                      />
                                      {isDistrictLoading ? (
                                        <option>Loading districts...</option>
                                      ) : (
                                        districts.map((district) => (
                                          <option
                                            key={district.id}
                                            value={district.id}
                                            label={district.name}
                                          />
                                        ))
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="branch_dist"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label htmlFor="branchPin">
                                      Branch PIN{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="branchPin"
                                      name="branch_pin"
                                      placeholder="Enter branch PIN"
                                      style={{ borderColor: "skyblue" }}
                                    />
                                    <ErrorMessage
                                      name="branch_pin"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                </div>
                                <div className="row ml-3 mr-3 mt-3">
                                  <div className="form-group col-12">
                                    <label htmlFor="branchAddress">
                                      Branch Address{" "}
                                      <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      id="branchAddress"
                                      name="branch_address"
                                      placeholder="Enter branch address"
                                      style={{ borderColor: "skyblue" }}
                                    />
                                    <ErrorMessage
                                      name="branch_address"
                                      component="div"
                                      className="text-error"
                                    />
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        )}
                        {current === 3 && (
                          <div
                            className="form-step"
                            style={{
                              border: "2px solid #007bff",
                              borderRadius: "8px",
                            }}
                          >
                            <fieldset>
                              <div className="form-card">
                                <div className="row">
                                  <div className="col-7">
                                    <h2 className="fs-title mt-3 ml-3">
                                      Logo and Templates:
                                    </h2>
                                  </div>
                                  <div className="col-5">
                                    <h2 className="steps mr-3 mt-3">
                                      Step 3 - {steps}
                                    </h2>
                                  </div>
                                </div>
                                <div className="form-group col-12">
                                  <MultiStepForm />
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        )}
                        <div className="form-navigation">
                          {current > 1 && (
                            <button
                              type="button"
                              className="previous action-button-previous"
                              onClick={prevStep}
                            >
                              Previous
                            </button>
                          )}
                          {current < steps && (
                            <button
                              type="button"
                              className="next action-button"
                              onClick={() => {
                                const currentTabValidationSchema =
                                  BranchValidation[`tab${current}`];
                                currentTabValidationSchema
                                  .validate(values, { abortEarly: false })
                                  .then(() => {
                                    setCurrent(current + 1);
                                    setTouched({});
                                  })
                                  .catch((validationErrors) => {
                                    const errors =
                                      validationErrors.inner.reduce(
                                        (acc, err) => {
                                          acc[err.path] = err.message;
                                          return acc;
                                        },
                                        {}
                                      );
                                    setTouched(errors);
                                  });
                              }}
                            >
                              Next
                            </button>
                          )}
                          {current === steps && (
                            <button
                              type="submit"
                              className="next action-button"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BranchCreate;
