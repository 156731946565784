import Api from "../Api"

export const getAllSubjects = (params) => {
    return Api.get(`/getall-subjects`,{params})
}

export const createSubject = (subjectData) => {
    return Api.post(`/create-subject`, subjectData);
};

export const createBranchSubject = (data)=> {
    return Api.post("/create-branchsubject", data);
}


export const getBranchSubjects = ( params) => {
  const queryString = new URLSearchParams(params).toString();
  return Api.get(`/get-branchsubjects`,{params});
};


export const getSubjects = async () => {
  try {
    const response = await Api.get("/getall-subjects");
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSubjectById = async (id) => {
  try {
    const response = await Api.get(`delete-subject/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBranchSubjectById = async (id) => {
  return Api.delete(`/delete-branchsubject/${id}`)
}

export const updateSubjectById = async (id, data) => {
  try {
    const response = await Api.post(`/update-subject/${id}`, data, {
      params: {
        id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubjectById = async (id) => {
  try {
    const response = await Api.get(`/get-subject/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSubjectsByBranchId = async (branchId) => {
  try {
    const response = await Api.get(`/get-subject-bybranch/${branchId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSubjectTypes = async () => {
  try {
      const response = await Api.get("/get-subject-types");
      return response.data; 
  } catch (error) {
      throw error;
  }
};
