import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { FaTrashAlt } from "react-icons/fa";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import MultiSelectDropdown from "../../Utility/Multiselect";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { createHomework } from "../../Services/CommonApis/HomeworkApi";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
} from "../../Modals/SweetAlertModel";
import "../../Styles/Homework.css";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { useParams } from "react-router-dom";
import { getSubjectsByBranchId } from "../../Services/CommonApis/subjectApi";

const HomeWorkCreateTab = () => {
  const [activeTab, setActiveTab] = useState("HomeWork");
  const [rows, setRows] = useState([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [specialRows, setSpecialRows] = useState([
    { student: [], instruction: "" },
  ]);
  const { branchId } = useParams();
  const [individualSpecialRows, setIndividualSpecialRows] = useState([
    { student: null, instruction: "" },
  ]);
  const togglePreview = () => {
    setIsPreviewVisible((prevVisible) => !prevVisible);
  };
  const [classOptions, setClassOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [previewContent, setPreviewContent] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const previewRef = useRef(null);
  const today = new Date().toISOString().split("T")[0];
  const [notificationOptions] = useState([
    { value: "3", label: "SMS" },
    { value: "1", label: "WhatsApp" },
    { value: "4", label: "Email" },
    { values: "2", label: "App" },
  ]);
  const [descriptionOptions] = useState([
    { value: "HomeWork", label: "HomeWork" },
    { value: "Classwork", label: "Classwork" },
    { value: "books", label: "Books to Carry" },
  ]);
  const listOptions = [
    { value: "branch", label: "Branch List" },
    { value: "staff", label: "Staff List" },
    { value: "parent", label: "Parent List" },
    { value: "student", label: "Student List" },
  ];
  const initialRows = [{ HomeWork: "", Classwork: "", books: "" }];
  const [selectedDescriptions, setSelectedDescriptions] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [charCounts, setCharCounts] = useState(
    initialRows.reduce(
      (acc, row, index) => ({
        ...acc,
        [index]: {
          HomeWork: row.HomeWork.length > 30 ? 30 : row.HomeWork.length,
          Classwork: row.Classwork.length > 30 ? 30 : row.Classwork.length,
          books: row.books.length > 30 ? 30 : row.books.length,
        },
      }),
      {}
    )
  );

  const HomeWorkValidationSchema = Yup.object().shape({
    class_id: Yup.string().required("Class is required"),
  });
  const CircularValidationSchema = Yup.object().shape({
    list_dropdown: Yup.array().required("At least one option must be selected"),
    description: Yup.string().required("Description is required"),
  });

  useEffect(() => {
    fetchClasses(branchId);
    fetchSubjectsByBranchId(branchId);
  }, [branchId]);
  const fetchClasses = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  const branch_id = branchId;

  useEffect(() => {
    if (selectedClassId && selectedSectionId) {
      const fetchStudents = async () => {
        try {
          const response = await getstudentbyclassandsection({
            branch_id,
            class_id: selectedClassId,
            section_id: selectedSectionId,
          });
          const students = response.data.data.map((student) => ({
            value: student.id,
            label: student.first_name,
          }));
          setStudentOptions(students);
        } catch (error) {
          console.error("Error fetching students:", error);
        }
      };

      fetchStudents();
    }
  }, [selectedClassId, selectedSectionId]);

  const addSpecialRow = () => {
    setSpecialRows((prevRows) => [
      ...prevRows,
      { student: [], instruction: "" },
    ]);
  };

  const addIndividualSpecialRow = () => {
    setIndividualSpecialRows((prevRows) => [
      ...prevRows,
      { student: null, instruction: "" },
    ]);
  };

  const removeIndividualSpecialRow = (index) => {
    setIndividualSpecialRows((prevRows) =>
      prevRows.filter((_, i) => i !== index)
    );
  };

  const handleClassSelect = (classId) => {
    setRows(
      Array(6).fill({
        subject: "",
        HomeWork: "",
        Classwork: "",
        bookstocarry: "",
        file: null,
      })
    );
  };

  const handleclasssection = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      const sections = response.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    showLoadingAlert();

    const formattedHomeworkType = values.description_types
      .map((option) => `'${option.label}'`)
      .join(", ");

    const formatRowsData = () => {
      const formattedData = {};
      console.log(values.rows);

      values.rows.forEach((row, index) => {
        const subjectId = selectedSubjects[index];
        const { HomeWork, Classwork, bookstocarry } = row;

        const formattedRow = {};
        if (HomeWork) formattedRow["HW"] = HomeWork;
        if (Classwork) formattedRow["CW"] = Classwork;
        if (bookstocarry) formattedRow["BCR"] = bookstocarry;

        formattedData[subjectId] = formattedRow;
      });

      return formattedData;
    };

    const formattedSpecialInstructionStudents = values.special_instruction_student.map(
      (student) => student.value
    );
    const formattedIndividualStudents = values.individualSpecialRows.map(
      (row) => row.student.value
    );
    const formattedIndividualMessages = values.individualSpecialRows.map(
      (row) => row.instruction
    );

    const formatDate = (dateString) => {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    };

    const dateString = formatDate(values.HomeWork_date);

    const formattedNotificationTypes = values.notification_type.map((option) =>
      parseInt(option.value, 10)
    );

    const result = formatRowsData();

    const structuredData = {
      branch_id: branchId,
      class_id: values.class_id,
      section_id: values.section_id,
      date: dateString,
      homework_type: formattedHomeworkType,
      email: values.email || "likhitha043@gmail.com",
      work_data: result,
      special_instruction_student: formattedSpecialInstructionStudents || [],
      special_instruction_message: values.special_instruction_message || "",
      notification_type: "homework",
      indivedual_student: formattedIndividualStudents || [],
      indivedual_message: formattedIndividualMessages || [],
      notification: formattedNotificationTypes,
      dateString: formatDate(values.HomeWork_date),
    };

    try {
      const response = await createHomework(structuredData);
      setApiMessage(response.data.message || response.message);
      showSuccessAlert("Success", apiMessage);
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };


  const handleSubmitCircular = async (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  useEffect(() => {
    setPreviewContent(
      generatePreview({
        class_id: selectedClassId,
        section_id: selectedSectionId,
        rows,
      })
    );
  }, [selectedClassId, selectedSectionId, rows]);
  const generatePreview = (values) => {
    const { class_id, section_id, rows } = values;

    const className =
      classOptions.find((c) => String(c.value) === String(class_id))?.label ||
      "";
    const sectionName =
      sectionOptions.find((s) => String(s.value) === String(section_id))
        ?.label || "";

    let previewContent = `Dear Parent,Your Child \nClass: ${className} Section: ${sectionName}\n`;
    if (rows && rows.length > 0) {
      rows.forEach((row) => {
        const subjectName =
          subjectOptions.find((s) => s.value === row.subject)?.label || "-";
        previewContent += `Subject: ${subjectName}\n`;

        if (selectedDescriptions.includes("HomeWork")) {
          previewContent += `HomeWork: ${row.HomeWork || "-"} `;
        }
        if (selectedDescriptions.includes("Classwork")) {
          previewContent += `Classwork: ${row.Classwork || "-"} `;
        }
        if (selectedDescriptions.includes("books")) {
          previewContent += `Books to Carry: ${row.bookstocarry || "-"} `;
        }

        previewContent += "\n";
      });
    }

    return previewContent.trim();
  };

  useEffect(() => {
    if (previewRef.current) {
      const element = previewRef.current;
      let offsetX, offsetY;

      const handleMouseDown = (e) => {
        e.preventDefault();
        const { clientX: startX, clientY: startY } = e;
        offsetX = e.clientX - element.getBoundingClientRect().left;
        offsetY = e.clientY - element.getBoundingClientRect().top;

        const handleMouseMove = (e) => {
          const x = e.clientX - offsetX;
          const y = e.clientY - offsetY;
          element.style.left = `${x}px`;
          element.style.top = `${y}px`;
        };

        const handleMouseUp = () => {
          document.removeEventListener("mousemove", handleMouseMove);
          document.removeEventListener("mouseup", handleMouseUp);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      };

      element.addEventListener("mousedown", handleMouseDown);

      return () => {
        element.removeEventListener("mousedown", handleMouseDown);
      };
    }
  }, [activeTab, previewRef]);
  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index][field] = value.slice(0, 30);
      return newRows;
    });
    setCharCounts((prevCounts) => ({
      ...prevCounts,
      [index]: {
        ...prevCounts[index],
        [field]: value.length > 30 ? 30 : value.length,
      },
    }));
  };
  const addRow = () => setRows([...rows, { subject: "", file: null }]);

  const handleAddRow = () => {
    setRows([...rows, { subject: '', file: '' }]);
  };

  const handleSubjectChange = (index, value) => {
    const updatedSubjects = [...selectedSubjects];
    updatedSubjects[index] = parseInt(value, 10);
    setSelectedSubjects(updatedSubjects);
  };

  const fetchSubjectsByBranchId = async (branchId) => {
    try {
      const response = await getSubjectsByBranchId(branchId);
      const subjects = response.branchsubject.map((subject) => ({
        subject_id: subject.subject_id,
        subject_name: subject.subject_name,
      }));
      setSubjectOptions(subjects);
    } catch (error) {
      console.error(error);
    }
  };
  const filteredSubjects = (index) => {
    console.log(selectedSubjects[index]);
    return subjectOptions.filter(
      (subject) =>
        !selectedSubjects.includes(subject.subject_id) ||
        selectedSubjects[index] === subject.subject_id
    );
  };


  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };


  return (
    <>
      <div className="custom-tabs-container">
        <ul className="custom-nav-tabs container" id="myTab" role="tablist">
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${activeTab === "HomeWork" ? "active" : ""
                }`}
              id="HomeWork-tab"
              data-toggle="tab"
              href="#HomeWork"
              role="tab"
              aria-controls="HomeWork"
              aria-selected={activeTab === "HomeWork"}
              onClick={() => setActiveTab("HomeWork")}
            >
              <i className="fas fa-book"></i> HomeWork
            </a>
          </li>
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${activeTab === "Specialinstruction" ? "active" : ""
                }`}
              id="Specialinstruction-tab"
              data-toggle="tab"
              href="#Specialinstruction"
              role="tab"
              aria-controls="Specialinstruction"
              aria-selected={activeTab === "Specialinstruction"}
              onClick={() => setActiveTab("Specialinstruction")}
            >
              <i className="fas fa-book"></i> Specialinstruction
            </a>
          </li>
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${activeTab === "circular" ? "active" : ""
                }`}
              id="circular-tab"
              data-toggle="tab"
              href="#circular"
              role="tab"
              aria-controls="circular"
              aria-selected={activeTab === "circular"}
              onClick={() => setActiveTab("circular")}
            >
              <i className="fas fa-bullhorn"></i> Circular
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-contentt">
        {activeTab === "HomeWork" && (
          <div id="HomeWork" className="tab-pane fade show active">
            <div className="d-flex justify-content-between mb-3">
              <h4>HomeWork Creation</h4>
            </div>
            <Formik
              initialValues={{
                HomeWork_date: today,
                class_id: "",
                section_id: "",
                description_types: "",
                notification_type: [],
                special_instruction_student: [],
                special_instruction_message: "",
                individualSpecialRows: [],
              }}
              validationSchema={HomeWorkValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <div className="row ml-3 mr-5 mt-3">
                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="HomeWork_date" className="form-label">
                          HomeWork Date <span className="mandatory">* </span>
                        </label>
                        <Field
                          type="date"
                          name="HomeWork_date"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="HomeWork_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="class_id" className="form-label">
                          Class <span className="mandatory">* </span>
                        </label>
                        <Field
                          as="select"
                          name="class_id"
                          className="form-control"
                          onChange={(e) => {
                            const classId = e.target.value;
                            handleClassSelect(classId);
                            handleclasssection(classId);
                            setSelectedClassId(classId);
                            setFieldValue("class_id", classId);
                          }}
                        >
                          <option value="">Select Class</option>
                          {classOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="class_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="section_id" className="form-label">
                          Section <span className="mandatory">* </span>
                        </label>
                        <Field
                          as="select"
                          name="section_id"
                          className="form-control"
                          onChange={(e) => {
                            const sectionId = e.target.value;
                            setFieldValue("section_id", sectionId);
                            setSelectedSectionId(sectionId);
                          }}
                        >
                          <option value="">Select Section</option>
                          {sectionOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="section_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label
                          htmlFor="description_types"
                          className="form-label"
                        >
                          HomeWork Types
                        </label>
                        <Select
                          isMulti
                          name="description_types"
                          options={descriptionOptions}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setSelectedDescriptions(selectedValues);
                            setFieldValue("description_types", selectedOptions);
                          }}
                          value={values.description_types}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label
                          htmlFor="notification_type"
                          className="form-label"
                        >
                          Notification Types
                        </label>
                        <MultiSelectDropdown
                          options={notificationOptions}
                          name="notification_type"
                          setFieldValue={setFieldValue}
                          value={values.notification_type}
                        />
                      </div>
                    </div>
                  </div>

                  <Button
                    variant="primary"
                    onClick={addRow}
                    style={{ float: "right", marginBottom: "10px" }}
                  >
                    Add Row
                  </Button>

                  <table className="table table-striped">
                    <thead
                      style={{ backgroundColor: "#f0f4f8", color: "#333" }}
                    >
                      <tr>
                        <th>Subject</th>
                        {selectedDescriptions.includes("HomeWork") && (
                          <th>HomeWork</th>
                        )}
                        {selectedDescriptions.includes("Classwork") && (
                          <th>Classwork</th>
                        )}
                        {selectedDescriptions.includes("books") && (
                          <th>Books to Carry</th>
                        )}
                        <th>File</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.length > 0 &&
                        rows.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <Field
                                as="select"
                                name={`rows[${index}].subject`}
                                className="form-control"
                                onChange={(e) => handleSubjectChange(index, e.target.value)}
                              >
                                <option value="" label="Select subject" />
                                {filteredSubjects(index).map((subject) => (
                                  <option key={subject.subject_id} value={subject.subject_id}>
                                    {subject.subject_name}
                                  </option>
                                ))}
                              </Field>
                            </td>

                            {selectedDescriptions.includes("HomeWork") && (
                              <td>
                                <Field
                                  as="textarea"
                                  maxLength={30}
                                  onChange={(e) => {
                                    handleInputChange(e, index, "HomeWork");
                                    setFieldValue(
                                      `rows[${index}].HomeWork`,
                                      e.target.value
                                    );
                                  }}
                                  name={`rows[${index}].HomeWork`}
                                  className="form-control"
                                />
                                <small>
                                  {" "}
                                  character count:{
                                    charCounts[index]?.HomeWork
                                  }{" "}
                                  / 30
                                </small>
                              </td>
                            )}
                            {selectedDescriptions.includes("Classwork") && (
                              <td>
                                <Field
                                  as="textarea"
                                  maxLength={30}
                                  onChange={(e) => {
                                    handleInputChange(e, index, "Classwork");
                                    setFieldValue(
                                      `rows[${index}].Classwork`,
                                      e.target.value
                                    );
                                  }}
                                  name={`rows[${index}].Classwork`}
                                  className="form-control"
                                />
                                <small>
                                  {" "}
                                  character count:{
                                    charCounts[index]?.Classwork
                                  }{" "}
                                  / 30
                                </small>
                              </td>
                            )}
                            {selectedDescriptions.includes("books") && (
                              <td>
                                <Field
                                  as="textarea"
                                  maxLength={30}
                                  onChange={(e) => {
                                    handleInputChange(e, index, "bookstocarry");
                                    setFieldValue(
                                      `rows[${index}].bookstocarry`,
                                      e.target.value
                                    );
                                  }}
                                  name={`rows[${index}].bookstocarry`}
                                  className="form-control"
                                />
                                <small>
                                  {" "}
                                  character count:
                                  {charCounts[index]?.bookstocarry} / 30
                                </small>
                              </td>
                            )}
                            <td>
                              <input
                                type="file"
                                name={`rows[${index}].file`}
                                className="form-control"
                                onChange={(e) =>
                                  setFieldValue(
                                    `rows[${index}].file`,
                                    e.currentTarget.files[0]
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => removeRow(index)}
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between mb-3 mt-5">
                    <h4>Special Instructions for multiple Students</h4>
                  </div>

                  <table className="table table-striped">
                    <thead
                      style={{ backgroundColor: "#f0f4f8", color: "#333" }}
                    >
                      <tr>
                        <th>Student</th>
                        <th>Special Instructions</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {specialRows.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <MultiSelectDropdown
                              options={studentOptions}
                              name="special_instruction_student"
                              setFieldValue={setFieldValue}
                              value={values.special_instruction_student}
                            />
                          </td>
                          <td>
                            <Field
                              as="textarea"
                              name="special_instruction_message"
                              className="form-control"
                              placeholder="Special Instructions"
                            />
                          </td>
                          <td className="text-center">

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between mb-3 mt-5">
                    <h4>Special Instructions for Individual Students</h4>
                    <Button variant="primary" onClick={addIndividualSpecialRow}>
                      Add Row
                    </Button>
                  </div>


                  <table className="table table-striped">
                    <thead
                      style={{ backgroundColor: "#f0f4f8", color: "#333" }}
                    >
                      <tr>
                        <th>Student</th>
                        <th>Special Instructions</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {individualSpecialRows.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <Select
                              options={studentOptions}
                              className="basic-single"
                              classNamePrefix="select"
                              name={`individualSpecialRows[${index}].student`}
                              value={
                                values.individualSpecialRows[index]?.student ||
                                null
                              }
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  `individualSpecialRows[${index}].student`,
                                  selectedOption
                                )
                              }
                            />
                          </td>
                          <td>
                            <Field
                              as="textarea"
                              name={`individualSpecialRows[${index}].instruction`}
                              className="form-control"
                              placeholder="Special Instructions"
                            />
                          </td>
                          <td className="text-center">
                            <FaTrashAlt
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => removeIndividualSpecialRow(index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>

                  <div className="row ml-3 mr-1 mt-7 d-flex justify-content-end">
                    <Button
                      onClick={togglePreview}
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        className={`fas ${isPreviewVisible ? "fa-eye-slash" : "fa-eye"
                          }`}
                      ></i>
                    </Button>

                    {isPreviewVisible && (
                      <div ref={previewRef} className="preview-box">
                        <p>SMS Content Preview</p>
                        <div className="areat">
                          <span
                            style={{
                              color: "green",
                            }}
                          >
                            {generatePreview(values).slice(0, 90)}
                          </span>
                          <span
                            style={{
                              color: "blue",
                            }}
                          >
                            {generatePreview(values).slice(90)}
                          </span>
                        </div>
                        <p>
                          Entered Count: {generatePreview(values).length}/950
                          SMS Count:{" "}
                          {Math.ceil(generatePreview(values).length / 160)}
                        </p>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <div className="tab-contentt">
        {activeTab === "Specialinstruction" && (
          <div id="Specialinstruction" className="tab-pane fade show active">
            <div className="d-flex justify-content-between mb-3">
              <h4>Special Instruction </h4>
            </div>
          </div>
        )}
      </div>
      {activeTab === "circular" && (
        <div className="ml-3 mr-3">
          <Formik
            initialValues={{
              list_dropdown: [],
              description: "",
            }}
            validationSchema={CircularValidationSchema}
            onSubmit={handleSubmitCircular}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div
                  className="ml-3 mr-3"
                  style={{ backgroundColor: "#fff", padding: "1rem" }}
                >
                  <div className="row">
                    <div className="col-4">
                      <label htmlFor="list_dropdown">List Dropdown</label>
                      <MultiSelectDropdown
                        name="list_dropdown"
                        options={listOptions}
                        value={values.list_dropdown}
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage
                        name="list_dropdown"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="description">Description</label>
                      <Field
                        as="textarea"
                        name="description"
                        id="description"
                        className="form-control"
                        style={{ height: "150px" }}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-4 mt-50">
                      <input
                        type="file"
                        name="file"
                        className="form-control"
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                        onChange={(e) => setFieldValue()}
                      />
                    </div>
                  </div>

                  <Button
                    className="mt-3 "
                    type="submit"
                    style={{ float: "right", marginBottom: "10px" }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default HomeWorkCreateTab;
