import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import "../../Styles/DragandDrop.css";

const DragNdrop = ({ setFieldValue, fieldName, file, onFileChange }) => {
  const [localFile, setLocalFile] = useState(file);

  useEffect(() => {
    setLocalFile(file);
  }, [file]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFile = selectedFiles[0];
      setLocalFile(newFile);
      onFileChange(fieldName, newFile);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFile = droppedFiles[0];
      setLocalFile(newFile);
      onFileChange(fieldName, newFile);
    }
  };

  const handleRemoveFile = () => {
    setLocalFile(null);
    onFileChange(fieldName, null);
  };

  return (
    <section className="drag-drop">
      <div
        className={`document-uploader ${
          localFile ? "upload-box active" : "upload-box"
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <AiOutlineCloudUpload />
            <div>
              <p>Drag and drop your files here</p>
              <p>Limit 15MB per file. Supported files: .JPG, .PNG</p>
            </div>
          </div>
          <input
            type="file"
            hidden
            id={`browse-${fieldName}`}
            onChange={handleFileChange}
            accept=".jpg,.png"
          />
          <label htmlFor={`browse-${fieldName}`} className="browse-btn">
            Browse files
          </label>
        </>
        {localFile && (
          <div className="file-list">
            <div className="file-list__container">
              <div className="file-item">
                <div className="file-info">
                  <p>{localFile.name}</p>
                </div>
                <div className="file-actions">
                  <MdClear onClick={handleRemoveFile} />
                </div>
              </div>
            </div>
          </div>
        )}
        {localFile && (
          <div className="success-file">
            <AiOutlineCheckCircle
              style={{ color: "#6DC24B", marginRight: 1 }}
            />
            <p>1 file selected</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragNdrop;
