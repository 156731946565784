import * as XLSX from "xlsx";

const handleDownload = () => {
  const fileName = "branches.xlsx";
  const headers = [
    "branch_name",
    "school_code",
    "branch_code",
    "email",
    "phone",
    "address",
    "city",
    "district",
    "pincode",
    "status",
  ];
  const data = [
    [
      "Branch A",
      "SCH001",
      "Br101",
      "branch1@gmail.com",
      "9390404583",
      "124 Main St",
      "New York",
      "NY",
      "100901",
      "active",
    ],
    [
      "Branch B",
      "SCH002",
      "Br102",
      "branch2@gmail.com",
      "9390404584",
      "457 Elm St",
      "Los Angeles",
      "CA",
      "900802",
      "inactive",
    ],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Branches");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleDownload;
