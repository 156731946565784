import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import {
  getAllTransportRoute,
  deleteTransportRouteById,
  getTransportRouteById,
  updateTransportRouteById,
} from "../../Services/CommonApis/TransportApi";
import { useParams } from "react-router-dom";
import TransportPilotDetails from "./TransportRoutes";

function TransportRoutesList() {
  const { branchId } = useParams();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editrouteId, setEditrouteId] = useState(null);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteTransportRouteById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The Transport Route has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert(
          "Error!",
          "There was an error deleting the Transport Route."
        );
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Transport Route is safe :)");
    }
  };

  const handleEdit = async (id) => {
    setEditrouteId(id);
    setShowEditModal(true);
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "start_point" },
        { data: "end_point" },
        { data: "distance" },
        {
          data: null,
          title: "Actions",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "0"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllTransportRoute({
            branch_id: branchId,
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data;
          const totalCount = response.data.total;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div>
      {showEditModal ? (
        <TransportPilotDetails editrouteId={editrouteId} />
      ) : (
        <>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h1>Transport Routes</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Transport Routes List</h3>
                    </div>
                    <div className="card-body">
                      <table
                        ref={tableRef}
                        className="table table-bordered table-hover dt-responsive nowrap"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Start Point</th>
                            <th>End Point</th>
                            <th>Distance</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default TransportRoutesList;
