import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import {
  GetCertificateType,
  createCertificateFields,
  GetCertificateFields,
  UpdateCertificateField,
  DeleteCertificateField,
  getCertificateFieldById,
} from "../../Services/AdminApis/CertificateApi";

const CertificateFieldList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [data, setData] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFieldId, setEditFieldId] = useState(null);
  const [editFieldName, setEditFieldName] = useState("");
  const [editCertificateTypeId, setEditCertificateTypeId] = useState("");
  const [editFieldData, setEditFieldData] = useState(null);

  useEffect(() => {
    getCertificates();
    getCertificatesFields();
  }, []);

  const getCertificates = async () => {
    try {
      const response = await GetCertificateType();
      console.log("bye", response);
      setCertificateTypes(response.data.certificate_types);
    } catch (error) {
      console.error("Error fetching group details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the group details."
      );
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await DeleteCertificateField(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The group has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the group.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The group is safe :)");
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getCertificateFieldById(id);

      const {
        certificate_type_id,
        id: fieldId,
        field_label,
      } = response.data.certificate_field;

      console.log(field_label, certificate_type_id, fieldId, "Hii");
      setEditFieldId(fieldId);
      setEditFieldName(field_label);
      setEditCertificateTypeId(certificate_type_id);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching the certificate field:", error);
    }
  };

  const handleUpdateField = async (values) => {
    console.log(values);
    try {
      showLoadingAlert();
      const requestData = {
        certificate_type_id: values.certificate_type,
        field_label: [values.field_label],
        field_type: ["text"],
      };

      await UpdateCertificateField(editFieldId, requestData);
      showSuccessAlert("Success!", "The field has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      console.error(error);
    }
  };

  const getCertificatesFields = async () => {
    try {
      const response = await GetCertificateFields();
      console.log("hii", response.data);
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  };

  const handleCreateField = async (values) => {
    try {
      console.log("Form Submitted", values);

      showLoadingAlert();

      const selectedCertificate = certificateTypes.find(
        (cert) => cert.certificate_type === values.certificate_type
      );

      // Prepare the data
      const requestData = {
        certificate_type_id: selectedCertificate
          ? selectedCertificate.id
          : null,
        field_label: values.fields.map((field) => field.fieldName),
        field_type: values.fields.map(() => "text"),
      };

      await createCertificateFields(requestData);

      showSuccessAlert("Success!", "The Field has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      console.error(error);
    }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        {
          data: null,
          title: "S.No",
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
        },
        { data: "certificate_type", title: "Certificate Name" },
        { data: "field_name", title: "Field Name" },
        {
          data: null,
          title: "Actions",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `<a title="Delete" data-id="${row.field_id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
          <div class="icon-group" style="display: flex; gap: 10px;">
            <a href="#" title="Edit" data-id="${row.field_id}" class="edit-icon"><i class="fas fa-edit"></i></a>
            ${deleteIcon}
          </div>
        `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          // Fetch the certificate fields data
          const response = await GetCertificateFields({
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          // Flatten the data to fit the DataTable format
          const flattenedData = response.data.certificate_fields.flatMap(
            (cert, index) =>
              cert.fields.map((field) => ({
                sno: index + 1, // S.No, which should be sequential
                certificate_type: cert.certificate_type,
                field_name: field.field_label,
                status: cert.status,
                id: cert.id, // Certificate type ID (for further context if needed)
                field_id: field.id, // Field ID used for actions (edit/delete)
              }))
          );

          const totalCount = flattenedData.length; // Adjust if necessary for pagination

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: flattenedData,
          });
        } catch (error) {
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id"); // Use field_id for delete
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id"); // Use field_id for edit
      handleEdit(id);
    });
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Certificate Fields List</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Certificate Fields Table
                  </h3>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New                  </button>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Field Modal */}
        <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Field</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              certificate_type: "",
              fields: [{ fieldName: "", fieldType: "" }],
            }}
            // validationSchema={validationSchema}
            onSubmit={handleCreateField}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="certificate">Certificate Name</label>
                    <Field
                      as="select"
                      name="certificate_type"
                      className="form-control"
                    >
                      <option value="">Select Certificate</option>
                      {certificateTypes.map((certificate) => (
                        <option
                          key={certificate.id}
                          value={certificate.certificate_type}
                        >
                          {certificate.certificate_type}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="certificate_type"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label>Fields</label>
                    <FieldArray name="fields">
                      {({ remove, push }) => (
                        <div>
                          {values.fields.map((field, index) => (
                            <div key={index} className="d-flex mb-2">
                              <Field
                                name={`fields[${index}].fieldName`}
                                className="form-control mr-2"
                                placeholder="Enter field name"
                              />
                              <ErrorMessage
                                name={`fields[${index}].fieldName`}
                                component="div"
                                className="text-danger"
                              />
                              <Button onClick={() => remove(index)}>-</Button>
                            </div>
                          ))}
                          <Button
                            variant="primary"
                            onClick={() =>
                              push({ fieldName: "", fieldType: "" })
                            }
                          >
                            +
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Field</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              certificate_type: editCertificateTypeId, // For selecting the certificate
              field_label: editFieldName, // Single field label to edit
            }}
            onSubmit={handleUpdateField}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  {/* Certificate Name Select */}
                  <div className="form-group">
                    <label>Certificate Name</label>
                    <Field
                      as="select"
                      name="certificate_type"
                      className="form-control"
                    >
                      <option value="">Select Certificate</option>
                      {certificateTypes.map((certificate) => (
                        <option key={certificate.id} value={certificate.id}>
                          {certificate.certificate_type}
                        </option>
                      ))}
                    </Field>
                  </div>

                  {/* Field Label Input */}
                  <div className="form-group">
                    <label>Field Label</label>
                    <Field
                      name="field_label"
                      className="form-control"
                      placeholder="Enter field label"
                    />
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default CertificateFieldList;
