const EXPIRATION_TIME = 24 * 60 * 1000;

export const setSession = (key, token) => {
  const item = {
    value: token,
    expiry: new Date().getTime() + EXPIRATION_TIME,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
};

export const getSession = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date().getTime();
  if (now > item.expiry) {
    sessionStorage.removeItem(key);
    return undefined;
  }
  return item.value; 
};

export const removeSession = (key) => {
  sessionStorage.removeItem(key);
};

export const removeAllSession = () => {
  sessionStorage.clear()
}
