import React, { useState, useEffect } from "react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import { getAllId } from "../../Services/AdminApis/studentIdCard";
import DragNdrop from "../CommonComponents/DragandDrop";

const SchoolBranchSettings = ({ current, steps }) => {
  const { values, setFieldValue } = useFormikContext();
  const REACT_APP_IMAGEURL = process.env.REACT_APP_IMAGEURL
  const [activeTab, setActiveTab] = useState("reportCard");
  const [selectedReportCard, setSelectedReportCard] = useState(false);
  const [selectedIdCard, setSelectedIdCard] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(false);
  const [idCardTemplates, setIdCardTemplates] = useState([]);
  const [reportCardTemplates, setReportCardTemplates] = useState([]);
  const [receiptTemplates, setReceiptTemplates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [showAdditionalCheckboxes, setShowAdditionalCheckboxes] =
    useState(false);
  const [showPrincipalSignature, setShowPrincipalSignature] = useState(false);
  const [showStudentPhotos, setShowStudentPhotos] = useState(false);
  const [fileStates, setFileStates] = useState({
    print_file: values.print_file || null,
    report_card: values.report_card || null,
    logo_file: values.logo_file || null,
    text_logo: values.text_logo || null,
    id_card_template: values.id_card_template || null,
    report_card_template: values.report_card_template || null,
    receipt_template: values.receipt_template || null,
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const idCardData = await getAllId("student");
        setIdCardTemplates(
          idCardData.data.map((item) => ({
            id: item.id,
            name: item.name,
            image: `/${item.file_path}`,
          }))
        );

        const reportCardData = await getAllId("report");
        setReportCardTemplates(
          reportCardData.data.map((item) => ({
            id: item.id,
            name: item.name,
            image: `/${item.file_path}`,
          }))
        );

        const receiptData = await getAllId("reciept");
        setReceiptTemplates(
          receiptData.data.map((item) => ({
            id: item.id,
            name: item.name,
            image: `/${item.file_path}`,
          }))
        );
      } catch (error) {
        setError("Failed to load templates. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    setFileStates({
      print_file: values.print_file,
      report_card: values.report_card,
      logo_file: values.logo_file,
      text_logo: values.text_logo,
      id_card_template: values.id_card_template,
      receipt_template: values.receipt_template,
      report_card_template: values.report_card_template,
    });
  }, [values]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setModalOpen(false);
  };

  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;
    if (name === "principalSignature") {
      setShowPrincipalSignature(checked);
    } else if (name === "studentPhotos") {
      setShowStudentPhotos(checked);
    } else {
      switch (activeTab) {
        case "reportCard":
          if (checked) {
            setShowAdditionalCheckboxes(true);
          }
          setSelectedReportCard(checked);
          break;
        case "idCard":
          setSelectedIdCard(checked);
          break;
        case "receipt":
          setSelectedReceipt(checked);
          break;
        default:
          break;
      }
    }
  };

  const handleTemplateSelect = (event) => {
    const templateId = parseInt(event.target.value, 10);
    const templates = {
      reportCard: reportCardTemplates,
      idCard: idCardTemplates,
      receipt: receiptTemplates,
    };
    const template = templates[activeTab].find((t) => t.id === templateId);
    if (!template) return;

    switch (activeTab) {
      case "reportCard":
        setSelectedReportCard(template);
        setFileStates((prevState) => ({
          ...prevState,
          report_card_template: template.id,
        }));
        setFieldValue("report_card_template", template.id);
        break;
      case "idCard":
        setSelectedIdCard(template);
        setFileStates((prevState) => ({
          ...prevState,
          id_card_template: template.id,
        }));
        setFieldValue("id_card_template", template.id);
        break;
      case "receipt":
        setSelectedReceipt(template);
        setFileStates((prevState) => ({
          ...prevState,
          receipt_template: template.id,
        }));
        setFieldValue("receipt_template", template.id);
        break;
      default:
        break;
    }
  };

  const handleViewButtonClick = () => {
    setModalOpen(true);
    setZoomLevel(1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleZoom = (event) => {
    event.preventDefault();
    const zoomDirection = event.deltaY < 0 ? 0.1 : -0.1;
    setZoomLevel((prevZoom) =>
      Math.min(Math.max(prevZoom + zoomDirection, 1), 3)
    );
  };

  const next = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const back = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFileChange = (fieldName, file) => {
    if (file) {
      setFileStates((prevState) => ({ ...prevState, [fieldName]: file }));
      setFieldValue(fieldName, file);
    }
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      <fieldset>
        <div className="form-card">
          <div className="row">
            <div className="col-7">
              <h2 className="fs-title mt-3 ml-3">School & Branch Settings:</h2>
            </div>
            <div className="col-5">
              <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
            </div>
          </div>

          <div className="ml-4 mr-4 mt-3">
            <div
              className="form-group col-12"
              style={{ border: "1px solid skyblue" }}
            >
              <nav className="nav nav-pills d-flex justify-content-between mt-2">
                {["reportCard", "idCard", "receipt"].map((tab) => (
                  <a
                    key={tab}
                    className={`nav-link tab-pills flex-fill text-center ${
                      activeTab === tab ? "active" : ""
                    }`}
                    onClick={() => handleTabChange(tab)}
                    style={{ cursor: "pointer" }}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)} Template
                  </a>
                ))}
              </nav>

              {[
                {
                  key: "reportCard",
                  title: "Report Card",
                  templates: reportCardTemplates,
                  value: values.report_card_template,
                  setName: "report_card_template",
                  selectName: "report_card_select",
                  selectedTemplate: selectedReportCard,
                },
                {
                  key: "idCard",
                  title: "ID Card",
                  templates: idCardTemplates,
                  value: values.id_card_template,
                  setName: "id_card_template",
                  selectName: "id_card_select",
                  selectedTemplate: selectedIdCard,
                },
                {
                  key: "receipt",
                  title: "Receipt",
                  templates: receiptTemplates,
                  value: values.receipt_template,
                  setName: "receipt_template",
                  selectName: "receipt_select",
                  selectedTemplate: selectedReceipt,
                },
              ].map(
                ({
                  key,
                  title,
                  templates,
                  value,
                  setName,
                  selectName,
                  selectedTemplate,
                }) =>
                  activeTab === key ? (
                    <div className="tab-content" key={key}>
                      <div className="form-group d-flex col-6 mt-3">
                        <Field
                          type="checkbox"
                          className="form-control mt-3 ml-0"
                          id={setName}
                          name={setName}
                          style={{ width: "20px", height: "20px" }}
                          checked={
                            activeTab === "reportCard"
                              ? selectedReportCard
                              : activeTab === "idCard"
                              ? selectedIdCard
                              : activeTab === "receipt"
                              ? selectedReceipt
                              : false
                          }
                          onChange={handleCheckboxChange}
                        />
                        <label className="mt-3 ml-1" htmlFor={setName}>
                          Enable {title} Template
                        </label>
                      </div>

                      {(activeTab === "reportCard" && selectedReportCard) ||
                      (activeTab === "idCard" && selectedIdCard) ||
                      (activeTab === "receipt" && selectedReceipt) ? (
                        <div className="form-group d-flex flex-column col-6 mt-3">
                          <label htmlFor={selectName}>
                            Select {title} <span className="mandatory">*</span>
                          </label>
                          <div className="d-flex align-items-start">
                            <select
                              className="form-control mt-2"
                              id={selectName}
                              name={selectName}
                              onChange={handleTemplateSelect}
                              value={
                                selectedTemplate ? selectedTemplate.id : ""
                              }
                              style={{
                                borderColor: "skyblue",
                                marginRight: "10px",
                                flexShrink: 0,
                              }}
                            >
                              <option value="">Select Template</option>
                              {templates.map((template) => (
                                <option key={template.id} value={template.id}>
                                  {template.name}
                                </option>
                              ))}
                            </select>
                            {selectedTemplate && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "20px",
                                }}
                              >
                                <img
                                  src={`${REACT_APP_IMAGEURL}/${selectedTemplate.image}`}
                                  alt={selectedTemplate.name}
                                  style={{
                                    maxWidth: "300px",
                                    maxHeight: "200px",
                                    objectFit: "contain",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleViewButtonClick}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {activeTab === "reportCard" && selectedReportCard && (
                        <div className="form-group d-flex flex-column mt-3">
                          <div className="d-flex align-items-center">
                            <Field
                              type="checkbox"
                              id="principalSignature"
                              name="principalSignature"
                              checked={showPrincipalSignature}
                              onChange={handleCheckboxChange}
                              style={{ marginRight: "8px" }}
                            />
                            <label
                              htmlFor="principalSignature"
                              className="mb-0"
                            >
                              Include Principal Signature
                            </label>
                          </div>

                          <div className="d-flex align-items-center mt-2">
                            <Field
                              type="checkbox"
                              id="studentPhotos"
                              name="studentPhotos"
                              checked={showStudentPhotos}
                              onChange={handleCheckboxChange}
                              style={{ marginRight: "8px" }}
                            />
                            <label htmlFor="studentPhotos" className="mb-0">
                              Include Student Photos
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null
              )}
            </div>
          </div>
        </div>
      </fieldset>

      {modalOpen && (
        <div className="modal-overlaynew" onClick={handleCloseModal}>
          <div
            className="modal-contentnew"
            onClick={(e) => e.stopPropagation()}
            onWheel={handleZoom}
          >
            <img
              src={`${REACT_APP_IMAGEURL}/${
                selectedReportCard?.image ||
                selectedIdCard?.image ||
                selectedReceipt?.image
              }`}
              alt={
                selectedReportCard?.name ||
                selectedIdCard?.name ||
                selectedReceipt?.name
              }
              style={{
                transform: `scale(${zoomLevel})`,
              }}
            />
          </div>
        </div>
      )}
      {/* New MultiStepForm Section */}
      <div className="card-header">
        <nav className="nav nav-pills nav-fill">
          <a
            className={`nav-link tab-pills ${
              currentStep === 0 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(0)}
            style={{ cursor: "pointer" }}
          >
            School Logo
          </a>
          <a
            className={`nav-link tab-pills ${
              currentStep === 1 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(1)}
            style={{ cursor: "pointer" }}
          >
            Report Card Logo
          </a>
          <a
            className={`nav-link tab-pills ${
              currentStep === 2 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(2)}
            style={{ cursor: "pointer" }}
          >
            Text Logo
          </a>
          <a
            className={`nav-link tab-pills ${
              currentStep === 3 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(3)}
            style={{ cursor: "pointer" }}
          >
            Printing Logo
          </a>
        </nav>
      </div>
      <div className="card-body" style={{ height: "300px" }}>
        {currentStep === 0 && (
          <div className="tab">
            <div className="mb-3">
              <label htmlFor="schoolLogo" className="form-label">
                School Logo
              </label>
              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="print_file"
                file={fileStates.print_file}
                onFileChange={handleFileChange}
              />
              <ErrorMessage
                name="print_file"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        )}
        {currentStep === 1 && (
          <div className="tab">
            <div className="mb-3">
              <label htmlFor="reportCardLogo" className="form-label">
                Report Card Logo
              </label>
              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="logo_file"
                file={fileStates.logo_file}
                onFileChange={handleFileChange}
              />
              <ErrorMessage
                name="logo_file"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div className="tab">
            <div className="mb-3">
              <label htmlFor="textLogo" className="form-label">
                Text Logo
              </label>
              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="report_card"
                file={fileStates.report_card}
                onFileChange={handleFileChange}
                style={{ margin: "0 auto" }}
              />
              <ErrorMessage
                name="report_card"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div className="tab">
            <div className="mb-3">
              <label htmlFor="printingLogo" className="form-label">
                Printing Logo
              </label>
              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="text_logo"
                file={fileStates.text_logo}
                onFileChange={handleFileChange}
              />
              <ErrorMessage
                name="text_logo"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        )}
      </div>
      <div className="card-footer d-flex justify-content-between mb-2">
        <div className="w-100 d-flex">
          {currentStep > 0 && (
            <button
              type="button"
              id="back_button"
              className="btn btn-secondary me-auto"
              onClick={back}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
          )}
        </div>
        <div className="w-100 d-flex justify-content-end">
          <button type="button" onClick={next} className="btn btn-primary">
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchoolBranchSettings;
