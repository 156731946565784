import React, { useEffect, useState, useRef, useCallback } from "react";
import { Field, Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { getAllExams } from "../../Services/AdminApis/ExamApi";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getBranchSubjects } from "../../Services/CommonApis/subjectApi";
import { useParams } from "react-router-dom";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { Dropdown } from "primereact/dropdown";
import { getExamConfigs } from "../../Services/CommonApis/ReportcardApi";
import { EnterMarks } from "../../Services/CommonApis/ReportcardApi";
import { getAllRemarks } from "../../Services/CommonApis/RemarkApi";
import Select from "react-select";
import { getAllPersonalityTraits } from "../../Services/AdminApis/PersonalityTraitsApi";
import { getStudentAttendance } from "../../Services/CommonApis/ParentPortalApi.js";

import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
} from "../../Modals/SweetAlertModel";
import "../../Styles/SchoolCreate.css";
import { components } from "react-select";
import MarksKeyNavigationHandler from "../../Pages/CommonPages/MarksNavigation.js";

function SubjectWiseMarksEntry() {
  const { branchId } = useParams();
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = 6;
  const id = 1;
  const [exams, setExams] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [entryType, setEntryType] = useState("");
  const [sections, setSections] = useState([]);
  const setFieldValueRef = useRef(null);
  const [navigationMode, setNavigationMode] = useState("horizontal");
  const [students, setStudents] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [traits, setTraits] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [isSubjectSelected, setIsSubjectSelected] = useState(false);
  const [examConfig, setExamConfig] = useState([]);
  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);
  useEffect(() => {
    fetchRemarks();
    fetchPersonalityTraits(branchId);
    fetchExams();
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);

  const fetchExams = async () => {
    try {
      const response = await getAllExams();
      setExams(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRemarks = async () => {
    try {
      const response = await getAllRemarks();
      setRemarks(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPersonalityTraits = async (branchId) => {
    try {
      const response = await getAllPersonalityTraits(branchId);
      setTraits(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStudentAttendance = async (branchId, studentId, index) => {
    try {
      const response = await getStudentAttendance({
        branch_id: branchId,
        student_id: studentId,
      });
      const { totalabsent, totalpresent } = response.data.data;
      setFieldValueRef.current(`students[${index}].presentDays`, totalpresent);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllStudentsAttendance = async (branchId) => {
    try {
      const attendancePromises = students.map((student, index) =>
        fetchStudentAttendance(branchId, student.id, index)
      );
      await Promise.all(attendancePromises);
    } catch (error) {
      console.error("Error fetching student attendance: ", error);
    }
  };

  useEffect(() => {
    if (students.length > 0) {
      fetchAllStudentsAttendance(branchId);
    }
  }, [students]);

  const handleMarksSubmission = async (values, { resetForm }) => {
    const data = {
      branch_id: branchId,
      students: values.students.map((student, index) => {
        const studentOptionId = studentOptions[index].id;
        const marksData = {};

        // Entry Type: Subjectwise
        if (values.entry_type === "0") {
          marksData.class_info = {
            class_id: values.class_id,
            section_id: values.section_id,
            academic_id: 1,
            entry_type: "subjectwise",
            exam_id: values.exam_id,
            present_days: student.presentDays || 0,
          };

          marksData.remarks = {
            personality_trait: student.traitname || "",
            class_teacher_remarks: student.classTeacherRemark || "",
            principal_remarks: student.principalRemark || "",
            promoted_to: {
              id: student.promotedToClass || "",
              name:
                classes.find((cls) => cls.id == student.promotedToClass)
                  ?.name || "",
            },
            detained_to: {
              id: student.detainedToClass || "",
              name:
                classes.find((cls) => cls.id === student.detainedToClass)
                  ?.name || "",
            },
            result: student.result || "",
          };

          marksData.marks = [
            {
              subject_id: values.subject_id, // Ensure `subject_id` is correctly referenced
              isabsent: student.is_absent ? 1 : 0,
              is_absent_reason: student.is_absent
                ? student.is_absent_reason || ""
                : "",
              internal: student.internal || 0,
              external: student.external || 0,
            },
          ];

          // Entry Type: Classwise
        } else if (values.entry_type === "1") {
          marksData.class_info = {
            class_id: values.class_id,
            section_id: values.section_id,
            academic_id: 1,
            entry_type: "classwise",
            exam_id: values.exam_id,
            present_days: student.presentDays || 0,
          };

          marksData.remarks = {
            personality_trait: student.traitname || "",
            class_teacher_remarks: student.classTeacherRemark || "",
            principal_remarks: student.principalRemark || "",
            promoted_to: {
              id: student.promotedToClass || "",
              name:
                classes.find((cls) => cls.id === student.promotedToClass)
                  ?.name || "",
            },
            detained_to: {
              id: student.detainedToClass || "",
              name:
                classes.find((cls) => cls.id === student.detainedToClass)
                  ?.name || "",
            },
            result: student.result || "",
          };

          marksData.marks = subjects.map((subject) => {
            const marksEntry = student.marks[subject.subject_id] || {};
            return {
              subject_id: subject.subject_id,
              isabsent: marksEntry.is_absent ? 1 : 0,
              is_absent_reason: marksEntry.is_absent
                ? marksEntry.is_absent_reason
                : "",
              internal: marksEntry.internal || 0,
              external: marksEntry.external || 0,
            };
          });
        }

        return {
          student_id: studentOptionId,
          marks_data: marksData,
        };
      }),
    };

    await CreateMarks(data);
    resetForm(); // Assuming resetForm should be called directly after submission
  };

  const CreateMarks = async (data, resetForm) => {
    showLoadingAlert();
    try {
      const response = await EnterMarks(data);
      showSuccessAlert("Success");
      // window.location.reload();
      resetForm();
      setClasses([]);
      setSections([]);
      setExams([]);
      setRemarks([]);
      setEntryType("");
      setStudents([]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExamConfigs = async (exam_id, class_id, section_id) => {
    try {
      const response = await getExamConfigs(exam_id, class_id, section_id);
      const examData = response.data.data.map((config) => ({
        subject_id: config.subject_id,
        subject_name: config.subject_name,
        is_grade: config.is_grade,
        max_marks: config.max_marks,
        pass_marks: config.pass_marks,
      }));
      setExamConfig(examData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSections = async (branchId, classId) => {
    try {
      const response = await getSectionByClassId(branchId, classId);
      setSections(response.data.sections);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubjects = async (branchId, classId, sectionId) => {
    try {
      const response = await getBranchSubjects({
        branch_id: branchId,
        class_id: classId,
        section_id: sectionId,
      });
      setSubjects(response.data.branchsubjects);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStudents = async (branchId, classId, sectionId) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );

      setStudents(
        response.data.data.map((student) => ({
          id: student.id,
          first_name: student.first_name,
          roll_no: student.roll_no,
          admission_no: student.admission_no,
          marks: {},
        }))
      );

      setStudentOptions(
        response.data.data.map((student) => ({
          id: student.id,
          first_name: student.first_name,
          roll_no: student.roll_no,
          admission_no: student.admission_no,
          marks: {},
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const entryTypeList = [
    { id: "0", name: "Subject wise" },
    { id: "1", name: "Class wise" },
  ];

  const subjectOptions = subjects.map((subject) => ({
    value: subject.subject_id,
    label: subject.subject_name,
  }));

  const initialValues = {
    exam_id: "",
    class_id: "",
    section_id: "",
    subject_id: "",
    entry_type: "",
    students: [],
  };

  const validationSchema = Yup.object({
    exam_id: Yup.string().required("Exam is required"),
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
  });

  const CustomMenuList = (props) => {
    const { options, selectProps, setFieldValue, index, values } = props;

    const isAllSelected =
      values.students[index]?.selected_subject?.length === options.length;

    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        const allSubjectValues = options.map((option) => option.value);

        setFieldValue(`students[${index}].selected_subject`, allSubjectValues);

        const allMarks = allSubjectValues.reduce((acc, subjectId) => {
          acc[subjectId] = {
            is_absent: true,
            is_absent_reason: "AB",
          };
          return acc;
        }, {});

        setFieldValue(`students[${index}].marks`, {
          ...values.students[index]?.marks,
          ...allMarks,
        });
      } else {
        setFieldValue(`students[${index}].selected_subject`, []);

        setFieldValue(`students[${index}].marks`, {});
      }
    };

    return (
      <components.MenuList {...props}>
        <div
          style={{
            padding: "5px",
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <p className="ml-2 mt-2">Select All</p>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const CustomOption = (props) => {
    const { isSelected, label, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          cursor: "pointer",
          backgroundColor: isSelected ? "lightgray" : "white",
          margin: "0",
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          style={{ marginRight: "10px" }}
        />
        <p style={{ marginLeft: "5px", marginTop: "5px" }}>{label}</p>
      </div>
    );
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Marks Entry</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Marks Entry</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="card m-3 p-3">
        <div className="head-card">
          <div className="row ">
            <h3>Marks Entry</h3>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleMarksSubmission(values);
            }}
          >
            {({ setFieldValue, values, handleSubmit, resetForm }) => {
              setFieldValueRef.current = setFieldValue;
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleMarksSubmission(values, { resetForm });
                  }}
                >
                  <div className="card-body">
                    <div className="row" style={{ flexWrap: "wrap" }}>
                      <div className="form-group col-4">
                        <label htmlFor="class_id">
                          Class Name <span className="mandatory">*</span>
                        </label>
                        <Field
                          as="select"
                          id="class_id"
                          name="class_id"
                          className="form-control"
                          onChange={(e) => {
                            const selectedClassId = e.target.value;
                            setFieldValue("class_id", selectedClassId);
                            setClassId(selectedClassId);
                            fetchSections(selectedClassId);

                            if (
                              values.exam_id &&
                              selectedClassId &&
                              sectionId
                            ) {
                              fetchExamConfigs(
                                values.exam_id,
                                selectedClassId,
                                sectionId
                              );
                            }
                          }}
                        >
                          <option value="">Select Class</option>
                          {classes.map((cls) => (
                            <option key={cls.id} value={cls.id}>
                              {cls.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="class_id"
                          component="div"
                          className="text-error"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label htmlFor="section_id">
                          Section Name <span className="mandatory">*</span>
                        </label>
                        <Field
                          as="select"
                          id="section_id"
                          name="section_id"
                          className="form-control"
                          onChange={(e) => {
                            const selectedSectionId = e.target.value;
                            setFieldValue("section_id", selectedSectionId);
                            setSectionId(selectedSectionId);

                            if (
                              values.exam_id &&
                              classId &&
                              selectedSectionId
                            ) {
                              fetchExamConfigs(
                                values.exam_id,
                                classId,
                                selectedSectionId
                              );
                            }
                          }}
                          disabled={!classId}
                        >
                          <option value="">Select Section</option>

                          {sections.map((section) => (
                            <option key={section.id} value={section.id}>
                              {section.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="section_id"
                          component="div"
                          className="text-error"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label htmlFor="exam_id">
                          Exam Name <span className="mandatory">*</span>
                        </label>
                        <Field
                          as="select"
                          id="exam_id"
                          name="exam_id"
                          className="form-control"
                          onChange={(e) => {
                            const selectedExamId = e.target.value;
                            setFieldValue("exam_id", selectedExamId);
                            if (selectedExamId && classId && sectionId) {
                              fetchExamConfigs(
                                selectedExamId,
                                classId,
                                sectionId
                              );
                            }
                          }}
                        >
                          <option value="">Select Exam</option>
                          {exams.map((exam) => (
                            <option key={exam.id} value={exam.id}>
                              {exam.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="exam_id"
                          component="div"
                          className="text-error"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label htmlFor="entry_type">
                          Entry Type <span className="mandatory">*</span>
                        </label>
                        <Field
                          as="select"
                          id="entry_type"
                          name="entry_type"
                          className="form-control"
                          onChange={(e) => {
                            const selectedEntryType = e.target.value;
                            setFieldValue("entry_type", selectedEntryType);
                            setEntryType(selectedEntryType);

                            if (selectedEntryType === "1") {
                              fetchSubjects(
                                branchId,
                                values.class_id,
                                sectionId
                              );
                              fetchStudents(
                                branchId,
                                values.class_id,
                                sectionId
                              );
                            } else if (selectedEntryType === "0") {
                              fetchSubjects(
                                branchId,
                                values.class_id,
                                sectionId
                              );
                            }
                          }}
                        >
                          <option value="">Select Entry Type</option>
                          {entryTypeList.map((entryType) => (
                            <option key={entryType.id} value={entryType.id}>
                              {entryType.name}
                            </option>
                          ))}
                        </Field>

                        <ErrorMessage
                          name="entry_type"
                          component="div"
                          className="text-error"
                        />
                      </div>
                      {values.entry_type === "0" && (
                        <div className="form-group col-4">
                          <label htmlFor="subject_id">
                            Subject Name <span className="mandatory">*</span>
                          </label>
                          <Field
                            as="select"
                            id="subject_id"
                            name="subject_id"
                            className="form-control"
                            onChange={(e) => {
                              const selectedSubjectId = e.target.value;
                              setFieldValue("subject_id", selectedSubjectId);
                              setSubjectId(selectedSubjectId);
                              setIsSubjectSelected(true);
                              fetchStudents(branchId, classId, sectionId);
                            }}
                            disabled={!sectionId}
                          >
                            <option value="">Select Subject</option>
                            {subjects.map((subject) => (
                              <option
                                key={subject.subject_id}
                                value={subject.subject_id}
                              >
                                {subject.subject_name} (100)
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="subject_id"
                            component="div"
                            className="text-error"
                          />
                        </div>
                      )}

                      <div className="form-group ml-5">
                        <label>Navigation Mode</label>
                        <div className="mt-2">
                          <label className="mr-2 ">
                            <input
                              className="mr-2"
                              type="radio"
                              name="navigationMode"
                              value="horizontal"
                              checked={navigationMode === "horizontal"}
                              onChange={(e) =>
                                setNavigationMode(e.target.value)
                              }
                            />
                            Horizontal
                          </label>
                          <label className="mr-2">
                            <input
                              className="mr-2"
                              type="radio"
                              name="navigationMode"
                              value="vertical"
                              checked={navigationMode === "vertical"}
                              onChange={(e) =>
                                setNavigationMode(e.target.value)
                              }
                            />
                            Vertical
                          </label>
                        </div>
                      </div>
                    </div>

                    <div id="msform1">
                      <ul id="progressbar">
                        <li
                          className={current >= 1 ? "active" : ""}
                          id="marks"
                          onClick={() => goToStep(1)}
                          style={{ width: "260px" }}
                        >
                          <strong> Marks Entry</strong>
                        </li>
                        <li
                          className={current >= 2 ? "active" : ""}
                          id="attendance"
                          onClick={() => goToStep(2)}
                          style={{ width: "260px" }}
                        >
                          <strong>Attendance</strong>
                        </li>

                        <li
                          className={current >= 3 ? "active" : ""}
                          id="remarks"
                          onClick={() => goToStep(3)}
                          style={{ width: "260px" }}
                        >
                          <strong>Remarks</strong>
                        </li>
                        <li
                          className={current >= 4 ? "active" : ""}
                          id="traits"
                          onClick={() => goToStep(4)}
                          style={{ width: "260px" }}
                        >
                          <strong>Personality Traits</strong>
                        </li>
                      </ul>
                      <div className="progress">
                        <div
                          ref={progressBarRef}
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <br />
                      {current === 1 && (
                        <div>
                          {isSubjectSelected && values.entry_type === "0" && (
                            <div className="mt-4">
                              <table className="table table-striped">
                                <thead style={{ backgroundColor: "skyblue" }}>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Student Name</th>
                                    <th>Roll No</th>
                                    <th>Admission No</th>
                                    <th>Is Absent?</th>
                                    <th>Marks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {students.map((student, index) => (
                                    <tr key={student.id}>
                                      <td>{index + 1}</td>
                                      <td>{student.first_name}</td>
                                      <td>{student.roll_no}</td>
                                      <td>{student.admission_no}</td>
                                      <td>
                                        <Field
                                          type="checkbox"
                                          name={`students[${index}].is_absent`}
                                          checked={
                                            values.students[index]?.is_absent
                                          }
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setFieldValue(
                                              `students[${index}].is_absent`,
                                              isChecked
                                            );
                                            if (isChecked) {
                                              setFieldValue(
                                                `students[${index}].internal`,
                                                "AB"
                                              );
                                              setFieldValue(
                                                `students[${index}].external`,
                                                "AB"
                                              );
                                            } else {
                                              setFieldValue(
                                                `students[${index}].internal`,
                                                ""
                                              );
                                              setFieldValue(
                                                `students[${index}].external`,
                                                ""
                                              );
                                            }
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {values.students[index]?.is_absent ? (
                                          <div className="row ml-5">
                                            <div className="mr-2 ml-2">
                                              <label>
                                                <Field
                                                  type="radio"
                                                  name={`students[${index}].marks`}
                                                  value="AB"
                                                  checked={
                                                    values.students[index]
                                                      ?.internal === "AB" ||
                                                    (!values.students[index]
                                                      ?.internal &&
                                                      values.students[index]
                                                        ?.is_absent)
                                                  }
                                                  onChange={() => {
                                                    setFieldValue(
                                                      `students[${index}].internal`,
                                                      "AB"
                                                    );
                                                    setFieldValue(
                                                      `students[${index}].external`,
                                                      "AB"
                                                    );
                                                  }}
                                                  className="ml-2 mr-2"
                                                />
                                                AB
                                              </label>
                                            </div>
                                            <div>
                                              <label>
                                                <Field
                                                  type="radio"
                                                  name={`students[${index}].marks`}
                                                  value="ML"
                                                  checked={
                                                    values.students[index]
                                                      ?.internal === "ML"
                                                  }
                                                  onChange={() => {
                                                    setFieldValue(
                                                      `students[${index}].internal`,
                                                      "ML"
                                                    );
                                                    setFieldValue(
                                                      `students[${index}].external`,
                                                      "ML"
                                                    );
                                                  }}
                                                  className="ml-2 mr-2"
                                                />
                                                ML
                                              </label>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            {/* Internal Marks Field */}
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  marginRight: "10px",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  Internal
                                                </label>
                                                {/* Internal Marks Field */}
                                                <Field
                                                  type="number"
                                                  name={`students[${index}].internal`}
                                                  className="form-control"
                                                  style={{
                                                    width: "70px",
                                                    height: "30px",
                                                    WebkitAppearance: "none",
                                                    MozAppearance: "textfield",
                                                  }} // Adjusted width and height
                                                  disabled={
                                                    values.students[index]
                                                      ?.is_absent
                                                  }
                                                  onKeyPress={(e) => {
                                                    if (
                                                      !/[0-9]/.test(e.key) &&
                                                      e.key !== "Backspace"
                                                    ) {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                                />
                                              </div>
                                                                                                  <div
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      <label
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        External
                                                      </label>

                                              {/* External Marks Field */}
                                              <Field
                                                type="number"
                                                name={`students[${index}].external`}
                                                className="form-control"
                                                style={{
                                                  width: "70px",
                                                  height: "30px",
                                                  WebkitAppearance: "none",
                                                  MozAppearance: "textfield",
                                                }} // Adjusted width and height
                                                disabled={
                                                  values.students[index]
                                                    ?.is_absent
                                                }
                                                onKeyPress={(e) => {
                                                  if (
                                                    !/[0-9]/.test(e.key) &&
                                                    e.key !== "Backspace"
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {values.entry_type === "1" && (
                            <div className="mt-4">
                              <table className="table table-striped">
                                <thead
                                  style={{
                                    backgroundColor: "skyblue",
                                    textAlign: "left",
                                  }}
                                >
                                  <tr>
                                    <th>S.No</th>
                                    <th>Student Name</th>
                                    <th>Is Absent</th>
                                    <th>Subjects</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {students.map((student, index) => (
                                    <tr key={student.id}>
                                      <td>{index + 1}</td>
                                      <td style={{ textAlign: "left" }}>
                                        <div>{student.first_name}</div>
                                        <div>
                                          <label>Roll No:</label>{" "}
                                          {student.roll_no}
                                        </div>
                                        <div>
                                          <label>Admission No:</label>{" "}
                                          {student.admission_no}
                                        </div>
                                      </td>

                                      {/* Is Absent Column */}
                                      <td
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          width: "200px",
                                          textAlign: "left",
                                        }}
                                      >
                                        <Select
                                          isMulti
                                          options={subjectOptions}
                                          value={subjectOptions.filter(
                                            (option) =>
                                              values.students[
                                                index
                                              ]?.selected_subject?.includes(
                                                option.value
                                              )
                                          )}
                                          onChange={(selectedOptions) => {
                                            const selectedValues =
                                              selectedOptions.map(
                                                (option) => option.value
                                              );
                                            setFieldValue(
                                              `students[${index}].selected_subject`,
                                              selectedValues
                                            );

                                            const newMarks =
                                              selectedValues.reduce(
                                                (acc, subjectId) => {
                                                  acc[subjectId] = {
                                                    is_absent: true,
                                                    is_absent_reason: "AB",
                                                  };
                                                  return acc;
                                                },
                                                {}
                                              );

                                            setFieldValue(
                                              `students[${index}].marks`,
                                              {
                                                ...values.students[index]
                                                  ?.marks,
                                                ...newMarks,
                                              }
                                            );
                                          }}
                                          placeholder="Select Subjects"
                                          components={{
                                            Option: CustomOption,
                                            MenuList: (props) => (
                                              <CustomMenuList
                                                {...props}
                                                setFieldValue={setFieldValue}
                                                index={index}
                                                values={values}
                                              />
                                            ),
                                          }}
                                        />
                                      </td>

                                      {/* Internal/External Marks Column */}
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            width: "600px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {subjects.map((subject, subIndex) => {
                                            const isSelected = values.students[
                                              index
                                            ]?.selected_subject?.includes(
                                              subject.subject_id
                                            );
                                            const isAbsent =
                                              values.students[index]?.marks?.[
                                                subject.subject_id
                                              ]?.is_absent;

                                            return (
                                              <div
                                                key={subIndex}
                                                className="col-4"
                                                style={{ marginBottom: "10px" }}
                                              >
                                                <label>
                                                  {subject.subject_name} (100)
                                                </label>

                                                {isSelected && isAbsent ? (
                                                  <div className="row">
                                                    <div className="mr-2 ml-2">
                                                      <input
                                                        type="radio"
                                                        name={`students[${index}].marks[${subject.subject_id}].is_absent_reason`}
                                                        value="AB"
                                                        checked={
                                                          values.students[index]
                                                            ?.marks?.[
                                                            subject.subject_id
                                                          ]
                                                            ?.is_absent_reason ===
                                                          "AB"
                                                        }
                                                        onChange={(e) =>
                                                          setFieldValue(
                                                            `students[${index}].marks[${subject.subject_id}].is_absent_reason`,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        AB
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        name={`students[${index}].marks[${subject.subject_id}].is_absent_reason`}
                                                        value="ML"
                                                        checked={
                                                          values.students[index]
                                                            ?.marks?.[
                                                            subject.subject_id
                                                          ]
                                                            ?.is_absent_reason ===
                                                          "ML"
                                                        }
                                                        onChange={(e) =>
                                                          setFieldValue(
                                                            `students[${index}].marks[${subject.subject_id}].is_absent_reason`,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        ML
                                                      </label>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      <label
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        Internal
                                                      </label>
                                                      <input
                                                        type="text"
                                                        value={
                                                          values.students[index]
                                                            ?.marks?.[
                                                            subject.subject_id
                                                          ]?.internal || ""
                                                        }
                                                        onChange={(e) =>
                                                          setFieldValue(
                                                            `students[${index}].marks[${subject.subject_id}].internal`,
                                                            e.target.value
                                                          )
                                                        }
                                                        className="form-control"
                                                        style={{
                                                          width: "70px",
                                                          height: "35px",
                                                        }}
                                                      />
                                                    </div>

                                                    <div
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      <label
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        External
                                                      </label>
                                                      <input
                                                        type="text"
                                                        value={
                                                          values.students[index]
                                                            ?.marks?.[
                                                            subject.subject_id
                                                          ]?.external || ""
                                                        }
                                                        onChange={(e) =>
                                                          setFieldValue(
                                                            `students[${index}].marks[${subject.subject_id}].external`,
                                                            e.target.value
                                                          )
                                                        }
                                                        className="form-control"
                                                        style={{
                                                          width: "70px",
                                                          height: "35px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}

                      {current === 3 && (
                        <div className="mt-4">
                          <table className="table table-striped">
                            <thead style={{ backgroundColor: "skyblue" }}>
                              <tr>
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Class Teacher Remark</th>
                                <th>Principal Remark</th>
                                <th>Promoted to</th>
                                <th>Detained to</th>
                                <th>Result</th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((student, index) => (
                                <tr key={student.id}>
                                  <td>{index + 1}</td>
                                  <td style={{ textAlign: "left" }}>
                                    <div>{student.first_name}</div>
                                    <div>
                                      <label>Roll No:</label> {student.roll_no}
                                    </div>
                                    <div>
                                      <label>Admission No:</label>{" "}
                                      {student.admission_no}
                                    </div>
                                  </td>

                                  {/* Remark Type */}
                                  {/* Class Teacher Remarks Column */}
                                  <td>
                                    <select
                                      className="form-control"
                                      value={
                                        values.students[index]
                                          ?.classTeacherRemark || ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `students[${index}].classTeacherRemark`,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        Select Class Teacher Remark
                                      </option>
                                      {remarks
                                        .filter(
                                          (remark) =>
                                            remark.remarks_by ===
                                            "Class Teacher"
                                        )
                                        .map((remark) => (
                                          <option
                                            key={remark.id}
                                            value={remark.name}
                                          >
                                            {remark.name}
                                          </option>
                                        ))}
                                    </select>
                                  </td>

                                  {/* Principal Remarks Column */}
                                  <td>
                                    <select
                                      className="form-control"
                                      value={
                                        values.students[index]
                                          ?.principalRemark || ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `students[${index}].principalRemark`,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        Select Principal Remark
                                      </option>
                                      {remarks
                                        .filter(
                                          (remark) =>
                                            remark.remarks_by === "Principle"
                                        )
                                        .map((remark) => (
                                          <option
                                            key={remark.id}
                                            value={remark.name}
                                          >
                                            {remark.name}
                                          </option>
                                        ))}
                                    </select>
                                  </td>

                                  {/* Promoted To */}
                                  <td>
                                    <div className="form-group">
                                      <Field
                                        as="select"
                                        name={`students[${index}].promotedToClass`}
                                        className="form-control"
                                        value={
                                          values.students[index]
                                            ?.promotedToClass || ""
                                        }
                                        onChange={(e) => {
                                          const selectedClassId =
                                            e.target.value;
                                          setFieldValue(
                                            `students[${index}].promotedToClass`,
                                            selectedClassId
                                          );
                                          setClassId(selectedClassId);
                                          fetchSections(selectedClassId);

                                          // Fetch exam configurations if required
                                          if (
                                            values.exam_id &&
                                            selectedClassId &&
                                            sectionId
                                          ) {
                                            fetchExamConfigs(
                                              values.exam_id,
                                              selectedClassId,
                                              sectionId
                                            );
                                          }
                                        }}
                                      >
                                        <option value="">Select Class</option>
                                        {classes.map((cls) => (
                                          <option key={cls.id} value={cls.id}>
                                            {cls.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </td>

                                  {/* Detained To */}
                                  <td>
                                    <div className="form-group">
                                      <Field
                                        as="select"
                                        name={`students[${index}].detainedToClass`}
                                        className="form-control"
                                        value={
                                          values.students[index]
                                            ?.detainedToClass || ""
                                        }
                                        onChange={(e) => {
                                          const selectedClassId =
                                            e.target.value;
                                          setFieldValue(
                                            `students[${index}].detainedToClass`,
                                            selectedClassId
                                          );
                                          setClassId(selectedClassId);
                                          fetchSections(selectedClassId);

                                          // Fetch exam configurations if required
                                          if (
                                            values.exam_id &&
                                            selectedClassId &&
                                            sectionId
                                          ) {
                                            fetchExamConfigs(
                                              values.exam_id,
                                              selectedClassId,
                                              sectionId
                                            );
                                          }
                                        }}
                                      >
                                        <option value="">Select Class</option>
                                        {classes.map((cls) => (
                                          <option key={cls.id} value={cls.id}>
                                            {cls.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </td>

                                  {/* Result */}
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`students[${index}].result`}
                                      style={{ width: "150px" }}
                                      value={
                                        values.students[index]?.result || ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `students[${index}].result`,
                                          e.target.value
                                        )
                                      }
                                    ></input>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                      {current === 4 && (
                        <div className="mt-4">
                          <table className="table table-striped">
                            <thead style={{ backgroundColor: "skyblue" }}>
                              <tr>
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Roll No</th>
                                <th>Admission No</th>
                                <th>Select Personality Trait</th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((student, index) => (
                                <tr key={student.id}>
                                  <td>{index + 1}</td>
                                  <td>{student.first_name}</td>
                                  <td>{student.roll_no}</td>
                                  <td>{student.admission_no}</td>

                                  <td>
                                    <select
                                      className="form-control "
                                      value={
                                        values.students[index]?.traitname || ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `students[${index}].traitname`,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        Select Personality Trait
                                      </option>
                                      {traits.map((trait) => (
                                        <option
                                          key={trait.id}
                                          value={trait.name}
                                        >
                                          {trait.name}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <button
                            type="submit"
                            className="btn btn-primary ml-3 mb-2 float-left"
                          >
                            Submit
                          </button>
                        </div>
                      )}
                      {current === 2 && (
                        <div className="mt-4">
                          <table className="table table-striped">
                            <thead style={{ backgroundColor: "skyblue" }}>
                              <tr>
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Roll No</th>
                                <th>Admission No</th>
                                <th>Total Present Days</th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((student, index) => (
                                <tr key={student.id}>
                                  <td>{index + 1}</td>
                                  <td>{student.first_name}</td>
                                  <td>{student.roll_no}</td>
                                  <td>{student.admission_no}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={
                                        values.students[index]?.presentDays ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `students[${index}].presentDays`,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        WebkitAppearance: "none",
                                        MozAppearance: "textfield",
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !/[0-9]/.test(e.key) &&
                                          e.key !== "Backspace"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder="Present Days"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="form-group col-3">
                            <label className="mr-5">Total Working days:</label>
                            <Field
                              type="text"
                              name="name"
                              className="form-control ml-4"
                              placeholder="Enter Total Working days"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
export default SubjectWiseMarksEntry;
