import React from "react";
import AdmissionEnquiry from "./Admissionenquiry";
import Announcement from "../CommonPages/Annoucement";
import SelectionProcess from "./SelectionProcess";
import Admission from "../CommonPages/AdmissionForm";
import Schedule from "../CommonPages/Schedule";
import Enroll from "./Enroll";
function AdmissionManagement() {
  return (
    <>
      {/* <section class="content"> */}
      {/* <div class="container-fluid"> */}
      {/* <div class="row"> */}
      {/* <div class="col-md-12"> */}
      {/* <div class="card"> */}
      <div class="card-header p-2">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link" href="#announcements" data-toggle="tab">
              Announcements
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#enquiry" data-toggle="tab">
              Form Selling
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#schedule" data-toggle="tab">
              Schedule
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="#selection" data-toggle="tab">
              Selection
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#admissionform" data-toggle="tab">
              Admission Form
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#enroll" data-toggle="tab">
              Enroll
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div class="tab-pane" id="announcements">
            <Announcement />
          </div>
          <div class="active tab-pane" id="enquiry">
            <AdmissionEnquiry />
          </div>
          <div class="tab-pane" id="admissionform">
            <Admission />
          </div>
          <div class="tab-pane" id="schedule">
            <Schedule />
          </div>

          <div class="tab-pane" id="enroll">
            <Enroll/>
          </div>
          <div class="tab-pane" id="selection">
            <SelectionProcess />
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
    </>
  );
}
export default AdmissionManagement;
