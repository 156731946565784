import Api from "../Api";

export const createSection = (data, id) => {
  return Api.post(`/create-section`, data);
};
export const getAllSections = (params) => {
  return Api.get(`/getall-sections`, { params });
};
export const getAllClasses = (params) => {
  return Api.get(`/getall-classes`, { params });
};
export const getSectionById = (id) => {
  return Api.get(`/get-section/${id}`);
};
export const getClassById = (id) => {
  return Api.get(`/get-class/${id}`);
};

export const updateSectionById = (id, data) => {
  return Api.post(`/update-section/${id}`, data);
};
export const deleteSectionById = (id) => {
  return Api.delete(`/delete-section/${id}`);
};
export const getSectionByBranchId = (id) => {
  return Api.get(`/get-sectionbybranch/${id}`);
};

export const getSectionByClassId = (classId) => {
  return Api.get(`/get-sectionbyclass/${classId}`);
};
export const getsectionsbyclassids = (data) => {
  return Api.post(`/get-sectionbyclassids`, data);
};
