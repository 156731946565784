import React, { useEffect, useState } from "react";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllExams } from "../../Services/AdminApis/ExamApi";
import { useParams } from "react-router-dom";
import {
  generateOverallExcel,
  getExamMarks,
} from "../../Services/CommonApis/ReportcardApi";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { generateOverallPdf } from "../../Services/CommonApis/ReportcardApi";

function OverallReport() {
  const { branchId } = useParams();
  const [students, setStudents] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [ClassName, setClassName] = useState("");
  const [mediumName, setMediumName] = useState("");
  const [academicName, setacademicName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [exams, setExams] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchExams();
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);

  const fetchExams = async () => {
    try {
      const response = await getAllExams();
      setExams(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMarks = async (branchId, sectionId, classId, examId) => {
    try {
      const response = await getExamMarks(branchId, sectionId, classId, examId);
      setBranchName(response.data.data[0].branch_name);
      setClassName(response.data.data[0].marks_data.class_info.class_name);
      setSectionName(response.data.data[0].marks_data.class_info.section_name);
      setacademicName(
        response.data.data[0].marks_data.class_info.academic_year
      );
      setMediumName(response.data.data[0].marks_data.class_info.medium_name);

      setStudents(response.data.data);

      if (response.data.data.length > 0) {
        const subjectsList = response.data.data[0].marks_data.marks.map(
          (mark) => ({
            subject_id: mark.subject_id,
            subject_name: mark.subject_name,
          })
        );
        setSubjects(subjectsList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    class_id: "",
    exam_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    exam_id: Yup.string().required("Exam is required"),
  });

  const handleGeneratePdf = () => {
    generateOverallPdf(branchId, selectedClassId, sectionId, selectedExamId);
  };
  const handleGenerateExcel = () => {
    generateOverallExcel(branchId, selectedClassId, sectionId, selectedExamId);
  };

  return (
    <div className="p-3">
      <div className="card ">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Overall Marks Report
          </h3>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="card-body">
                <div className="row" style={{ flexWrap: "wrap" }}>
                  <div className="form-group col-4">
                    <label htmlFor="class_id">
                      Class Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="class_id"
                      name="class_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedClassId = e.target.value;
                        setFieldValue("class_id", selectedClassId);
                        setSelectedClassId(selectedClassId);
                        if (selectedClassId) {
                          fetchSections(selectedClassId);
                        }
                      }}
                    >
                      <option value="">Select Class</option>
                      {classes.map((cls) => (
                        <option key={cls.id} value={cls.id}>
                          {cls.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group col-4">
                    <label htmlFor="section_id">
                      Section Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="section_id"
                      name="section_id"
                      className="form-control"
                      value={sectionId || ""}
                      onChange={(e) => {
                        const selectedSectionId = e.target.value;
                        setFieldValue("section_id", selectedSectionId);
                        setSectionId(selectedSectionId);
                      }}
                      disabled={!selectedClassId}
                    >
                      <option value="">Select Section</option>
                      {sections.map((section) => (
                        <option key={section.id} value={section.id}>
                          {section.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="section_id"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group col-4">
                    <label htmlFor="exam_id">
                      Exam Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="exam_id"
                      name="exam_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedExamId = e.target.value;
                        setFieldValue("exam_id", selectedExamId);
                        setSelectedExamId(selectedExamId);
                        fetchMarks(
                          branchId,
                          selectedClassId,
                          sectionId,
                          selectedExamId
                        );
                      }}
                    >
                      <option value="">Select Exam</option>
                      {exams.map((exam) => (
                        <option key={exam.id} value={exam.id}>
                          {exam.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="exam_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {students.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="btn"
                style={{
                  backgroundColor: "#6f42c1",
                  color: "white",
                  marginRight: "10px",
                  marginLeft: "30px",
                  marginBottom: "30px",
                }}
                onClick={handleGeneratePdf}
              >
                Download Pdf
              </button>
              <button
                className="btn btn-success"
                style={{
                  marginRight: "10px",
                  marginBottom: "30px",
                }}
                onClick={handleGenerateExcel}
              >
                Download Excel
              </button>
            </div>
            <div style={{ border: "1px solid skyblue", padding: "15px" }}>
              <h3 style={{ textAlign: "center" }}>{branchName}</h3>
              <div className="row">
                <div className="col-3 text-center row">
                  <p>Class:</p>
                  <p>{ClassName}</p>
                </div>
                <div className="col-3 text-center row">
                  <p>Section:</p>
                  <p>{sectionName}</p>
                </div>
                <div className="col-3 text-center row">
                  <p>Medium:</p> <p>{mediumName}</p>
                </div>
                <div className="col-3 text-center row">
                  <p>Academic year:</p> <p>{academicName}</p>
                </div>
              </div>

              <table
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid #ddd",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  style={{
                    backgroundColor: "skyblue",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        width: "5%",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      S.No
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Admission No
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Student Name
                    </th>
                    {subjects.map((subject) => (
                      <th
                        key={subject.subject_id}
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {subject.subject_name}
                      </th>
                    ))}
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Total Marks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Percentage
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Section Rank
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Class Rank
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Attendance
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, rowIndex) => (
                    <tr
                      key={student.id}
                      style={{
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {rowIndex + 1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.admission_no}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.student_name}
                      </td>
                      {subjects.map((subject) => {
                        const mark = student.marks_data.marks.find(
                          (m) => m.subject_id === subject.subject_id
                        );
                        const totalMarks = mark
                          ? parseInt(mark.internal) + parseInt(mark.external)
                          : "N/A";
                        return (
                          <td
                            key={subject.subject_id}
                            style={{
                              padding: "10px",
                              textAlign: "left",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {totalMarks}
                          </td>
                        );
                      })}
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        {student.marks_data.marks.reduce(
                          (sum, mark) =>
                            sum +
                            (mark.internal ? parseInt(mark.internal) : 0) +
                            (mark.external ? parseInt(mark.external) : 0),
                          0
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.marks_data.percentage}%
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.marks_data.section_rank}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.marks_data.class_rank}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.attendance.present_count}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "left",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {student.marks_data.result}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OverallReport;
