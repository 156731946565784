import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../Styles/SchoolCreate.css";
import * as Yup from "yup";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  getParentById,
  updateParentById,
} from "../../Services/CommonApis/parentApi";
import { getQualifications } from "../../Services/AdminApis/QualificationApi";
import { getAllOccupations } from "../../Services/AdminApis/occupationApi";

const ParentEdit = ({ parentId }) => {
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const [parentData, setParentData] = useState(null);
  const [qualificationData, setQualificationData] = useState([]);
  const [occupationData, setOccupationData] = useState([]);
  const [fileName, setFileName] = useState("Choose");
  const steps = 4;

  useEffect(() => {
    if (parentId) {
      fetchParent(parentId);
      fetchQualifications();
      fetchOccupations();
    }
  }, [parentId]);

  const fetchParent = async (id) => {
    try {
      const response = await getParentById(id);
      setParentData(response.data.parent);
    } catch (error) {
      console.error(error);
    }
  };

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  const handleSubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the parent profile",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await updateParentById(parentId, values);
        if (response.status === 200) {
          showSuccessAlert(
            "Updated!",
            "The parent information has updated succesfully!"
          );
          window.location.reload();
        }
      } catch (error) {
        console.error("API error:", error);
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during parent creation.";
        showErrorAlert("Error!", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Parent creation has been cancelled.");
    }
  };

  const fetchQualifications = async () => {
    try {
      const response = await getQualifications();
      setQualificationData(response.data.qualifications);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOccupations = async () => {
    try {
      const response = await getAllOccupations();
      setOccupationData(response.data.occupations);
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = parentData || {
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    alt_phone: "",
    alt_email: "",
    education: "",
    occupation: "",
    annual_income: "",
    mother_name: "",
    mother_phone: "",
    mother_email: "",
    mother_dob: "",
    mother_education: "",
    mother_occupation: "",
    mother_annual_income: "",
    mother_aadhaar_no: "",
    mother_pan_card: "",
    date_of_birth: "",
    gender: "",
    blood_group: "",
    religion: "",
    cast: "",
    image: "",
    mother_tongue: "",
    aadhaar_card_no: "",
    pan_card_no: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    tmp_address: "",
    temp_city: "",
    temp_state: "",
    temp_pin: "",
    temp_country: "",
  };

  const parentValidation = Yup.object({
    date_of_birth: Yup.string().required("Date of birth is required"),
    gender: Yup.string().required("Gender is required"),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileFormat",
        "Only jpeg, png, jpg formats are allowed",
        (value) =>
          value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
      ),
    aadhaar_card_no: Yup.string()
      .required("Aadhaar card number is required")
      .max(12, "Aadhaar card number cannot exceed 12 characters"),
    pan_card_no: Yup.string()
      .required("PAN card number is required")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN card number")
      .length(10, "PAN card number must be exactly 10 characters"),
    address: Yup.string()
      .required("Address is required")
      .max(255, "Address cannot exceed 255 characters"),
    city: Yup.string()
      .required("City is required")
      .max(255, "City cannot exceed 255 characters"),
    state: Yup.string()
      .required("State is required")
      .max(255, "State cannot exceed 255 characters"),
    country: Yup.string()
      .required("Country is required")
      .max(255, "Country cannot exceed 255 characters"),
    pin: Yup.string()
      .required("Pin is required")
      .matches(/^\d{6}$/, "Pin must be 6 digits"),
    first_name: Yup.string()
      .required("First Name is required")
      .max(255, "First name cannot exceed 255 characters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .max(255, "Last name cannot exceed 255 characters"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    alt_phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    alt_email: Yup.string()
      .required("Alternate email is required")
      .email("Must be a valid email")
      .max(100, "Alternate email cannot exceed 100 characters"),
    education: Yup.string().required("Education is required"),
    occupation: Yup.string().required("Occupation is required"),
    mother_email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid Gmail address"
      )
      .required("Email is required"),
    mother_education: Yup.string().required("Mother's education is required"),
    mother_occupation: Yup.string().required("Mother's occupation is required"),
    mother_annual_income: Yup.string()
      .required("Mother's annual income is required")
      .max(255, "Mother's annual income cannot exceed 255 characters"),
    mother_aadhaar_no: Yup.string()
      .required("Mother's Aadhaar number is required")
      .max(12, "Mother's Aadhaar number cannot exceed 12 characters"),
    mother_pan_card: Yup.string()
      .required("Mother's PAN card is required")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN card number")
      .length(10, "Mother's PAN card number must be exactly 10 characters"),
    mother_dob: Yup.string().required("Mother's date of birth is required"),
  });

  return (
    <>
      <div className="card">
        <div className="head-card">
          <div className="row ml-4">
            <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
              <Formik
                initialValues={initialValues}
                validationSchema={parentValidation}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form id="msform">
                    <ul id="progressbar">
                      <li
                        className={current >= 1 ? "active" : ""}
                        id="additionalInfo"
                        onClick={() => goToStep(1)}
                        style={{ width: "264px" }}
                      >
                        <strong>Personal Information</strong>
                      </li>

                      <li
                        className={current >= 2 ? "active" : ""}
                        id="EducationInfo"
                        onClick={() => goToStep(2)}
                        style={{ width: "264px" }}
                      >
                        <strong>Education and Occupation</strong>
                      </li>
                      <li
                        className={current >= 3 ? "active" : ""}
                        id="contactInfo"
                        onClick={() => goToStep(3)}
                        style={{ width: "264px" }}
                      >
                        <strong>Contact Information</strong>
                      </li>
                      <li
                        className={current >= 4 ? "active" : ""}
                        id="personalInfo"
                        onClick={() => goToStep(4)}
                        style={{ width: "264px" }}
                      >
                        <strong>Additional Information</strong>
                      </li>
                    </ul>
                    <div className="progress">
                      <div
                        ref={progressBarRef}
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <br />
                    {current === 1 && (
                      <div
                        className="form-step"
                        style={{
                          border: "2px solid #007bff",
                          borderRadius: "8px",
                        }}
                      >
                        <fieldset>
                          <div className="form-card">
                            <div className="row">
                              <div className="col-7">
                                <h2 className="fs-title mt-3 ml-3">
                                  Personal Information:
                                </h2>
                              </div>
                              <div className="col-5">
                                <h2 className="steps mr-3 mt-3">
                                  Step 1 - {steps}
                                </h2>
                              </div>
                            </div>
                            <div className="row ml-3 mr-3 mt-3">
                              <div className="form-group col-4">
                                <label htmlFor="first_name">
                                  First Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                  placeholder="John"
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="middle_name">Middle Name</label>
                                <Field
                                  type="text"
                                  name="middle_name"
                                  className="form-control"
                                  placeholder="Michael"
                                />
                                <ErrorMessage
                                  name="middle_name"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="last_name">
                                  Last Name <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                  placeholder="Doe"
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="date_of_birth">
                                  Date of Birth{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="date_of_birth"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="date_of_birth"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="gender">
                                  Gender <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="gender"
                                  className="form-control"
                                >
                                  <option value="">Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </Field>
                                <ErrorMessage
                                  name="gender"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="blood_group">Blood Group</label>
                                <Field
                                  type="text"
                                  name="blood_group"
                                  className="form-control"
                                  placeholder="O+"
                                />
                                <ErrorMessage
                                  name="blood_group"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="religion">Religion</label>
                                <Field
                                  type="text"
                                  name="religion"
                                  className="form-control"
                                  placeholder="Christianity"
                                />
                                <ErrorMessage
                                  name="religion"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="cast">Caste</label>
                                <Field
                                  type="text"
                                  name="cast"
                                  className="form-control"
                                  placeholder="General"
                                />
                                <ErrorMessage
                                  name="cast"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    )}
                    {current === 2 && (
                      <div
                        className="form-step"
                        style={{
                          border: "2px solid #007bff",
                          borderRadius: "8px",
                        }}
                      >
                        <fieldset>
                          <div className="form-card">
                            <div className="row">
                              <div className="col-7">
                                <h2 className="fs-title mt-3 ml-3">
                                  Education and Occupation:
                                </h2>
                              </div>
                              <div className="col-5">
                                <h2 className="steps mr-3 mt-3">
                                  Step 3 - {steps}
                                </h2>
                              </div>
                            </div>
                            <div className="row ml-3 mr-3 mt-3">
                              <div className="form-group col-4">
                                <label htmlFor="aadhaar_card_no">
                                  Aadhaar No{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="aadhaar_card_no"
                                  className="form-control"
                                  placeholder="1234-5678-9012"
                                />
                                <ErrorMessage
                                  name="aadhaar_card_no"
                                  component="div"
                                  className="text-error"
                                />
                              </div>

                              <div className="form-group col-4">
                                <label htmlFor="pan_card_no">
                                  PAN Card <span className="mandatory">*</span>
                                </label>

                                <Field name="pan_card_no">
                                  {({ field, form }) => (
                                    <input
                                      type="text"
                                      {...field}
                                      className="form-control"
                                      placeholder="AABCT1234E"
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        // Set the value as uppercase
                                        form.setFieldValue(
                                          "pan_card_no",
                                          value.toUpperCase()
                                        );
                                      }}
                                    />
                                  )}
                                </Field>

                                <ErrorMessage
                                  name="pan_card_no"
                                  component="div"
                                  className="text-error"
                                />
                              </div>

                              <div className="form-group col-4">
                                <label htmlFor="education">
                                  Education <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="education"
                                  className="form-control"
                                  placeholder="Select Education"
                                >
                                  <option value="" label="Select Education" />
                                  {qualificationData.map((qualification) => (
                                    <option
                                      key={qualification.id}
                                      value={qualification.id}
                                    >
                                      {qualification.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="education"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="occupation">
                                  Occupation{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="occupation"
                                  className="form-control"
                                  placeholder="select occupation"
                                >
                                  <option value="" label="Select Occupation" />
                                  {occupationData.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="occupation"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="annual_income">
                                  Annual Income{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="annual_income"
                                  className="form-control"
                                  placeholder="$50,000"
                                />
                                <ErrorMessage
                                  name="annual_income"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    )}
                    {current === 3 && (
                      <div
                        className="form-step"
                        style={{
                          border: "2px solid #007bff",
                          borderRadius: "8px",
                        }}
                      >
                        <fieldset>
                          <div className="form-card">
                            <div className="row">
                              <div className="col-7">
                                <h2 className="fs-title mt-3 ml-3">
                                  Contact Information:
                                </h2>
                              </div>
                              <div className="col-5">
                                <h2 className="steps mr-3 mt-3">
                                  Step 2 - {steps}
                                </h2>
                              </div>
                            </div>
                            <div className="row ml-3 mr-3 mt-3">
                              <div className="form-group col-3">
                                <label htmlFor="phone">
                                  Phone <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="phone"
                                  className="form-control"
                                  placeholder="1234567890"
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 10);
                                  }}
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-3">
                                <label htmlFor="email">
                                  Email <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="email@example.com"
                                  disabled={true}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-3">
                                <label htmlFor="alt_phone">
                                  Alternate Phone{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="alt_phone"
                                  className="form-control"
                                  placeholder="0987654321"
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 10);
                                  }}
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="alt_phone"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-3">
                                <label htmlFor="alt_email">
                                  Alternate Email{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="email"
                                  name="alt_email"
                                  className="form-control"
                                  placeholder="email@example.com"
                                />
                                <ErrorMessage
                                  name="alt_email"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-12">
                                <label htmlFor="tmp_address">
                                  Temporary Address{" "}
                                </label>
                                <Field
                                  as="textarea"
                                  name="tmp_address"
                                  className="form-control"
                                  placeholder="456 Secondary St"
                                />
                                <ErrorMessage
                                  name="tmp_address"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="temp_city">
                                  Temporary City{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="temp_city"
                                  className="form-control"
                                  placeholder="Shelbyville"
                                />
                                <ErrorMessage
                                  name="temp_city"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="temp_state">
                                  Temporary State{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="temp_state"
                                  className="form-control"
                                  placeholder="Indiana"
                                />
                                <ErrorMessage
                                  name="temp_state"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="temp_country">
                                  Temporary Country{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="temp_country"
                                  className="form-control"
                                  placeholder="USA"
                                />
                                <ErrorMessage
                                  name="temp_country"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="temp_pin">
                                  Temporary PIN Code{" "}
                                </label>
                                <Field
                                  type="number"
                                  name="temp_pin"
                                  className="form-control"
                                  placeholder="46077"
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 6);
                                  }}
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="temp_pin"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <div className="d-flex align-items-center">
                                  <Field
                                    type="checkbox"
                                    id="copy_address"
                                    name="copy_address"
                                    className="form-control mt-2 ml-0"
                                    style={{ width: "21px", height: "21px" }}
                                  />
                                  <label
                                    htmlFor="copy_address"
                                    className="form-check-label ml-2 mt-1"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Copy temporary address to main address
                                  </label>
                                </div>
                              </div>
                              <div className="form-group col-12">
                                <label htmlFor="address">
                                  Address <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  name="address"
                                  className="form-control"
                                  placeholder="123 Main St"
                                />
                                <ErrorMessage
                                  name="address"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="city">
                                  City <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  placeholder="Springfield"
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="state">
                                  State <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="state"
                                  className="form-control"
                                  placeholder="Illinois"
                                />
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="country">
                                  Country <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="country"
                                  className="form-control"
                                  placeholder="USA"
                                />
                                <ErrorMessage
                                  name="country"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-6">
                                <label htmlFor="pin">
                                  PIN Code <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="pin"
                                  className="form-control"
                                  placeholder="62704"
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 6);
                                  }}
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="pin"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    )}
                    {current === 4 && (
                      <div
                        className="form-step"
                        style={{
                          border: "2px solid #007bff",
                          borderRadius: "8px",
                        }}
                      >
                        <fieldset>
                          <div className="form-card">
                            <div className="row">
                              <div className="col-7">
                                <h2 className="fs-title mt-3 ml-3">
                                  Additional Information:
                                </h2>
                              </div>
                              <div className="col-5">
                                <h2 className="steps mr-3 mt-3">
                                  Step 4 - {steps}
                                </h2>
                              </div>
                            </div>
                            <div className="row ml-3 mr-3 mt-3">
                              <div className="form-group col-4">
                                <label htmlFor="mother_name">
                                  Mother’s Name{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="mother_name"
                                  className="form-control"
                                  placeholder="Emily Johnson"
                                />
                                <ErrorMessage
                                  name="mother_name"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_phone">
                                  Mother’s Phone{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="mother_phone"
                                  className="form-control"
                                  placeholder="1234567890"
                                />
                                <ErrorMessage
                                  name="mother_phone"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_email">
                                  Mother’s Email{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="email"
                                  name="mother_email"
                                  className="form-control"
                                  placeholder="example@gmail.com"
                                />
                                <ErrorMessage
                                  name="mother_email"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_education">
                                  Education <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="mother_education"
                                  className="form-control"
                                  placeholder="Select Education"
                                >
                                  <option value="" label="Select Education" />
                                  {qualificationData.map((qualification) => (
                                    <option
                                      key={qualification.id}
                                      value={qualification.id}
                                    >
                                      {qualification.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="mother_education"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_occupation">
                                  Mother’s Occupation{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="mother_occupation"
                                  className="form-control"
                                  placeholder="select occupation"
                                >
                                  <option value="" label="Select Occupation" />
                                  {occupationData.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="mother_occupation"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_annual_income">
                                  Mother’s Annual Income{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="mother_annual_income"
                                  className="form-control"
                                  placeholder="$30,000"
                                />
                                <ErrorMessage
                                  name="mother_annual_income"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_aadhaar_no">
                                  Mother’s Aadhaar No{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="mother_aadhaar_no"
                                  className="form-control"
                                  placeholder="1234-5678-9012"
                                />
                                <ErrorMessage
                                  name="mother_aadhaar_no"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_pan_card">
                                  Mother’s PAN Card{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="mother_pan_card"
                                  className="form-control"
                                  placeholder="AABCT1234E"
                                />
                                <ErrorMessage
                                  name="mother_pan_card"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="mother_dob">
                                  Mother’s Date of Birth{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="mother_dob"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="mother_dob"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                              <div class="form-group col-4">
                                <label htmlFor="image">
                                  Parent Image{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="input-group">
                                  <div className="custom-file">
                                    <Field name="image">
                                      {({ field }) => (
                                        <input
                                          type="file"
                                          className="custom-file-input"
                                          placeholder="Select File"
                                          id="image"
                                          onChange={(event) => {
                                            const file =
                                              event.currentTarget.files[0];
                                            setFieldValue("image", file);
                                            setFileName(
                                              file ? file.name : "Choose file"
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <label
                                      className="custom-file-label"
                                      htmlFor="image"
                                    >
                                      {fileName}
                                    </label>
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="image"
                                  component="div"
                                  className="text-error"
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    )}

                    <div className="form-navigation">
                      {current > 1 && (
                        <button
                          type="button"
                          className="previous action-button-previous"
                          onClick={prevStep}
                        >
                          Previous
                        </button>
                      )}
                      {current < steps && (
                        <button
                          type="button"
                          className="next action-button"
                          onClick={() => setCurrent(current + 1)}
                        >
                          Next
                        </button>
                      )}
                      {current === steps && (
                        <button type="submit" className="next action-button">
                          Submit
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentEdit;
