import React, { useState, useEffect } from "react";
import { getClassByBranchId } from "../../Services/AdminApis/ScheduleApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { getsubjectsbybranchIdandClassid, editexamconfig } from "../../Services/CommonApis/ReportcardApi";
import { getAllexamlocks, getAllexamtypes } from "../../Services/CommonApis/examreportloockApi";
import ReportcardConfiguration from "../AdminPages/Reportcardconfiguration";
import { updateConfigById } from "../../Services/CommonApis/ReportcardApi";


import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import KeyNavigationHandler from "../../Utility/CommonUtility/HandleKeyNavigation";

const EditExamConfig = ({ examConfigData, ExamConfigId, onBack }) => {
  console.log(ExamConfigId)

  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const { branchId, examId } = useParams();
  const [selectedClassId, setSelectedClassId] = useState("");
  const [topperVisibleStatus, setTopperVisibleStatus] = useState("1");
  const [rankVisibilityStatus, setRankVisibilityStatus] = useState("1");
  const [apiMessage, setApiMessage] = useState("");
  const [navigationMode, setNavigationMode] = useState("horizontal");
  const [isGrade, setIsGrade] = useState("no");
  const [grade, setGrade] = useState("1");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [sectionType, setSectionType] = useState("1");
  const [examlock, setExamlock] = useState([]);
  const [examtypes, setExamtypes] = useState([]);
  const examIdd = examConfigData?.exam_id || '';


  const initialFormData = {
    class_id: "",
    section_id: [],
    isgrade: "",
    entergrade: "",
    subjectOptions: [],
    exam_id: "",
    lock_report: "",
    max_marks: "",
    pass_marks: "",
    topper_visible: "",
    rank_visible: "",
    sequence: "",
    is_average: "",
    add_in_grand: "",
    internal: "",
    external: ""
  };

  useEffect(() => {
    if (ExamConfigId) {
      handleEdit(ExamConfigId)
    }
  }, [ExamConfigId])

  const handleEdit = async (ExamConfigId) => {
    const response = await editexamconfig(ExamConfigId);

    setFormData(response.data.data)
  };
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);


  useEffect(() => {
    if (examId) {
      fetchExamConfig(examId);
    }
  }, [examId]);

  useEffect(() => {
    fetchexamlocks();
    fetchexamtypes();
  }, []);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchExamConfig = async (id) => {
    try {
      const response = await editexamconfig(id);
      const data = response.data;
      console.log("Fetched Exam Config Data:", data);

      setRankVisibilityStatus(data.rank_visible.toString());
      const topperVisible = data.topper_visible !== undefined ? data.topper_visible.toString() : "";




      setFormData({
        class_id: data.class_id,
        section_id: data.section_id,
        isgrade: data.is_grade,
        entergrade: data.enter_grade,
        subjectOptions: data.subjects.map((subject) => ({
          subject_id: subject.subject_id,
          maxMark: subject.max_marks,
          passMark: subject.pass_marks,
          type: subject.type,
        })),
        exam_id: data.exam_id,
        lock_report: data.lock_report,
        max_marks: data.max_marks,
        pass_marks: data.pass_marks,
        topper_visible: topperVisible,
        rank_visible: data.rank_visible ? data.rank_visible.toString() : "",
        sequence: data.sequence || "",
        is_average: data.is_average,
        add_in_grand: data.add_in_grand,
        internal: data.internal,
        external: data.external,
      });


      setSelectedClassId(data.class_id);
      setNavigationMode(data.navigationMode || "horizontal");
      setSectionType(data.sectionType || "0");

      const sectionsResponse = await getSectionByClassId(data.class_id);
      const sections = sectionsResponse.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching exam configuration:", error);
    }
  };

  const fetchexamlocks = async () => {
    try {
      const response = await getAllexamlocks();
      setExamlock(response.data.data);
    } catch (error) {
      console.log("Error fetching exam locks:", error);
    }
  };

  const fetchexamtypes = async () => {
    try {
      const response = await getAllexamtypes();
      setExamtypes(response.data.data);
    } catch (error) {
      console.log("Error fetching exam types:", error);
    }
  };

  const handleClassChange = async (classId) => {
    setSelectedClassId(classId);
    try {
      const response = await getSectionByClassId(classId);
      const sections = response.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };
  const sectionIddup = formData.section_id;
  const classIddup = formData.class_id;
  useEffect(() => {
    fetchSubjectsByBranchId(branchId, classIddup);

  }, [classIddup, branchId]);

  const fetchSubjectsByBranchId = async (branchId, classIddup) => {
    try {
      const response = await getsubjectsbybranchIdandClassid({
        branchId: branchId,
        classId: classIddup,

      })
      const subjects = response.data.branchsubject;
      setSubjectOptions(subjects);
    } catch (error) {
      console.log("Error fetching subjects:", error);
    }
  };

  const fetchSubjectsByBranchIdclassid = async (branchId, selectedClassId) => {
    try {

      const response = await getsubjectsbybranchIdandClassid({
        branchId: branchId,
        classId: selectedClassId,
      });
      const subjects = response.data.branchsubject;
      setSubjectOptions(subjects);


      setFormData((prev) => ({ ...prev, subjectOptions: subjects }));

    } catch (error) {

      console.error("Error fetching subjects:", error);
    }
  };

  const handleMarksChange = (index, field, value) => {
    const updatedSubjects = [...subjectOptions];
    updatedSubjects[index][field] = value;
    setSubjectOptions(updatedSubjects);
    setFormData((prev) => ({ ...prev, subjectOptions: updatedSubjects }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
      const response = await updateConfigById(ExamConfigId, formData);
      if (response.status === 200) {
        showSuccessAlert("Exam configuration updated successfully!");
      } else {
        showErrorAlert("Error updating exam configuration.");
      }
    } catch (error) {
      console.error("Error updating exam configuration:", error);
      showErrorAlert("Failed to update exam configuration.");
    }
  }

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Report Card Configuration</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">Report Card Configuration

          </h3>
          <button
            type="button"
            onClick={() => onBack(classIddup, sectionIddup, examIdd)}
            className="btn btn-primary "
            style={{ marginLeft: "55em" }}
          >
            Back
          </button>

        </div>
        <div className="card-body">
          <form onSubmit={handleFormSubmit}>
            <div className="row ml-2 mr-5 mt-3">



              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="class_id">Class *</label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      classOptions.find((option) => option.value === formData.class_id)?.label || ""
                    }
                    readOnly
                    style={{ backgroundColor: "white", cursor: "not-allowed" }}
                  />
                </div>

              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="lock_report">Exam lock *</label>
                  <select
                    name="lock_report"
                    className="form-control"
                    value={formData.lock_report}
                    onChange={(e) => {
                      const lock_reportId = e.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        lock_report: lock_reportId,
                      }));
                    }}
                  >
                    <option value="">Select Exam Lock</option>
                    {examlock.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="exam_">Exam Type *</label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      examtypes.find((option) => option.id === formData.exam_id)?.name || ""
                    }
                    readOnly
                    style={{ backgroundColor: "white", cursor: "not-allowed" }}
                  />
                </div>

              </div>


              <div className="form-group col-2 ml-2">
                <label htmlFor="topperVisibleStatus">Topper Visible Status</label>
                <div className="d-flex mt-2">

                  <div className="form-check mr-2">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="topper_visible_yes"
                      name="topper_visible"
                      value="1"
                      checked={String(formData.topper_visible) === "1"}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          topper_visible: parseInt(e.target.value, 10),
                        }))
                      }
                    />
                    <label className="form-check-label ml-1" htmlFor="topper_visible_yes">
                      Yes
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="topper_visible_no"
                      name="topper_visible"
                      value="0"
                      checked={String(formData.topper_visible) === "0"}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          topper_visible: parseInt(e.target.value, 10),
                        }))
                      }
                    />
                    <label className="form-check-label ml-1" htmlFor="topper_visible_no">
                      No
                    </label>
                  </div>
                </div>
              </div>


              <div className="form-group col-2 ml-2">
                <label htmlFor="rankVisibleStatus">Rank Visible Status</label>
                <div className="d-flex mt-2">

                  <div className="form-check mr-2">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="rank_visible_yes"
                      name="rank_visible"
                      value="1"
                      checked={String(formData.rank_visible) === "1"}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          rank_visible: parseInt(e.target.value, 10),
                        }))
                      }
                    />
                    <label className="form-check-label ml-1" htmlFor="rank_visible_yes">
                      Yes
                    </label>
                  </div>


                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="rank_visible_no"
                      name="rank_visible"
                      value="0"
                      checked={String(formData.rank_visible) === "0"}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          rank_visible: parseInt(e.target.value, 10),
                        }))
                      }
                    />
                    <label className="form-check-label ml-1" htmlFor="rank_visible_no">
                      No
                    </label>
                  </div>


                </div>
              </div>

              <div className="form-group">
                <label>Navigation Mode</label>
                <div className="mt-2">
                  <label className="mr-2">
                    <input
                      className="mr-2"
                      type="radio"
                      name="navigationMode"
                      value="horizontal"
                      checked={navigationMode === "horizontal"}
                      readOnly
                    />
                    Horizontal
                  </label>
                  <label className="mr-2">
                    <input
                      className="mr-2"
                      type="radio"
                      name="navigationMode"
                      value="vertical"
                      checked={navigationMode === "vertical"}
                      readOnly
                    />
                    Vertical
                  </label>
                </div>

              </div>
              <div className="form-group col-2 ml-2">
                <label htmlFor="section_type">Apply to all Section</label>
                <div className="d-flex mt-2">
                  <div className="form-check mr-2">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="sectiontype_yes"
                      name="section_type"
                      value="1"
                      checked={sectionType === "1"}
                      readOnly
                    />
                    <label className="form-check-label ml-1" htmlFor="sectiontype_yes">
                      Yes
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="sectiontype_no"
                      name="section_type"
                      value="0"
                      checked={sectionType === "0"}
                      readOnly
                    />
                    <label className="form-check-label ml-1" htmlFor="sectiontype_no">
                      No
                    </label>
                  </div>
                </div>
              </div>


              {sectionType === "0" && (
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="section_id">Section *</label>
                    <Select
                      name="section_id"
                      options={sectionOptions}
                      value={sectionOptions.find(
                        (option) => formData.section_id === option.value
                      )}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                          ? selectedOption.value
                          : null;
                        setFormData((prev) => ({
                          ...prev,
                          section_id: selectedValue,
                        }));
                        setSelectedSectionId(selectedValue);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="row">

              <form>
                <DragDropContext >
                  <Droppable droppableId="mainSubjects">
                    {(provided) => (
                      <div className="mt-3">
                        <h5 className="ml-2">Main Subjects</h5>
                        <table
                          className="table table-bordered mt-4 ml-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <thead>
                            <tr>
                              <th>S No</th>
                              <th style={{ width: "200px" }}>Subject</th>
                              <th>Enter as Grade</th>
                              <th>Internal</th>
                              <th>External</th>
                              <th>Max Marks</th>
                              <th>Pass Marks</th>
                              <th>Is Average</th>
                              <th>Add in Grand Total</th>
                              <th>Is Grade</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>


                              <td>
                                <div
                                  className="form-control"
                                  style={{
                                    borderColor: "skyblue",
                                    backgroundColor: "white",
                                    cursor: "not-allowed"

                                  }}
                                >
                                  {formData.subject_id
                                    ? subjectOptions.find(
                                      (subject) => subject.subject_id === formData.subject_id
                                    )?.subject_name || "Select Subject"
                                    : "Select Subject"}
                                </div>


                                <input
                                  type="hidden"
                                  name="subject_id"
                                  value={formData.subject_id}
                                />
                              </td>



                              <td>
                                <input
                                  type="checkbox"
                                  name="enterAsGrade"
                                  checked={formData.enterAsGrade || false}
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      enterAsGrade: e.target.checked,
                                    }))
                                  }
                                />
                              </td>


                              <td>
                                <input
                                  type="number"
                                  name="internal"
                                  value={formData.internal || ""}
                                  onChange={(e) =>
                                    setFormData({ ...formData, internal: e.target.value })
                                  }
                                  className="form-control"
                                  id="internal"
                                  placeholder="Internal Marks"
                                  style={{ borderColor: "skyblue" }}
                                />
                              </td>


                              <td>
                                <input
                                  type="number"
                                  name="external"
                                  value={formData.external || ""}
                                  onChange={(e) =>
                                    setFormData({ ...formData, external: e.target.value })
                                  }
                                  className="form-control"
                                  id="external"
                                  placeholder="External Marks"
                                  style={{ borderColor: "skyblue" }}
                                />
                              </td>


                              <td>
                                <input
                                  type="number"
                                  name="max_marks"
                                  value={formData.max_marks || ""}
                                  onChange={(e) =>
                                    setFormData({ ...formData, max_marks: e.target.value })
                                  }
                                  className="form-control"
                                  id="max_marks"
                                  placeholder="Max Marks"
                                  style={{ borderColor: "skyblue" }}
                                />
                                {formData.max_marks === "" && (
                                  <div className="text-danger">Max Marks is required</div>
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  name="pass_marks"
                                  value={formData.pass_marks || ""}
                                  onChange={(e) =>
                                    setFormData({ ...formData, pass_marks: e.target.value })
                                  }
                                  className="form-control"
                                  id="pass_marks"
                                  placeholder="Pass Marks"
                                  style={{ borderColor: "skyblue" }}
                                />
                                {formData.pass_marks === "" && (
                                  <div className="text-danger">Pass Marks is required</div>
                                )}
                              </td>

                              <td>
                                <input
                                  type="checkbox"
                                  name="isAverage"
                                  checked={formData.is_average === "1"}
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      is_average: e.target.checked ? "1" : "0",
                                    }))
                                  }
                                />
                              </td>


                              <td>
                                <input
                                  type="checkbox"
                                  name="addInGrandTotal"
                                  checked={formData.add_in_grand === "1"}
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      add_in_grand: e.target.checked ? "1" : "0",
                                    }))
                                  }
                                />
                              </td>


                              <td>
                                <input
                                  type="checkbox"
                                  name="is_grade"
                                  checked={formData.is_grade === 1}
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      is_grade: e.target.checked ? 1 : 0,
                                    }))
                                  }
                                />
                              </td>

                            </tr>
                            {provided.placeholder}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </form>

            </div>
            <button type="submit" className="btn btn-primary mt-3 ml-3">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditExamConfig;
