import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { useParams } from "react-router-dom";
import { getAllSubjects } from "../../Services/CommonApis/subjectApi";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {
  createSubject,
  createBranchSubject,
} from "../../Services/CommonApis/subjectApi";
import { FieldArray } from "formik";
import { getsectionsbyclassids } from "../../Services/CommonApis/SectionApi";
import { components } from "react-select";

import BeatLoader from "react-spinners/BeatLoader";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

function BranchSubjectCreate() {
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classId, setClassId] = useState([]);
  const [newSubject, setNewSubject] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [loading, setLoading] = useState(true);

  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchAllSubjects();
  }, [branchId]);

  useEffect(() => {
    if (classId) {
      fetchSectionByClassId(classId);
    }
  }, [classId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      setClassLoading(true);
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setClassLoading(false);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      setSectionLoading(true);
      const response = await getsectionsbyclassids({ class_id: classId });
      setSectionList(response.data.sections);
    } catch (error) {
      console.log(error);
    } finally {
      setSectionLoading(false);
    }
  };

  const fetchAllSubjects = async () => {
    try {
      const response = await getAllSubjects();
      setSubjects(
        response.data.data.map((subject) => ({
          value: subject.id,
          label: subject.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewSubject = async () => {
    if (newSubject) {
      try {
        const response = await createSubject({ name: newSubject });
        const newSubjectData = response.data;
        setSubjects((prevSubjects) => [
          ...prevSubjects,
          { value: newSubjectData.id, label: newSubjectData.name },
        ]);
        setNewSubject("");
        setShowModal(false);
        fetchAllSubjects();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const valuesWithBranchId = {
      ...values, // Spread the existing values (including section_id, etc.)
      branch_id: branchId, // Add the branch_id variable (make sure it's defined)
    };
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create branch subjects",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();

      try {
        const response = await createBranchSubject(valuesWithBranchId);
        if (response.status === 201) {
          showSuccessAlert(
            "Created!",
            "Branch subjects have been created successfully."
          );
          resetForm();

          // window.location.reload();
        } else {
          const errorMessage =
            response.data?.message ||
            "Failed to create branch subjects. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API error:", error);
        const errorMessage =
          error.response.data ||
          "An error occurred during branch subject creation.";
        showErrorAlert("Error!", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        "Branch subject creation has been cancelled."
      );
    }
  };
  const CustomMenuList = (props) => {
    const { options, setFieldValue, values, selectProps } = props;

    // Get the selected class IDs from Formik values
    const selectedClassIds = values.class_id || [];
    const isAllSelected = selectedClassIds.length === options.length;

    // Handle Select All logic
    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        // Select all class IDs
        const allClassValues = options.map((option) => option.value);
        setFieldValue("class_id", allClassValues);
        selectProps.onChange(options); // Update react-select internal state
      } else {
        // Deselect all classes
        setFieldValue("class_id", []);
        selectProps.onChange([]); // Clear the react-select internal state
      }
    };

    return (
      <components.MenuList {...props}>
        <div
          style={{
            padding: "6px",
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <p className="ml-3 mt-2">Select All</p>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const CustomOption = (props) => {
    const { isSelected, label, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          cursor: "pointer",
          backgroundColor: isSelected ? "lightgray" : "white",
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null} // Handled by react-select
          style={{ marginRight: "8px" }}
        />
        <p style={{ marginLeft: "5px", marginBottom: "1px", marginTop: "1px" }}>
          {label}
        </p>
      </div>
    );
  };
  const CustomMenuListSection = (props) => {
    const { options, setFieldValue, values, selectProps } = props;

    // Get the selected section IDs from Formik values
    const selectedSectionIds = values.section_id || [];
    const isAllSelected = selectedSectionIds.length === options.length;

    // Handle Select All logic for sections
    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        // Select all section IDs
        const allSectionValues = options.map((option) => option.value);
        setFieldValue("section_id", allSectionValues);
        selectProps.onChange(options); // Update react-select internal state
      } else {
        // Deselect all sections
        setFieldValue("section_id", []);
        selectProps.onChange([]); // Clear the react-select internal state
      }
    };

    return (
      <components.MenuList {...props}>
        <div
          style={{
            padding: "6px",
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <p className="ml-3 mt-2">Select All Sections</p>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const validationSchema = Yup.object().shape({
    class_id: Yup.array()
      .min(1, "Please select at least one class.")
      .required("Class is required."),
    section_id: Yup.array()
      .min(1, "Please select at least one section.")
      .required("Section is required."),
    subjects: Yup.array()
      .of(
        Yup.object().shape({
          subject_type: Yup.string().required("Subject type is required."),
          subject_id: Yup.string().required("Subject name is required."),
          subject_label: Yup.string()
            .min(3, "Report card name must be at least 3 characters.")
            .required("Report card name is required."),
          subject_code: Yup.string()
            .matches(/^[A-Z]{3}$/, "Subject code must be 3 uppercase letters.")
            .required("Subject code is required."),
        })
      )
      .required("At least one subject is required."),
  });

  const subjectOptions = [
    { value: "add-new", label: "Add New Subject", isAddNew: true },
    ...subjects,
  ];
  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <BeatLoader size={15} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
                Subject Creation <small> Form</small>
              </h3>
            </div>
            <Formik
              initialValues={{
                class_id: "",
                section_id: "",
                subjects: [
                  {
                    subject_type: "",
                    subject_id: "",
                    subject_label: "",
                    exam_type: "",
                    subject_code: "",
                  },
                ],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="card-body p-2">
                    <div className="card p-4">
                      <div className="row">
                        <div className="form-group col-4">
                          <label htmlFor="class_id">
                            Class Name <span className="mandatory">*</span>
                          </label>
                          <Select
                            id="class_id"
                            name="class_id"
                            options={classList.map((cls) => ({
                              value: cls.id,
                              label: cls.name,
                            }))}
                            onChange={(selected) => {
                              const selectedClassIds = selected.map(
                                (option) => option.value
                              );
                              setFieldValue("class_id", selectedClassIds); // Assuming class_id is an array
                              setClassId(selectedClassIds);
                            }}
                            isLoading={isClassLoading} // Show loading state
                            isMulti // Allow multiple selections
                            placeholder="Select Class"
                            classNamePrefix="react-select" // For custom styling
                            isClearable // Allow clearing the selection
                            components={{
                              MenuList: (props) => (
                                <CustomMenuList
                                  {...props}
                                  setFieldValue={setFieldValue}
                                  values={values} // Pass Formik values
                                />
                              ),
                              Option: CustomOption, // Custom option component
                            }}
                          />
                          <ErrorMessage
                            name="class_id"
                            component="div"
                            className="text-error"
                          />
                        </div>

                        <div className="form-group col-4">
                          <label htmlFor="section_id">
                            Section Name <span className="mandatory">*</span>
                          </label>
                          <Select
                            id="section_id"
                            name="section_id"
                            options={sectionList.map((section) => ({
                              value: section.id,
                              label: `${section.class_name} - ${section.name}`,
                            }))}
                            onChange={(selected) => {
                              const selectedSectionIds = selected.map(
                                (option) => option.value
                              );
                              setFieldValue("section_id", selectedSectionIds); // Assuming section_id is an array
                            }}
                            isLoading={isSectionLoading} // Set loading state
                            isMulti // Allow multiple selections
                            placeholder="Select Section"
                            classNamePrefix="react-select" // Optional: for custom styles
                            isClearable // Optional: allow clearing the selection
                            components={{
                              MenuList: (props) => (
                                <CustomMenuListSection
                                  {...props}
                                  setFieldValue={setFieldValue}
                                  values={values} // Pass Formik values
                                />
                              ),
                              Option: CustomOption, // Reuse the CustomOption component from the class dropdown
                            }}
                          />
                          <ErrorMessage
                            name="section_id"
                            component="div"
                            className="text-error"
                          />
                        </div>
                      </div>
                      <FieldArray name="subjects">
                        {({ insert, remove, push }) => (
                          <>
                            {values.subjects && values.subjects.length > 0
                              ? values.subjects.map((subject, index) => (
                                  <div className="card p-4 w-100 mb-4">
                                    <div className="row" key={index}>
                                      <div className="form-group col-3">
                                        <label
                                          htmlFor={`subjects.${index}.subject_type`}
                                        >
                                          Subject Type
                                        </label>
                                        <Field
                                          as="select"
                                          name={`subjects.${index}.subject_type`}
                                          className="form-control"
                                        >
                                          <option value="">Select Type</option>
                                          <option value="main">
                                            Main Subject
                                          </option>
                                          <option value="optional">
                                            Optional Subject
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          name={`subjects.${index}.subject_type`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>

                                      <div className="form-group col-3">
                                        <label
                                          htmlFor={`subjects.${index}.subject_id`}
                                        >
                                          Subject Name
                                        </label>
                                        <Select
                                          value={
                                            subjectOptions.find(
                                              (option) =>
                                                option.value ===
                                                subject.subject_id
                                            ) || null
                                          }
                                          options={subjectOptions}
                                          onChange={(selected) => {
                                            if (selected.value === "add-new") {
                                              setShowModal(true);
                                              return;
                                            }
                                            setFieldValue(
                                              `subjects.${index}.subject_id`,
                                              selected.value
                                            ); // Set only the value part
                                            setSelectedSubject(selected);

                                            const subjectShortName =
                                              selected.label
                                                .substring(0, 3)
                                                .toUpperCase();

                                            setFieldValue(
                                              `subjects.${index}.subject_code`,
                                              subjectShortName
                                            );
                                          }}
                                          getOptionLabel={(option) =>
                                            option.isAddNew ? (
                                              <div className="custom-dropdown-item">
                                                <FaPlus
                                                  style={{ marginRight: 8 }}
                                                />
                                                {option.label}
                                              </div>
                                            ) : (
                                              option.label
                                            )
                                          }
                                          getOptionValue={(option) =>
                                            option.value
                                          }
                                          placeholder="Select Subject"
                                          styles={{
                                            container: (provided) => ({
                                              ...provided,
                                              width: "100%",
                                            }),
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                            }),
                                            valueContainer: (provided) => ({
                                              ...provided,
                                              height: "auto",
                                              minHeight: "38px",
                                              overflowY: "auto",
                                            }),
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`subjects.${index}.subject_id`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-2">
                                        <label
                                          htmlFor={`subjects.${index}.subject_code`}
                                        >
                                          Subject code
                                        </label>
                                        <Field
                                          type="text"
                                          name={`subjects.${index}.subject_code`}
                                          className="form-control"
                                          placeholder="Subject code"
                                        />
                                        <ErrorMessage
                                          name={`subjects.${index}.subject_code`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>

                                      <div className="form-group col-3">
                                        <label
                                          htmlFor={`subjects.${index}.subject_label`}
                                        >
                                          Report Card Name
                                        </label>
                                        <Field
                                          type="text"
                                          name={`subjects.${index}.subject_label`}
                                          className="form-control"
                                          placeholder="Enter report card name"
                                        />
                                        <ErrorMessage
                                          name={`subjects.${index}.subject_label`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>

                                      <div className="form-group col-1 mt-4">
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => remove(index)}
                                        >
                                          -
                                        </button>
                                        {index ===
                                          values.subjects.length - 1 && (
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "10px",
                                            }}
                                            onClick={() =>
                                              push({
                                                class_id: "",
                                                section_id: "",
                                                subject_type: "",
                                                subject_id: "",
                                                subject_label: "",
                                                is_grade: "",
                                                enter_as_grade: "",
                                                add_grand: "",
                                                apply_to_all: "",
                                              })
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null}
                          </>
                        )}
                      </FieldArray>

                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary ml-5 mt-4"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Subject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <label htmlFor="new-subject">Subject Name</label>
                  <input
                    type="text"
                    id="new-subject"
                    value={newSubject}
                    onChange={(e) => setNewSubject(e.target.value)}
                    placeholder="New Subject Name"
                    className="form-control"
                  />
                </div>
                <div style={{ marginLeft: "8px", marginTop: "30px" }}>
                  <Tooltip title="Need Approval" placement="left">
                    <IconButton style={{ padding: 0 }}>
                      <InfoIcon style={{ color: "blue" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddNewSubject}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default BranchSubjectCreate;
