import React from "react";
import axios from "axios";

const DownloadFeePdf = () => {
  const handleDownload = async (id) => {
    try {
      const response = await axios.get(
        `http://localhost:8000/api/generate-fee-pdf`,
        {
          params: {
            student_id: 1,  // Replace with dynamic student ID if needed
            branch_id: 1,   // Replace with dynamic branch ID if needed
          },
          responseType: "blob" // Ensure this is part of the second parameter object
        }
      );
  
      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileUrl = window.URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", `fee_invoice_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  

  return <button onClick={() => handleDownload(1)}>Download Fee PDF</button>;
};

export default DownloadFeePdf;
