import React, { useState, useEffect } from "react";
import { getSchools } from "../../Services/AdminApis/schoolApi";

function SchoolGrid() {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      const response = await getSchools();
      setSchools(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="card-body">
        <div className="row">
          {schools.map((school) => (
            <div
              key={school.id}
              className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column"
            >
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  {school.school_code}
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>{school.name}</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Address: </b> {school.address}
                      </p>
                      <ul className="ml-4 mb-0 fa-ul text-muted">
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-building"></i>
                          </span>{" "}
                          City:{" "}
                          {`${school.dist}, ${school.city}, ${school.state}`}
                        </li>
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-phone"></i>
                          </span>{" "}
                          Pin #: {school.pin}
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 text-center">
                      <img
                        src="/plugins/dist/img/user2-160x160.jpg"
                        alt="user-avatar"
                        className="img-circle img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <a href="#" class="btn btn-sm bg-teal">
                      <i class="fas fa-edit"></i>Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-primary ml-2">
                      <i className="fas fa-user"></i> View Profile
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SchoolGrid;
